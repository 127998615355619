/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export { SHOW_POPUP, showPopup } from 'SourceStore/Popup/Popup.action';

export const CLEAR_POPUP_STATE = 'CLEAR_POPUP_STATE';

/** @namespace Scandipwa/Store/Popup/Action/clearPopupState */
export const clearPopupState = () => ({
    type: CLEAR_POPUP_STATE
});
