/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { createRef } from 'react';

import ClickOutside from 'Component/ClickOutside';
import TextPlaceholder from 'Component/TextPlaceholder';
import SourceExpandableContent from 'SourceComponent/ExpandableContent/ExpandableContent.component';

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        ...SourceExpandableContent.propTypes,
        isNoContentDisplay: PropTypes.bool,
        hasClickOutside: PropTypes.bool
    };

    static defaultProps = {
        ...SourceExpandableContent.defaultProps,
        isNoContentDisplay: false,
        hasClickOutside: false,
        subHeading: ''
    };

    sizeFiltersRef = createRef();

    componentDidUpdate() {
        const { sizeFiltersHeight, setSizeFiltersHeight, attributeCode } = this.props;

        if (
            attributeCode === 'size'
            && this.sizeFiltersRef.current
            && this.sizeFiltersRef.current.clientHeight !== sizeFiltersHeight
        ) {
            setSizeFiltersHeight(this.sizeFiltersRef.current.clientHeight);
        }
    }

    renderContentChildren() {
        const { children, isNoContentDisplay } = this.props;
        const { isContentExpanded } = this.state;

        if (isNoContentDisplay && !isContentExpanded) {
            return null;
        }

        return children;
    }

    renderContent() {
        const { mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              ref={ this.expandableContentRef }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { this.renderContentChildren() }
            </div>
        );
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            heading,
            subHeading,
            mix
        } = this.props;

        return (
            <button
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
            >
                <span
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading.replace('_', ' ') } length="medium" />
                    ) : (
                        heading.replace('_', ' ')
                    ) }
                </span>
                <span
                  block="ExpandableContent"
                  elem="SubHeading"
                  mix={ { ...mix, elem: 'ExpandableContentSubHeading' } }
                >
                    { subHeading.replace('_', ' ') }
                </span>
            </button>
        );
    }

    forceCollapse = () => {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            this.setState(
                ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            );
        }
    };

    render() {
        const { mix, attributeCode, hasClickOutside } = this.props;

        if (attributeCode === 'size') {
            if (hasClickOutside) {
                return (
                    <ClickOutside onClick={ this.forceCollapse }>
                        <article
                          block="ExpandableContent"
                          mix={ mix }
                          ref={ this.sizeFiltersRef }
                        >
                            { this.renderButton() }
                            { this.renderContent() }
                        </article>
                    </ClickOutside>
                );
            }

            return (
                <article
                  block="ExpandableContent"
                  mix={ mix }
                  ref={ this.sizeFiltersRef }
                >
                    { this.renderButton() }
                    { this.renderContent() }
                </article>
            );
        }

        if (hasClickOutside) {
            return (
                <ClickOutside onClick={ this.forceCollapse }>
                    <article
                      block="ExpandableContent"
                      mix={ mix }
                    >
                        { this.renderButton() }
                        { this.renderContent() }
                    </article>
                </ClickOutside>
            );
        }

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;
