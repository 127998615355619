/** @namespace Scandipwa/Util/PromoBanner/toggleStickyClass */
export const toggleStickyClass = (isSticky) => {
    if (isSticky) {
        document.documentElement.classList.add('sticky-ios');
    } else {
        document.documentElement.classList.remove('sticky-ios');
    }
};

/** @namespace Scandipwa/Util/PromoBanner/getBanner */
export const getBanner = () => {
    const bannerList = document.getElementById('global-promo-banner-list');
    const bannerItem = document.querySelector('[id^=\'global_promo_banner_\']');
    return bannerList || bannerItem;
};

/** @namespace Scandipwa/Util/PromoBanner/handleIosStickyHeader */
export const handleIosStickyHeader = () => {
    const banner = getBanner();
    if (!banner) {
        return;
    }

    const windowY = window.scrollY;

    const sticky = banner.offsetHeight;
    const isSticky = windowY < sticky;

    toggleStickyClass(isSticky);
};
