/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/no-did-update-set-state */
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';

/** @namespace Scandipwa/Component/FieldZip/Component */
export class FieldZipComponent extends PureComponent {
    static propTypes = {
        isRequired: PropTypes.bool,
        placeholder: PropTypes.string,
        validateZip: PropTypes.func.isRequired,
        isZipValid: PropTypes.bool.isRequired,
        invalidMessage: PropTypes.string.isRequired,
        minLength: PropTypes.bool.isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string,
        validateForm: PropTypes.bool,
        validation: PropTypes.array,
        message: PropTypes.string,
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        type: PropTypes.string
    };

    static defaultProps = {
        isRequired: false,
        placeholder: '',
        onChange: PropTypes.func,
        value: '',
        validateForm: false,
        validation: [],
        message: undefined,
        formRef: () => {},
        type: 'text'
    };

    state = {
        ...this.state,
        zip: '',
        showMessage: false,
        isTwoDigit: false,
        isFocused: false
    };

    componentDidMount() {
        const { value, minLength } = this.props;

        if (value.length >= minLength) {
            this.setState({ zip: value });
        }
    }

    componentDidUpdate() {
        const {
            validateForm,
            validateZip,
            validation,
            message
        } = this.props;

        const { zip } = this.state;

        if (validateForm || (validation.length > 0 && message)) {
            if (!validateZip(zip)) {
                this.setState({ showMessage: true });
            } else {
                this.setState({ showMessage: false });
            }
        }
    }

    handleInputChange = (e) => {
        const { target: { value: inputValue } } = e;
        const { validateZip, onChange } = this.props;

        const value = inputValue.toLocaleUpperCase().replaceAll('_', '');

        this.setState({ zip: value });
        validateZip(value);

        onChange(value);
    };

    handleInputBlur = () => {
        const { showMessage } = this.state;
        if (!showMessage) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    showMessage: true
                })
            );
        }

        this.setState({ isFocused: false });
    };

    handleFocus = () => {
        this.setState({ isFocused: true });
    };

    handleClick = (e) => {
        const { target } = e;
        const { zip } = this.state;
        target.selectionStart = zip.indexOf('_');
        target.selectionEnd = zip.indexOf('_');
    };

    maskBuilder = () => {
        const { minLength } = this.props;

        return '9'.repeat(minLength);
    };

    render() {
        const {
            placeholder,
            isRequired,
            isZipValid,
            invalidMessage,
            formRef,
            minLength,
            type
        } = this.props;

        const {
            zip,
            showMessage,
            isFocused
        } = this.state;

        return (
            <>
                <div block="FieldInput" elem="Wrapper">
                    <div
                      block="FieldInput"
                      elem="InputWrapper"
                      mods={ {
                          isInvalid: !isZipValid && showMessage && invalidMessage.length > 0,
                          isFilled: zip !== '',
                          isFocused
                      } }
                    >
                        <InputMask
                          mask={ this.maskBuilder() }
                          maskChar="_"
                          onChange={ this.handleInputChange }
                          value={ zip }
                          onBlur={ this.handleInputBlur }
                          onFocus={ this.handleFocus }
                          onClick={ this.handleClick }
                          name="postcode"
                          id="postcode"
                        >
                            { (validProps) => (
                                <input
                                  block="FieldInput"
                                  elem="Input"
                                  ref={ formRef }
                                  { ...validProps }
                                  name="postcode"
                                  id="postcode"
                                  minLength={ minLength }
                                  type={ type }
                                  dir="ltr"
                                />
                            ) }
                        </InputMask>
                        { placeholder
                        && (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label
                          block="FieldInput"
                          elem="Label"
                          mods={ { isRequired, isFilled: zip !== '', isFocused } }
                        >
                            { placeholder }
                        </label>
                        ) }
                    </div>
                </div>
                <span block="FieldInput" elem="Error">{ !isZipValid && showMessage ? invalidMessage : ' ' }</span>
            </>
        );
    }
}

export default FieldZipComponent;
