import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';

import {
    REGISTER_CONFIRM_EMAIL_ID,
    REGISTER_CONFIRM_PASSWORD_ID,
    REGISTER_EMAIL_ID,
    REGISTER_FIRSTNAME_ID,
    REGISTER_LASTNAME_ID,
    REGISTER_PASSWORD_ID
} from './MyAccountCreateAccount.config';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    locale: state.ConfigReducer.locale
});

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    static propTypes = {
        ...super.propTypes,
        isNoTitle: PropTypes.bool,
        isRegisterDisabled: PropTypes.bool,
        locale: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...super.defaultProps,
        isNoTitle: false
    };

    stateMap = {
        [REGISTER_FIRSTNAME_ID]: 'firstnameRegister',
        [REGISTER_LASTNAME_ID]: 'lastnameRegister',
        [REGISTER_EMAIL_ID]: 'emailRegister',
        [REGISTER_CONFIRM_EMAIL_ID]: 'emailMatchRegister',
        [REGISTER_PASSWORD_ID]: 'passwordRegister',
        [REGISTER_CONFIRM_PASSWORD_ID]: 'passwordMatchRegister'
    };

    handleDataChange(event, field) {
        const { onDataChange } = this.props;
        const { value = '', id } = field;
        const stateField = this.stateMap[id];
        const state = { [stateField]: value };

        onDataChange(state);
    }

    containerProps() {
        const {
            onDataChange,
            isNoTitle,
            isRegisterDisabled,
            locale
        } = this.props;

        return {
            ...super.containerProps(),
            onDataChange,
            isNoTitle,
            isRegisterDisabled,
            locale
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        handleDataChange: this.handleDataChange.bind(this)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
