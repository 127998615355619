/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { FIELD_TYPE } from 'Component/Field/Field.config';
import {
    FieldFile as SourceFieldFile
} from 'SourceComponent/FieldFile/FieldFile.component';

import './FieldFile.styles.scss';

/** @namespace Scandipwa/Component/FieldFile/Component */
export class FieldFileComponent extends SourceFieldFile {
    renderLabel() {
        const { attr: { required } } = this.props;
        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor="input-file">
                    { __('Membership Card Image') }
                    { required && <span block="Field" elem="Required">*</span> }
                </label>
            </div>
        );
    }

    render() {
        const {
            attr = {},
            attr: { accept = '' } = {},
            events = {},
            setRef,
            fileName
        } = this.props;

        const allowedFieldTypes = (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .join(', ');

        return (
            <div
              block="Field"
              mods={ { type: FIELD_TYPE.file, hasFile: !!fileName } }
            >
                { this.renderLabel() }
                <input
                  ref={ (elem) => setRef(elem) }
                  type={ FIELD_TYPE.file }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                />
                { this.renderFileLabel() }
                { allowedFieldTypes.length > 0 && this.renderSubLabel(allowedFieldTypes) }
            </div>
        );
    }
}

export default FieldFileComponent;
