/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace,quote-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { updateEventData } from 'Store/Event/Event.action'; // Note: Event store was not created by plugin due to a bug
import BrowserDatabase from 'Util/BrowserDatabase';

import { PLP_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, {
    EVENT_GTM_PAGE_DATA,
    EVENT_GTM_PRODUCT_ADD_TO_CART,
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART,
    EVENT_GTM_REMOVE_FROM_CART_GA4
} from '../../util/Event';
import { handlePageDataEventPromiseError } from '../../util/Events/PageData/Event';

export class CartItemChangeQuantityPlugin {
    handleChangeQuantity = (args, callback, instance) => {
        const [quantity] = args;
        const { item, item: { quantity: oldQuantity } } = instance.props;

        this.handleChangeState = {
            newQuantity: quantity,
            oldQuantity,
            item
        };

        callback(...args);
    };

    // eslint-disable-next-line no-unused-vars
    changeItemQty = (args, callback, instance) => {
        const [dispatch] = args;
        const {
            newQuantity, item, oldQuantity
        } = this.handleChangeState;

        const cartTotals = BrowserDatabase.getItem(CART_TOTALS);
        const additionalItem = getAdditionalItem(item, cartTotals) || {};
        const { product: additionalProduct } = additionalItem;

        additionalProduct.oldQuantity = oldQuantity;
        additionalProduct.newQuantity = newQuantity;

        return callback(...args)
            .then(
                (result) => {
                    // eslint-disable-next-line fp/no-let
                    let additionalEventData = { name: 'increase quantity' };

                    if (oldQuantity < newQuantity) {
                        Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                            product: item,
                            quantity: newQuantity - oldQuantity,
                            isItem: true,
                            isFromCart: true
                        });
                    } else {
                        additionalEventData = { name: 'decrease quantity' };

                        Event.dispatch(EVENT_GTM_REMOVE_FROM_CART_GA4, {
                            item,
                            quantity: oldQuantity - newQuantity
                        });

                        Event.dispatch(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
                            item,
                            quantity: oldQuantity - newQuantity
                        });
                    }

                    Event.dispatch(EVENT_GTM_PAGE_DATA, {
                        type: PLP_TYPE,
                        changeQuantityProducts: [additionalItem]
                    });

                    dispatch(updateEventData({ ..._getEventData(), ...additionalEventData }));

                    return result;
                }
            );
    };
}

export const getAdditionalItem = (item, additionalCartTotals) => {
    const { items = [] } = additionalCartTotals;
    const { sku } = item;

    return items.find(({ sku: additionalItemSku }) => sku === additionalItemSku);
};

export const _getEventData = () => ({
    eventType: 'cart',
    widget: 'page',
    origin: 'dropdown'
});

const {
    handleChangeQuantity,
    changeItemQty
} = new CartItemChangeQuantityPlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            'handleChangeQuantity': handleChangeQuantity
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            'changeItemQty': changeItemQty
        }
    },
    'Store/Cart/Dispatcher/changeItemQtyFetchMutationCatch': {
        'function': (args, callback, context) => handlePageDataEventPromiseError(
            args, callback, context, _getEventData(), updateEventData
        )
    }
};
