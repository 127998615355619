/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

/**
 * @namespace Scandipwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewrites {
    renderDefaultPage() {
        return (
            <main style={ { height: '100vh' } } />
        );
    }
}

export default UrlRewritesComponent;
