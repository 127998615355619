/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const FORM_NOT_STARTED_STEP = 0;
export const FORM_STARTED_STEP = 1;
export const FORM_DATA_OBJECT = 'form';

/** @namespace Gtm/Component/GoogleTagManager/Events/PageDataEvent/Handlers/FormDataEvent/Handler */
export class FormDataEventHandler {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-magic-construct
    constructor(appState) {
        this.appState = appState;
    }

    /**
     * Returns form object with forms information for pageData object
     *
     * @param data
     * @returns {{name: *, stepNumber: *, type: *, status: string}}
     */
    getFormData(data) {
        const {
            formType,
            lastStep,
            name,
            step
        } = data;

        return {
            name,
            status: this.getFormStatus(step, lastStep),
            stepNumber: step,
            type: formType
        };
    }

    /**
     * Returns form status
     *
     * @param step
     * @param lastStep
     * @returns {string}
     */
    getFormStatus(step, lastStep) {
        switch (step) {
        case FORM_NOT_STARTED_STEP:
            return 'not started';
        case FORM_STARTED_STEP:
            return 'started';
        case lastStep:
            return 'completed';
        default:
            return 'in progress';
        }
    }
}

export default FormDataEventHandler;
