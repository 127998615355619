/* eslint-disable no-magic-numbers */
/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';

import Event, { EVENT_GTM_PURCHASE } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEventEvent from './BaseEvent.event';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/**
 * On order success page "Purchase"
 */
export class PurchaseEvent extends BaseEventEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PURCHASE, ({ orderID: orderId, totals }) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    async handler(orderId, totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const {
            shipping_addresses: {
                selected_shipping_method: {
                    address: {
                        email,
                        telephone,
                        firstname,
                        lastname,
                        street,
                        region: { label: region_label },
                        city,
                        country: { code: country_code }
                    }
                }
            }
        } = totals;

        this.pushEventData({
            ecommerce: {
                currencyCode: this.getCurrencyCode(),
                purchase: {
                    actionField: this.getActionFields(orderId, totals),
                    products: this.getProducts(totals)
                },
                order_email: email.toLowerCase(),
                order_email_hash: await this.getOrderEmailHash(email),
                phone_number: telephone,
                address_fname: firstname.toLowerCase(),
                address_lname: lastname.toLowerCase(),
                address_street: street[0].toLowerCase(),
                address_state: region_label.toLowerCase(),
                address_city: city.toLowerCase(),
                address_country: country_code
            }
        });
    }

    /**
     * Get order information
     *
     * @param orderId
     * @param tax_amount
     * @param grand_total
     * @param shipping_amount
     * @param discount_amount
     * @param coupon_code
     * @returns {{revenue: number, coupon: string, shipping: number, coupon_discount_amount: number, tax: number, id: string}}
     */
    getActionFields(orderId = '', totals) {
        const {
            cashOnDeliveryFee,
            prices: {
                coupon_code = '',
                applied_taxes,
                discount,
                subtotal_including_tax: { value: subtotal },
                shipping_incl_tax
            }
        } = totals;

        const taxAmount = applied_taxes.reduce(
            ({ amount: { value } }, { amount: { value: currentValue } }) => value + currentValue,
            { amount: { value: 0 } }
        );

        const tax = +roundPrice(taxAmount || 0);

        return {
            id: orderId,
            tax,
            coupon: coupon_code || '',
            revenue: +roundPrice(subtotal + (cashOnDeliveryFee || 0) - tax),
            shipping: +roundPrice(shipping_incl_tax || 0),
            coupon_discount_amount: +roundPrice(Math.abs(discount?.amount?.value || 0))
        };
    }

    /**
     * Get product detail
     *
     * @param totals
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, category: string, brand: string, url: string}[]}
     * @param cartData
     */
    getProducts({ items = [] }) {
        const products = items.reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getItemData(item),
                    quantity: ProductHelper.getQuantity(item)
                }
            ]
        ), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => products.push(data));

        return products;
    }

    async getOrderEmailHash(email) {
        const msgBuffer = new TextEncoder().encode(email);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }
}

export default PurchaseEvent;
