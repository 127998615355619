/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_SELECT_ITEM_GA4 } from '../../../util/Event';
import ProductHelper from '../GA4_utils';
import { NOT_APPLICABLE } from '../GA4_utils/Product';
import BaseEventEvent from './BaseEvent.event';

/**
 * Product click event
 */
export class SelectItemEvent extends BaseEventEvent {
    /**
     * Set delay
     *
     * @type {number}
     */
    eventHandleDelay = 2000;

    /**
     * Bind click events
     */
    bindEvent() {
        Event.observer(EVENT_GTM_SELECT_ITEM_GA4, (product) => {
            this.handle(product);
        });
    }

    /**
     * Handle product click
     */
    handler(product) {
        const category = this.getAppState()?.BreadcrumbsReducer?.breadcrumbs?.[0]?.name;

        this.pushEventData({
            currency: this.getCurrencyCode(),
            items: [
                {
                    ...ProductHelper.getProductData(product, category),
                    quantity: product.salable_qty || NOT_APPLICABLE,
                    item_variant: NOT_APPLICABLE,
                    ...this.getItemListData(product, true)
                }
            ]
        });
    }
}

export default SelectItemEvent;
