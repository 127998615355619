/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';
import { appendWithStoreCode } from 'Util/Url';

import AdyenPaymentDetailsMutation from '../query/AdyenPaymentDetails.mutation';
import { THREE_DS2_DIV_ID, THREE_DS2_POPUP_ID } from './Adyen';
import { buildAdyenCheckoutComponent, mountAdyenPaymentMethodComponent } from './AdyenCheckout';

export const AWAIT_CODE = 'await';
export const THREE_DS2_CODE = 'threeDS2';
export const ADYEN_CC_PAYMENT_COMPONENT_ID = '#adyen-checkout-card';

/**
 * @namespace Scandiweb/AdyenPayments/Util/AdyenCCMethod/handleAction
 */
export const handleAction = (action) => {
    const { type } = action;

    if (type === THREE_DS2_CODE || type === AWAIT_CODE) {
        getStore().dispatch(showPopup(THREE_DS2_POPUP_ID));
    }

    const popupId = THREE_DS2_DIV_ID;

    try {
        window.adyenElement.createFromAction(
            action
        ).mount(`#${ popupId }`);
    } catch (error) {
        console.log('error >>> ', error);
    }
};

/**
 * Based on the response we can start a 3DS2 validation or place the order
 * @param responseJSON
 * @namespace Scandiweb/AdyenPayments/Util/AdyenCCMethod/handleAdyenResult
 */
export const handleAdyenResult = (response) => {
    const {
        isFinal,
        action
    } = response;

    // close the popup after the 3DS2 validation
    getStore().dispatch(hideActiveOverlay());

    if (!isFinal) {
        handleAction(JSON.parse(action));
        return;
    }

    window.location.replace(
        appendWithStoreCode(`/checkout/success?orderId=${window.orderNumber}`)
    );
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCCMethod/handleOnAdditionalDetails */
export const handleOnAdditionalDetails = async (state) => {
    const { data } = state;
    const cartId = getStore().getState().CartReducer?.cartTotals?.id;
    const payload = JSON.stringify({
        ...data,
        orderId: window.orderNumber
    });

    if (!cartId) {
        return;
    }

    try {
        const {
            adyenPaymentDetails
        } = await fetchMutation(AdyenPaymentDetailsMutation.handleAdyenPaymentDetails(payload, cartId));

        handleAdyenResult(adyenPaymentDetails);
    } catch (error) {
        getStore().dispatch(showNotification('error', __('The payment was refused')));
        getStore().dispatch(hideActiveOverlay());

        if (window.removeLoader) {
            window.removeLoader();
        }

        if (window.adyenComponent) {
            window.adyenComponent.unmount();
            window.adyenComponent.remount();
        }

        console.log('error >>> ', error);
    }
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCCMethod/handleOnSubmit */
export const handleOnSubmit = (_state, _component) => {
    window.adyenStateData = _state;
    getStore().dispatch(hideActiveOverlay());
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCCMethod/initializeCCAdyenCheckoutComponent */
export const initializeCCAdyenCheckoutComponent = (paymentMethodsResponse) => buildAdyenCheckoutComponent(
    paymentMethodsResponse,
    handleOnAdditionalDetails,
    undefined,
    handleOnSubmit
);

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCCMethod/renderCCPaymentMethod */
export const renderCCPaymentMethod = (paymentMethod, _extraDetails, globalConfigs) => {
    const { brands } = paymentMethod;
    const {
        adyen_client_key_test: adyenClientKeyTest,
        adyen_client_key_live: adyenClientKeyLive,
        adyen_demo_mode: isDemoMode,
        adyen_has_holder_name: hasHolderName,
        adyen_holder_name_required: holderNameRequired
    } = getStore().getState().ConfigReducer;

    if (isDemoMode && !adyenClientKeyTest) {
        return false;
    }
    if (!isDemoMode && !adyenClientKeyLive) {
        return false;
    }

    const componentConfig = {
        ...globalConfigs,
        paymentMethodsConfiguration: {
            card: {
                brands,
                hasHolderName,
                holderNameRequired,
                enableStoreDetails: false
            }
        }
    };

    mountAdyenPaymentMethodComponent(
        'card',
        componentConfig,
        ADYEN_CC_PAYMENT_COMPONENT_ID
    );

    return true;
};
