/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

class UrlHandlerPlugin {
    getBypassCacheHosts = (args, callback, instance) => ([
        ...callback.apply(instance, args),
        '(?!^.*checkout_com)',
        '(?!^.*pay.google.com)'
    ]);
}

export const { getBypassCacheHosts } = new UrlHandlerPlugin();

export default {
    'SW/Handler/UrlHandler/getBypassCacheHosts': {
        function: [
            {
                position: 100,
                implementation: getBypassCacheHosts
            }
        ]
    }
};
