/* eslint-disable react/no-unknown-property */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { Form as SourceForm } from 'SourceComponent/Form/Form.component';
import FormPortalCollector from 'Util/FormPortalCollector';

/** @namespace Scandipwa/Component/Form/Component */
export class FormComponent extends SourceForm {
    static propTypes = {
        ...super.propTypes,
        onSubmitSuccess: PropTypes.func,
        onSubmitError: PropTypes.func,
        onSubmit: PropTypes.func,
        id: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        onSubmitSuccess: () => {},
        onSubmitError: () => {},
        onSubmit: () => {},
        mix: {},
        id: ''
    };

    __construct() {
        if (!window.formPortalCollector) {
            window.formPortalCollector = new FormPortalCollector();
        }
    }

    handleFormSubmit = async (e) => {
        const {
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id
        } = this.props;

        e.preventDefault();
        e.stopPropagation();
        onSubmit();
        const portalData = id ? await window.formPortalCollector.collect(id) : [];
        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;
            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());
        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        asyncData.then(
            /** @namespace Scandipwa/Component/Form/Component/FormComponent/asyncData/then */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    onSubmitSuccess(inputValues, asyncDataList);
                    return;
                }
                onSubmitError(inputValues, invalidFields);
            },
            /** @namespace Scandipwa/Component/Form/Component/FormComponent/asyncData/then/onSubmitError/catch */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };
}

export default FormComponent;
