/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from 'SourceStore/Config/Config.action';

export const SET_SITE_BASE_LOADED = 'SET_SITE_BASE_LOADED';

/** @namespace Scandipwa/Store/Config/Action/setSiteBaseLoaded */
export const setSiteBaseLoaded = () => ({
    type: SET_SITE_BASE_LOADED
});
