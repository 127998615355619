/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import { ProductConfigurableAttributeDropdown as SourceComponent }
from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Component */
export class ProductConfigurableAttributeDropdownComponent extends SourceComponent {
    static propTypes = {
        ...SourceComponent.propTypes,
        isLabelInner: PropTypes.bool,
        innerLabel: PropTypes.string,
        isSize: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        attributeValidation: PropTypes.object
    };

    static defaultProps = {
        ...SourceComponent.defaultProps,
        isLabelInner: false,
        innerLabel: '',
        isSize: false,
        attributeValidation: {}
    };

    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            onChange,
            isLabelInner,
            innerLabel,
            isSize,
            attributeValidation
        } = this.props;

        return (
            <Field
              id={ selectName }
              name={ selectName }
              type="select"
              placeholder={ __('Choose') }
              mix={ { block: 'ProductConfigurableAttributeDropdown' } }
              options={ selectOptions }
              value={ selectValue }
              events={ {
                  onChange
              } }
              isLabelInner={ isLabelInner }
              innerLabel={ innerLabel }
              isSize={ isSize }
              attributeValidation={ attributeValidation }
            />
        );
    }
}

export default ProductConfigurableAttributeDropdownComponent;
