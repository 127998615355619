/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    requestUrlRewrite() {
        const { requestUrlRewrite, urlRewrite } = this.props;

        if (urlRewrite.blockBERequest || window.blockPDPRefresh) { // Do not send request to BE for urlRewrite retreival
            return false;
        }

        return requestUrlRewrite(location.pathname);
    }

    getIsLoading() {
        if (window.blockPDPRefresh) {
            return false;
        }

        return super.getIsLoading();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
