/* eslint-disable no-magic-numbers */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import { STOCK_TYPE } from 'Component/Product/Stock.config';
import { ProductConfigurableAttributesContainer as SourceContainer }
from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Scandipwa/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer extends SourceContainer {
    static propTypes = {
        // eslint-disable-next-line react/forbid-foreign-prop-types
        ...super.propTypes,
        isLabelInner: PropTypes.bool,
        product: ProductType.isRequired,
        isDropdown: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isLabelInner: false,
        isDropdown: false
    };

    containerProps() {
        const {
            isLabelInner,
            product,
            isDropdown,
            isContentExpanded,
            isSize,
            getIsSizeDisabled
        } = this.props;

        return {
            ...super.containerProps(),
            isLabelInner,
            product,
            isDropdown,
            isContentExpanded,
            isSize,
            getIsSizeDisabled
        };
    }

    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);
        // If value matches current attribute_value, option should be enabled
        if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
            return true;
        }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    (attribute_code === 'size' ? true : stock_status === STOCK_TYPE.IN_STOCK)
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => (attributes[key] || {}).attribute_value === value)
                );
            });
    }

    componentDidMount() {
        const { configurable_options, configurable_options: { size } } = this.props;

        if (configurable_options && Object.keys(configurable_options).length === 1 && size) {
            this.existColorParameter = setInterval(() => {
                const { parameters: { color } } = this.props;

                if (color) {
                    this.autoSelectSize();
                    clearInterval(this.existColorParameter);
                }
            }, 200);
        }
    }

    componentWillUnmount() {
        if (this.existColorParameter) {
            clearInterval(this.existColorParameter);
        }
    }

    autoSelectSize() {
        const {
            configurable_options: { size },
            updateConfigurableVariant
        } = this.props;

        if (size) {
            const { attribute_options } = size;
            const autoSelectSizes = ['OSFA', 'UA', 'Adult', 'الكبار'];

            if (attribute_options) {
                const availableOptions = Object.values(attribute_options).filter(
                    (option) => this.getIsConfigurableAttributeAvailable({
                        attribute_code: 'size',
                        attribute_value: option.value
                    })
                );

                if (availableOptions.length === 1 && autoSelectSizes.includes(availableOptions[0].label)) {
                    updateConfigurableVariant('size', availableOptions[0].value);
                }
            }
        }
    }

    isSelected({ attribute_code, attribute_value }) {
        const { parameters = {} } = this.props;
        const parameter = parameters[attribute_code];

        if (parameter === undefined) {
            return false;
        }

        if (typeof parameter !== 'string') {
            return parameter.includes(attribute_value);
        }

        return parameter === attribute_value;
    }
}

export default ProductConfigurableAttributesContainer;
