/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import getStore from 'Util/Store';

/** @namespace Gtm/Util/Events/PageData/Event/handlePageDataEventPromiseError */
export const handlePageDataEventPromiseError = (args, callback, context, eventData, dispatchEvent) => {
    const [{ message }] = args[0];
    const additionalEventData = { name: 'error', error: message };

    const store = getStore();
    store.dispatch(dispatchEvent({ ...eventData, ...additionalEventData }));

    return callback(...args);
};
