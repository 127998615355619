/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_GTM_CHECKOUT = 'gtm_checkout';
export const EVENT_GTM_CHECKOUT_OPTION = 'gtm_checkout_option';
export const EVENT_GTM_IMPRESSIONS = 'gtm_impressions';
export const EVENT_GTM_IMPRESSIONS_PLP = 'gtm_impressions_plp';
export const EVENT_GTM_IMPRESSIONS_HOME = 'gtm_impressions_home';
export const EVENT_GTM_IMPRESSIONS_CROSS_SELL = 'gtm_impressions_cross_sell';
export const EVENT_GTM_IMPRESSIONS_WISHLIST = 'gtm_impressions_wishlist';
export const EVENT_GTM_IMPRESSIONS_SEARCH = 'gtm_impressions_search';
export const EVENT_GTM_IMPRESSIONS_LINKED = 'gtm_impressions_linked';
export const EVENT_GTM_META_UPDATE = 'gtm_meta_update';
export const EVENT_GTM_GENERAL_INIT = 'gtm_general_init';
export const EVENT_GTM_PRODUCT_ADD_TO_CART = 'gtm_product_add_to_cart';
export const EVENT_GTM_PRODUCT_CLICK = 'gtm_product_click';
export const EVENT_GTM_PRODUCT_DETAIL = 'gtm_product_detail';
export const EVENT_GTM_PRODUCT_REMOVE_FROM_CART = 'gtm_product_remove_from_cart';
export const EVENT_GTM_PURCHASE = 'gtm_purchase';
export const EVENT_GTM_USER_LOGIN = 'gtm_user_login';
export const EVENT_GTM_USER_REGISTER = 'gtm_user_register';
export const EVENT_GTM_PAGE_DATA = 'gtm_page_data';
export const EVENT_GTM_ORDER_CONFIRMATION = 'gtm_order_confirmation';
export const EVENT_GTM_GENERAL_GA4 = 'gtm_general_ga4';
export const EVENT_GTM_SELECT_ITEM_GA4 = 'gtm_select_item_ga4';
export const EVENT_GTM_VIEW_ITEM_LIST_GA4 = 'gtm_view_item_list_ga4';
export const EVENT_GTM_CATEGORY_SORT_CHANGE_GA4 = 'gtm_category_sort_change_ga4';
export const EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4 = 'gtm_category_refinement_select_ga4';
export const EVENT_GTM_VIEW_ITEM_GA4 = 'gtm_view_item_ga4';
export const EVENT_GTM_ADD_TO_CART_GA4 = 'gtm_add_to_cart_ga4';
export const EVENT_GTM_VIEW_CART_GA4 = 'gtm_view_cart_ga4';
export const EVENT_GTM_BEGIN_CHECKOUT_GA4 = 'gtm_begin_checkout_ga4';
export const EVENT_GTM_ADD_SHIPPING_INFO_GA4 = 'gtm_add_shipping_info_ga4';
export const EVENT_GTM_ADD_PAYMENT_INFO_GA4 = 'gtm_add_payment_info_ga4';
export const EVENT_GTM_PURCHASE_GA4 = 'gtm_purchase_ga4';
export const EVENT_GTM_ADD_TO_WISHLIST_GA4 = 'gtm_add_to_wishlist_ga4';
export const EVENT_GTM_REMOVE_FROM_CART_GA4 = 'gtm_remove_from_cart_ga4';
export const EVENT_GTM_VIEW_PROMOTION_GA4 = 'gtm_view_promotion_ga4';
export const EVENT_GTM_SELECT_PROMOTION_GA4 = 'gtm_select_promotion_ga4';
