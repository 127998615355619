/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';
import { fetchQuery } from 'Util/Request';

import AdyenPaymentsQuery from '../query/AdyenPayments.query';
import { formatAdyenPayementExtraDetails } from '../util/Adyen';

export const AdyenPaymentsContext = createContext({
    selectedPaymentType: '',
    adyenPaymentsConfigs: {},
    setSelectedPaymentType: () => {},
    setAdyenPaymentsConfigs: () => {}
});

AdyenPaymentsContext.displayName = 'AdyenPaymentsContext';

/** @namespace Scandiweb/AdyenPayments/Context/AdyenPayments/AdyenPaymentsProvider */
export const AdyenPaymentsProvider = ({ children }) => {
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [adyenPaymentMethods, setAdyenPaymentMethods] = useState({});

    const cartTotals = useSelector((state) => state.CartReducer?.cartTotals);

    const fetchAdyenPaymentMethods = async () => {
        const { id: cartId } = cartTotals;

        if (!cartId) {
            return;
        }

        const { prices: { grand_total: { value: cartTotalValue } } } = cartTotals;

        if (cartTotalValue === 0) {
            return;
        }

        try {
            const {
                adyenPaymentMethods: {
                    paymentMethodsResponse,
                    paymentMethodsExtraDetails
                }
            } = await fetchQuery(AdyenPaymentsQuery.getPaymentMethods(cartId));

            const formattedExtraDetails = formatAdyenPayementExtraDetails(paymentMethodsExtraDetails);

            setAdyenPaymentMethods({
                paymentMethodsResponse,
                paymentMethodsExtraDetails: formattedExtraDetails
            });
        } catch (error) {
            console.log('error >>> ', error);
        }
    };

    useEffect(() => {
        fetchAdyenPaymentMethods();
    }, [cartTotals]);

    const value = {
        selectedPaymentType,
        setSelectedPaymentType,
        adyenPaymentMethods,
        setAdyenPaymentMethods
    };

    return (
        <AdyenPaymentsContext.Provider value={ value }>
            { children }
        </AdyenPaymentsContext.Provider>
    );
};

AdyenPaymentsProvider.displayName = 'AdyenPaymentsProvider';
AdyenPaymentsProvider.propTypes = {
    children: ChildrenType.isRequired
};
