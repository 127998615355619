/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { SWITCH_STORE_LANGUAGE } from 'Component/Router/Router.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import { setSiteBaseLoaded } from 'Store/Config/Config.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getCartId } from 'Util/Cart';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';

import Router from './Router.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const ConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);
export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    siteBaseHasLoaded: state.ConfigReducer.siteBaseHasLoaded,
    default_description: state.ConfigReducer.default_description,
    default_keywords: state.ConfigReducer.default_keywords,
    default_title: state.ConfigReducer.default_title,
    title_prefix: state.ConfigReducer.title_prefix,
    title_suffix: state.ConfigReducer.title_suffix,
    meta_title: state.MetaReducer.title,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    device: state.ConfigReducer.device,
    isOffline: state.OfflineReducer.isOffline,
    isBigOffline: state.OfflineReducer.isBigOffline,
    isNotificationListVisible: state.NotificationReducer.isNotificationListVisible,
    locale: state.ConfigReducer.code,
    google_optimize: state.ConfigReducer.google_optimize,
    configurableProductsAttrs: state.ConfigReducer.configurableProductsAttrs,
    forceShowSubscriptionPopup: state.OverlayReducer.forceShowSubscriptionPopup
});

/** @namespace Scandipwa/Component/Router/Container/mapDispatchToProps */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setBaseSiteLoaded: () => dispatch(setSiteBaseLoaded()),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    addProduct: (options) => CartDispatcher.then(
        /** @namespace Component/AddToCart/Container/then */
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    init: async () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, undefined, undefined, true)
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        if (!isSignedIn()) {
            BrowserDatabase.deleteItem('customer');
        }
    }
});

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...SourceRouterContainer.propTypes,
        init: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        updateConfigDevice: PropTypes.func.isRequired,
        setBaseSiteLoaded: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string,
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        // eslint-disable-next-line react/boolean-prop-naming
        siteBaseHasLoaded: PropTypes.bool,
        isNotificationListVisible: PropTypes.bool.isRequired,
        isBigOffline: PropTypes.bool,
        meta_title: PropTypes.string,
        locale: PropTypes.string,
        google_optimize: PropTypes.string
    };

    static defaultProps = {
        ...SourceRouterContainer.defaultProps,
        baseLinkUrl: window.location.origin,
        default_description: '',
        default_keywords: '',
        default_title: '',
        title_prefix: '',
        title_suffix: '',
        siteBaseHasLoaded: false,
        isBigOffline: false,
        meta_title: '',
        google_optimize: '',
        locale: ''
    };

    state = {
        isLoading: false
    };

    componentDidMount() {
        const { setBaseSiteLoaded } = this.props;

        window.addEventListener('resize', this.handleResize);
        window.segatc = this.segAddToCartFunction.bind(this);

        // Check if it is a bot
        if (navigator.userAgent.includes('bot')
            || navigator.userAgent.includes('facebook')
            || navigator.userAgent.includes('render')// rendertron or prerenderer
        ) {
            setBaseSiteLoaded();
        } else {
            window.addEventListener('click', this.handleFirstInteraction);
            window.addEventListener('touchstart', this.handleFirstInteraction);
            window.addEventListener('mousemove', this.handleFirstInteraction);
            window.addEventListener('mousewheel', this.handleFirstInteraction);
            window.addEventListener('wheel', this.handleFirstInteraction);
            window.addEventListener('scroll', this.handleFirstInteraction);

            this.checkStoreLanguageRedirect();
        }
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        const elem = document.getElementsByClassName('global-promo-banner');

        this.removeFixedBodyStyle();

        if (elem && elem.length > 0) {
            const { pathname } = location;

            if (pathname.match(/checkout/)) {
                elem[0].style.display = 'none';
            } else {
                elem[0].removeAttribute('style');
            }
        }

        const globalPromoBannerList = document.getElementsByClassName('global-promo-banner-list');

        if (globalPromoBannerList && globalPromoBannerList.length > 0) {
            const { pathname } = location;

            if (pathname.match(/checkout/)) {
                globalPromoBannerList[0].style.display = 'none';
            } else {
                globalPromoBannerList[0].removeAttribute('style');
            }
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                canonical_url,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                robots
            } = this.props;

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle,
                canonical_url,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                robots
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        window.removeEventListener('click', this.handleFirstInteraction);
        window.removeEventListener('touchstart', this.handleFirstInteraction);
        window.removeEventListener('mousemove', this.handleFirstInteraction);
        window.removeEventListener('mousewheel', this.handleFirstInteraction);
        window.removeEventListener('wheel', this.handleFirstInteraction);
        window.removeEventListener('scroll', this.handleFirstInteraction);
    }

    containerFunctions = {
        setIsLoading: this.setIsLoading.bind(this),
        onLanguageSwitchClick: this.onLanguageSwitchClick.bind(this)
    };

    setIsLoading(isLoading) {
        this.setState({
            isLoading
        });
    }

    onLanguageSwitchClick() {
        if (window.storeList) {
            const { pathname } = location;
            const store = window.storeList[0];

            if (store === 'en') {
                location.replace(`/ar${pathname}`);
            } else if (store === 'ar') {
                const newPath = pathname.replace('/ar', '');

                if (newPath === '') {
                    location.replace('/');
                } else {
                    location.replace(newPath);
                }
            } else if (store === 'ksa_ar') {
                location.replace(`/en${pathname}`);
            } else if (store === 'ksa_en') {
                const newPath = pathname.replace('/en', '');

                if (newPath === '') {
                    location.replace('/');
                } else {
                    location.replace(newPath);
                }
            }
        }
    }

    checkStoreLanguageRedirect() {
        const { location: { search } } = window;
        const params = new URLSearchParams(search);

        if (params.has('keeplang')) {
            return;
        }

        if (!BrowserDatabase.getItem(SWITCH_STORE_LANGUAGE)) {
            BrowserDatabase.setItem(1, SWITCH_STORE_LANGUAGE);
            const { pathname } = location;
            const browserLanguage = window.navigator.language;

            if (browserLanguage && window.storeList) {
                const isBrowserArabic = browserLanguage.indexOf('ar') === 0;
                const store = window.storeList[0];

                if (store === 'en' && isBrowserArabic) {
                    location.replace(`/ar${pathname}`);
                } else if (store === 'ar' && !isBrowserArabic) {
                    const newPath = pathname.replace('/ar', '');

                    if (newPath === '') {
                        location.replace('/');
                    } else {
                        location.replace(newPath);
                    }
                } else if (store === 'ksa_ar' && !isBrowserArabic) {
                    location.replace(`/en${pathname}`);
                } else if (store === 'ksa_en' && isBrowserArabic) {
                    const newPath = pathname.replace('/en', '');

                    if (newPath === '') {
                        location.replace('/');
                    } else {
                        location.replace(newPath);
                    }
                }
            }
        }
    }

    removeFixedBodyStyle() {
        if (!document.getElementById('slider_overlay')) {
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('width');
            document.body.style.removeProperty('height');
            document.body.style.removeProperty('overflow');
            document.documentElement.style.removeProperty('overflow');
            document.documentElement.classList.remove('overlayFullScreen');
        }
    }

    handleResize = async () => {
        const { updateConfigDevice } = this.props;
        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: navigator.userAgentData.mobile,
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                windows: isMobile.windows(model)
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                windows: isMobile.windows()
            });
        }
    };

    handleFirstInteraction = async () => {
        const { setBaseSiteLoaded } = this.props;

        setBaseSiteLoaded();

        window.removeEventListener('click', this.handleFirstInteraction);
        window.removeEventListener('touchstart', this.handleFirstInteraction);
        window.removeEventListener('mousemove', this.handleFirstInteraction);
        window.removeEventListener('mousewheel', this.handleFirstInteraction);
        window.removeEventListener('wheel', this.handleFirstInteraction);
        window.removeEventListener('scroll', this.handleFirstInteraction);
    };

    segAddToCartFunction(style_number, sizeOption, colorOption) {
        const { addProduct } = this.props;

        const products = this.getTransformedProduct(style_number, sizeOption, colorOption);
        const cartId = getCartId();

        addProduct({
            products,
            cartId
        }).then(
            /** @namespace Scandipwa/Component/Router/Container/RouterContainer/segAddToCartFunction/addProduct/then */
            () => {
                showNotification('success', __('Product has been added to cart'));
            },
            /** @namespace Scandipwa/Component/Router/Container/RouterContainer/segAddToCartFunction/addProduct/then/catch */
            (error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                showNotification('error', __('Something went wrong! Please, try again!'));
            }
        );
    }

    getTransformedProduct(style_number, sizeOption, colorOption) {
        const { configurableProductsAttrs } = this.props;

        const {
            color_attr_id,
            size_attr_id
        } = configurableProductsAttrs;

        const parameters = {
            color: colorOption,
            size: sizeOption
        };

        const attributes = {
            color: { attribute_id: color_attr_id },
            size: { attribute_id: size_attr_id }
        };

        const configurableOptions = transformParameters(parameters, attributes);
        const product = {
            sku: style_number,
            type_id: PRODUCT_TYPE.configurable
        };

        return magentoProductTransform(
            ADD_TO_CART,
            product,
            1,
            [],
            Array.from(configurableOptions),
        );
    }

    containerProps() {
        const {
            isBigOffline,
            siteBaseHasLoaded,
            isNotificationListVisible,
            baseLinkUrl,
            locale,
            google_optimize,
            forceShowSubscriptionPopup
        } = this.props;

        const { isLoading } = this.state;

        return {
            ...super.containerProps(),
            isBigOffline,
            siteBaseHasLoaded,
            isLoading,
            isNotificationListVisible,
            baseLinkUrl,
            locale,
            google_optimize,
            forceShowSubscriptionPopup
        };
    }

    render() {
        return (
            <Router
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
