/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace,quote-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { updateEventData } from 'Store/Event/Event.action'; // Note: Event store was not created by plugin due to a bug

import Event, {
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART,
    EVENT_GTM_REMOVE_FROM_CART_GA4
} from '../../util/Event';
import { handlePageDataEventPromiseError } from '../../util/Events/PageData/Event';

const GLOBAL_PRODUCT_REMOVE_STATE_HANDLE = 'GLOBAL_PRODUCT_REMOVE_STATE_HANDLE';

export class CartItemRemovePlugin {
    handleRemoveItem = (args, callback, instance) => {
        callback(...args);
        const { item } = instance.props;
        const { qty: quantity } = item;

        window[GLOBAL_PRODUCT_REMOVE_STATE_HANDLE] = {
            item,
            quantity
        };
    };

    // eslint-disable-next-line no-unused-vars
    removeProductFromCart = (args, callback, instance) => {
        const [dispatch] = args;
        const { item, quantity = 0 } = window.GLOBAL_PRODUCT_REMOVE_STATE_HANDLE || {};

        // eslint-disable-next-line fp/no-delete
        delete window.GLOBAL_PRODUCT_REMOVE_STATE_HANDLE;

        return callback(...args)
            .then(
                (result) => {
                    const additionalEventData = { name: 'product removed' };

                    Event.dispatch(EVENT_GTM_REMOVE_FROM_CART_GA4, {
                        item,
                        quantity
                    });

                    Event.dispatch(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
                        item,
                        quantity
                    });

                    dispatch(updateEventData({ ..._getEventData(), ...additionalEventData }));

                    return result;
                }
            );
    };
}

export const _getEventData = () => ({
    eventType: 'cart',
    widget: 'page'
});

const {
    handleRemoveItem,
    removeProductFromCart
} = new CartItemRemovePlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            'handleRemoveItem': handleRemoveItem
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            'removeProductFromCart': removeProductFromCart
        }
    },
    'Store/Cart/Dispatcher/removeProductFromCartFetchMutationError': {
        'function': (args, callback, context) => handlePageDataEventPromiseError(
            args, callback, context, _getEventData(), updateEventData
        )
    }
};
