/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { createPortal } from 'react-dom';

import { CATEGORY_FILTER_POPUP } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { LANG_BLOCK_POPUP } from 'Component/ChooseLanguageSelector/ChooseLanguageSelector.config';
import { MENU_MOBILE } from 'Component/Header/Header.config';
import { STYLEGUIDE_POPUP } from 'Route/StyleGuide/StyleGuide.config';
import SourcePopup from 'SourceComponent/Popup/Popup.component';
import CSS from 'Util/CSS';
import isMobile from 'Util/Mobile';
import { handleIosStickyHeader } from 'Util/PromoBanner/PromoBanner';
import { setQueryParams } from 'Util/Url';

import './Popup.extended.style';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    state = {
        isFrozen: false,
        initialScrollPos: 0
    };

    onHide() {
        const { clearPopupState } = this.props;

        clearPopupState();

        super.onHide();
    }

    /**
     * Making hidePopupAndGoBack() be the same as hidePopUp() because ....
     * Why on earth is hidePopupAndGoBack() being the default when closing the popup !!!!!!
     */
    hidePopupAndGoBack() {
        this.hidePopUp();
    }

    hidePopUp() {
        const { hideActiveOverlay, goToPreviousNavigationState, activeOverlay } = this.props;
        const isVisible = this.getIsVisible();

        this.onHide();

        if (isVisible) {
            hideActiveOverlay();
            goToPreviousNavigationState();

            if (activeOverlay === STYLEGUIDE_POPUP) { // checking for specific case, because when it is closed it is not scrollable
                document.body.style.overflow = 'initial';
            }
        }
    }

    renderTitle() {
        const { title, payload } = this.props;
        if (!title && !payload && !payload.title) {
            return null;
        }

        return (
            <h2 block="Popup" elem="Heading">
                { title || payload.title }
            </h2>
        );
    }

    setInnerHeightVariable() {
        document.documentElement.style.setProperty(
            '--window-inner-height-checkout',
            `${ window.innerHeight }px`
        );
    }

    componentDidMount() {
        this.handleScroll();
        this.setInnerHeightVariable();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.setInnerHeightVariable);

        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        this.handleScroll();

        const prevWasVisible = this.getIsVisible(prevProps);
        const isVisible = this.getIsVisible();

        const { activeOverlay } = this.props;
        const { activeOverlay: prevActiveOverlay } = prevProps;

        if (isVisible && !prevWasVisible) {
            this.onVisible();
        } else if (!isVisible && prevWasVisible) {
            this.onHide();
        } else if (activeOverlay === '' && prevActiveOverlay !== '') {
            this.onHide();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.setInnerHeightVariable);

        super.componentWillUnmount();
    }

    handleScroll = () => {
        const { block } = this.props;
        const topBanner = document.querySelector('[id^=\'global_promo_banner_\']');
        const topBannerHeight = topBanner ? topBanner.offsetHeight : 0;
        const navBarHeight = 60;
        const pageYOffset = isMobile.iOS() && document.body.style.top
            ? -1 * parseInt(document.body.style.top, 10) : window.pageYOffset;

        const offset = pageYOffset || window.pageYOffset;
        const top = (pageYOffset > topBannerHeight || window.pageYOffset > topBannerHeight)
            ? navBarHeight
            : topBannerHeight + navBarHeight - offset;

        if (block === 'SubscriptionBlockNonRegister') {
            CSS.setVariable(
                this.overlayRef,
                'mobile-popup-top',
                `${ top }px`
            );

            CSS.setVariable(
                this.overlayRef,
                'mobile-block-max-height',
                `${ window.innerHeight - top }px`
            );

            CSS.setVariable(
                this.overlayRef,
                'mobile-block-half-height',
                `${ (window.innerHeight / 2) - top }px`
            );
        }

        if (block === 'ChooseLangBlock') {
            CSS.setVariable(
                this.overlayRef,
                'mobile-langpopup-top',
                `${ top }px`
            );

            CSS.setVariable(
                this.overlayRef,
                'mobile-langpopup-max-height',
                `${ window.innerHeight - top }px`
            );
        }
    };

    freezeScroll = () => {
        const { isFrozen } = this.state;
        const { areOtherOverlaysOpen } = this.props;

        if (isFrozen || areOtherOverlaysOpen) {
            return;
        }
        const scrollPos = window.pageYOffset * -1;

        this.setState({ isFrozen: true, initialScrollPos: scrollPos * -1 }, () => {
            document.body.style.top = `${ scrollPos }px`;
            document.body.style.position = 'fixed';
            document.body.style.right = '0';
            document.body.style.left = '0';
            document.body.style.bottom = '0';
        });
    };

    unfreezeScroll() {
        const { activeOverlay } = this.props;
        const { isFrozen, initialScrollPos } = this.state;

        if (!isFrozen) {
            return;
        }

        if (activeOverlay === '') {
            document.body.style.overflow = 'initial';
        }

        document.body.style.position = 'relative';
        document.body.style.top = 'initial';
        window.scrollTo(0, initialScrollPos);
        document.body.style.right = 'initial';
        document.body.style.left = 'initial';
        document.body.style.bottom = 'initial';

        this.setState({ isFrozen: false });
    }

    renderCloseButton() {
        const { changeHeaderState, activeOverlay, isHideCloseButton } = this.props;

        if (isHideCloseButton) {
            return null;
        }

        const isLanguagePopup = activeOverlay === LANG_BLOCK_POPUP;

        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={ () => {
                  this.hidePopUp(); changeHeaderState(isLanguagePopup);
              } }
            />
        );
    }

    onResetAllFiltersClick = () => {
        const { location, history } = this.props;

        setQueryParams({
            customFilters: '',
            priceMin: '',
            priceMax: '',
            page: ''
        }, location, history);
    };

    renderResetAllFiltersButtton = () => {
        const { payload, activeOverlay } = this.props;
        const { selectedFilters } = payload[activeOverlay] || {};

        if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
            return null;
        }

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="CategoryPage"
              elem="SelectedFilter"
              mods={ { clearAll: true } }
              onKeyDown={ this.onResetAllFiltersClick }
              onClick={ this.onResetAllFiltersClick }
            >
                { __('Clear all') }
            </div>
        );
    };

    handleClickOutside() {
        const { isCloseOnOutsideClick } = this.props;

        if (!isCloseOnOutsideClick) {
            return;
        }

        this.hidePopupAndGoBack();
    }

    renderPopupTotalItemsButton = () => {
        const { payload, activeOverlay, changeHeaderState } = this.props;
        const { totalItems } = payload[activeOverlay] || {};
        const isLanguagePopup = activeOverlay === LANG_BLOCK_POPUP;

        if (totalItems === 0 || totalItems === {}) {
            return null;
        }

        return (
            <button
              block="Button"
              elem="Primary"
              mods={ { color: 'brown' } }
              onClick={ () => {
                  this.hidePopUp(); changeHeaderState(isLanguagePopup);
              } }
            >
                { `${__('Show')} ${totalItems} ${ totalItems === 1 ? __('Item') : __('Items') }` }
            </button>
        );
    };

    renderStickyContent() {
        const { activeOverlay } = this.props;

        if (activeOverlay === CATEGORY_FILTER_POPUP) {
            return (
                <div block="CategoryFilterOverlay" elem="PopupStickySection">
                    { this.renderResetAllFiltersButtton() }
                    { this.renderPopupTotalItemsButton() }
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            mix,
            areOtherOverlaysOpen,
            block,
            activeOverlay
        } = this.props;
        const isVisible = this.getIsVisible();

        const areOverlaysOpen = activeOverlay === MENU_MOBILE || activeOverlay === LANG_BLOCK_POPUP;

        if (window.matchMedia('(max-width: 1200px)').matches && !areOverlaysOpen) {
            handleIosStickyHeader();
        }

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen, block } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
                { this.renderStickyContent() }
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
