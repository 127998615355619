/* eslint-disable react/no-unknown-property */
import { ContentWrapper as SourceContentWrapper } from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.style';

/** @namespace Scandipwa/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends SourceContentWrapper {
    render() {
        const {
            children,
            mix,
            wrapperMix,
            label,
            dataComponentSubPosition = null,
            carouselId = null,
            contentTitle = null
        } = this.props;

        return (
            <section
              mix={ mix }
              aria-label={ label }
              data-component-sub-position={ dataComponentSubPosition }
              data-carousel-id={ carouselId }
              data-id={ carouselId }
              data-content-title={ contentTitle }
            >
                <div block="ContentWrapper" mix={ wrapperMix }>
                    { children }
                </div>
            </section>
        );
    }
}

export default ContentWrapperComponent;
