/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author      Gela Gzirishvili <info@scandiweb.com>
 */

import Loader from 'Component/Loader';
import Field from 'Component/Field';
import Form from 'Component/Form';
import Image from 'Component/Image';
import { Field as QueryField } from 'SourceUtil/Query';
import FieldFile from 'Component/FieldFile';
import MembershipCardImageQuery from '../query/MembershipCardImage.query';
import { fetchMutation } from 'SourceUtil/Request';
import MobileLogin from 'Component/MobileLogin';
import UtilIsMobile from 'Util/Mobile';
import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';
import { validatePassword } from 'Util/Validator';
import {
    REGISTER_CONFIRM_EMAIL_ID,
    REGISTER_CONFIRM_PASSWORD_ID,
    REGISTER_EMAIL_ID,
    REGISTER_FIRSTNAME_ID,
    REGISTER_LASTNAME_ID,
    REGISTER_PASSWORD_ID
} from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';

const isPartnership = (instance) => {
    const {
        location: {
            search = ""
        } = {}
    } = window;

    const {
        partnerships: {
            is_enabled: isEnabled = false,
            partners
        } = {}
    } = instance.props

    if (!isEnabled || !partners) {
        return false;
    }

    const list = partners?.map(({ url }) => url);

    if (!search || !list) {
        return false;
    }


    const identifier = search.split('=')[1];

    if (list.indexOf(identifier) === -1) {
        return false;
    }

    return identifier;
}

const renderPartnershipCreateAccount = (args, callback, instance) => {
    const { partner: { url } = {}, isMobile } = instance.props;

    const stateMap = {
        [REGISTER_FIRSTNAME_ID]: 'firstnameRegister',
        [REGISTER_LASTNAME_ID]: 'lastnameRegister',
        [REGISTER_EMAIL_ID]: 'emailRegister',
        [REGISTER_CONFIRM_EMAIL_ID]: 'emailMatchRegister',
        [REGISTER_PASSWORD_ID]: 'passwordRegister',
        [REGISTER_CONFIRM_PASSWORD_ID]: 'passwordMatchRegister'
    };

    const handleDataChange = (event, field) => {
        const { onDataChange } = instance.props;
        const { value = '', id } = field;
        const stateField = stateMap[id];
        const state = { [stateField]: value };

        onDataChange(state);
    }

    if (!url) {
        return callback(...args)
    }

    const {
        onCreateAccountAttempt,
        onCreateAccountSuccess,
        isRegisterDisabled,
        optionalFields: stateOptionalFields,
        mandatoryFields: stateMandatoryFields,
        partner: {
            mandatory_fields: propsMandatoryFields,
            optional_fields: propsOptionalFields,
            partner_image: imageUrl,
            isMembershipImage
        },
        setUploadFile,
        resetFieldValue,
        setRef,
        showNotification,
        range,
        minimunPasswordCharacter,
        onDataChange
    } = instance.props;

    const mandatoryFields = propsMandatoryFields?.indexOf(",") > -1 ? propsMandatoryFields.split(',') : [propsMandatoryFields || null];
    const optionalFields = propsOptionalFields?.indexOf(",") > -1 ? propsOptionalFields.split(',') : [propsOptionalFields || null];

    const isMembershipImageRequired = mandatoryFields?.indexOf("membership_image") > -1;

    const validateFile = (value) => {
        if (!value && isMembershipImageRequired) {
            return false;
        }

        if (!value) {
            return false;
        }

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const allowedSize = 5 * 1024 * 1024; // 5 MB
        const { type: fileType, size: fileSize } = value;

        if (!allowedTypes.includes(fileType)) {
            showNotification("error", __("Invalid file type. Please upload a JPG, JPEG or PNG image."));
            return false;
        }
        if (fileSize > allowedSize) {
            showNotification("error", __("File size exceeds the limit of 5 MB. Please upload a smaller image."));
            return false;
        }

        return true;
    }

    return (
        <div>
            {
                url
                && (
                    <Image
                        src={imageUrl}
                        alt={`Partner image.`}
                        ratio="16x9"
                        width="100%"
                        height="100%"
                    />
                )
            }
            <Form
                key="create-account"
                onSubmit={onCreateAccountSuccess}
                onError={onCreateAccountAttempt}
            >
                <div block="RegistrationTitle">
                    <h3>
                        {__('Registration')}
                    </h3>
                </div>
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('First Name') }
                  attr={ {
                      id: REGISTER_FIRSTNAME_ID,
                      name: 'firstname',
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Last Name') }
                  attr={ {
                      id: REGISTER_LASTNAME_ID,
                      name: 'lastname',
                      autocomplete: 'family-name'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: REGISTER_EMAIL_ID,
                      name: 'email',
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Confirm Your Email') }
                  attr={ {
                      id: REGISTER_CONFIRM_EMAIL_ID,
                      name: 'confirmEmail',
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email,
                      match: (value) => {
                          const email = document.getElementById(REGISTER_EMAIL_ID);

                          return value && email.value === value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Emails do not match')
                      }
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Password') }
                  attr={ {
                      id: REGISTER_PASSWORD_ID,
                      name: 'password',
                      autocomplete: 'new-password'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const email = document.getElementById(REGISTER_EMAIL_ID);

                          if (value && email.value === value) {
                              return __('Passwords can\'t be the same as email!');
                          }

                          if (value.length < range.min) {
                              return __('Password should be at least %s characters long', range.min);
                          }

                          return validatePassword(value, range, minimunPasswordCharacter);
                      }
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Confirm password') }
                  attr={ {
                      id: REGISTER_CONFIRM_PASSWORD_ID,
                      name: 'confirm_password',
                      autocomplete: 'new-password'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const password = document.getElementById(REGISTER_PASSWORD_ID);

                          return value && password.value === value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Passwords do not match!')
                      }
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                {
                    mandatoryFields?.map((field) => {
                        if (!field) return null;

                        const trimmedField = field.trim();

                        if (trimmedField === "membership_image") {
                            return null;
                        }

                        const title = trimmedField.indexOf("_") > -1 ? trimmedField.split("_").join(" ") : trimmedField;

                        return (
                            <Field
                                type={ trimmedField === "expiry_date" ? FIELD_TYPE.date : FIELD_TYPE.text }
                                label={ `${title}*` }
                                attr={ {
                                    id: field,
                                    name: field
                                } }
                                validationRule={ {
                                    isRequired: true
                                } }
                                events={ {
                                    onChange: (elem, field) => onDataChange({
                                        mandatoryFields: {
                                            ...stateMandatoryFields,
                                            [trimmedField]: field.value
                                        }
                                    })
                                } }
                                validateOn={ ['onChange', 'onBlur'] }
                                showCheckMark
                            />
                        )
                    })
                }
                {
                    optionalFields?.map((field) => {
                        if (!field) return null;

                        const trimmedField = field.trim();

                        if (trimmedField === "membership_image") {
                            return null;
                        }

                        const title = trimmedField.indexOf("_") > -1 ? trimmedField.split("_").join(" ") : trimmedField;

                        return (
                            <Field
                                type={ trimmedField === "expiry_date" ? FIELD_TYPE.date : FIELD_TYPE.text }
                                label={ `${title}*` }
                                attr={ {
                                    id: field,
                                    name: field
                                } }
                                events={ {
                                    onChange: (elem, field) => onDataChange({
                                        optionalFields: {
                                            ...stateOptionalFields,
                                            [trimmedField]: field.value
                                        }
                                    })
                                } }
                                showCheckMark
                            />
                        )
                    })
                }
                {
                    isMembershipImage &&
                    (
                        <>
                            <FieldFile
                                attr={{
                                    name: 'my-file',
                                    label: 'My File',
                                    required: isMembershipImageRequired
                                }}
                                events={{
                                    onChange: setUploadFile
                                }}
                                setRef={setRef}
                                validate={validateFile}
                                resetFieldValue={resetFieldValue}
                            />
                            <span block="Field" elem="UploaderNote">
                                { __('PLEASE UPLOAD A JPG, JPEG, OR PNG IMAGE. SIZE OF THE IMAGE SHOULD NOT EXCEED 5 MB.') }
                            </span>
                        </>
                    )
                }
                <div
                    block="Register"
                    elem="Subscription"
                >
                    <div block="Agreement">
                        <Field
                            type={ FIELD_TYPE.checkbox }
                            attr={ {
                                id: 'is_subscribed',
                                name: 'is_subscribed',
                                defaultChecked: true
                            } }
                            value="is_subscribed"
                            mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                        />
                        <p
                            block="MyAccountOverlay"
                            elem="Terms"
                        >
                            {__('Sign up for emails to get updates from PUMA on products and offers. You can unsubscribe at any time free of charge.')}
                        </p>
                        {isMobile ? instance.renderLoginTermsOfUseMessage() : instance.renderTermsOfUseMessage()}
                    </div>
                </div>
                <div block="RegisterButton">
                    <div block="MyAccountOverlay" elem="Buttons">
                        <button
                            block="Button"
                            type="submit"
                            disabled={isRegisterDisabled}
                        >
                            {__('Create an account')}
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

const partnershipSignUp = (args, callback, instance) => {
    const { isLoading, partner: { url } = {}, partner } = instance.props;

    if (!url) {
        return callback(...args)
    }

    const isMobileContent = UtilIsMobile.any();

    return (
        <div block="SignInPage">
            {instance.renderAccountDashboard()}
            <div block="Login">
                <Loader isLoading={isLoading} />
                {(isMobileContent &&
                <div
                    block="MobileLoginForm"
                >
                    <MobileLogin partner={partner} isMobile />
                </div>
                )}
                {(!isMobileContent &&
                <div
                    block="RegisterForm"
                    elem="Container"
                >
                    {instance.renderCreateAccount()}
                </div>
                )}
            </div>
        </div>
    );
};

const uploadImage = async (image, instance, isRequired) => {
    const {
        isMobile,
        partner: {
            isMembershipImage: mobileIsMembershipImage
        } = {}
    } = instance.props;
    const { partner: { isMembershipImage } = {} } = instance.state;
    const { showNotification } = instance.props;

    if (
        (isMobile && !mobileIsMembershipImage)
        || (!isMobile && !isMembershipImage)
    ) return "";

    if (isRequired && Object.keys(image).length === 0) {
        showNotification('error', __("Please upload a membership card image."));
        return null;
    }

    if (Object.keys(image).length === 0) {
        return '';
    }

    try {
        const {
            MembershipCardImage: {
                url: imageUrl
            } = {}
        } = await fetchMutation(MembershipCardImageQuery.getMutation(image));

        return imageUrl;
    } catch (e) {
        showNotification('error', e?.message || __("Something went wrong with the image upload."));

        return null;
    }


}

const handleFieldsToCheck = (string) => {
    if (!string) return [];

    return string?.indexOf(",") > -1 ? string?.split(",") : [string || null];
}

const onCreateAccountSuccessPartnership = async (args, callback, instance) => {
    const {
        isMobile,
        partner: {
            url: mobileUrl = "",
            mandatory_fields: mobileMandatoryFieldsString = ""
        } = {}
    } = instance.props;
    const { partner: { url } = {} } = instance.state;

    if (!url && !isMobile || isMobile && !mobileUrl) {
        return callback(...args);
    }

    const [fields] = args;

    const {
        createAccount,
        onSignIn,
        showNotification,
    } = instance.props;
    const {
        partner: {
            mandatory_fields: mandatoryFieldsString = "",
        } = {},
        mandatoryFields = {},
        optionalFields = {},
        membership_card_image = {}
    } = instance.state;

    const {
        password,
        email,
        firstname,
        lastname,
        is_subscribed,
    } = fields;

    try {
        const fieldsToCheck = handleFieldsToCheck(mandatoryFieldsString || mobileMandatoryFieldsString);
        const isMembershipImageRequired = fieldsToCheck?.indexOf("membership_image") > -1;

        const imageUrl = await uploadImage(membership_card_image, instance, isMembershipImageRequired);

        if (imageUrl === null) {
            return;
        }

        const customerData = {
            customer: {
                firstname: firstname.value,
                lastname: lastname.value,
                email: email.value,
                is_subscribed: is_subscribed.checked,
                partnership: {
                    mandatory_fields: mandatoryFields ? JSON.stringify(mandatoryFields) : "",
                    optional_fields: optionalFields ? JSON.stringify(optionalFields) : "",
                    membership_card_image_url: imageUrl,
                    partner_id: url || mobileUrl
                }
            },
            password: password.value
        };

        const code = await createAccount(customerData);

        if (code === 2) {
            instance.setState({ state: STATE_CONFIRM_EMAIL });
        } else {
            onSignIn ? onSignIn() : instance.onSignIn();
        }
    } catch (e) {
        console.error(e);
        showNotification("error", e?.message || "Something went wrong")
    }
}

const addPartnershipsMethods = (member, instance) => {
    return {
        ...member,
        setUploadFile: (fileData) => {
            if (!fileData) {
                return;
            }

            instance.setState({ membership_card_image: fileData });
        },
        resetFieldValue: (e) => {
            e?.preventDefault();
            instance.setState({ membership_card_image: {} });
        },
        setRef: (ref) => {
            instance.imageUploadRef = ref;
        }
    }
}

const validatePartnerships = (instance) => {
    const { partnerships: { partners } = {} } = instance.props;
    if (!partners) return;
    const identifier = isPartnership(instance);
    if (!identifier) return;
    const partner = partners.find(({ url }) => url === identifier);

    instance.setState({ partner })

}

const partnershipsLifeCycle = (args, callback, instance) => {
    callback(...args);
    validatePartnerships(instance);
}

const partnerContainerProps = (args, callback, instance) => {
    const { partnerships } = instance.props;
    const { partner } = instance.state;

    return {
        ...callback(...args),
        partnerships,
        partner
    };
}

const mstpAddPartnerships = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        partnerships: state.ConfigReducer.partnerships,
    };
};

const addPartnershipCustomerField = (args, callback) => {
    const parent = callback(...args);
    parent.addField(new QueryField('is_partnership_customer_stored'));

    return parent;
}

const renderMobilePartnershipsForm = (args, callback, instance) => {
    const { partner: { url } = {} } = instance.props;

    if (!url) {
        return callback(...args);
    }

    return (
        <>
            {instance.renderButtonsMobile()}
            {instance.renderRegisterFormMobile()}
        </>
    );
}

const renderPartnershipButtonsMobile = (args, callback, instance) => {
    const { partner: { url } = {} } = instance.props;

    if (!url) {
        return callback(...args);
    }

    return (
        <div block="MobileFormButtons">
            <div block="RegisterMobileButton">
                <button
                    // eslint-disable-next-line react/jsx-no-bind
                    block={'_active'}
                >
                    {__('Registration')}
                </button>
            </div>
        </div>
    );
}


// vvv Plug into register function to send the mandatory fields to the API
export default {
    'Component/MyAccountOverlay/Component': {
        'member-function': {
            render: partnershipSignUp,
            renderCreateAccount: renderPartnershipCreateAccount
        }
    },
    'Component/MyAccountOverlay/Container': {
        'member-function': {
            onCreateAccountSuccess: onCreateAccountSuccessPartnership,
            componentDidMount: partnershipsLifeCycle,
            componentDidUpdate: partnershipsLifeCycle,
            containerProps: partnerContainerProps
        },
        'member-property': {
            containerFunctions: addPartnershipsMethods
        }
    },
    'Component/MyAccountOverlay/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mstpAddPartnerships
            }
        ]
    },
    'Query/MyAccount/Query': {
        'member-function': {
            getCreateAccountMutation: addPartnershipCustomerField
        }
    },
    'Scandipwa/Component/MobileLogin/Component': {
        'member-function': {
            renderMobileLoginRegisterForm: renderMobilePartnershipsForm,
            renderButtonsMobile: renderPartnershipButtonsMobile,
            renderRegisterFormMobile: renderPartnershipCreateAccount
        }
    },
    'Scandipwa/Component/MobileLogin/Container': {
        'member-function': {
            onCreateAccountSuccess: onCreateAccountSuccessPartnership
        },
        'member-property': {
            containerFunctions: addPartnershipsMethods
        }
    }
};
