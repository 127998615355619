/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MOBILE_FORGOT_PASSWORD_POPUP } from 'Component/ForgotPassword/ForgotPassword.config';
import { CUSTOMER_ACCOUNT, CUSTOMER_SUB_ACCOUNT } from 'Component/Header/Header.config';
import { PASSWORD_LENGTH } from 'Component/MobileLogin/MobileLogin.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device.type';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY,
    STATE_CREATE_ACCOUNT,
    STATE_FORGOT_PASSWORD,
    STATE_LOGGED_IN,
    STATE_SIGN_IN
} from '../MyAccountOverlay/MyAccountOverlay.config';
import MobileLoginComponent from './MobileLogin.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "accountDispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MobileLogin/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    customer: state.MyAccountReducer.customer,
    device: state.ConfigReducer.device,
    isPasswordForgotSend: state.MyAccountReducer.isPasswordForgotSend,
    isOverlayVisible: state.OverlayReducer.activeOverlay === CUSTOMER_ACCOUNT,
    locale: state.ConfigReducer.locale,
    minimunPasswordLength: state.ConfigReducer.minimun_password_length,
    minimunPasswordCharacter: state.ConfigReducer.required_character_classes_number
});

/** @namespace Scandipwa/Component/MobileLogin/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    setHeaderState: (headerState) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, headerState)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showPopup: (payload) => dispatch(showPopup('title', payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateCustomerLoadingStatus: (status) => dispatch(updateIsLoading(status)),
    createAccount: (options) => MyAccountDispatcher.then(
        /** @namespace Component/MyAccountOverlay/Container/then */
        ({ default: dispatcher }) => dispatcher.createAccount(options, dispatch)
    )
});

/** @namespace Scandipwa/Component/MobileLogin/Container */
export class MobileLoginContainer extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        goToPreviousHeaderState: PropTypes.func,
        hideActiveOverlay: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool,
        isOverlayVisible: PropTypes.bool.isRequired,
        isPasswordForgotSend: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        showNotification: PropTypes.func.isRequired,
        message: PropTypes.string,
        requestCustomerData: PropTypes.func.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        updateCustomerLoadingStatus: PropTypes.func.isRequired,
        locale: PropTypes.string.isRequired
    };

    static defaultProps = {
        isCheckout: false,
        goToPreviousHeaderState: () => {},
        message: ''
    };

    containerFunctions = {
        handleCreateAccount: this.handleCreateAccount.bind(this),
        handleForgotPassword: this.handleForgotPassword.bind(this),
        handleSignIn: this.handleSignIn.bind(this),
        onDataChange: this.onDataChange.bind(this),
        onFormError: this.onFormError.bind(this),
        onSignIn: this.onSignIn.bind(this),
        onVisible: this.onVisible.bind(this),
        setLoadingState: this.setLoadingState.bind(this),
        setLoginActiveTab: this.setLoginActiveTab.bind(this),
        setRegisterActiveTab: this.setRegisterActiveTab.bind(this),
        setSignInState: this.setSignInState.bind(this),
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this)
    };

    static getDerivedStateFromProps(props, state) {
        const {
            isSignedIn,
            isPasswordForgotSend,
            showNotification,
            isOverlayVisible,
            device
        } = props;

        const {
            isPasswordForgotSend: currentIsPasswordForgotSend,
            state: myAccountState
        } = state;

        const { location: { pathname, state: { isForgotPassword } = {} } } = history;

        const stateToBeUpdated = {};

        if (!device.isMobile) {
            if (!isOverlayVisible && !isSignedIn) {
                if (pathname !== '/forgot-password' && !isForgotPassword) {
                    stateToBeUpdated.state = STATE_SIGN_IN;
                }
            } else if (!isOverlayVisible && isSignedIn) {
                stateToBeUpdated.state = STATE_LOGGED_IN;
            }
        }

        if (myAccountState !== STATE_LOGGED_IN && isSignedIn) {
            stateToBeUpdated.isLoading = false;
            stateToBeUpdated.state = STATE_LOGGED_IN;
        }

        if (myAccountState === STATE_LOGGED_IN && !isSignedIn) {
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        if (isPasswordForgotSend !== currentIsPasswordForgotSend) {
            stateToBeUpdated.isLoading = false;
            stateToBeUpdated.isPasswordForgotSend = isPasswordForgotSend;
            // eslint-disable-next-line max-len
            showNotification('success', __('If there is an account associated with the provided address you will receive an email with a link to reset your password.'));
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        return Object.keys(stateToBeUpdated).length ? stateToBeUpdated : null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { state: oldMyAccountState } = prevState;
        const { state: newMyAccountState } = this.state;
        const { location: { pathname } } = history;

        const {
            isSignedIn,
            hideActiveOverlay,
            isCheckout,
            goToPreviousHeaderState,
            showNotification
        } = this.props;

        this.enableDisableSignIn();
        this.enableDisableRegister();

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (isSignedIn !== prevIsSignedIn) {
            if (isSignedIn) {
                showNotification('success', __('You are successfully logged in!'));
            }

            hideActiveOverlay();

            if (isCheckout) {
                goToPreviousHeaderState();
            }
        }

        if (!pathname.includes(CHECKOUT_URL) && newMyAccountState === STATE_LOGGED_IN) {
            history.push({ pathname: appendWithStoreCode('/customer/account') });
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = this.redirectOrGetState(props);
    }

    setLoginActiveTab() {
        this.setState({ activeTab: 'login' });
    }

    setRegisterActiveTab() {
        this.setState({ activeTab: 'register' });
    }

    setSignInState(state) {
        this.setState({ state });
    }

    setLoadingState(isLoading) {
        const { updateCustomerLoadingStatus } = this.props;
        updateCustomerLoadingStatus(isLoading);
    }

    onCreateAccountSuccess() {
        // Nothing, just to attach plugins
    }

    enableDisableSignIn() {
        const {
            emailLogin,
            passwordLogin
        } = this.state;

        if (emailLogin
            && passwordLogin
            && passwordLogin.length > PASSWORD_LENGTH) {
            this.setState({ isSignInDisabled: false });
        } else {
            this.setState({ isSignInDisabled: true });
        }
    }

    enableDisableRegister() {
        const {
            emailRegister,
            firstnameRegister,
            lastnameRegister,
            emailMatchRegister,
            passwordRegister,
            passwordMatchRegister
        } = this.state;

        if (firstnameRegister
            && lastnameRegister
            && emailRegister
            && emailMatchRegister
            && passwordRegister
            && passwordMatchRegister
            && passwordRegister.length > PASSWORD_LENGTH
            && emailRegister === emailMatchRegister
            && passwordRegister === passwordMatchRegister
        ) {
            this.setState({ isRegisterDisabled: false });
        } else {
            this.setState({ isRegisterDisabled: true });
        }
    }

    redirectOrGetState(props) {
        const {
            showOverlay,
            setHeaderState,
            isPasswordForgotSend,
            device
        } = props;

        const { location: { pathname, state: { isForgotPassword } = {} } } = history;

        const state = {
            state: isSignedIn() ? STATE_LOGGED_IN : STATE_SIGN_IN,
            isPasswordForgotSend,
            isLoading: false,
            emailLogin: '',
            passwordLogin: '',
            emailRegister: '',
            emailMatchRegister: '',
            firstnameRegister: '',
            lastnameRegister: '',
            passwordRegister: '',
            passwordMatchRegister: '',
            isSignInDisabled: true,
            isRegisterDisabled: true,
            showForgotPasswordPopup: false,
            isConfirmed: false,
            activeTab: 'login'
        };

        // if customer got here from forgot-password
        if (pathname !== '/forgot-password' && !isForgotPassword) {
            return state;
        }

        state.state = STATE_FORGOT_PASSWORD;

        setHeaderState({
            name: CUSTOMER_SUB_ACCOUNT,
            title: 'Forgot password',
            onBackClick: (e) => {
                history.push({ pathname: appendWithStoreCode('/my-account') });
                this.handleSignIn(e);
            }
        });

        if (device.isMobile) {
            history.push({ pathname: appendWithStoreCode('/my-account'), state: { isForgotPassword: true } });
            return state;
        }

        showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);

        return state;
    }

    onDataChange(state) {
        this.setState(state);
    }

    onVisible() {
        const { setHeaderState, isCheckout, device } = this.props;

        if (device.isMobile && !isCheckout) {
            setHeaderState({ name: CUSTOMER_ACCOUNT, title: __('Sign in') });
        }
    }

    onSignIn() {
        const {
            requestCustomerData
        } = this.props;

        if (isSignedIn()) {
            requestCustomerData();

            // TODO uncomment that
            // if (window.pageData) {
            //     if (window.pageData.customer) {
            //         window.pageData.customer.email = email;
            //     } else {
            //         window.pageData.customer = { email };
            //     }
            // }

            // BrowserDatabase.setItem(email, CUSTOMER_EMAIL_COOKIE);
        }
    }

    onFormError() {
        this.setState({ isLoading: false });
    }

    stopLoading = () => this.setState({ isLoading: false });

    stopLoadingAndHideOverlay = () => {
        const { hideActiveOverlay } = this.props;
        this.stopLoading();
        hideActiveOverlay();
    };

    handleForgotPassword(e) {
        const { showOverlay, showPopup } = this.props;

        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({ showForgotPasswordPopup: true }, () => {
            showPopup(__('Forgot your password?'));
            showOverlay(MOBILE_FORGOT_PASSWORD_POPUP);
        });
    }

    handleSignIn(e) {
        const { setHeaderState } = this.props;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_SIGN_IN });

        setHeaderState({
            name: CUSTOMER_ACCOUNT,
            title: __('Sign in')
        });
    }

    handleCreateAccount(e) {
        const { setHeaderState } = this.props;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_CREATE_ACCOUNT });

        setHeaderState({
            name: CUSTOMER_SUB_ACCOUNT,
            title: __('Create account'),
            onBackClick: () => this.handleSignIn(e)
        });
    }

    render() {
        const { minimunPasswordLength } = this.props;
        const range = { min: minimunPasswordLength, max: 64 };

        return (
            <MobileLoginComponent
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              range={ range }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLoginContainer);
