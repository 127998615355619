/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import ForgotPassword from 'Component/ForgotPassword';
import Form from 'Component/Form';
import { ARABIC_LOCALE, ENGLISH_LOCALE } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import isArabic from 'Util/Arabic';
import isKsa from 'Util/Arabic/isKsa';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { SIGN_EMAIL_ID, SIGN_PASSWORD_ID } from './MyAccountSignIn.config';

import './MyAccountSignIn.style';

/** @namespace Scandipwa/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    static propTypes = {
        ...super.propTypes,
        isNoTitle: PropTypes.bool.isRequired,
        isSignInDisabled: PropTypes.bool.isRequired,
        onDataChange: PropTypes.func.isRequired,
        showForgotPasswordPopup: PropTypes.bool.isRequired,
        locale: PropTypes.string.isRequired
    };

    renderHeading() {
        const { isNoTitle } = this.props;

        if (isNoTitle) {
            return null;
        }

        return (
            <div block="LoginTitle">
                <h3>{ __('Login') }</h3>
            </div>
        );
    }

    renderMessage() {
        return (
            <div block="LoginMessage">
                <span>{ __('You have a membership with us. Sign in or continue as a guest.') }</span>
            </div>
        );
    }

    renderActions() {
        const {
            handleForgotPassword,
            isSignInDisabled,
            showForgotPasswordPopup,
            isCheckout
        } = this.props;

        return (
            <div block="MyAccountSignIn" elem="LoginButton" mods={ { isCheckout } }>
                <button
                  block="Button"
                  elem={ isCheckout ? 'Checkout' : 'Primary' }
                  mods={ { withArrow: true } }
                  disabled={ isSignInDisabled }
                >
                    { __('Sign in') }
                </button>
                <button
                  block="Button"
                  elem="Forgot"
                  type="Button"
                  mods={ { likeLink: true } }
                  onClick={ handleForgotPassword }
                >
                    { __('Forgot Your Password?') }
                </button>
                { showForgotPasswordPopup ? <ForgotPassword /> : null }
            </div>
        );
    }

    renderFields() {
        const {
            emailValue,
            onDataChange,
            minimunPasswordLength,
            isFromCheckout
        } = this.props;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: SIGN_EMAIL_ID,
                      name: 'email',
                      defaultValue: emailValue,
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  events={ {
                      onChange: onDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Password') }
                  attr={ {
                      id: SIGN_PASSWORD_ID,
                      name: 'password',
                      autocomplete: 'current-password'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      isRequired: !isFromCheckout,
                      range: {
                          min: minimunPasswordLength
                      },
                      customErrorMessages: {
                          onRangeFailMin: __('Password should be at least %s characters long', minimunPasswordLength)
                      }
                  } }
                  events={ {
                      onChange: onDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
            </>
        );
    }

    getTermsOfUseLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE:
            return isKsa() ? '/terms-of-use.html' : '/ar/terms-of-use.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/terms-of-use.html' : '/terms-of-use.html';
        default:
            return '/terms-of-use.html';
        }
    }

    getPrivacyPolicyLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE: // don' t have arabic version yet
            return isKsa() ? '/privacy-policy.html' : '/ar/privacy-policy.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/privacy-policy.html' : '/privacy-policy.html';
        default:
            return '/politica-de-privacidad.html';
        }
    }

    // Arabic sentence structure is totally different which does not allow us to use translation in this case.
    renderTermsOfUseMessage() {
        if (isArabic()) {
            return (
                <p block="LoginForm" elem="PolicyMessage">
                    يرجى العلم أنك توافق عبر تسجيل دخولك على
                    <span>&nbsp;</span>
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getPrivacyPolicyLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Privacy Policy') }
                    </a>
                    { __(' and ') }
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getTermsOfUseLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Terms of use') }
                    </a>
                    <span>&nbsp;</span>
                    الخاصة بـ PUMA.
                </p>
            );
        }

        return (
            <p block="LoginForm" elem="PolicyMessage">
                { __('By logging in, you agree to PUMA’s ') }
                <a
                  block="Link"
                  mix={ { block: 'Link', elem: 'Primary' } }
                  href={ this.getPrivacyPolicyLink() }
                  target="_blank"
                  rel="noreferrer"
                >
                    { __('Privacy Policy') }
                </a>
                { __(' and ') }
                <a
                  block="Link"
                  mix={ { block: 'Link', elem: 'Primary' } }
                  href={ this.getTermsOfUseLink() }
                  target="_blank"
                  rel="noreferrer"
                >
                    { __('Terms of use') }
                </a>
                .
            </p>
        );
    }

    renderForm() {
        const {
            onSignInSuccess,
            onFormError,
            isCheckout
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                { !isCheckout && this.renderHeading() }
                { this.renderFields() }
                { isCheckout && this.renderMessage() }
                { this.renderTermsOfUseMessage() }
                { this.renderActions() }
            </Form>
        );
    }

    render() {
        const { isCheckout } = this.props;

        return (
            <div block="MyAccountSignIn" mods={ { isCheckout } }>
                { this.renderForm() }
            </div>
        );
    }
}

export default MyAccountSignInComponent;
