/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { fetchQuery } from 'Util/Request';

import CheckoutComQuery from '../../query/CheckoutCom.query';
import CheckoutComCardComponent from './CheckoutComCard.component';

/** @namespace Checkoutcom/Component/CheckoutComCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    default_country: state.ConfigReducer.default_country,
    locale: state.ConfigReducer.code
});

/** @namespace Checkoutcom/Component/CheckoutComCard/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Checkoutcom/Component/CheckoutComCard/Container */
export class CheckoutComCardContainer extends PureComponent {
    firstnameInput = {};

    lastnameInput = {};

    // eslint-disable-next-line react/sort-comp
    firstNameInputListener = () => null;

    // eslint-disable-next-line react/sort-comp
    lastNameInputListener = () => null;

    static propTypes = {
        setCheckoutComCardData: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        billingAddress: PropTypes.object,
        default_country: PropTypes.string.isRequired,
        customer: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired
    };

    static defaultProps = {
        billingAddress: {}
    };

    /**
     * Container methods that should be available on component
     * @type {{}}
     */
    containerMethods = {
        requestConfig: this.requestConfig.bind(this),
        loadWidgetScript: this.loadFrameScript.bind(this)
    };

    /**
     * Constructor
     * @param props
     * @param context
     */
    __construct(props, context) {
        super.__construct(props, context);

        this.state = {
            isLoading: false,
            public_key: '',
            style: '',
            debug: false,
            language_fallback: ''
        };
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        const { setOrderButtonEnableStatus } = this.props;

        setOrderButtonEnableStatus(false);

        this.firstnameInput = document.querySelector(`#${ SHIPPING_STEP } [name="firstname"]`);
        this.lastnameInput = document.querySelector(`#${ SHIPPING_STEP } [name="lastname"]`);

        if (this.firstnameInput) {
            this.firstNameInputListener = ({ target: { value } }) => {
                if (!window.Frames) {
                    return;
                }

                const firstname = value;
                const lastname = this.lastnameInput.value;

                if (window.Frames.isCardValid()) {
                    window.Frames.cardholder = {
                        name: `${ firstname } ${ lastname }`
                    };

                    window.Frames.submitCard();
                }

                setOrderButtonEnableStatus(false);
            };

            this.firstnameInput.addEventListener('change', this.firstNameInputListener);
        }

        if (this.lastnameInput) {
            this.lastNameInputListener = ({ target: { value } }) => {
                if (!window.Frames) {
                    return;
                }

                const firstname = this.firstnameInput.value;
                const lastname = value;

                if (window.Frames.isCardValid()) {
                    window.Frames.cardholder = {
                        name: `${ firstname } ${ lastname }`
                    };

                    window.Frames.submitCard();
                }

                setOrderButtonEnableStatus(false);
            };

            this.lastnameInput.addEventListener('change', this.lastNameInputListener);
        }
    }

    /**
     * Component will unmount
     */
    componentWillUnmount() {
        if (!window.Frames) {
            return;
        }

        const {
            Events: {
                CARD_VALIDATION_CHANGED,
                READY,
                CARD_TOKENIZED,
                FRAME_VALIDATION_CHANGED
            }
        } = window.Frames;

        window.Frames.removeAllEventHandlers(CARD_VALIDATION_CHANGED);
        window.Frames.removeAllEventHandlers(READY);
        window.Frames.removeAllEventHandlers(CARD_TOKENIZED);
        window.Frames.removeAllEventHandlers(FRAME_VALIDATION_CHANGED);

        // eslint-disable-next-line fp/no-delete
        delete window.Frames;

        if (this.firstnameInput) {
            this.firstnameInput.removeEventListener('change', this.firstNameInputListener);
        }

        if (this.lastnameInput) {
            this.lastnameInput.removeEventListener('change', this.lastNameInputListener);
        }
    }

    /**
     * Load configuration
     * @return {Promise<Request>}
     */
    requestConfig() {
        this.setState({ isLoading: true });

        const promise = fetchQuery([CheckoutComQuery.getCardConfigQuery()]);

        promise.then(
            /** @namespace Checkoutcom/Component/CheckoutComCard/Container/CheckoutComCardContainer/requestConfig/promise/then */
            ({ storeConfig: { checkout_com: { card } } }) => this.setState({
                ...card
            }),
            /** @namespace Checkoutcom/Component/CheckoutComCard/Container/CheckoutComCardContainer/requestConfig/promise/then/catch */
            () => this.setState({ isLoading: false })
        );

        return promise;
    }

    /**
     * Load frame script
     */
    loadFrameScript(callback) {
        const frameUrl = 'https://cdn.checkout.com/js/framesv2.min.js';
        const frameScriptId = 'checkout-com-frame-script';

        const existingScript = document.getElementById(frameScriptId);

        if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
        }

        const script = document.createElement('script');
        script.id = frameScriptId;
        script.src = frameUrl;
        script.async = true;
        script.onload = callback;
        document.head.insertBefore(script, document.head.childNodes[0]);
    }

    /**
     * Get error list
     * @returns {*}
     * @private
     */
    _getErrors = () => ({
        'card-number': __('Please enter a valid card number'),
        'expiry-date': __('Please enter a valid expiry date'),
        cvv: __('Please enter a valid CVV code')
    });

    /**
     * Get error message
     * @param event
     * @returns {string|*}
     * @private
     */
    _getErrorMessage = (event) => {
        if (event.isValid || event.isEmpty) {
            return '';
        }

        return this._getErrors()[event.element];
    };

    /**
     * On validation changed event
     * @param event
     * @private
     */
    _onValidationChanged = (event) => {
        const targetSelector = '.CheckoutComCardPayment-ErrorMessage';
        const errorMessage = document.querySelector(targetSelector);
        errorMessage.textContent = this._getErrorMessage(event);
    };

    getDefaultAddressId(addresses) {
        let id = null;

        if (addresses) {
            addresses.map((address) => {
                if (address.default_shipping) {
                    id = address.id;
                }
            });

            if (id === null && addresses.length > 0) {
                return addresses[0].id;
            }
        }

        return id;
    }

    /**
     * Add events to Frames.
     * @return {void}
     */
    addFramesEvents = () => {
        if (!window.Frames) {
            return;
        }

        const {
            setCheckoutComCardData,
            setOrderButtonEnableStatus
        } = this.props;

        window.Frames.addEventHandler(
            window.Frames.Events.READY,
            () => this.setState({ isLoading: false })
        );

        window.Frames.addEventHandler(
            window.Frames.Events.CARD_VALIDATION_CHANGED,
            () => {
                const { customer: { selectedBillingId, selectedAddressId, addresses = [] } } = this.props;

                const addressId = selectedBillingId || selectedAddressId;
                const defaultAddressId = this.getDefaultAddressId(addresses);
                const currentAddressId = addressId === undefined ? defaultAddressId : addressId;

                const address = addresses.length ? addresses.find(({ id }) => id === currentAddressId) : {};

                const { firstname: addressFirstname, lastname: addressLastname } = address;

                // We trigger address save when we place order, which means `billingAddress` object
                // is empty at moment of calling tokenization
                const firstname = addressFirstname || (this.firstnameInput ? this.firstnameInput.value : null);
                const lastname = addressLastname || (this.lastnameInput ? this.lastnameInput.value : null);

                if (window.Frames.isCardValid()) {
                    window.Frames.cardholder = {
                        name: `${ firstname } ${ lastname }`
                    };

                    window.Frames.submitCard();
                }

                setOrderButtonEnableStatus(false);
            }
        );

        window.Frames.addEventHandler(
            window.Frames.Events.CARD_TOKENIZED,
            (event) => {
                setCheckoutComCardData({ cardToken: event.token, cardBin: event.bin });
                window.Frames.enableSubmitForm();

                setOrderButtonEnableStatus(true);
            }
        );

        window.Frames.addEventHandler(
            window.Frames.Events.FRAME_VALIDATION_CHANGED,
            this._onValidationChanged.bind(this)
        );
    };

    /**
     * Render
     *
     * @returns {*}
     */
    render() {
        return (
            <CheckoutComCardComponent
              { ...this.containerMethods }
              { ...this.state }
              { ...this.props }
              addFramesEvents={ this.addFramesEvents }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComCardContainer);
