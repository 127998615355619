/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isArabic } from 'Util/Arabic';
import { formatCurrency, roundPrice } from 'Util/Price';

export class MyAccountOrderPopupComponentPlugin {
    renderTotals = this.renderTotals.bind(this);

    renderCustomsDuty(customs_duty_amount, currency_code) {
        return (
            <>
                <dt>{ __('Estimated duties:') }</dt>
                <dd>
                    { isArabic()
                        ? `${roundPrice(customs_duty_amount) } ${formatCurrency(currency_code)}`
                        : `${formatCurrency(currency_code)}${roundPrice(customs_duty_amount) }` }
                </dd>
            </>
        );
    }

    renderCashOnDeliveryFee(amount, currency_code) {
        return (
            <>
                <dt>{ __('Cash on Delivery Fee') }</dt>
                <dd>
                    { isArabic()
                        ? `${roundPrice(amount) } ${formatCurrency(currency_code)}`
                        : `${formatCurrency(currency_code)}${roundPrice(amount) }` }
                </dd>
            </>
        );
    }

    renderTotals(args, callback, instance) {
        const { order: { base_order_info, shipping_info }, currency_code } = instance.props;
        const {
            grand_total,
            sub_total,
            discount_amount,
            cash_on_delivery_fee,
            customs_duty_amount
        } = base_order_info || {};
        const { shipping_incl_tax } = shipping_info;

        const subTotalWithoutCustomsDuty = sub_total - customs_duty_amount;

        return (
            <div block="MyAccountOrderPopup" elem="OrderWrapper">
                <h4>{ __('Order Total') }</h4>
                <dl>
                    <dt>{ __('Subtotal: ') }</dt>
                    <dd>
                        { isArabic()
                            ? `${roundPrice(subTotalWithoutCustomsDuty) } ${formatCurrency(currency_code)}`
                            : `${formatCurrency(currency_code)}${roundPrice(subTotalWithoutCustomsDuty) }` }
                    </dd>
                    { discount_amount < 0 ? instance.renderDiscount(discount_amount, currency_code) : null }
                    { shipping_incl_tax > 0
                        ? instance.renderShippingTotal(shipping_incl_tax, currency_code)
                        : null }
                    { cash_on_delivery_fee > 0
                        ? this.renderCashOnDeliveryFee(cash_on_delivery_fee, currency_code) : null }
                    { customs_duty_amount > 0 ? this.renderCustomsDuty(customs_duty_amount, currency_code) : null }
                    <dt>{ __('Grand total: ') }</dt>
                    <dd>
                        { isArabic()
                            ? `${roundPrice(grand_total) } ${formatCurrency(currency_code)}`
                            : `${formatCurrency(currency_code)}${roundPrice(grand_total) }` }
                    </dd>
                </dl>
            </div>
        );
    }
}

export const { renderTotals } = new MyAccountOrderPopupComponentPlugin();

export default {
    'Scandipwa/Component/MyAccountOrderPopup/Component': {
        'member-function': {
            renderTotals
        }
    }
};
