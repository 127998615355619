/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { updateEventData } from 'Store/Event/Event.action'; // Note: Event store was not created by plugin due to a bug
import getStore from 'Util/Store';

import { handlePageDataEventPromiseError } from '../../util/Events/PageData/Event';

export const onCustomerSaveFetchMutationThen = (args, callback, _context) => {
    const {
        updateCustomer: {
            customer: {
                is_subscribed
            }
        }
    } = args[0];

    // eslint-disable-next-line fp/no-let
    let additionalEventData = { widget: 'page', name: 'subscribed' };

    if (is_subscribed) {
        this.dispatchEventData({ ...this._getEventData(), ...additionalEventData });
    } else {
        additionalEventData = { name: 'cancelled', widget: 'page' };

        this.dispatchEventData({ ...this._getEventData(), ...additionalEventData });
    }

    return callback(...args);
};

export const fetchMutationThenSuccess = (args, callback, _context) => {
    const additionalEventData = { name: 'subscribed' };

    this.dispatchEventData({ ...this._getEventData(), ...additionalEventData });

    return callback(...args);
};

export const dispatchEventData = (eventData) => {
    const store = getStore();
    store.dispatch(updateEventData(eventData));
};

export const _getEventData = () => ({
    widget: 'modal',
    eventType: 'newsletter'
});

export default {
    'Component/Subscription/Container/fetchMutationThenError': {
        function: (args, callback, context) => handlePageDataEventPromiseError(
            args,
            callback,
            context,
            _getEventData(),
            updateEventData
        )
    },
    'Component/MyAccountCustomerPopup/Container/onCustomerSaveFetchMutationThen': {
        function: onCustomerSaveFetchMutationThen
    },
    'Component/Subscription/Container/fetchMutationThenSuccess': {
        function: fetchMutationThenSuccess
    }
};
