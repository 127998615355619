/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export * from 'SourceComponent/Router/Router.config';

export const NOTIFICATIONS = 'NOTIFICATIONS';
export const BEFORE_ITEMS_TYPE = 'BEFORE_ITEMS_TYPE';
export const SWITCH_ITEMS_TYPE = 'SWITCH_ITEMS_TYPE';
export const AFTER_ITEMS_TYPE = 'AFTER_ITEMS_TYPE';
export const MOBILE_WIDTH = 767;
export const HEADER_IS_MOBILE_UNTIL = 1180;
export const CACHE_NAME = 'app-runtime-static';
export const STORE_CODE_UAE = 'ar';
export const STORE_CODE_KSA_AR = 'ksa_ar';
export const STORE_CODE_KSA_EN = 'ksa_en';
export const STORE_CODE_EN = 'en';
export const CUSTOMER_EMAIL_COOKIE = 'customer_email';
export const SWITCH_STORE_LANGUAGE = 'switch_store_language';
