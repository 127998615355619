/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author      Gela Gzirishvili <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

/** @namespace Partnerships/Query/MembershipCardImage/Query */
export class MembershipCardImageQuery {
    /**
     * Get membership card image query
     */
    getMutation(file) {
        return new Field('MembershipCardImage')
            .addArgument('file', 'PartnershipImageInput!', file)
            .addField('url');
    }
}

export default new MembershipCardImageQuery();
