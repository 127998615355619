/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PDP_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import { EVENT_GTM_PAGE_DATA } from '../../util/Event';
import Event from '../../util/Event/Event';

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/dispatchProductPageDataEvent */
// eslint-disable-next-line no-unused-vars
export const state = (prop, instance) => ({
    prevAreDetailsLoaded: false,
    ...prop
});

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/dispatchProductPageDataEvent */
export const dispatchProductPageDataEvent = (quantity, prevQuantity) => {
    Event.dispatch(
        EVENT_GTM_PAGE_DATA,
        {
            type: PDP_TYPE,
            quantity,
            prevQuantity
        }
    );
};

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/componentDidUpdate */
export const componentDidUpdate = (args, callback, instance) => {
    const { quantity, areDetailsLoaded } = instance.props;
    // Not using this from previous props as there can be scenario when areDetailsLoaded === prevAreDetailsLoaded on every
    // component update
    const { prevAreDetailsLoaded } = instance.state;

    if (areDetailsLoaded !== prevAreDetailsLoaded) {
        dispatchProductPageDataEvent(quantity, quantity);

        instance.setState({ prevAreDetailsLoaded: !prevAreDetailsLoaded });
    }

    return callback(...args);
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            componentDidUpdate
        },
        'member-property': {
            state
        }
    }
};
