/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isMobile as sourceIsMobile } from 'SourceUtil/Mobile/isMobile';

export * from 'SourceUtil/Mobile/isMobile';

export const isMobile = {
    ...sourceIsMobile,
    any: () => window.matchMedia('(max-width: 767px)').matches,
    tablet: () => window.matchMedia('(min-width: 768px) and (max-width: 1180px)').matches
};

export default isMobile;
