/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AdyenCheckout from '@adyen/adyen-web';

import {
    DEFAULT_COUNTRY_KSA, DEFAULT_COUNTRY_UAE
} from 'Component/CheckoutNewAddressForm/CheckoutNewAddressForm.config';
import CheckoutQuery from 'Query/Checkout.query';
import { SHIPPING_ADDRESS_KEY } from 'Route/Checkout/Checkout.config';
import { fetchMutation, fetchQuery } from 'SourceUtil/Request';
import { updateEmail } from 'Store/Checkout/Checkout.action';
import { showNotification } from 'Store/Notification/Notification.action';
import isKsa from 'Util/Arabic/isKsa';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId } from 'Util/Cart';
import getStore from 'Util/Store';

import AdyenPaymentStatusQuery from '../query/AdyenPaymentStatus.query';
import SetPaymentMethodAndPlaceOrderMutation from '../query/SetPaymentMethodAndPlaceOrder.mutation';
import { ADYEN_APPLE_PAY_METHOD_CODE, ADYEN_GC_METHOD_CODE, getCheckoutEnvironment } from './Adyen';
// eslint-disable-next-line import/no-cycle
import { validateActionOrPlaceOrder } from './AdyenHppMethod';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "cartDispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCheckout/buildAdyenCheckoutComponent */
export const buildAdyenCheckoutComponent = (
    paymentMethodsResponse,
    handleOnAdditionalDetails,
    handleOnCancel = undefined,
    handleOnSubmit = undefined
) => {
    if (!paymentMethodsResponse) {
        return null;
    }

    const {
        adyen_client_key_test: adyenClientKeyTest,
        adyen_client_key_live: adyenClientKeyLive,
        adyen_demo_mode: isDemoMode
    } = getStore().getState().ConfigReducer;

    const configuration = {
        environment: getCheckoutEnvironment(isDemoMode),
        clientKey: isDemoMode ? adyenClientKeyTest : adyenClientKeyLive,
        paymentMethodsResponse,
        onAdditionalDetails: handleOnAdditionalDetails,
        onCancel: handleOnCancel,
        onSubmit: handleOnSubmit
    };

    // eslint-disable-next-line new-cap
    return configuration;
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCheckout/getAddressInfo */
export const getAddressInfo = (shippingContact) => {
    const {
        locality: city,
        givenName: firstname,
        familyName: lastname,
        addressLines,
        phoneNumber: telephone
    } = shippingContact;

    const country_id = isKsa() ? DEFAULT_COUNTRY_KSA : DEFAULT_COUNTRY_UAE;

    return {
        city,
        country_id,
        firstname,
        lastname,
        region: addressLines[0],
        region_id: 0,
        street: addressLines,
        telephone
    };
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCheckout/handleApplePayExpressAuthorize */
export const handleApplePayExpressAuthorize = async (
    successEvent,
    failEvent,
    paymentData,
    shippingMethods,
    applePayExpressSource,
    setApplePayExpressLoaderState
) => {
    try {
        const [{ identifier: expressId }] = shippingMethods;
        const {
            payment: {
                shippingContact,
                shippingContact: {
                    emailAddress,
                    countryCode
                }
            }
        } = paymentData;

        const country_id = isKsa() ? DEFAULT_COUNTRY_KSA : DEFAULT_COUNTRY_UAE;

        if (countryCode !== country_id) {
            getStore().dispatch(showNotification('error', __('Please use an address from %s', country_id)));

            throw new Error(__('Please use an address from %s', country_id));
        }

        if (setApplePayExpressLoaderState) {
            setApplePayExpressLoaderState(true);
        }

        const cart_id = getCartId();

        if (!isSignedIn()) {
            getStore().dispatch(updateEmail(emailAddress));
            await fetchMutation(CheckoutQuery.getSaveGuestEmailMutation(emailAddress, cart_id));
        }

        const address = getAddressInfo(shippingContact);

        const saveAddressInfo = {
            billing_address: address,
            shipping_address: address,
            shipping_carrier_code: expressId,
            shipping_method_code: expressId
        };

        await fetchMutation(CheckoutQuery.getSaveAddressInformation(
            saveAddressInfo,
            getCartId()
        ));

        await CartDispatcher.then(
            /** @namespace Route/Checkout/Container/then */
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(getStore().dispatch)
        );

        const setBillingAddressOnCart = {
            ...address,
            country_code: address.country_id,
            save_in_address_book: false
        };

        // eslint-disable-next-line fp/no-delete
        delete setBillingAddressOnCart.country_id;

        BrowserDatabase.setItem(address, SHIPPING_ADDRESS_KEY);

        await fetchMutation(CheckoutQuery.getSetBillingAddressOnCart({
            cart_id,
            billing_address: {
                address: setBillingAddressOnCart
            }
        }));

        const stateData = JSON.stringify(window.adyenStateData.data);
        const additionalData = {
            cartId: cart_id,
            stateData,
            guestEmail: '',
            brand_code: ADYEN_APPLE_PAY_METHOD_CODE
        };

        const {
            setPaymentMethodAndPlaceOrder: {
                order: {
                    order_number: orderNumber
                } = {}
            } = {}
        } = await fetchMutation(SetPaymentMethodAndPlaceOrderMutation.setPaymentMethodAndPlaceOrder({
            cart_id,
            payment_method: {
                code: ADYEN_APPLE_PAY_METHOD_CODE,
                adyen_additional_data: additionalData
            },
            apple_pay_express_source: applePayExpressSource
        }));

        window.orderNumber = orderNumber;
        const {
            adyenPaymentStatus
        } = await fetchQuery(AdyenPaymentStatusQuery.getAdyenPaymentStatus(orderNumber, cart_id));

        if (window.setOrderButtonEnableStatus) {
            window.setOrderButtonEnableStatus(false);
        }

        successEvent();

        return validateActionOrPlaceOrder(adyenPaymentStatus, orderNumber);
    } catch (error) {
        console.log('error >>>', error);
        getStore().dispatch(showNotification('error', __('An error occurred, please try another payment method.')));
        failEvent();

        if (setApplePayExpressLoaderState) {
            setApplePayExpressLoaderState(false);
        }

        return null;
    }
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenCheckout/mountAdyenPaymentMethodComponent */
export const mountAdyenPaymentMethodComponent = async (
    paymentMethodType,
    configuration,
    elementLabel,
    applePayExpressSource,
    setApplePayExpressLoaderState
) => {
    const {
        adyen_demo_mode: isDemoMode
    } = getStore().getState().ConfigReducer;

    if (!elementLabel) {
        return false;
    }

    try {
        // vvv Gift cards do not have a Web Component (they fallback to HPP)
        if (configuration.type === ADYEN_GC_METHOD_CODE) {
            return false;
        }

        // eslint-disable-next-line new-cap
        const checkout = await AdyenCheckout(configuration);

        const adyenComponent = 'requiredShippingContactFields' in configuration ? checkout.create(paymentMethodType, {
            requiredShippingContactFields: configuration.requiredShippingContactFields,
            shippingMethods: configuration.shippingMethods,
            onAuthorized: (successEvent, failEvent, paymentData) => {
                handleApplePayExpressAuthorize(
                    successEvent,
                    failEvent,
                    paymentData,
                    configuration.shippingMethods,
                    applePayExpressSource,
                    setApplePayExpressLoaderState
                );
            }
        }).mount(elementLabel) : checkout.create(paymentMethodType).mount(elementLabel);

        if ('requiredShippingContactFields' in configuration) {
            window.adyenExpressElement = checkout;
            window.adyenExpressComponent = adyenComponent;
        } else {
            window.adyenElement = checkout;
            window.adyenComponent = adyenComponent;
        }
    } catch (error) {
        if (getCheckoutEnvironment(isDemoMode) === 'test') {
            console.log('error >>> ', error);
        }
    }

    return false;
};
