/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import CheckoutComApplePayContainer from '../component/CheckoutComApplePay/CheckoutComApplePay.container';
import CheckoutComCardContainer from '../component/CheckoutComCard/CheckoutComCard.container';
import CheckoutComGooglePay from '../component/CheckoutComGooglePay';

export const CHECKOUTCOM_CC = 'checkoutcom_card_payment';
export const CHECKOUTCOM_APPLE_PAY = 'checkoutcom_apple_pay';
export const CHECKOUTCOM_GOOGLE_PAY = 'checkoutcom_google_pay';

export class CheckoutPaymentsComponent {
    /**
     * Payment render map
     * @param originalMember
     * @param instance
     * @returns {*}
     */
    aroundPaymentRenderMap = (originalMember, instance) => ({
        ...originalMember,
        [CHECKOUTCOM_CC]: this.renderCheckoutComCardPayment.bind(instance),
        [CHECKOUTCOM_APPLE_PAY]: this.renderCheckoutComApplePayPayment.bind(instance),
        [CHECKOUTCOM_GOOGLE_PAY]: this.renderCheckoutComGooglePayPayment.bind(instance)
    });

    /**
     * Render checkout.com card payment
     * @returns {*}
     */
    renderCheckoutComCardPayment() {
        const { setCheckoutComCardData, setOrderButtonEnableStatus, billingAddress } = this.props;

        return (
            <CheckoutComCardContainer
              billingAddress={ billingAddress }
              setCheckoutComCardData={ setCheckoutComCardData }
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
        );
    }

    /**
     * Render payment method
     * @returns {*}
     * @param args
     * @param callback
     * @param instance
     */
    aroundRenderPayment = (args, callback, instance) => {
        const original = callback.apply(instance, args);
        const [method] = args;
        const { code } = method;

        // Skip ApplePay method rendering if browser is not supported
        if (code === CHECKOUTCOM_APPLE_PAY && !window.ApplePaySession) {
            return null;
        }

        return original;
    };

    renderCheckoutComGooglePayPayment() {
        const {
            setOrderButtonVisibility,
            setDetailsStep,
            setLoading
        } = this.props;

        return (
            <CheckoutComGooglePay
              setOrderButtonVisibility={ setOrderButtonVisibility }
              setDetailsStep={ setDetailsStep }
              setLoading={ setLoading }
            />
        );
    }

    /**
     * Render checkout.com apple pay payment
     * @returns {*}
     */
    renderCheckoutComApplePayPayment() {
        const {
            setOrderButtonVisibility,
            billingAddress,
            savePaymentInformation,
            setDetailsStep,
            setLoading
        } = this.props;

        return (
            <CheckoutComApplePayContainer
              setCheckoutLoading={ setLoading }
              savePaymentInformation={ savePaymentInformation }
              billingAddress={ billingAddress }
              setOrderButtonVisibility={ setOrderButtonVisibility }
              setDetailsStep={ setDetailsStep }
            />
        );
    }
}

export const {
    aroundPaymentRenderMap,
    aroundRenderPayment
} = new CheckoutPaymentsComponent();

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            paymentRenderMap: [
                {
                    position: 100,
                    implementation: aroundPaymentRenderMap
                }
            ],
            renderPayment: [
                {
                    position: 100,
                    implementation: aroundRenderPayment
                }
            ]
        }
    }
};
