/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceKeyValueTable from 'SourceComponent/KeyValueTable/KeyValueTable.component';
import { isArabic } from 'Util/Arabic';

import './KeyValueTable.style';

/** @namespace Scandipwa/Component/KeyValueTable/Component */
export class KeyValueTableComponent extends SourceKeyValueTable {
    getValueFromStreet(data) {
        const { key, source: { [key]: value } } = data;

        if (!Array.isArray(value)) {
            return [value];
        }

        return value;
    }

    renderTableRow = (data) => {
        const { key } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        if (key === 'street') {
            const streets = this.getValueFromStreet(data);

            return (
                streets.map((value) => (
                    <div
                      block="TableEntry"
                      elem={ key }
                      key={ key }
                      dir={ isArabic() ? 'rtl' : 'ltr' }
                    >
                        { value }
                    </div>
                ))
            );
        }

        return (
            <div block="TableEntry" elem={ key } key={ key } dir={ isArabic() && key !== 'telephone' ? 'rtl' : 'ltr' }>
                { value }
            </div>
        );
    };

    renderTable() {
        return (
            <div block="KeyValueTable" elem="Wrapper">
                <div block="KeyValueTable">
                    { this.dataPairArray.map(this.renderTableRow) }
                </div>
            </div>
        );
    }
}

export default KeyValueTableComponent;
