/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { appendWithStoreCode } from 'Util/Url';

import { EVENT_GTM_IMPRESSIONS } from '../Event';

/**
 * Map GTM event names to config event names
 * @param name
 * @returns {string}
 */
export const mapGtmEventNames = (name) => {
    if (name.includes('impressions')) {
        return EVENT_GTM_IMPRESSIONS;
    }

    return name;
};

/**
 * Check if push event is enabled in config
 * @param eventName
 * @returns {boolean}
 */
export const isEventEnabled = (eventName) => {
    const {
        gtm: {
            events = {}
        } = {}
    } = BrowserDatabase.getItem('config') || {};

    return !!events[mapGtmEventNames(eventName)];
};

/**
 * Check if push event is happening on "/checkout" which isn't the real checkout page and causes events to fire twice
 * @returns {boolean}
 */
export const isFakeCheckoutPage = () => window.location.pathname === appendWithStoreCode(CHECKOUT_URL);
