/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceSharedTransition from 'SourceComponent/SharedTransition/SharedTransition.component';

import './SharedTransition.style';

/** @namespace Scandipwa/Component/SharedTransition/Component */
export class SharedTransitionComponent extends SourceSharedTransition {
    setTransform(key) {
        const {
            state: {
                [key]: {
                    width,
                    height,
                    left,
                    right,
                    top
                }
            }
        } = this.props;

        this.sharedContainer.current.style.cssText = `
            --shared-element-width: ${width}px;
            --shared-element-height: ${height}px;
            --shared-element-top: ${top}px;
            --shared-element-left: ${left}px;
            --shared-element-right: ${right}px;
            --shared-element-animation-speed: ${this.animationSpeed}ms;
        `;
    }
}

export default SharedTransitionComponent;
