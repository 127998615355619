/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Popup from 'Component/Popup/Popup.container';
import { isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import { fetchQuery } from 'Util/Request/Query';
import getStore from 'Util/Store';

import { AdyenPaymentsContext } from '../../context/AdyenPayments';
import AdyenPaymentsQuery from '../../query/AdyenPayments.query';
import {
    ADYEN_APPLE_PAY_METHOD_CODE,
    ADYEN_GOOGLE_PAY_METHOD_CODE,
    ADYEN_HPP_METHOD_CODE,
    ADYEN_SCHEME_PAYMENT_TYPE,
    THREE_DS2_DIV_ID,
    THREE_DS2_POPUP_ID
} from '../../util/Adyen';
import { initializeCCAdyenCheckoutComponent, renderCCPaymentMethod } from '../../util/AdyenCCMethod';
import { initializeHppAdyenCheckoutComponent, renderHppCheckoutComponent } from '../../util/AdyenHppMethod';

import '@adyen/adyen-web/dist/adyen.css';
import './AdyenCheckout.style';

/** @namespace Scandiweb/AdyenPayments/Component/AdyenCheckout/Container */
export class AdyenCheckoutContainer extends PureComponent {
    static propTypes = {
        code: PropTypes.string.isRequired,
        isApplePayExpress: PropTypes.bool.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        applePayExpressSource: PropTypes.string.isRequired
    };

    static contextType = AdyenPaymentsContext;

    prevContext = {};

    containerFunctions = {};

    state = {
        expressVisible: false,
        expressLoaderVisible: false
    };

    async componentDidMount() {
        const { setOrderButtonEnableStatus, isApplePayExpress } = this.props;
        const {
            setSelectedPaymentType,
            adyenPaymentMethods: {
                paymentMethodsResponse
            } = {}
        } = this.context;

        if (isApplePayExpress) {
            setSelectedPaymentType('applepay');
            await this.getShippingMethods();
        }

        if (paymentMethodsResponse) {
            await this.initializeAdyenPaymentComponent();
        } else {
            this.existPaymentMethodsResponse = setInterval(async () => {
                const {
                    adyenPaymentMethods: {
                        paymentMethodsResponse: paymentMethodsResponseTry
                    } = {}
                } = this.context;

                if (paymentMethodsResponseTry) {
                    clearInterval(this.existPaymentMethodsResponse);
                    await this.initializeAdyenPaymentComponent();
                    this.prevContext = this.context;

                    if (!setOrderButtonEnableStatus) {
                        return;
                    }

                    window.setOrderButtonEnableStatus = setOrderButtonEnableStatus;
                }
            }, 300);// eslint-disable-line no-magic-numbers

            return;
        }

        this.prevContext = this.context;

        if (!setOrderButtonEnableStatus) {
            return;
        }

        window.setOrderButtonEnableStatus = setOrderButtonEnableStatus;
    }

    componentDidUpdate(prevProps) {
        const { code, isApplePayExpress } = this.props;
        const { code: prevCode } = prevProps;
        const { selectedPaymentType } = this.context;
        const { selectedPaymentType: prevSelectedPaymentType } = this.prevContext;

        if (isApplePayExpress) {
            return;
        }

        if (
            prevCode !== code
            || prevSelectedPaymentType !== selectedPaymentType
        ) {
            this.initializeAdyenPaymentComponent();
        }

        this.prevContext = this.context;
    }

    componentWillUnmount() {
        clearInterval(this.existPaymentMethodsResponse);
        this.unmountAdyenPaymentComponent();
    }

    async getShippingMethods() {
        const cart_id = !isSignedIn() ? getCartId() : null;

        try {
            const {
                getApplePayExpressShipping: {
                    label,
                    price,
                    id
                }
            } = await fetchQuery(AdyenPaymentsQuery.getApplePayExpressShippingQuery(cart_id));

            this.setState({
                expressId: id,
                expressLabel: label,
                expressPrice: price,
                expressVisible: true
            });
        } catch (error) {
            console.error(error);

            this.setState({
                expressVisible: false
            });
        }
    }

    setApplePayExpressLoaderState = (newState) => {
        this.setState({ expressLoaderVisible: newState });
    };

    async initializeAdyenPaymentComponent() {
        const {
            code,
            isApplePayExpress,
            applePayExpressSource
        } = this.props;
        const {
            expressId,
            expressLabel,
            expressPrice,
            expressVisible
        } = this.state;
        const {
            selectedPaymentType,
            adyenPaymentMethods: {
                paymentMethodsResponse,
                paymentMethodsExtraDetails
            } = {}
        } = this.context;
        const { paymentMethods } = paymentMethodsResponse;

        this.unmountAdyenPaymentComponent();

        if (isApplePayExpress) {
            if (!expressVisible || !paymentMethods) {
                return;
            }

            const paymentMethod = paymentMethods.find(({ type }) => type === selectedPaymentType);

            if (!paymentMethod) {
                return;
            }

            const globalConfigs = initializeHppAdyenCheckoutComponent(paymentMethodsResponse);
            const shippingMethod = [{
                label: expressLabel,
                detail: '',
                amount: expressPrice,
                identifier: expressId
            }];

            if (paymentMethodsExtraDetails?.applepay?.configuration?.amount?.value) {
                // eslint-disable-next-line max-len
                paymentMethodsExtraDetails.applepay.configuration.amount.value = this.getCorrectedAmountForAppleExpress(paymentMethodsExtraDetails.applepay.configuration.amount.value);
            }

            globalConfigs.shippingMethods = shippingMethod;
            globalConfigs.requiredShippingContactFields = ['postalAddress', 'name', 'email', 'phone'];
            renderHppCheckoutComponent(
                paymentMethod,
                paymentMethodsExtraDetails,
                selectedPaymentType,
                globalConfigs,
                true,
                applePayExpressSource,
                this.setApplePayExpressLoaderState
            );

            return;
        }

        if (
            code === ADYEN_HPP_METHOD_CODE
            || code === ADYEN_GOOGLE_PAY_METHOD_CODE
            || code === ADYEN_APPLE_PAY_METHOD_CODE
        ) {
            const paymentMethod = paymentMethods.find(({ type }) => type === selectedPaymentType);
            const globalConfigs = initializeHppAdyenCheckoutComponent(paymentMethodsResponse);
            renderHppCheckoutComponent(
                paymentMethod,
                paymentMethodsExtraDetails,
                selectedPaymentType,
                globalConfigs,
                false
            );
        } else {
            const paymentMethod = paymentMethods.find(({ type }) => type === ADYEN_SCHEME_PAYMENT_TYPE);
            const globalConfigs = initializeCCAdyenCheckoutComponent(paymentMethodsResponse);
            renderCCPaymentMethod(paymentMethod, paymentMethodsExtraDetails, globalConfigs);
        }
    }

    getCorrectedAmountForAppleExpress(initialValue) {
        const totals = getStore().getState().CartReducer?.cartTotals?.prices;

        if (!totals) {
            return initialValue;
        }
        const { shipping_incl_tax = 0, grand_total: { value: grandTotalValue } } = totals;
        const { expressId, expressPrice } = this.state;

        // eslint-disable-next-line no-magic-numbers, max-len
        return expressId === 'freeshipping' ? (grandTotalValue - shipping_incl_tax) * 100 : (grandTotalValue - shipping_incl_tax + (expressPrice * 1)) * 100;
    }

    unmountAdyenPaymentComponent() {
        if (!window.adyenComponent) {
            return;
        }

        window.adyenComponent.unmount();
    }

    getActivePaymentMethod() {
        const {
            code
        } = this.props;
        const {
            selectedPaymentType
        } = this.context;

        return code === ADYEN_HPP_METHOD_CODE ? selectedPaymentType : code;
    }

    containerProps = () => {};

    render() {
        const { isApplePayExpress } = this.props;
        const { expressVisible, expressLoaderVisible } = this.state;

        if (isApplePayExpress) {
            if (!expressVisible) {
                return null;
            }

            return (
                <>
                    <Loader isLoading={ expressLoaderVisible } />
                    <div id={ `adyen-checkout-${ this.getActivePaymentMethod() }-express` } />
                </>
            );
        }

        return (
            <>
                <div id={ `adyen-checkout-${ this.getActivePaymentMethod() }` } />
                <div id={ THREE_DS2_POPUP_ID } />
                <Popup
                  id={ THREE_DS2_POPUP_ID }
                  mix={ { block: 'AdyenCC', elem: 'Popup' } }
                  isCloseOnOutsideClick={ false }
                  isHideCloseButton
                >
                    <div id={ THREE_DS2_DIV_ID } />
                </Popup>
            </>
        );
    }
}

export default AdyenCheckoutContainer;
