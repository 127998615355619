export const APPEND_PAGE = 'APPEND_PAGE';
export const APPEND_LAST_LOCAL_PAGE = 'APPEND_LAST_LOCAL_PAGE';
export const UPDATE_PRODUCT_LIST_ITEMS = 'UPDATE_PRODUCT_LIST_ITEMS';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';
export const UPDATE_PAGE_LOAD_STATUS = 'UPDATE_PAGE_LOAD_STATUS';
export const UPDATE_PAGE_NUMBER_TO_SHOW = 'UPDATE_PAGE_NUMBER_TO_SHOW';

/**
 * Append page to the list.
 * @param promo_tile {Array<String>} List of promo tiles for a category
 * @param {Array<Object>} items List of products returned from fetch
 * @param {Number} currentPage Number of requested page
 * @namespace Scandipwa/Store/ProductList/Action/appendPage */
export const appendPage = (
    promo_tile,
    items,
    currentPage
) => ({
    type: APPEND_PAGE,
    promo_tile,
    items,
    currentPage
});

/**
 * Append last page with local data to renderable page list.
 * @param {Number} currentPage Number of requested page
 * @namespace Scandipwa/Store/ProductList/Action/appendLastLocalPage */
export const appendLastLocalPage = (currentPage) => ({
    type: APPEND_LAST_LOCAL_PAGE,
    currentPage
});

/**
 * Update product list with new list (rewrite if already exists).
 * @param promo_tile {Array<String>} List of promo tiles for a category
 * @param {Array<Object>} items List of products returned from fetch
 * @param {Number} currentPage Number of requested page
 * @param {Number} total_count Number of requested page
 * @param total_pages
 * @param args
 * @return {void}
 * @namespace Scandipwa/Store/ProductList/Action/updateProductListItems */
export const updateProductListItems = (
    promo_tile,
    items,
    currentPage,
    total_count,
    total_pages,
    args
) => ({
    type: UPDATE_PRODUCT_LIST_ITEMS,
    promo_tile,
    items,
    currentPage,
    total_pages,
    total_count,
    args
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Scandipwa/Store/ProductList/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_LOAD_STATUS,
    isLoading: status
});

/** @namespace Scandipwa/Store/ProductList/Action/updatePageLoadingStatus */
export const updatePageLoadingStatus = () => ({
    type: UPDATE_PAGE_LOAD_STATUS
});

/**
 * @namespace Scandipwa/Store/ProductList/Action/updatePagesNumberToShow */
export const updatePagesNumberToShow = (pagesNumberToShow) => ({
    type: UPDATE_PAGE_NUMBER_TO_SHOW,
    pagesNumberToShow
});
