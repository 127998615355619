/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';

/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Scandipwa/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    containerProps() {
        const { onClick } = this.props;

        return {
            ...super.containerProps(),
            onClick
        };
    }
}

export default ImageContainer;
