/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Gtm/Component/GoogleTagManager/Events/PageDataEvent/Handlers/CustomerDataEvent/Handler */
export class CustomerDataEventHandler {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-magic-construct
    constructor(appState) {
        this.appState = appState;
        this.setRegisteredUserParam();
    }

    getCustomerData() {
        return {
            gender: '', // as this value can`t be gathered from current site
            ID: BrowserDatabase.getItem('guest_quote_id'),
            anonymous: this.isAnonymous(),
            authenticated: !!BrowserDatabase.getItem('auth_token'),
            registered: !!BrowserDatabase.getItem('registered_user'),
            sessionID: this.getSessionId(),
            // for location also need some third party integration to gather needed data
            location: {
                city: '',
                country: '',
                postalCode: '',
                region: '',
                ipAddress: ''
            }
        };
    }

    isAnonymous() {
        const knownUser = BrowserDatabase.getItem('known_user') ? BrowserDatabase.getItem('known_user') : '';

        if (knownUser === '') {
            BrowserDatabase.setItem(true, 'known_user', '2592000');
            return true;
        }

        return false;
    }

    setRegisteredUserParam() {
        // eslint-disable-next-line no-unused-expressions
        BrowserDatabase.getItem('auth_token')
            ? BrowserDatabase.setItem(true, 'registered_user', '2592000')
            : null;
    }

    getSessionId() {
        const { ConfigReducer: { gtm: { session_id } } } = this.appState;

        return session_id;
    }
}

export default CustomerDataEventHandler;
