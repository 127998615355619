/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import { IS_CART_RESET } from 'Component/CheckoutSuccess/CheckoutSuccess.container';
import CheckoutQuery from 'Query/Checkout.query';
import { SHIPPING_ADDRESS_KEY } from 'Route/Checkout/Checkout.config';
import { fetchMutation } from 'SourceUtil/Request';
import { PAYMENT_TOTALS } from 'Store/Checkout/Checkout.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getCartId } from 'Util/Cart';

import { CHECKOUTCOM_APPLE_PAY, CHECKOUTCOM_CC, CHECKOUTCOM_GOOGLE_PAY } from './CheckoutPayments.component.plugin';

export const PAGEDATA_ORDER_STORAGE = 'CHECKOUTCOM_PAGEDATA_ORDER';
export const CHECKOUTCOM_PAGEDATA_ORDER_STORAGE = 'CHECKOUTCOM_PAGEDATA_ORDER';
export const CHECKOUTCOM_PAYMENT_TOTALS_STORAGE = 'CHECKOUTCOM_PAYMENT_TOTALS';

/** @namespace ScandiPWA/CheckoutComGraphQl/Plugin/Container */
export class CheckoutContainer {
    /**
     * Save payment method and place order
     * Currently, there is no solution to call callback in case of checkout.com payment methods
     * @param args
     * @param callback
     * @param instance
     * @returns {Promise<void>}
     */
    aroundSavePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
        const [paymentInformation] = args;
        const { paymentMethod: { code, additional_data } } = paymentInformation;
        const { shippingAddress } = instance.state;

        if (code === CHECKOUTCOM_APPLE_PAY) {
            if (window.applePayCheckoutComSession) {
                window.applePayCheckoutComSession.begin();
            }

            return;
        }

        if (code === CHECKOUTCOM_GOOGLE_PAY) {
            document.dispatchEvent(new CustomEvent('triggerGooglePayPopup'));

            return;
        }

        if (code === CHECKOUTCOM_CC) {
            const cart_id = getCartId();

            try {
                await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                    cart_id,
                    payment_method: {
                        code, [code]: additional_data
                    }
                }));

                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
                const {
                    placeOrder: {
                        order: {
                            order_id,
                            checkout_com
                        }
                    }
                } = orderData;

                if (checkout_com) {
                    const { threeds_redirect } = checkout_com;

                    BrowserDatabase.setItem(0, IS_CART_RESET);
                    if (threeds_redirect) {
                        // We use those data for GTM pageData push
                        BrowserDatabase.setItem(window.pageData.order, CHECKOUTCOM_PAGEDATA_ORDER_STORAGE);
                        BrowserDatabase.setItem(shippingAddress, SHIPPING_ADDRESS_KEY);
                        BrowserDatabase.setItem(
                            instance.props.totals,
                            CHECKOUTCOM_PAYMENT_TOTALS_STORAGE
                        );

                        window.location.href = threeds_redirect;

                        return;
                    }
                }

                BrowserDatabase.setItem(shippingAddress, SHIPPING_ADDRESS_KEY);
                instance.setDetailsStep(order_id);
            } catch (e) {
                instance._handleError(e);
            }
        } else {
            await callback.apply(instance, args);
        }
    };
}

export const { aroundSavePaymentMethodAndPlaceOrder } = new CheckoutContainer();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: [
                {
                    position: 100,
                    implementation: aroundSavePaymentMethodAndPlaceOrder
                }
            ]
        }
    }
};
