/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MyAccountAddressTableComponent from 'Component/MyAccountAddressTable/MyAccountAddressTable.component';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "accountDispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountAddressTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateCustomerDetails: () => MyAccountDispatcher.then(
        /** @namespace Component/MyAccountAddressTable/Container/then */
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/MyAccountAddressTable/Container */
export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    static propTypes = {
        ...super.propTypes,
        updateCustomerDetails: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions,
        getFormatedRegion: this.getFormatedRegion.bind(this),
        onDelete: this.onDelete.bind(this)
    };

    state = {
        isLoading: false
    };

    containerProps() {
        const { updateCustomerDetails, showNotification, showAdditionalFields } = this.props;
        const { isLoading } = this.state;

        return {
            ...super.containerProps(),
            showAdditionalFields,
            isLoading,
            showNotification,
            updateCustomerDetails
        };
    }

    handleAction = () => {
        const {
            updateCustomerDetails
        } = this.props;

        updateCustomerDetails();
    };

    onDelete() {
        const { address: { id }, showNotification } = this.props;
        const query = MyAccountQuery.getDeleteAddressMutation(id);
        this.setState({ isLoading: true });

        fetchMutation(query).then(
            /** @namespace Scandipwa/Component/MyAccountAddressTable/Container/MyAccountAddressTableContainer/onDelete/fetchMutation/then */
            () => {
                this.setState({ isLoading: false });
                this.handleAction();
            },
            /** @namespace Scandipwa/Component/MyAccountAddressTable/Container/MyAccountAddressTableContainer/onDelete/fetchMutation/then/catch */
            ([err]) => {
                showNotification('info', err.message);
                this.setState({ isLoading: false });
                // console.log('here', err.message, err);
            }
        );
    }

    getFormatedRegion(address) {
        const { countries } = this.props;
        const { country_id, region: { region_id, region } = {} } = address;

        const country = countries.find(({ id }) => id === country_id);
        if (!country) {
            return {};
        }

        const { label, available_regions } = country;
        const regions = available_regions || [];
        const { name } = regions.find(({ id }) => id === region_id) || { name: region };

        return {
            country: label,
            region: name
        };
    }

    render() {
        return (
            <MyAccountAddressTableComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
