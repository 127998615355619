/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import isKsa from 'Util/Arabic/isKsa';

export class CartQueryPlugin {
    getCustomsDutyDataFields = (args, callback) => {
        if (isKsa()) {
            return [
                ...callback(...args),
                'customs_duty_amount'
            ];
        }

        return callback(...args);
    };
}

export const { getCustomsDutyDataFields } = new CartQueryPlugin();

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getPricesFields: getCustomsDutyDataFields
        }
    }
};
