/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import { CATEGORY_SORT } from 'Component/CategorySort/CategorySort.config';
import ClickOutside from 'Component/ClickOutside';
import { FIELDS_WITH_PLACEHOLDERS } from 'Component/FieldSelect/FieldSelect.config';
import SourceFieldSelect from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.extended.style';
import './FieldSelect.style.override';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    static propTypes = {
        ...super.propTypes,
        labelPlaceholder: PropTypes.string,
        isRequired: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        attributeValidation: PropTypes.object
    };

    static defaultProps = {
        ...super.defaultProps,
        labelPlaceholder: '',
        isRequired: false,
        attributeValidation: {}
    };

    renderOption = (option) => {
        const {
            id,
            label,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered
              } }
              key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
              id={ `o${ id }` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                <span>{ label }</span>
            </li>
        );
    };

    renderLabelPlaceholder() {
        const { labelPlaceholder, isRequired } = this.props;

        if (labelPlaceholder === '') {
            return null;
        }

        return (
            <span
              block="FieldSelect"
              elem="LabelPlaceholder"
              mods={ { isRequired } }
            >
                { labelPlaceholder }
            </span>
        );
    }

    onSelectBlur = (e) => {
        const { onChange, handleSelectExpand } = this.props;

        handleSelectExpand();
        onChange(e);
    };

    renderNativeSelect() {
        const {
            attr: {
                id,
                name
            },
            attr,
            options,
            isDisabled,
            isExpanded,
            autocomplete,
            skipValue,
            isLabelInner,
            innerLabel,
            unsortedOptions,
            events,
            handleSelectListOptionClick,
            setRef
        } = this.props;

        const labelForSelect = isLabelInner && innerLabel
            ? (
                <label
                  htmlFor={ id ? id.toString() : null }
                  block="FieldSelect"
                  elem="InnerLabel"
                >
                    { innerLabel }
                </label>
            ) : null;

        const shouldRemovePlaceholder = name && !FIELDS_WITH_PLACEHOLDERS.includes(name);
        const optionsWithPlaceholder = name === CATEGORY_SORT ? unsortedOptions : options;
        const optionsWithNoPlaceholder = name === CATEGORY_SORT
            ? unsortedOptions.filter((option) => option.isPlaceholder !== true)
            : options.filter((option) => option.isPlaceholder !== true);

        return (
            <>
                { labelForSelect }
                <select
                  block="FieldSelect"
                  elem="Select"
                  autoComplete={ autocomplete }
                  mods={ { isExpanded } }
                  ref={ (elem) => setRef(elem) }
                  { ...attr }
                  { ...events }
                  disabled={ isDisabled }
                  tabIndex="0"
                  onChange={ handleSelectListOptionClick }
                  onBlur={ this.onSelectBlur }
                  data-skip-value={ skipValue }
                >
                    { shouldRemovePlaceholder
                        ? optionsWithNoPlaceholder.map(this.renderNativeOption.bind(this))
                        : optionsWithPlaceholder.map(this.renderNativeOption.bind(this)) }
                </select>
            </>
        );
    }

    renderNativeOption = (option) => {
        const {
            id,
            value,
            disabled,
            label,
            selected
        } = option;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled }
              selected={ selected }
            >
                { label }
            </option>
        );
    };

    renderOptions() {
        const {
            options,
            isExpanded
        } = this.props;

        const optionsWithNoPlaceholder = options.filter((option) => option.isPlaceholder !== true);
        // eslint-disable-next-line no-magic-numbers
        const noScrollBar = optionsWithNoPlaceholder.length < 5;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded, noScrollBar } }
            >
                { optionsWithNoPlaceholder.map(this.renderOption) }
            </ul>
        );
    }

    render() {
        const {
            attr: { name },
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            value,
            message,
            attributeValidation: {
                isSizeValid
            }
        } = this.props;

        const sizeValidation = name === 'size' && !isSizeValid && value === '';

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  block="FieldSelect"
                  mods={ { isNotValid: (!value && !!message) || sizeValidation } }
                  onClick={ handleSelectExpand }
                  onKeyPress={ handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select drop-down"
                  aria-expanded={ isExpanded }
                >
                    { this.renderLabelPlaceholder() }
                    { this.renderNativeSelect() }
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
