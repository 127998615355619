/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CartReducer as SourceCartReducer } from 'SourceStore/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

export const CART_TOTALS = 'cart_totals';
export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';
export * from 'SourceStore/Cart/Cart.reducer';

/** @namespace Scandipwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action) => {
    const { cartData: { items = [], shipping_addresses = {}, ...rest } = {} } = action;

    const cartTotals = {
        ...rest,
        items: [],
        shipping_addresses: {}
    };

    if (items.length) {
        const normalizedItemsProduct = items.map((item) => {
            const {
                bundle_customizable_options,
                configurable_customizable_options,
                downloadable_customizable_options,
                virtual_customizable_options,
                simple_customizable_options,
                ...normalizedItem
            } = item;

            normalizedItem.product = getIndexedProduct(item.product, item.sku);

            normalizedItem.customizable_options = bundle_customizable_options
                || configurable_customizable_options
                || downloadable_customizable_options
                || virtual_customizable_options
                || simple_customizable_options
                || [];

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    cartTotals.shipping_addresses = shipping_addresses[0] || {};

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    // TODO Why do we have two places to store totals? Should refactor to a single.
    BrowserDatabase.setItem(
        cartTotals,
        PAYMENT_TOTALS
    );

    return { cartTotals, isLoading: false };
};

export default SourceCartReducer;
