/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './CheckoutComGooglePay.style.scss';

/** @namespace Checkoutcom/Component/CheckoutComGooglePay/Component */
export class CheckoutComGooglePayComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isGooglePayAvailable: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        googlePayConfiguration: PropTypes.object.isRequired
    };

    renderLoader() {
        return (
            <Loader isLoading />
        );
    }

    renderGooglePayButton() {
        const {
            googlePayConfiguration: { button_style }
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              block="CheckoutComGooglePay"
              elem="GooglePayButton"
              mods={ { type: button_style } }
            />
        );
    }

    renderGooglePayUnavailableNotice() {
        return (
            <div
              block="CheckoutComGooglePay"
              elem="NotAvailableNotice"
            >
                { __('Google Pay is not available') }
            </div>
        );
    }

    render() {
        const {
            isLoading,
            isGooglePayAvailable
        } = this.props;

        return (
            <div block="CheckoutComGooglePay">
                { isLoading && this.renderLoader() }
                { !isLoading && isGooglePayAvailable && this.renderGooglePayButton() }
                { !isLoading && !isGooglePayAvailable && this.renderGooglePayUnavailableNotice() }
            </div>
        );
    }
}

export default CheckoutComGooglePayComponent;
