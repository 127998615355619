import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProductCarousel } from 'Component/Html/Html.component';
import { RECOMMENDER_CHECKOUT_PAGE_ID } from 'Route/Checkout/Checkout.config';
import history from 'Util/History';

/** @namespace Scandipwa/Component/OrderSuccessCarousel/Component */
export class OrderSuccessCarouselComponent extends PureComponent {
    static propTypes = {
        productIds: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    render() {
        const { productIds } = this.props;

        if (productIds.length === 0) {
            return null;
        }

        return (
            <ProductCarousel
              productSkuArray={ productIds }
              productButtonStyle={ null }
              title={ __('Recommended for you') }
              button=""
              carouselId={ RECOMMENDER_CHECKOUT_PAGE_ID }
              history={ history }
              isRecommender
            />
        );
    }
}

export default OrderSuccessCarouselComponent;
