/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Beshoy Samuel <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

export class CheckoutQuery {
     /**
      * Get order request fields
      * @returns {Field}
      * @private
      */
     _aroundGetOrderField = (args, callback) => {
         const parent = callback(...args);
         parent.addField(this._getOrderTabbyFields());

         return parent;
     };

     /**
      * Get order request tabby method fields
      * @returns {Field}
      * @private
      */
     _getOrderTabbyFields() {
         return new Field('tabby')
             .addFieldList(['gateway_redirect']);
     }
}

export const { _aroundGetOrderField } = new CheckoutQuery();

export default {
    'Query/Checkout/Query': {
        'member-function': {
            _getOrderField: [
                {
                    position: 120,
                    implementation: _aroundGetOrderField
                }
            ]
        }
    }
};
