/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4 } from '../../../util/Event';
import BaseEventEvent from './BaseEvent.event';

/**
 * Category refinement select
 * @namespace Gtm/Component/GoogleTagManager/GA4_events/CategoryRefinementSelect/Event */
export class CategoryRefinementSelectEvent extends BaseEventEvent {
    /**
     * Set delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Bind category refinement events
     */
    bindEvent() {
        Event.observer(EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4, (data) => {
            this.handle(data);
        });
    }

    /**
     * Handle category refinement select
     */
    handler({ filter, value }) {
        this.pushEventData({
            domevent: filter.toLowerCase(),
            domlabel: typeof value === 'string' ? value.toLowerCase() : value
        });
    }
}

export default CategoryRefinementSelectEvent;
