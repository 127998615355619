/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PropTypes } from 'prop-types';

import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import OrderSuccessCarousel from 'Component/OrderSuccessCarousel';
import { RECOMMENDER_CHECKOUT_PAGE_ID, SHIPPING_ADDRESS_KEY } from 'Route/Checkout/Checkout.config';
import {
    CheckoutSuccess as SourceCheckoutSuccess
} from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import './CheckoutSuccess.extended.style';

/** @namespace Scandipwa/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    static propTypes = {
        ...super.propTypes,
        isLoading: PropTypes.bool.isRequired,
        storeName: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired
    };

    // So plugin can attach to something
    componentDidMount() {}

    renderShippingAddress() {
        const shippingAddress = BrowserDatabase.getItem(SHIPPING_ADDRESS_KEY);

        const formattedAddress = { ...shippingAddress };

        if (!formattedAddress.region && formattedAddress.region_id) {
            formattedAddress.region = { region_id: shippingAddress.region_id };
        }

        if (!formattedAddress.region && !formattedAddress.region_id) {
            return null;
        }

        return (
            <div block="CheckoutSuccess" elem="ShippingAddress">
                <h3>{ __('Shipping Address') }</h3>
                <MyAccountAddressTable
                  address={ formattedAddress }
                  showAdditionalFields
                  mix={ { block: 'CheckoutSuccess', elem: 'ShippingAddressTable' } }
                />
            </div>
        );
    }

    renderButtons() {
        return (
            <div block="CheckoutSuccess" elem="Buttons">
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Link
                      block="Button"
                      elem="Checkout"
                      mods={ { withArrow: true } }
                      to="/"
                    >
                        { __('Continue shopping') }
                    </Link>
                </div>
                { this.renderCreateAccountButton() }
            </div>
        );
    }

    renderCreateAccountButton() {
        const { isSignedIn = false } = this.props;

        const accountButtonsText = isSignedIn ? __('My account') : __('Create an account');

        return (
            <div
              block="CheckoutSuccess"
              elem="ButtonWrapper"
              mods={ { isCreateAccountButton: true } }
            >
                <Link
                  block="Button"
                  elem="Checkout"
                  mods={ { withArrow: true } }
                  to="/customer/account/"
                >
                    { accountButtonsText }
                </Link>
            </div>
        );
    }

    renderThankYou() {
        return (<p>{ __('Thank you for your order at PUMA.') }</p>);
    }

    render() {
        const {
            orderID, isLoading
        } = this.props;

        if (isLoading) {
            return <Loader isLoading={ isLoading } />;
        }

        return (
            <div block="CheckoutSuccess">
                { this.renderThankYou() }
                <h3>{ __('The number of your order is: %s.', orderID) }</h3>
                <p>
                    { /* eslint-disable-next-line max-len */ }
                    { __('You will receive an email with your order details and a separate email once your order has been shipped.') }
                </p>
                { this.renderButtons() }
                { this.renderShippingAddress() }
                <div id={ RECOMMENDER_CHECKOUT_PAGE_ID }>
                    <OrderSuccessCarousel />
                </div>
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
