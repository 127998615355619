/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

export const CHECKOUTCOM_CC = 'checkoutcom_card_payment';
export const CHECKOUTCOM_APPLE_PAY = 'checkoutcom_apple_pay';

export class CheckoutShippingBillingContainer {
    aroundGetPaymentData = (args, callback = () => {}, instance) => {
        const asyncData = window.formPortalCollector.collect('SHIPPING_STEP');
        const { selectedPaymentMethod } = instance.state;
        const { paymentMethods } = instance.props;

        const [{ code: firstAvailablePaymentMethod }] = paymentMethods;
        const paymentMethod = selectedPaymentMethod || firstAvailablePaymentMethod;

        const parent = callback.apply(instance, args);

        if (paymentMethod !== CHECKOUTCOM_CC) {
            return parent;
        }

        const [{ asyncData: { cardBin, cardToken } }] = asyncData;

        return {
            ...parent,
            additional_data: {
                cardBin,
                cardToken
            }
        };
    };
}

export const { aroundGetPaymentData } = new CheckoutShippingBillingContainer();

export default {
    'Scandipwa/Component/CheckoutShippingBilling/Container': {
        'member-function': {
            getPaymentData: aroundGetPaymentData
        }
    }
};
