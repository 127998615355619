/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SIZE_SELECTOR_POPUP_ID } from 'Component/SizeSelectorPopup/SizeSelectorPopup.config';
import ProductListQuery from 'Query/ProductList.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { HistoryType, LocationType } from 'Type/Router.type';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandipwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cloudinaryStatus: state.ConfigReducer.isCloudinaryCDNActive,
    version: state.ConfigReducer.code,
    use_thumbnail_as_swatch: state.ConfigReducer.use_thumbnail_as_swatch,
    sizeSelectorPopupPayload: state.PopupReducer.popupPayload[SIZE_SELECTOR_POPUP_ID] || {},
    activeOverlay: state.OverlayReducer.activeOverlay,
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showSizePopup: (payload) => dispatch(showPopup(SIZE_SELECTOR_POPUP_ID, payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...super.propTypes,
        location: LocationType,
        history: HistoryType,
        cloudinaryStatus: PropTypes.bool,
        use_thumbnail_as_swatch: PropTypes.bool,
        activeOverlay: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        use_thumbnail_as_swatch: false,
        showSizePopup: PropTypes.func.isRequired,
        sizeSelectorPopupPayload: PropTypes.object.isRequired,
        showNotification: PropTypes.object.isRequired,
        activeOverlay: ''
    };

    state = {
        ...this.state,
        cloudinaryStatus: false,
        changedProductImage: '',
        changedProductImageHover: '',
        activeImage: 0,
        simplePriceRange: null,
        simplePriceRangeHover: null,
        selectedColorSwatch: {},
        sliderColorsImgsUrls: {},
        sliderActiveImage: 0
    };

    containerProps() {
        const {
            activeOverlay,
            use_thumbnail_as_swatch,
            cloudinaryStatus,
            sizeSelectorPopupPayload,
            device,
            isInfiniteLoaderEnabled
        } = this.props;

        const {
            changedProductImage,
            changedProductImageHover,
            activeImage,
            selectedColorSwatch,
            sliderActiveImage,
            sliderColorsImgsUrls,
            sliderOverlayWidth
        } = this.state;

        return {
            ...super.containerProps(),
            activeOverlay,
            changedProductImage,
            changedProductImageHover,
            use_thumbnail_as_swatch,
            activeImage,
            selectedColorSwatch,
            cloudinaryStatus,
            sizeSelectorPopupPayload,
            device,
            sliderActiveImage,
            sliderColorsImgsUrls,
            sliderOverlayWidth,
            isInfiniteLoaderEnabled
        };
    }

    getAdditionalImages(product_id) {
        const options = {
            args: {
                filter: {
                    productID: product_id
                }
            }
        };

        executeGet(prepareQuery(ProductListQuery.getPlpSliderImgUrls(options)), 'plp_gallery_images')
            .then(
                /** @namespace Scandipwa/Component/ProductCard/Container/ProductCardContainer/getAdditionalImages/then/catch/executeGet/then */
                ({
                    products: {
                        items
                    }
                }) => {
                    const uniqueColors = [];
                    const sliderColorsImgsUrls = {};

                    items[0].variants.forEach((variant) => {
                        const {
                            product: {
                                stock_status,
                                color,
                                media_gallery_entries
                            }
                        } = variant;

                        if (stock_status === 'IN_STOCK' && !uniqueColors.includes(color)) {
                            uniqueColors.push(color);
                            sliderColorsImgsUrls[color] = media_gallery_entries;
                        }
                    });

                    this.setState({
                        sliderColorsImgsUrls
                    });
                }
            ).catch(
                /** @namespace Scandipwa/Component/ProductCard/Container/ProductCardContainer/getAdditionalImages/then/catch */
                (error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    showNotification('error', __('Something went wrong! Please, try again!'));
                }
            );
    }

    onActiveImageChange(e, activeImage) {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            activeImage
        });
    }

    containerFunctions = {
        ...this.containerFunctions,
        changeProductThumbnailPrice: this.changeProductImage.bind(this),
        onActiveImageChange: this.onActiveImageChange.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        filterValidVariants: this.filterValidVariants.bind(this),
        onAddToCartClick: this.onAddToCartClick.bind(this),
        onColorSwatchChange: this.onColorSwatchChange.bind(this),
        getMatchingVariantColor: this.getMatchingVariantColor.bind(this),
        onSliderButtonClick: this.onSliderButtonClick.bind(this),
        setSliderOverlayWidth: this.setSliderOverlayWidth.bind(this)
    };

    setSliderOverlayWidth(sliderWidth) {
        this.setState({
            sliderOverlayWidth: sliderWidth
        });
    }

    onSliderButtonClick(activeImage, e) {
        const { product: { id } } = this.props;
        const { sliderColorsImgsUrls } = this.state;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            sliderActiveImage: activeImage
        });

        if (Object.keys(sliderColorsImgsUrls).length === 0) {
            this.getAdditionalImages(id);
        }
    }

    onAddToCartClick() {
        const { showSizePopup, product } = this.props;

        showSizePopup({
            product,
            selectedColor: this.getMatchingVariantColor(),
            title: __('Select size')
        });
    }

    onColorSwatchChange(selectedColorSwatch) {
        this.setState({ selectedColorSwatch });
    }

    changeProductImage(image, simplePriceRange = null, e = null, isHover = false) {
        const { device } = this.props;

        if (e && !device.isMobile) {
            e.stopPropagation();
        }

        if (isHover) {
            this.setState({
                changedProductImageHover: image,
                simplePriceRangeHover: simplePriceRange
            });
        } else {
            this.setState({
                changedProductImage: image,
                simplePriceRange
            });
        }

        this.setState({ sliderActiveImage: 0 });
    }

    getMatchingVariantColor() {
        const { product: { thumbnail: { url: productThumbnailUrl = '' } = {}, variants = [] } } = this.props;
        const { selectedColorSwatch: { attribute_value: selectedColor } } = this.state;

        if (selectedColor) {
            return selectedColor;
        }

        if (variants.length === 0) {
            return null;
        }

        const { color: firstColor } = variants[0];

        return variants.reduce((acc, variant) => {
            const { thumbnail: { url }, color } = variant;

            if (url === productThumbnailUrl) {
                return color;
            }

            return acc;
        }, firstColor);
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [], variants },
            product
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;

        const matchingVariantColor = this.getMatchingVariantColor();

        if (!url || !matchingVariantColor) {
            return undefined;
        }

        const { style_url } = variants.find((variant) => variant.color === matchingVariantColor);

        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: style_url || rewriteUrlPath,
            state: { product: { ...product, sku: undefined, id: undefined }, prevCategoryId: category },
            search: matchingVariantColor ? `?color=${matchingVariantColor}` : ''
        };
    }

    getIsConfigurableAttributeAvailable({ stock_status }) {
        return stock_status === 'IN_STOCK';
    }

    filterValidVariants(variants) {
        const uniqueColors = [];

        return variants.filter((variant) => {
            const { stock_status, color } = variant;

            if (stock_status === 'IN_STOCK' && !uniqueColors.includes(color)) {
                uniqueColors.push(color);

                return variant;
            }

            return false;
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
