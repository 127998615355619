/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from 'Type/ProductList.type';

import ProductAttributeValueComponent from './ProductAttributeValue.component';

/** @namespace Scandipwa/Component/ProductAttributeValue/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/ProductAttributeValue/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ProductAttributeValue/Container */
export class ProductAttributeValueContainer extends PureComponent {
    static propTypes = {
        product: ProductType
    };

    static defaultProps = {
        product: {}
    };

    containerFunctions = {
        getSwatch: this.getSwatch.bind(this),
        isColorActive: this.isColorActive.bind(this),
        getCategoryLabel: this.getCategoryLabel.bind(this)
    };

    containerProps = () => ({});

    getSwatch(color) {
        const { product } = this.props;

        if (!product) {
            return false;
        }

        const { variants } = product;

        if (variants === undefined) {
            return false;
        }

        return variants.reduce((acc, variant) => {
            if (acc) {
                return acc;
            }

            const { swatch_image_url, color: variantColor } = variant;

            if (variantColor === color) {
                return swatch_image_url;
            }

            return false;
        }, false);
    }

    getCategoryLabel(categoryCode, categoryOptions) {
        const { label } = Object.values(categoryOptions).find(({ value_string }) => value_string === categoryCode);
        return label;
    }

    isColorActive(parameters, color) {
        // eslint-disable-next-line no-prototype-builtins
        if (parameters && parameters.hasOwnProperty('color') && color) {
            return parameters.color === color.attribute_value;
        }

        return false;
    }

    render() {
        return (
            <ProductAttributeValueComponent
              { ...this.props }
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeValueContainer);
