/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import MyAccountCreateAccount from 'Component/MyAccountCreateAccount';
import { ARABIC_LOCALE, ENGLISH_LOCALE } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import MyAccountSignIn from 'Component/MyAccountSignIn';
import isArabic from 'Util/Arabic';
import isKsa from 'Util/Arabic/isKsa';

import './MobileLogin.style.scss';

/** @namespace Scandipwa/Component/MobileLogin/Component */
export class MobileLoginComponent extends PureComponent {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        handleForgotPassword: PropTypes.func.isRequired,
        onFormError: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        handleSignIn: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        isLandingPage: PropTypes.bool.isRequired,
        isCheckout: PropTypes.bool.isRequired,
        setLoginActiveTab: PropTypes.func.isRequired,
        setRegisterActiveTab: PropTypes.func.isRequired,
        setSignInState: PropTypes.func.isRequired,
        state: PropTypes.string.isRequired,
        onDataChange: PropTypes.func.isRequired,
        isRegisterDisabled: PropTypes.bool.isRequired,
        locale: PropTypes.string.isRequired
    };

    renderLoginFormMobile() {
        const {
            handleCreateAccount,
            handleForgotPassword,
            isCheckout,
            onFormError,
            onSignIn,
            setLoadingState,
            state
        } = this.props;

        return (
            <MyAccountSignIn
              handleCreateAccount={ handleCreateAccount }
              handleForgotPassword={ handleForgotPassword }
              isCheckout={ isCheckout }
              onFormError={ onFormError }
              onSignIn={ onSignIn }
              setLoadingState={ setLoadingState }
              state={ state }
              isNoTitle
            />
        );
    }

    getTermsAndConditionsLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE:
            return isKsa() ? '/terms-and-conditions.html' : '/ar/terms-and-conditions.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/terms-and-conditions.html' : '/terms-and-conditions.html';
        default:
            return '/terminos-y-condiciones.html';
        }
    }

    getTermsOfUseLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE:
            return isKsa() ? '/terms-of-use.html' : '/ar/terms-of-use.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/terms-of-use.html' : '/terms-of-use.html';
        default:
            return '/terms-of-use.html';
        }
    }

    getPrivacyPolicyLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE: // don' t have arabic version yet
            return isKsa() ? '/privacy-policy.html' : '/ar/privacy-policy.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/privacy-policy.html' : '/privacy-policy.html';
        default:
            return '/politica-de-privacidad.html';
        }
    }

    // Arabic sentence structure is totally different which does not allow us to use translation in this case.
    renderLoginTermsOfUseMessage() {
        if (isArabic()) {
            return (
                <p block="LoginForm" elem="PolicyMessage">
                    يرجى العلم أنك توافق عبر تسجيل دخولك على
                    <span>&nbsp;</span>
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getPrivacyPolicyLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Privacy Policy') }
                    </a>
                    { __(' and ') }
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getTermsOfUseLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Terms of use') }
                    </a>
                    <span>&nbsp;</span>
                    الخاصة بـ PUMA.
                </p>
            );
        }

        return (
            <p block="LoginForm" elem="PolicyMessage">
                { __('By logging in, you agree to PUMA’s ') }
                <a
                  block="Link"
                  mix={ { block: 'Link', elem: 'Primary' } }
                  href={ this.getPrivacyPolicyLink() }
                  target="_blank"
                  rel="noreferrer"
                >
                    { __('Privacy Policy') }
                </a>
                { __(' and ') }
                <a
                  block="Link"
                  mix={ { block: 'Link', elem: 'Primary' } }
                  href={ this.getTermsOfUseLink() }
                  target="_blank"
                  rel="noreferrer"
                >
                    { __('Terms of use') }
                </a>
                .
            </p>
        );
    }

    // Arabic sentence structure is totally different which does not allow us to use translation in this case.
    renderRegistrationTermsOfUseMessage() {
        if (isArabic()) {
            return (
                    <p>
                        بإنشاء حساب، أنت توافق على
                        <span>&nbsp;</span>
                        <a
                          block="Link"
                          mix={ { block: 'Link', elem: 'Primary' } }
                          href={ this.getPrivacyPolicyLink() }
                          target="_blank"
                          rel="noreferrer"
                        >
                            { __('Privacy Policy') }
                        </a>
                        { __(' and ') }
                        <a
                          block="Link"
                          mix={ { block: 'Link', elem: 'Primary' } }
                          href={ this.getTermsOfUseLink() }
                          target="_blank"
                          rel="noreferrer"
                        >
                            { __('Terms of use') }
                        </a>
                        <span>&nbsp;</span>
                        الخاصة بـ PUMA.
                    </p>
            );
        }

        return (
                <p>
                    { __('By creating an account, you agree to PUMA\'s ') }
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getPrivacyPolicyLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Privacy Policy') }
                    </a>
                    { __(' and ') }
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getTermsOfUseLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Terms of use') }
                    </a>
                    .
                </p>
        );
    }

    renderRegisterFormMobile() {
        const {
            state,
            handleSignIn,
            setLoadingState,
            setSignInState,
            onSignIn,
            isLandingPage,
            isRegisterDisabled,
            onDataChange
        } = this.props;

        return (
            <MyAccountCreateAccount
              state={ state }
              handleSignIn={ handleSignIn }
              setLoadingState={ setLoadingState }
              setSignInState={ setSignInState }
              onSignIn={ onSignIn }
              isLandingPage={ isLandingPage }
              isRegisterDisabled={ isRegisterDisabled }
              onDataChange={ onDataChange }
              isNoTitle
            />
        );
    }

    renderButtonsMobile() {
        const { activeTab, setLoginActiveTab, setRegisterActiveTab } = this.props;
        return (
            <div block="MobileFormButtons">
                <div block="LoginMobileButton">
                    <button
                      onClick={ setLoginActiveTab }
                      block={ activeTab === 'login' ? '_active' : '' }
                    >
                        { __('Login') }
                    </button>
                </div>
                <div block="RegisterMobileButton">
                    <button
                      onClick={ setRegisterActiveTab }
                      block={ activeTab === 'register' ? '_active' : '' }
                    >
                        { __('Registration') }
                    </button>
                </div>
            </div>
        );
    }

    renderMobileLoginRegisterForm() {
        const { activeTab } = this.props;

        return (
            <>
                { this.renderButtonsMobile() }
                { activeTab === 'login' ? (
                    this.renderLoginFormMobile()
                ) : (
                    this.renderRegisterFormMobile()
                ) }
            </>
        );
    }

    render() {
        return (
            <div block="Login">
                <div block="MobileLoginForm">
                    { this.renderMobileLoginRegisterForm() }
                </div>
            </div>
        );
    }
}

export default withRouter(
    MobileLoginComponent
);
