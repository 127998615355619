/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import './DatePicker.styles.scss';

/** @namespace Scandipwa/Component/DatePicker/Component */
export class DatePickerComponent extends PureComponent {
    static propTypes = {
        days: PropTypes.array.isRequired,
        years: PropTypes.array.isRequired,
        months: PropTypes.array.isRequired,
        changeIfLeapYear: PropTypes.func.isRequired,
        changeDays: PropTypes.func.isRequired,
        getDate: PropTypes.func.isRequired,
        getDay: PropTypes.func.isRequired,
        dayWasChanged: PropTypes.bool.isRequired,
        monthWasChanged: PropTypes.bool.isRequired,
        yearWasChanged: PropTypes.bool.isRequired,
        selectedYear: PropTypes.number.isRequired,
        selectedMonth: PropTypes.number.isRequired,
        selectedDay: PropTypes.number.isRequired
    };

    state = {
        isYearsOpened: false,
        isMonthsOpened: false,
        isDaysOpened: false
    };

    changeYearState = () => {
        const { isYearsOpened } = this.state;
        this.setState({
            isYearsOpened: !isYearsOpened
        });
    };

    changeMonthState = () => {
        const { isMonthsOpened } = this.state;

        this.setState({
            isMonthsOpened: !isMonthsOpened
        });
    };

    changeDayState = () => {
        const { isDaysOpened } = this.state;

        this.setState({
            isDaysOpened: !isDaysOpened
        });
    };

    checkIfNaN(num) {
        // eslint-disable-next-line no-restricted-globals
        return isNaN(num);
    }

    render() {
        const {
            days,
            years,
            months,
            changeIfLeapYear,
            changeDays,
            getDate,
            dayWasChanged,
            monthWasChanged,
            yearWasChanged,
            selectedYear,
            selectedMonth,
            selectedDay,
            getDay
        } = this.props;

        if (dayWasChanged && monthWasChanged && yearWasChanged) {
            if (this.checkIfNaN(selectedYear) || this.checkIfNaN(selectedMonth) || this.checkIfNaN(selectedDay)) {
                getDate('');
            } else {
                getDate(`${ selectedYear }-${ selectedMonth }-${ selectedDay }`);
            }
        }

        return (
            <div
              id="DatePicker"
              block="DatePicker"
            >
                <div
                  block="DatePicker"
                  elem="Years"
                >
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'years',
                          name: 'years',
                          selectPlaceholder: 'YYYY'
                      } }
                      isRequired
                      options={ years.map((year) => ({ label: year, value: year })) }
                      events={ {
                          onChange: changeIfLeapYear
                      } }
                      noSort
                    />
                </div>
                <div
                  block="DatePicker"
                  elem="Months"
                >
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'months',
                          name: 'months',
                          selectPlaceholder: 'MM'
                      } }
                      isRequired
                      options={ months.map((month, index) => ({ label: month, value: index + 1 })) }
                      events={ {
                          onChange: changeDays
                      } }
                      noSort
                    />
                </div>
                <div
                  block="DatePicker"
                  elem="Days"
                >
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'days',
                          name: 'days',
                          selectPlaceholder: 'DD'
                      } }
                      isRequired
                      options={ days.map((day) => ({ label: day, value: day })) }
                      events={ {
                          onChange: getDay
                      } }
                      noSort
                    />
                </div>
            </div>
        );
    }
}

export default DatePickerComponent;
