/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import { Field } from 'SourceUtil/Query';

export class CheckoutQuery {
    /**
     * Get order request fields
     * @returns {Field}
     * @private
     */
    _aroundGetOrderField = (args, callback) => {
        const parent = callback(...args);
        parent.addField(this._getOrderCheckoutComFields());

        return parent;
    };

    /**
     * Get order request checkout.com method fields
     * @returns {Field}
     * @private
     */
    _getOrderCheckoutComFields() {
        return new Field('checkout_com')
            .addFieldList(['threeds_redirect']);
    }
}

export const { _aroundGetOrderField } = new CheckoutQuery();

export default {
    'Query/Checkout/Query': {
        'member-function': {
            _getOrderField: [
                {
                    position: 100,
                    implementation: _aroundGetOrderField
                }
            ]
        }
    }
};
