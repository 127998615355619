/* eslint-disable no-restricted-globals,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

// eslint-disable-next-line import/no-cycle
import ParentProduct from '../utils/Product';

export const SIZE_ATTRIBUTE = 'size';
export const GENDER_ATTRIBUTE = 'gender';
export const COLOR_ATTRIBUTE = 'color';
export const NOT_APPLICABLE = 'N/A';
export const GENDER_LIST = {
    7850: 'Female',
    4311: 'Unisex',
    7849: 'Male',
    4705: 'Girls',
    4397: 'Men',
    4298: 'Women'
};

/**
 * Product helper, contain all related to product data prepare methods
 */
export class Product extends ParentProduct {
    /**
     * Get item data as object
     *
     * @param item
     * @param category
     * @return {{item_id, affiliation: string, price: number, discount: *, item_name, item_category2: *, currency: *, item_category3: *, item_brand: string, item_category: *, item_variant}}
     */
    static getItemData(item, category = '') {
        if (item && Object.values(item).length) {
            const { product = {}, sku = '' } = item;
            const configurableVariantIndex = this.getSelectedVariantIndex(product, sku);

            return {
                ...this.getProductData({ ...product, configurableVariantIndex }, category),
                item_variant: sku
            };
        }

        return {};
    }

    static getAttribute(variant, parentAttributes, attributeName) {
        const {
            attribute_value: attribute_variant_value = ''
        } = variant?.attributes?.[attributeName] || {};
        const {
            attribute_value: attribute_product_value = '',
            attribute_options = {}
        } = parentAttributes[attributeName] || {};
        const attributeValue = attribute_variant_value || attribute_product_value;

        if (attributeName === GENDER_ATTRIBUTE) {
            const gender = variant.gender ? GENDER_LIST[variant.gender] : GENDER_LIST[attributeValue];

            return gender?.toLowerCase() || NOT_APPLICABLE;
        }

        const { label = NOT_APPLICABLE } = attribute_options[attributeValue] || {};

        return label.toLowerCase() || NOT_APPLICABLE;
    }

    /**
     * Get product data as object
     *
     * @param product
     * @param category
     * @return {{item_id, affiliation: string, price: number, discount: *, item_name, item_category2: *, currency: *, item_category3: *, item_brand: (string|string), item_category: *}}
     */
    static getProductData(product, category = '') {
        const {
            sku,
            name,
            type_id,
            variants = [],
            attributes = {},
            configurableVariantIndex = this.getSelectedVariantIndex(product, sku)
        } = product;
        const selectedVariant = variants[configurableVariantIndex] || product;

        return {
            item_id: sku,
            item_name: name,
            price: this.getPrice(selectedVariant, type_id),
            discount: this.getDiscount(selectedVariant),
            item_brand: category.toLowerCase(),
            item_category: this.getAttribute(selectedVariant, attributes, SIZE_ATTRIBUTE),
            item_category2: this.getAttribute(selectedVariant, attributes, GENDER_ATTRIBUTE),
            item_category3: this.getAttribute(selectedVariant, attributes, COLOR_ATTRIBUTE),
            currency: BrowserDatabase.getItem('cart_totals')?.prices?.quote_currency_code,
            affiliation: window.location.hostname
        };
    }

    /**
     * Get product discount
     *
     * @param product
     * @returns {*}
     */
    static getDiscount(product) {
        const productDiscount = product.price_range.minimum_price.discount;

        return productDiscount.amount_off || productDiscount.percent_off;
    }
}

export default Product;
