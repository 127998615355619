/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-unused-state */
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import validationConfig from 'Component/Form/Form.config';

import FieldZipComponent from './FieldZip.component';

/** @namespace Scandipwa/Component/FieldZip/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    minLength: state.ConfigReducer.postcode_minlength
});

/** @namespace Scandipwa/Component/FieldZip/Container */
export class FieldZipContainer extends PureComponent {
    static propTypes = {
        isRequired: PropTypes.bool,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        // eslint-disable-next-line react/boolean-prop-naming
        validateForm: PropTypes.bool,
        minLength: PropTypes.number.isRequired
    };

    static defaultProps = {
        isRequired: false,
        placeholder: '',
        onChange: () => {},
        validateForm: false
    };

    state = {
        isZipValid: false,
        invalidMessage: ''
    };

    containerFunctions = {
        validateZip: this.validateZip.bind(this)
    };

    validateZip(zip) {
        const { minLength } = this.props;
        const isValidLength = zip.replace(/_/g, '').length >= minLength;

        if (!isValidLength) {
            this.setState({ isZipValid: false, invalidMessage: validationConfig.notEmpty.message });
            return false;
        }

        this.setState({ isZipValid: true });
        return true;
    }

    render() {
        return (
            <FieldZipComponent
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, null)(FieldZipContainer);
