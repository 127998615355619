/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import ProductsPageDataEventHandler from './ProductsPageDataEvent.handler';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

export const PDP_DATA_OBJECT = 'product';

export class ProductPageDataEventHandler extends ProductsPageDataEventHandler {
    getProductPageData(data) {
        const { quantity, prevQuantity } = data;
        const product = this.getProduct();
        const productPrimaryCategory = this.getProductAttribute(
            null, product, 'primary_category_pim_id'
        ) || '';

        const { inStock, inventory, orderable } = this.getProductStatusData(product);

        return {
            discount: this.getDiscountAmount(product),
            inStock,
            inventory,
            orderable,
            price: this.getPrice(product),
            productCategory: this.getProductCategoryName(productPrimaryCategory),
            category: productPrimaryCategory,
            productID: product?.id ? product.id.toString() : '',
            productName: this.getProductName(product),
            styleID: this.getProductAttributeValue(product, 'style_number'),
            localName: '', // For now it stays empty
            quantity,
            wasQuantity: prevQuantity,
            pricing: {
                basePrice: this.getProductBasePrice(product),
                markdownAmount: this.getMarkdownAmount(product),
                discount: this.getDiscountAmount(product),
                price: this.getPrice(product),
                subtotal: this.getProductAttribute(null, product, 'subtotal')
            },
            promos: {
                id: '', // TODO
                code: '', // TODO
                name: '', // TODO
                type: '', // TODO
                amount: '' // TODO
            },
            discounted: this.getProductDiscountedStatus(product),
            skuID: this.getProductSku(product),
            bundle: this.isProductBundle(product),
            set: '', // For now it stays empty
            markdown: this.getMarkdownStatus(product),
            EAN: this.getProductAttribute(null, product, 'ean') || '',
            UPC: this.getProductAttribute(null, product, 'uc') || '',
            imageURL: this.getProductImageUrl(product),
            season: this.getAttributeLabelFromBrowserDatabase(null, product, 'season'),
            searchColor: '', // Not available from BE
            pumaColorDescription: this.getAttributeLabelFromBrowserDatabase(
                null, product, 'color_description'
            ),
            division: this.getAttributeLabelFromBrowserDatabase(
                null, product, 'product_division'
            ),
            lineName: this.getAttributeLabelFromBrowserDatabase(null, product, 'line_name'),
            gender: this.getAttributeLabelFromBrowserDatabase(null, product, 'gender'),
            colorCode: this.getAttributeLabelFromBrowserDatabase(null, product, 'color'),
            labelColor: '', // For now it stays empty
            ageGroup: this.getAttributeLabelFromBrowserDatabase(null, product, 'age_group'),
            department: this.getAttributeLabelFromBrowserDatabase(null, product, 'dept_code'),
            sport: this.getAttributeLabelFromBrowserDatabase(null, product, 'sport_code'),
            class: '', // Not available from BE
            subCat: this.getAttributeLabelFromBrowserDatabase(null, product, 'subcat_id'),
            productCollection: this.getAttributeLabelFromBrowserDatabase(
                null, product, 'collection'
            ),
            technology: this.getAttributeLabelFromBrowserDatabase(null, product, 'technology'),
            mainStyle: '' // Not available from BE
        };
    }

    getProductVariantsData() {
        const product = this.getProduct();

        const collected_skus = [];
        const productVariants = [];
        const styledSku = product.sku.replace('_', '-');
        const productUrl = product.url;
        const baseUrl = window.location.origin;

        const {
            configurable_options: {
                color: {
                    attribute_options: productColorAttrOptions
                }
            },
            variants
        } = product;

        variants.map((variant) => {
            const {
                attributes: {
                    style_number: {
                        attribute_value: variatStyleAttrValue
                    },
                    color: {
                        attribute_value: variantColorAttrValue
                    }
                },
                thumbnail: {
                    url: thumbnailUrl
                },
                swatch_image_url: swatchImageUrl
            } = variant;

            if (!collected_skus.includes(variatStyleAttrValue)) {
                const styleNumber = variatStyleAttrValue.replace('_', '-');
                const { label: variantColorLabel, value: variantColoValue } = productColorAttrOptions[variantColorAttrValue];

                const variantUrl = `${baseUrl + productUrl.replace(styledSku, styleNumber) }?color=${ variantColoValue}`;

                productVariants.push({
                    color: variantColorLabel,
                    url: variantUrl,
                    image: thumbnailUrl,
                    icon: swatchImageUrl
                });
                collected_skus.push(variatStyleAttrValue);
            }
        });

        return productVariants;
    }

    getProduct() {
        const { ProductReducer: { product } } = this.appState;

        return product;
    }
}

export default ProductPageDataEventHandler;
