/* eslint-disable array-callback-return */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const CATEGORY_REFINEMENTS_DATA_OBJECT = 'categoryRefinements';

/** @namespace Gtm/Component/GoogleTagManager/Events/PageDataEvent/Handlers/CategoryRefinementsDataEvent/Handler */
export class CategoryRefinementsDataEventHandler {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-magic-construct
    constructor(appState) {
        this.appState = appState;
    }

    getRefinements(data) {
        const {
            ProductListReducer: {
                currentArgs: {
                    filter: {
                        customFilters,
                        priceRange
                    } = {},
                    sort
                }
            }
        } = this.appState;

        const customFilterKeys = Object.keys(customFilters || {});

        const refinements = [];
        customFilterKeys.map((key) => {
            customFilters[key].map((value) => {
                refinements.push(
                    this.getFilterObject(key, value)
                );
            });
        });

        refinements.push(this.getSortObject(sort));

        if (this.getPriceRangeObject(priceRange) !== null) {
            refinements.push(this.getPriceRangeObject(priceRange));
        }

        this.updateEventData(data);

        return refinements;
    }

    updateEventData(data) {
        const {
            eventData,
            updateEventData
        } = data;

        if (updateEventData) {
            updateEventData(eventData);
        }
    }

    getPriceRangeObject(priceRange) {
        return (priceRange.min === 0 && priceRange.max === 0) ? null : {
            name: 'price_range',
            type: 'filter',
            valueID: `price-${priceRange.min}-to-${priceRange.max}`,
            valueName: `Price ${priceRange.min} to ${priceRange.max}`
        };
    }

    getFilterObject(name, value) {
        const {
            ProductListInfoReducer: { filters }
        } = this.appState;

        // eslint-disable-next-line dot-notation
        const filterValue = filters[name]['attribute_options'][value]['label'];

        return {
            name,
            type: 'filter',
            valueID: value,
            valueName: filterValue
        };
    }

    getSortObject(sort) {
        return {
            name: 'sort',
            type: 'sort',
            valueID: `sort-direction-${sort.sortDirection}-sort-key-${sort.sortKey}`,
            valueName: `Sort direction ${sort.sortDirection}. Sort key ${sort.sortKey}`
        };
    }
}

export default CategoryRefinementsDataEventHandler;
