/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Link from 'Component/Link';

import AdyenCheckout from '../component/AdyenCheckout';
import { ADYEN_APPLE_PAY_METHOD_CODE } from '../util/Adyen';

export class CartOverlayComponent {
    renderActions(args, callback, instance) {
        const { totals: { items }, handleCheckoutClick } = instance.props;

        const options = !items || items.length < 1
            ? {
                onClick: (e) => e.preventDefault(),
                disabled: true
            }
            : {};

        return (
            <div block="CartOverlay" elem="Actions">
                <button
                  block="CartOverlay"
                  elem="CheckoutButton"
                  mix={ { block: 'Button', elem: 'Primary', mods: { color: 'brown' } } }
                  onClick={ handleCheckoutClick }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...options }
                >
                    <span />
                    { __('Secure checkout') }
                </button>
                { !!window.ApplePaySession && (
                    <AdyenCheckout
                      code={ ADYEN_APPLE_PAY_METHOD_CODE }
                      isApplePayExpress
                      applePayExpressSource="express_mini_cart"
                    />
                ) }
                <Link
                  block="CartOverlay"
                  elem="CartButton"
                  to="/cart"
                >
                    { __('Go to cart') }
                </Link>
            </div>
        );
    }
}

export const { renderActions } = new CartOverlayComponent();

export default {
    'Scandipwa/Component/CartOverlay/Component': {
        'member-function': {
            renderActions
        }
    }
};
