/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from 'SourceComponent/Notification/Notification.config';
export const ANIMATION_DURATION = 700;
export const NOTIFICATION_LIFETIME = 5000;
export const ERROR_NOTIFICATION_LIFETIME = 5000;
export const WISHLIST_ERROR = 'wishlist-login';
