/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Image from 'Component/Image';
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.extended.style';

/** @namespace Scandipwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderStringValue(value, label) {
        const { isFormattedAsText, isSelected, isSize } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            return label || value || __('N/A');
        }

        if (!isSwatch) {
            return this.renderDropdown(value);
        }

        return (
            <span
              block="ProductAttributeValue"
              elem="String"
              mods={ { isSelected } }
              title={ label }
            >
                { isSize ? label || value : value || label }
            </span>
        );
    }

    renderColorValue(color, label) {
        if (!color) {
            return null;
        }

        const { isFormattedAsText, isSelected } = this.props;
        const isLight = this.getIsColorLight(color);

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const showBorder = color === '#ffffff' || color === '#FFFFFF';

        return (
            <data
              block="ProductAttributeValue"
              elem="Color"
              mods={ { showBorder } }
              value={ label }
              title={ label }
              style={ {
                  '--option-background-color': color,
                  '--option-border-color': isLight ? '#000' : color,
                  '--option-check-mark-background': isLight ? '#000' : '#fff',
                  '--option-is-selected': +isSelected
              } }
            />
        );
    }

    renderSelectAttribute() {
        const {
            attribute: {
                is_boolean,
                attribute_label,
                attribute_value,
                attribute_code,
                attribute_options = []
            },
            getCategoryLabel,
            isFilter
        } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const { label, swatch_data } = attributeOption;

        // A BE defect fix https://github.com/scandipwa/base-theme/issues/822
        if (attribute_code === 'category_id') {
            return this.renderStringValue(getCategoryLabel(attribute_value, attribute_options));
        }

        if (is_boolean) {
            return this.renderStringValue(attribute_label || __('N/A'));
        }

        if (!swatch_data) {
            return this.renderStringValue(label || __('N/A'));
        }

        const { value, type } = swatch_data;

        switch (type) {
        case '0':
            return this.renderStringValue(value, label);
        case '1':
            return isFilter ? this.renderColorValue(value, label) : this.renderSwatch(value);
        case '2':
            return this.renderImageValue(value, label);
        case '3':
            return this.renderSwatch(value);
        default:
            return this.renderStringValue(label || __('N/A'));
        }
    }

    renderSwatch(value) {
        const {
            attribute,
            attribute: { attribute_value },
            getSwatch,
            isColorActive
        } = this.props;

        if (!getSwatch) {
            return null;
        }

        const swatchUrl = getSwatch(Number(attribute_value)) || value;

        if (!swatchUrl) {
            return null;
        }

        return (
            <div>
                <div
                  block="ProductAttributeValue"
                  elem="ColorLink"
                  mods={ { isActive: isColorActive(this.props.parameters, attribute) } }
                >
                    <Image src={ swatchUrl } />
                </div>
            </div>
        );
    }

    renderDropdown(value) {
        const {
            isSelected,
            attribute: { attribute_code }
        } = this.props;

        if (!value) {
            return null;
        }

        if (attribute_code === 'color') {
            return this.renderSwatch(value);
        }

        if (!value) {
            return null;
        }

        return (
            <div
              block="ProductAttributeValue"
              elem="Text"
              mods={ { isSelected } }
            >
                <label
                  block="ProductAttributeValue"
                  elem="Label"
                  htmlFor={ value }
                >
                    { value }
                </label>
            </div>
        );
    }

    render() {
        const {
            isAvailable,
            isDisabled,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            isSize
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        if (!isAvailable && attribute_code !== 'color') {
            return null;
        }

        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        return (
            <button
              block="ProductAttributeValue"
              mods={ { isNotAvailable } }
              disabled={ isDisabled }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
              id={ isSize ? (`o${attribute_value}`) : (`c${attribute_value}`) }
            >
                { this.renderAttributeByType() }
            </button>
        );
    }
}

export default ProductAttributeValueComponent;
