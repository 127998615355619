/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */import PropTypes from 'prop-types';
import { createRef, lazy, Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductCardWishlist from 'Component/ProductCardWishlist';
import ProductPrice from 'Component/ProductPrice';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import SizeSelectorPopup from 'Component/SizeSelectorPopup';
import { SIZE_SELECTOR_POPUP_ID } from 'Component/SizeSelectorPopup/SizeSelectorPopup.config';
import Slider from 'Component/Slider/Slider.component';
import TextPlaceholder from 'Component/TextPlaceholder';
import { SWATCH_COLOR_KEY } from 'Route/ProductPage/ProductPage.config';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { isArabic } from 'Util/Arabic';
import { formatImageUrl } from 'Util/ImageCDN';
import media from 'Util/Media';
import isMobile from 'Util/Mobile';

import './ProductCard.style';
import './ProductCard.extended.style';

// eslint-disable-next-line max-len
export const SwatchSlider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/SwatchSlider'));
export const ProductLabel = lazy(() => import('Component/ProductLabel'));

/** @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...super.propTypes,
        availableVisualOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })).isRequired,
        getAttribute: PropTypes.func.isRequired,
        showProductButton: PropTypes.bool,
        productButtonStyles: PropTypes.objectOf(
            PropTypes.string
        ),
        isSliderDragged: PropTypes.bool,
        cloudinaryStatus: PropTypes.bool.isRequired,
        isInfiniteLoaderEnabled: PropTypes.bool.isRequired,
        productIndex: PropTypes.number,
        onActiveImageChange: PropTypes.func.isRequired,
        onAddToCartClick: PropTypes.func.isRequired,
        getMatchingVariantColor: PropTypes.func.isRequired,
        activeImage: PropTypes.number.isRequired,
        getIsConfigurableAttributeAvailable: PropTypes.func.isRequired,
        filterValidVariants: PropTypes.func.isRequired,
        changeProductThumbnailPrice: PropTypes.func.isRequired,
        changedProductImage: PropTypes.string.isRequired,
        isPlp: PropTypes.bool,
        use_thumbnail_as_swatch: PropTypes.bool.isRequired,
        changedProductImageHover: PropTypes.string.isRequired,
        showSizePopup: PropTypes.func.isRequired,
        onColorSwatchChange: PropTypes.func.isRequired,
        sizeSelectorPopupPayload: PropTypes.object.isRequired,
        activeOverlay: PropTypes.string,
        selectedColorSwatch: PropTypes.object
    };

    static defaultProps = {
        ...super.defaultProps,
        showProductButton: false,
        buttonText: '',
        productButtonStyles: {},
        isSliderDragged: false,
        productIndex: undefined,
        isPlp: false,
        activeOverlay: '',
        selectedColorSwatch: {}
    };

    state = {
        productButtonHovered: false
    };

    sliderRef = createRef();

    componentDidUpdate() {
        const { setSliderOverlayWidth } = this.props;

        if (this.sliderRef.current) {
            const sliderWidth = this.sliderRef.current.draggableRef.current.offsetWidth;
            setSliderOverlayWidth(sliderWidth);
        }
    }

    handleLinkClick = (e) => {
        const { target: { classList } } = e;
        const { activeOverlay, registerSharedElement } = this.props;

        if (classList.contains('Button-AddToCart') || activeOverlay === SIZE_SELECTOR_POPUP_ID) {
            e.preventDefault();

            return;
        }

        registerSharedElement(this.imageRef);
    };

    renderPrice(isPreview = false) {
        const {
            getActiveProduct,
            productPrice,
            isInfiniteLoaderEnabled,
            product: {
                disable_sale_percentage_label
            }
        } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (!Object.keys(product).length) {
            return isInfiniteLoaderEnabled ? <div /> : <TextPlaceholder length="long" />;
        }

        const variantPrice = this.getConfigurablePriceByImage();

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ variantPrice || productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  disablePercentageLabel={ disable_sale_percentage_label }
                />
            </div>
        );
    }

    getConfigurablePriceByImage() {
        const {
            product: {
                sku,
                variants,
                thumbnail: {
                    url: thumbnail
                },
                is_sliced
            } = {},
            changedProductImage: changedProductImageStatic,
            changedProductImageHover
        } = this.props;

        const changedProductImage = changedProductImageHover || changedProductImageStatic;

        const lowestPricedStyleVariant = variants.reduce((acc, variant) => {
            const {
                style_number,
                price_range: {
                    minimum_price: {
                        final_price: {
                            value
                        }
                    }
                } = {},
                thumbnail: { url } = {}
            } = variant;
            const {
                variant: prevVariant,
                variant: {
                    price_range: {
                        minimum_price: {
                            final_price: {
                                value: prevLowPrice
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = acc;

            if (
                (changedProductImage && url === changedProductImage)
                || (!changedProductImage && style_number === sku)
            ) {
                return {
                    variant: prevLowPrice < value ? prevVariant : variant
                };
            }

            return acc;
        }, {});

        if (lowestPricedStyleVariant.variant && is_sliced) {
            const { variant: { price_range } } = lowestPricedStyleVariant;

            return price_range;
        }

        const firstVariant = is_sliced ? null : variants.find((variant) => variant.stock_status === 'IN_STOCK');
        const initialImage = firstVariant?.thumbnail?.url || thumbnail;
        const productImageLink = changedProductImage || initialImage;

        const variantDisplayed = variants.filter((variant) => {
            const {
                thumbnail: {
                    url
                } = {}
            } = variant;

            return url === productImageLink;
        });

        if (!variantDisplayed.length) {
            return null;
        }

        return variantDisplayed[0].price_range;
    }

    renderMainDetails() {
        const { isInfiniteLoaderEnabled, product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name, isPlaceholder: !name } }
            >
                { isInfiniteLoaderEnabled ? name : <TextPlaceholder content={ name } length="long" /> }
            </p>
        );
    }

    renderConfigurableAttributes() {
        const {
            product: { configurable_options, variants = [] },
            use_thumbnail_as_swatch,
            isInfiniteLoaderEnabled
        } = this.props;

        if (!configurable_options) {
            return (
                <ul block="ProductCard" mods={ { isLoading: true, isThumbnail: use_thumbnail_as_swatch } }>
                    { Array.from({ length: 3 }, (_, i) => (
                        <li
                          key={ i }
                          block="ProductCard"
                          elem="ListItem"
                        >
                            { isInfiniteLoaderEnabled ? <div /> : <TextPlaceholder length="block" /> }
                        </li>
                    )) }
                </ul>
            );
        }

        return [this.renderSwatch(variants), this.renderNumberOfColorSwatches(variants)];
    }

    renderSwatch(variants) {
        const {
            onActiveImageChange,
            activeImage,
            getIsConfigurableAttributeAvailable,
            filterValidVariants
        } = this.props;

        const isVisible = filterValidVariants(variants).length === 1;

        const isAnySwatchAvailable = variants.reduce((acc, variant) => {
            if (acc) {
                return acc;
            }

            return getIsConfigurableAttributeAvailable(variant);
        }, false);

        if (!isAnySwatchAvailable) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <div
                  block="ProductCard"
                  elem="Swatches"
                  mods={ { isVisible } }
                >
                    <div
                      aria-hidden="true"
                      block="Container"
                      elem="Swatches"
                    >
                        <SwatchSlider
                          activeImage={ activeImage }
                          onActiveImageChange={ onActiveImageChange }
                        >
                            { filterValidVariants(variants).map((variant, i) => (
                                this.renderConfigurableAttributeValue(variant, i)
                            )) }
                        </SwatchSlider>
                    </div>
                </div>
            </Suspense>
        );
    }

    renderNumberOfColorSwatches(variants) {
        const { filterValidVariants, isPlp } = this.props;

        const totalNumberOfColorSwatches = filterValidVariants(variants).length;

        if (!isPlp || totalNumberOfColorSwatches === 0 || totalNumberOfColorSwatches > 1) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="NumberOfColorSwatches"
            >
                { __('1 Color') }
            </div>
        );
    }

    renderConfigurableAttributeValue(variant) {
        const {
            product,
            changeProductThumbnailPrice,
            onColorSwatchChange,
            selectedColorSwatch: {
                attribute_value: selectedColorId
            } = {}
        } = this.props;

        const {
            sku,
            color,
            thumbnail,
            swatch_image_url
        } = variant;

        const { url: thumbnailUrl = '' } = thumbnail || {};

        // Faking attribute structure from existing data so component can render
        const syntheticAttribute = {
            sku,
            attribute_code: 'color',
            attribute_type: 'select',
            attribute_value: color,
            attribute_options: {
                [SWATCH_COLOR_KEY]: {
                    swatch_data: {
                        value: swatch_image_url,
                        type: '3' // swatch
                    }
                }
            }
        };

        if (isMobile.any()) {
            return (
                <div
                  aria-hidden="true"
                  block="ProductCard"
                  elem="ColorAttribute"
                  key={ sku }
                    /* eslint-disable-next-line react/jsx-no-bind */
                  onTouchStart={ (e) => changeProductThumbnailPrice(
                      thumbnailUrl,
                      this.collectSimplePrice(variant.color),
                      e
                  ) }
                >
                    <ProductAttributeValue
                      key={ sku }
                      attribute={ syntheticAttribute }
                      // eslint-disable-next-line react/jsx-no-bind
                      getLink={ () => null }
                      product={ product }
                      isMobile={ isMobile.any() }
                      onClick={ onColorSwatchChange }
                      parameters={ { color: selectedColorId } }
                    />
                </div>
            );
        }

        return (
            <div
              block="ProductCard"
              elem="ColorAttribute"
              key={ sku }
              /* eslint-disable-next-line react/jsx-no-bind */
              onMouseEnter={ () => changeProductThumbnailPrice(
                  thumbnailUrl,
                  this.collectSimplePrice(variant.color),
                  null,
                  true
              ) }
              // eslint-disable-next-line react/jsx-no-bind
              onMouseLeave={ () => changeProductThumbnailPrice('', null, null, true) }
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => changeProductThumbnailPrice(
                  thumbnailUrl,
                  this.collectSimplePrice(variant.color)
              ) }
            >
                <ProductAttributeValue
                  key={ sku }
                  attribute={ syntheticAttribute }
                  // eslint-disable-next-line react/jsx-no-bind
                  getLink={ () => null }
                  product={ product }
                  onClick={ onColorSwatchChange }
                  parameters={ { color: selectedColorId } }
                />
            </div>
        );
    }

    collectSimplePrice(variantColor) {
        const { product: { variants } } = this.props;

        const variantDisplayed = variants.find(({ color }) => color === variantColor);

        return variantDisplayed.price_range;
    }

    renderPicture() {
        const {
            product: {
                id,
                is_sliced,
                name,
                thumbnail: {
                    url = ''
                } = {},
                variants = []
            },
            changedProductImage,
            changedProductImageHover,
            cloudinaryStatus,
            sliderActiveImage,
            onSliderButtonClick,
            device,
            sliderOverlayWidth,
            getMatchingVariantColor
        } = this.props;

        // For sliced products we will use image defined in configurable product
        const firstVariant = is_sliced ? null : variants.find((variant) => variant.stock_status === 'IN_STOCK');
        const initialImage = firstVariant?.thumbnail?.url || url;
        const productImageLink = (changedProductImageHover || changedProductImage) || initialImage;

        if (!id) {
            return this.renderPlaceholderImg(false);
        }

        const mobileView = isMobile.any();
        const activeColor = getMatchingVariantColor();

        return (
            <>
                <Slider
                  ref={ this.sliderRef }
                  onActiveImageChange={ onSliderButtonClick }
                  activeImage={ sliderActiveImage }
                  sliderOverlayWidth={ sliderOverlayWidth }
                  showArrows
                  device={ device }
                  isProductCardSlider
                >
                    <Image
                      imageRef={ this.imageRef }
                      src={ formatImageUrl(cloudinaryStatus, productImageLink, '480') }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'ProductCard', elem: 'Picture', mods: { isPlaceholder: !id } } }
                      isPlaceholder={ !id }
                    />
                    { this.renderAdditionalSliderImages(productImageLink, cloudinaryStatus, name, activeColor) }
                </Slider>
                { mobileView ? this.renderStageGlide(activeColor) : null }
            </>
        );
    }

    renderAdditionalSliderImages(productImageLink, cloudinaryStatus, name, activeColor) {
        const { sliderColorsImgsUrls } = this.props;

        if (Object.keys(sliderColorsImgsUrls).length === 0) {
            return this.renderPlaceholderImg(true);
        }

        return (
            sliderColorsImgsUrls[activeColor].map((media) => {
                const { thumbnail: { url } } = media;

                if (url === productImageLink) {
                    return false;
                }

                return (
                    <Image
                      src={ formatImageUrl(cloudinaryStatus, url, '480') }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'ProductCard', elem: 'Picture' } }
                      key={ url }
                    />
                );
            })
        );
    }

    renderPlaceholderImg(isGallery = false) {
        const { isInfiniteLoaderEnabled } = this.props;

        if (isGallery || isInfiniteLoaderEnabled) {
            return (
                <Image
                  src={ media('placeholder-logo.jpeg', 'custom_images/') }
                  alt="placeholder"
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture' } }
                />
            );
        }

        return (
            <Image
              src={ formatImageUrl(false, '', '480') }
              alt="placeholder"
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mods: { isPlaceholder: true } } }
              isPlaceholder
            />
        );
    }

    renderStageGlide(activeColor) {
        const {
            product: { id },
            sliderColorsImgsUrls,
            sliderActiveImage
        } = this.props;

        if (!id) {
            return null;
        }

        // Showing 5 glides [1,1,1,1,1] as a placeholder because we don't know yet how many images will be there
        const glideNumArray = Object.keys(sliderColorsImgsUrls).length === 0
            ? [1, 1, 1, 1, 1] : sliderColorsImgsUrls[activeColor];

        return (
            <div block="ProductGallery" elem="Glide">
                { glideNumArray.map((media, i) => (
                            <div
                              block="ProductGallery"
                              elem="GlideStage"
                              mods={ {
                                  isActive: isArabic() ? i === Math.abs(sliderActiveImage) : i === sliderActiveImage
                              } }
                              key={ i.toString() }
                            />
                )) }
            </div>
        );
    }

    addHashtag(color) {
        if (!color) {
            return '';
        }

        if (color.includes('#')) {
            return color;
        }

        return `#${ color }`;
    }

    getBorderColor(productButtonStyles) {
        const {
            border,
            borderColor
        } = productButtonStyles;

        if (border === '1') {
            return this.addHashtag(borderColor);
        }

        return 'black';
    }

    renderProductButton() {
        const { showProductButton, buttonText, productButtonStyles } = this.props;
        const { productButtonHovered } = this.state;

        const isVisible = showProductButton && !!buttonText;

        const {
            backgroundColor,
            color,
            hoverBackgroundColor,
            hoverColor
        } = productButtonStyles;

        const buttonStyles = {
            backgroundColor: productButtonHovered
                ? (this.addHashtag(hoverBackgroundColor) || 'white')
                : (this.addHashtag(backgroundColor) || 'black'),
            color: productButtonHovered
                ? (this.addHashtag(hoverColor) || 'black')
                : (this.addHashtag(color) || 'white'),
            border: `1px solid ${ this.getBorderColor(productButtonStyles) }`
        };

        return (
            <div
              block="ProductCard"
              elem="ProductButton"
              mods={ { isVisible } }
              style={ buttonStyles }
              /* eslint-disable-next-line react/jsx-no-bind */
              onMouseEnter={ () => this.setState({ productButtonHovered: true }) }
              /* eslint-disable-next-line react/jsx-no-bind */
              onMouseLeave={ () => this.setState({ productButtonHovered: false }) }
            >
                { buttonText }
            </div>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            isSliderDragged,
            linkTo,
            product: {
                url
            }
        } = this.props;

        // prevents failures during load, when product data is not there
        if (!url) {
            return (<div>{ children }</div>);
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ !isSliderDragged ? linkTo : {} }
              onClick={ this.handleLinkClick }
              mix={ mix }
            >
                { children }
            </Link>
        );
    }

    renderAddToCart() {
        const {
            onAddToCartClick,
            product: { id },
            selectedColorSwatch,
            isLoading,
            isPlp,
            isInfiniteLoaderEnabled
        } = this.props;

        const isColorSelected = Object.keys(selectedColorSwatch).length;

        if (!isPlp || (isMobile.any() && !isColorSelected)) {
            return null;
        }

        if (isLoading || !id) {
            return (
                <div
                  block="ProductCard"
                  elem="AddToCart"
                >
                    { isInfiniteLoaderEnabled ? <div /> : <TextPlaceholder length="short" /> }
                </div>
            );
        }

        return (
            <>
                <div
                  block="ProductCard"
                  elem="AddToCart"
                >
                    <div
                      aria-hidden="true"
                      block="Button"
                      elem="AddToCart"
                      onKeyDown={ onAddToCartClick }
                      onClick={ onAddToCartClick }
                    >
                        { __('Add to cart') }
                    </div>
                </div>
                { this.renderSizeSelectorPopup() }
            </>
        );
    }

    renderWishlistButton() {
        const { product, getMatchingVariantColor } = this.props;

        if (Object.keys(product).length === 0) {
            return null;
        }

        const color = getMatchingVariantColor();

        return (
            <ProductCardWishlist
              mix={ { block: 'ProductCard', elem: 'Wishlist' } }
              magentoProduct={ product }
              color={ color }
            />
        );
    }

    renderSizeSelectorPopup() {
        const {
            sizeSelectorPopupPayload: {
                product: {
                    id: payloadId
                } = {}
            } = {},
            product: {
                id
            }
        } = this.props;

        if (!payloadId || !id || payloadId !== id) {
            return null;
        }

        return <SizeSelectorPopup />;
    }

    renderContent() {
        const {
            children,
            isLoading,
            product,
            product: { label_text },
            use_thumbnail_as_swatch
        } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                { this.renderCardLinkWrapper((
                    <>
                        { this.renderProductButton() }
                        { this.renderWishlistButton() }
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                            { label_text && (
                                <Suspense fallback={ null }>
                                    <ProductLabel product={ product } />
                                </Suspense>
                            ) }
                        </figure>
                        <div block="ProductCard" elem="Content" mods={ { isThumbnail: use_thumbnail_as_swatch } }>
                            { this.renderConfigurableAttributes() }
                            { <ProductLabel
                              product={ product }
                              isBadge
                            /> }
                            { this.renderMainDetails() }
                            { this.renderPrice() }
                            { this.renderAddToCart() }
                        </div>
                    </>
                )) }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </>
        );
    }

    renderFallback() {
        return (
            <div block="ProductCard" elem="ContentPlaceholder" />
        );
    }

    renderVisualConfigurableOptions() {
        const { availableVisualOptions } = this.props;

        return (
            <div block="ProductCard" elem="ConfigurableOptions">
                { availableVisualOptions.map(({ value, label }) => (
                    <span
                      block="ProductCard"
                      elem="Color"
                      key={ value }
                      style={ { backgroundColor: value } }
                      aria-label={ label }
                    />
                )) }
            </div>
        );
    }

    render() {
        const {
            mix,
            productIndex,
            isPlp,
            onColorSwatchChange,
            activeOverlay,
            getMatchingVariantColor,
            product
        } = this.props;

        if (Object.keys(product).length > 0 && !getMatchingVariantColor()) {
            return null;
        }

        // First 4 items we render immediately, they will always be visible
        // eslint-disable-next-line no-magic-numbers
        if (isPlp || (typeof productIndex !== 'undefined' && productIndex < 4)) {
            return (
                <ClickOutside
                // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => isMobile.any() && !activeOverlay && onColorSwatchChange({}) }
                >
                    <li
                      block="ProductCard"
                      mix={ mix }
                    // eslint-disable-next-line react/jsx-no-bind
                      onMouseLeave={ () => isMobile.any()
                        && activeOverlay !== SIZE_SELECTOR_POPUP_ID
                        && onColorSwatchChange({}) }
                      dir={ isArabic() ? 'rtl' : 'ltr' }
                    >
                        { this.renderContent() }
                    </li>
                </ClickOutside>
            );
        }

        return (
            <li
              block="ProductCard"
              mix={ mix }
              dir={ isArabic() ? 'rtl' : 'ltr' }
              // eslint-disable-next-line react/jsx-no-bind
              onMouseLeave={ () => isMobile.any() && onColorSwatchChange({}) }
            >
                <RenderWhenVisible fallback={ this.renderFallback }>
                    { this.renderContent() }
                </RenderWhenVisible>
            </li>
        );
    }
}

export default ProductCardComponent;
