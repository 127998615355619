/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable fp/no-let */
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';
import { appendWithStoreCode } from 'Util/Url';

import AdyenPaymentDetailsMutation from '../query/AdyenPaymentDetails.mutation';
import {
    ADYEN_AMAZON_PAY_PAYMENT_TYPE,
    ADYEN_APPLE_PAY_PAYMENT_TYPE,
    ADYEN_GOOGLE_PAY_PAYMENT_TYPE,
    ADYEN_PAYPAL_PAYMENT_TYPE,
    getCheckoutEnvironment
} from './Adyen';
// eslint-disable-next-line import/no-cycle
import { buildAdyenCheckoutComponent, mountAdyenPaymentMethodComponent } from './AdyenCheckout';

export const handleActionPaymentMethods = ['paypal'];

/**
 * only the handleAction is supported.
 * createAction can also be used, it will requeire creating a popup for it
 * @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/renderActionComponent
 */
export const renderActionComponent = (resultCode, action, _component) => {
    // vvv If this is a handleAction method then do it that way, otherwise createFrom action
    if (handleActionPaymentMethods.includes(window.selectedAlternativePaymentMethodType)) {
        window.adyenComponent.handleAction(JSON.parse(action));
    } else {
        // vvv input popup id here
        const popupId = '';

        if (!popupId) {
            return;
        }

        const actionNode = document.getElementById(popupId);

        if (resultCode !== 'RedirectShopper') {
            // vvv show popup here
        }

        window.adyenElement.createFromAction(action).mount(actionNode);
    }
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/validateActionOrPlaceOrder */
export const validateActionOrPlaceOrder = (response, orderId, component) => {
    const {
        action,
        isFinal,
        resultCode
    } = response;

    if (!isFinal) {
        renderActionComponent(resultCode, action, component);
        return;
    }

    window.location.replace(
        appendWithStoreCode(`/checkout/success?orderId=${window.orderNumber}`)
    );
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/handleOnCancel */
export const handleOnCancel = async (state, _component) => {
    const { data } = state;
    const cartId = getStore().getState().CartReducer.cartTotals.id;

    const payload = JSON.stringify({
        ...data,
        orderId: window.orderNumber,
        cancelled: true
    });

    if (!cartId) {
        return;
    }

    try {
        await fetchMutation(AdyenPaymentDetailsMutation.handleAdyenPaymentDetails(payload, cartId));

        window.location.replace(
            appendWithStoreCode(`/checkout/success?orderId=${window.orderNumber}`)
        );
    } catch (error) {
        console.log('error >>> ', error);
    }
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/handleOnSubmit */
export const handleOnSubmit = async (_state, _component) => {
    window.adyenStateData = _state;

    const billingForm = document.querySelector('#SHIPPING_STEP');

    if (!billingForm) {
        return;
    }

    const submitButton = billingForm.querySelector('button[type="submit"]');

    if (!submitButton) {
        return;
    }

    submitButton.disabled = false;

    submitButton.click();
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/handleOnAdditionalDetails */
export const handleOnAdditionalDetails = async (state, _component) => {
    const { data } = state;
    const cartId = getStore().getState().CartReducer.cartTotals.id;

    const payload = JSON.stringify({
        ...data,
        orderId: window.orderNumber
    });

    if (!cartId) {
        return;
    }

    try {
        await fetchMutation(AdyenPaymentDetailsMutation.handleAdyenPaymentDetails(payload, cartId));

        window.location.replace(
            appendWithStoreCode(`/checkout/success?orderId=${window.orderNumber}`)
        );
    } catch (error) {
        console.log('error >>> ', error);
    }
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/initializeHppAdyenCheckoutComponent */
export const initializeHppAdyenCheckoutComponent = (paymentMethodsResponse) => buildAdyenCheckoutComponent(
    paymentMethodsResponse,
    handleOnAdditionalDetails,
    handleOnCancel,
    handleOnSubmit,
);

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/buildComponentConfiguration */
export const buildComponentConfiguration = async (
    paymentMethod,
    paymentMethodsExtraInfo,
    selectedAlternativePaymentMethodType
) => {
    const showPayButtonPaymentMethods = [
        'paypal',
        'applepay',
        'paywithgoogle',
        'googlepay',
        'amazonpay'
    ];
    const showPayButton = showPayButtonPaymentMethods.includes(
        paymentMethod.type
    );
    const {
        adyen_has_holder_name: hasHolderName,
        adyen_holder_name_required: holderNameRequired
    } = getStore().getState().ConfigReducer;

    // vvv expose selectedAlternativePaymentMethodType for later use
    window.selectedAlternativePaymentMethodType = selectedAlternativePaymentMethodType;

    /* Use the storedPaymentMethod object and the custom onChange function as the configuration object together */
    let configuration = Object.assign(paymentMethod,
        {
            showPayButton,
            hasHolderName,
            holderNameRequired,
            onClick(resolve, _reject) {
                // ^^^ can add form validation here
                // vvv for paypal add a workaround, remove when component fixes it
                if (selectedAlternativePaymentMethodType === ADYEN_PAYPAL_PAYMENT_TYPE) {
                    return true;
                }

                return resolve();
            }
        });

    // Use extra configuration from the paymentMethodsExtraInfo object if available
    if (paymentMethod.type in paymentMethodsExtraInfo
        && 'configuration' in paymentMethodsExtraInfo[paymentMethod.type]
    ) {
        configuration = Object.assign(
            configuration,
            paymentMethodsExtraInfo[paymentMethod.type].configuration
        );
    }

    // vvv Workaround for Google pay. We are adding gatewayMerchantId and setting merchantName to the same value.
    if (paymentMethod.type === ADYEN_GOOGLE_PAY_PAYMENT_TYPE) {
        if ('configuration' in configuration
            && !configuration.configuration?.merchantName) {
            configuration.configuration.merchantName = configuration.configuration.gatewayMerchantId;
        }
    }

    // Extra apple pay configuration
    if (paymentMethod.type === ADYEN_APPLE_PAY_PAYMENT_TYPE) {
        if ('configuration' in configuration
            && 'merchantName' in configuration.configuration) {
            configuration.totalPriceLabel = configuration.configuration.merchantName;
        }
    }

    // Extra amazon pay configuration first call to amazon page
    if (paymentMethod.type === ADYEN_AMAZON_PAY_PAYMENT_TYPE) {
        configuration.productType = 'PayAndShip';
        configuration.checkoutMode = 'ProcessOrder';
        const url = new URL(location.href);
        url.searchParams.delete('amazonCheckoutSessionId');
        configuration.returnUrl = url.href;
        configuration.onSubmit = async (state, amazonPayComponent) => {
            try {
                await handleOnSubmit(state.data, amazonPayComponent);
            } catch (error) {
                amazonPayComponent.handleDeclineFlow();
            }
        };
    }

    return configuration;
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/mountPaymentMethodComponent */
export const mountPaymentMethodComponent = (
    paymentMethod,
    configuration,
    isApplePayExpress,
    applePayExpressSource,
    setApplePayExpressLoaderState
) => {
    try {
        const amazonSessionKey = '';
        const containerId = `#adyen-checkout-adyen_${paymentMethod.type}${isApplePayExpress ? '-express' : ''}`;
        const url = new URL(location.href);
        // Handles the redirect back to checkout page with amazonSessionKey in url
        if (
            paymentMethod.type === 'amazonpay'
            && url.searchParams.has(amazonSessionKey)
        ) {
            const componentConfig = {
                amazonCheckoutSessionId: url.searchParams.get(amazonSessionKey),
                showOrderButton: false,
                amount: {
                    currency: configuration.amount.currency,
                    value: configuration.amount.value
                },
                showChangePaymentDetailsButton: false
            };

            const amazonPayComponent = mountAdyenPaymentMethodComponent(
                'amazonpay',
                componentConfig,
                containerId
            );

            amazonPayComponent.submit();
            window.adyenPaymentComponent = amazonPayComponent;
        }

        mountAdyenPaymentMethodComponent(
            paymentMethod.type,
            configuration,
            containerId,
            applePayExpressSource,
            setApplePayExpressLoaderState
        );
    } catch (error) {
        // The component does not exist yet
        if (getCheckoutEnvironment() === 'test') {
            console.log('error >>> ', error);
        }
    }
};

/** @namespace Scandiweb/AdyenPayments/Util/AdyenHppMethod/renderHppCheckoutComponent */
export const renderHppCheckoutComponent = (
    paymentMethod,
    paymentMethodsExtraInfo,
    selectedPaymentType,
    globalConfigs,
    isApplePayExpress,
    applePayExpressSource = null,
    setApplePayExpressLoaderState = null
) => {
    const configuration = {
        ...globalConfigs,
        paymentMethodsConfiguration: {
            ...buildComponentConfiguration(
                paymentMethod,
                paymentMethodsExtraInfo,
                selectedPaymentType
            )
        }
    };

    mountPaymentMethodComponent(
        paymentMethod,
        configuration,
        isApplePayExpress,
        applePayExpressSource,
        setApplePayExpressLoaderState
    );
};
