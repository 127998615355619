/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
import { HOME_PAGE, SEARCH } from 'Component/Header/Header.config';

import { PLP_PAGE, SEARCH_PAGE } from '../../component/GoogleTagManager/GA4_events/ViewItemList.event';
import Event, {
    EVENT_GTM_IMPRESSIONS_HOME,
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH,
    EVENT_GTM_IMPRESSIONS_WISHLIST,
    EVENT_GTM_VIEW_ITEM_LIST_GA4
} from '../../util/Event';

/** MyAccountMyWishlistContainer */
export const MyAccountMyWishlistContainer_render = (args, callback, instance) => {
    const { wishlistItems, isWishlistLoading } = instance.props;

    if (!isWishlistLoading && Object.keys(wishlistItems).length > 0) {
        const items = Object.values(wishlistItems).reduce(
            (acc, item) => {
                if (!Object.keys(item).length) {
                    return acc;
                }

                const {
                    sku,
                    wishlist: {
                        sku: variantSku = sku
                    } = {}
                } = item;

                return [
                    ...acc,
                    { product: item, sku: variantSku }
                ];
            },
            []
        );

        Event.dispatch(EVENT_GTM_IMPRESSIONS_WISHLIST, { items });
    }

    return callback(...args);
};

/** ProductLinks */
// Linked products are not used on Puma site
// export const ProductLinks_componentDidUpdate = (args, callback, instance) => {
//     const [prevProps] = args;
//
//     const { areDetailsLoaded } = instance.props;
//     const { areDetailsLoaded: wereDetailsLoaded } = prevProps;
//
//     if (areDetailsLoaded && wereDetailsLoaded) {
//         const { linkType = '', linkedProducts = {} } = instance.props;
//         const { items = {} } = linkedProducts[linkType] || {};
//
//         if (items.length) {
//             Event.dispatch(EVENT_GTM_IMPRESSIONS_LINKED, { items });
//         }
//     }
//
//     callback(...args);
// };

/**
 * Get query variable value (from react router)
 * Copied from Util/Url to avoid calling Store util which breaks plugin sequence.
 * @param {String} variable Variable from URL
 * @param {Object} variable location object from react-router
 * @param location
 * @return {String|boolean} Variable value
 * @namespace Util/Url/getQueryParam
 */
export const getQueryParam = (variable, location) => {
    const query = location.search.substring(1);
    const vars = query.split('&');
    // eslint-disable-next-line fp/no-loops,fp/no-let
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }

    return false;
};

/** ProductList */
export const ProductList_componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;
    const {
        pages,
        isLoading,
        selectedFilters: filters,
        category = {}
    } = instance.props;
    const {
        isLoading: prevIsLoading,
        pages: prevPages
    } = prevProps;
    const currentPage = getQueryParam('page', location) || 1;
    const pagesLength = Object.keys(pages || {}).length;
    const prevPagesLength = Object.keys(prevPages || {}).length;

    if (!pagesLength
        || !Object.keys(pages[currentPage] || {}).length
        || isLoading
        || (isLoading === prevIsLoading && pagesLength === prevPagesLength)
    ) {
        return;
    }

    const { currentRouteName } = window;

    if (currentRouteName === HOME_PAGE) {
        Event.dispatch(
            EVENT_GTM_IMPRESSIONS_HOME,
            { items: pages[currentPage], filters }
        );
    } else if (currentRouteName === SEARCH) {
        if (JSON.stringify(prevPages) !== JSON.stringify(pages)) {
            Event.dispatch(
                EVENT_GTM_IMPRESSIONS_SEARCH,
                { items: pages[currentPage], filters }
            );

            Event.dispatch(
                EVENT_GTM_VIEW_ITEM_LIST_GA4,
                { pageType: SEARCH_PAGE, items: pages[currentPage], filters }
            );
        }
    } else {
        Event.dispatch(
            EVENT_GTM_IMPRESSIONS_PLP,
            { items: pages[currentPage], filters, category }
        );

        Event.dispatch(
            EVENT_GTM_VIEW_ITEM_LIST_GA4,
            {
                pageType: PLP_PAGE, items: pages[currentPage], filters, category
            }
        );
    }
};

export default {
    'Component/MyAccountMyWishlist/Container': {
        'member-function': {
            'render': MyAccountMyWishlistContainer_render
        }
    },
    // Linked products are not used on Puma site
    // 'Component/ProductLinks/Container': {
    //     'member-function': {
    //         'componentDidUpdate': ProductLinks_componentDidUpdate
    //     }
    // },
    'Component/ProductList/Container': {
        'member-function': {
            'componentDidUpdate': ProductList_componentDidUpdate
        }
    }
};
