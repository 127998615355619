/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_AddToCartPopup
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

// eslint-disable-next-line import/prefer-default-export
export const ADD_TO_CART_POPUP = 'ADD_TO_CART_POPUP';
