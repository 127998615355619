/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DEFAULT_COUNTRY_UAE } from 'Component/CheckoutNewAddressForm/CheckoutNewAddressForm.config';
import Loader from 'Component/Loader';
import { STORE_CODE_KSA_AR, STORE_CODE_UAE } from 'Component/Router/Router.config';

import './CheckoutComCard.style';

/** @namespace Checkoutcom/Component/CheckoutComCard/Component */
export class CheckoutComCardComponent extends PureComponent {
    /**
     * Props
     * @type {*}
     */
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        public_key: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
        language_fallback: PropTypes.string.isRequired,
        loadWidgetScript: PropTypes.func.isRequired,
        addFramesEvents: PropTypes.func.isRequired,
        requestConfig: PropTypes.func.isRequired,
        default_country: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired
    };

    /**
     * On component update
     */
    componentDidMount() {
        const { requestConfig } = this.props;

        requestConfig().then(this.launchPaymentMethod);
    }

    /**
     * Constructor
     * @param props
     * @param context
     */
    __construct(props, context) {
        super.__construct(props, context);

        this.state = {
            isLoading: true,
            isInitialized: false
        };
    }

    /**
     * Launch payment method
     */
    launchPaymentMethod = () => {
        const {
            loadWidgetScript,
            public_key,
            style,
            language_fallback,
            addFramesEvents,
            default_country,
            locale
        } = this.props;
        const { isInitialized } = this.state;

        if (!public_key || isInitialized) {
            return;
        }

        const checkoutStyle = JSON.parse(style);
        checkoutStyle.style.placeholder.focus.border = 'none';
        const isArabic = (default_country === DEFAULT_COUNTRY_UAE && locale === STORE_CODE_UAE)
            || locale === STORE_CODE_KSA_AR;

        loadWidgetScript(() => {
            window.Frames.init(
                {
                    publicKey: public_key,
                    localization: isArabic ? {
                        cardNumberPlaceholder: __('Card number'),
                        expiryMonthPlaceholder: __('MM'),
                        expiryYearPlaceholder: __('YY '),
                        cvvPlaceholder: __('CVV')
                    } : language_fallback,
                    ...checkoutStyle
                }
            );

            addFramesEvents();

            this.setState({ isLoading: false, isInitialized: true });
        });
    };

    /**
     * Render
     * @returns {*}
     */
    render() {
        const { isLoading } = this.props;
        const { isLoading: stateLoading } = this.state;
        const showLoader = isLoading || stateLoading;

        return (
            <div block="CheckoutComCardPayment" elem="Wrapper">
                <Loader isLoading={ showLoader } />

                <div block="CheckoutComCardPayment" elem="Frames">
                    <div block="card-frame" />
                </div>
                <div block="CheckoutComCardPayment" elem="ErrorMessage" />
            </div>
        );
    }
}

export default CheckoutComCardComponent;
