/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { NAV_STATE } from './NavigationTabs.action';

export const initialState = {
    navState: false
};

/** @namespace Scandipwa/Store/NavigationTabs/Reducer/NavigationTabsReducer */
export const NavigationTabsReducer = (state = initialState, action) => {
    const { type, navState = false } = action;

    switch (type) {
    case NAV_STATE:
        return { ...state, navState };
    default:
        return state;
    }
};
export default NavigationTabsReducer;
