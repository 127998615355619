/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { CheckoutPayment as SourceComponent } from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.style';
import './CheckoutPayment.style.override';

/** @namespace Scandipwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceComponent {
    renderImage = () => {
        const {
            method: { img_src }
        } = this.props;

        if (!img_src) {
            return null;
        }

        return (
            <img
              block="CheckoutPayment"
              elem="Image"
              src={ img_src }
              alt="payment_method_logo"
            />
        );
    };

    render() {
        const {
            isSelected,
            method: {
                title,
                img_src
            }
        } = this.props;

        const hasImage = !!img_src;

        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected, hasImage } }
                  elem="Button"
                  onClick={ this.onClick }
                  type="button"
                >
                    { title }
                    { this.renderImage() }
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
