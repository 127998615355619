/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/boolean-prop-naming */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SwatchSliderComponent from './SwatchSlider.component';

/** @namespace Scandipwa/Component/SwatchSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    use_thumbnail_as_swatch: state.ConfigReducer.use_thumbnail_as_swatch
});

/** @namespace Scandipwa/Component/SwatchSlider/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Scandipwa/Component/SwatchSlider/Container */
export class SwatchSliderContainer extends PureComponent {
    static propTypes = {
        onActiveImageChange: PropTypes.func.isRequired,
        use_thumbnail_as_swatch: PropTypes.bool
    };

    static defaultProps = {
        use_thumbnail_as_swatch: false
    };

    containerFunctions = {
        changeActiveSwatch: this.changeActiveSwatch.bind(this)
    };

    changeActiveSwatch(e, nextImage) {
        const { onActiveImageChange } = this.props;

        if (!e) {
            return;
        }

        e.stopPropagation();
        onActiveImageChange(e, nextImage);
    }

    render() {
        return (
            <SwatchSliderComponent
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps)(SwatchSliderContainer);
