/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Loader from 'Component/Loader/Loader.component';
import SourceMyAccountAddressTable from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.style';

/** @namespace Scandipwa/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTable {
    static propTypes = {
        ...super.propTypes,
        onDelete: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...super.defaultProps,
        onDelete: () => null
    };

    // PumaPWA Function copied fromMyAccountAddressTable.component.js and is for Puma UAE only
    get dataPairArray() {
        const { address, getFormatedRegion, showAdditionalFields } = this.props;
        const regionData = getFormatedRegion(address);
        const additionalFields = [
            {
                key: 'region',
                label: __('State/Province'),
                source: address
            },
            {
                key: 'city',
                label: __('City'),
                source: address
            },
            {
                key: 'country',
                label: __('County'),
                source: regionData
            },
            {
                key: 'telephone',
                label: __('Phone number'),
                source: address
            }
        ];

        const fields = [
            {
                key: 'firstname',
                label: __('First name'),
                source: address
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: address
            },
            {
                key: 'street',
                label: __('Street'),
                source: address
            }
        ];

        return [...fields, ...(showAdditionalFields ? additionalFields : [])];
    }

    renderActions() {
        const {
            onEditClick,
            showActions,
            onDelete,
            isLoading
        } = this.props;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <button
                  block="Link"
                  mix={ { block: 'Link', elem: 'EditAddressButton' } }
                  onClick={ onEditClick }
                >
                    { __('Edit address') }
                </button>
                <Loader isLoading={ isLoading } />
                <button
                  block="Link"
                  elem="DeleteAddressButton"
                  onClick={ onDelete }
                >
                    { __('Delete') }
                </button>
            </>
        );
    }
}

export default MyAccountAddressTableComponent;
