/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AdyenCheckout from '../component/AdyenCheckout';
import { ADYEN_APPLE_PAY_METHOD_CODE } from '../util/Adyen';

export class AddToCartPopupComponent {
    aroundRenderProceedToCheckoutButton = (args, callback, instance) => (
        <>
            { callback.apply(instance, args) }
            { !!window.ApplePaySession && (
                <AdyenCheckout
                  code={ ADYEN_APPLE_PAY_METHOD_CODE }
                  isApplePayExpress
                  applePayExpressSource="express_mini_cart"
                />
            ) }
        </>
    );
}

export const { aroundRenderProceedToCheckoutButton } = new AddToCartPopupComponent();

export default {
    'Scandipwa/Component/AddToCartPopup/Component': {
        'member-function': {
            renderProceedToCheckoutButton: aroundRenderProceedToCheckoutButton
        }
    }
};
