/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ParentBaseEvent from '../events/BaseEvent.event';
import ProductHelper from '../GA4_utils';
import { EVENT_VIEW_ITEM_LIST_GA4 } from '../GoogleTagManager.config';

/**
 * Base event for GTM PWA events
 */
export class BaseEventEvent extends ParentBaseEvent {
    /**
     * Prepare cart data
     *
     * @param itemsProp
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, category: string, brand: string}[]}
     */
    prepareCartData(itemsProp = null) {
        const {
            total_quantity = 0,
            items: cartItems = [],
            groupedProducts = {}
        } = this.getCartProductData();

        const items = itemsProp || cartItems;

        const itemsData = items
            .map((item) => ({
                ...ProductHelper.getItemData(item),
                quantity: ProductHelper.getQuantity(item)
            }));

        if (total_quantity !== 0) {
            Object.values(groupedProducts || {}).forEach(({ data }) => itemsData.push(data));
        }

        return itemsData;
    }

    /**
     * Get data from impression
     *
     * @param product
     * @param addPosition
     * @param noIndex
     */
    getItemListData(product, addPosition = false, noIndex = false) {
        const data = this.getProductFromImpression(product) || {};

        if (Object.keys(data).length) {
            const {
                index = 0,
                item_list_id = '',
                item_list_name = ''
            } = data;

            if (addPosition) {
                const { item_list_position } = data;

                return {
                    index, item_list_id, item_list_name, item_list_position
                };
            }

            if (noIndex) {
                return {
                    item_list_id, item_list_name
                };
            }

            return {
                index, item_list_id, item_list_name
            };
        }

        return {};
    }

    /**
     * Get product position in impression
     *
     * @return {*}
     * @param clickedProduct
     */
    getProductFromImpression(clickedProduct) {
        const { impressions_ga4 = [] } = this.getStorage(EVENT_VIEW_ITEM_LIST_GA4);
        const id = ProductHelper.getSku(clickedProduct);
        const { sku } = clickedProduct;

        return impressions_ga4.find(({ item_id: impressionId }) => (
            impressionId === id || impressionId === sku
        ));
    }

    /**
     * Get cart sub total
     *
     * @return {string}
     */
    getItemsTotal() {
        return this.getAppState().CartReducer.cartTotals?.prices?.grand_total?.value || 0;
    }

    /**
     * Get cart items with 'index', 'item_list_id' and 'item_list_name'
     * attributes if available
     *
     * @param itemsProp
     * @returns {*}
     */
    getCartItemsWithListData(itemsProp = null) {
        const items = itemsProp || this.getCartProductData().items;

        return items.map(
            (item) => ({
                ...ProductHelper.getItemData(item),
                ...this.getItemListData(item.product),
                quantity: ProductHelper.getQuantity(item)
            })
        );
    }
}

export default BaseEventEvent;
