/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import './CartOverlay.plugin.style.scss';

export class CartOverlayPlugin {
    renderSubTotal(args, callback, instance) {
        const {
            totals: {
                prices: {
                    subtotal_including_tax: {
                        value: subtotalIncludingTax = 0
                    },
                    shipping_incl_tax = 0,
                    customs_duty_amount = 0
                } = {}
            }
        } = instance.props;

        const hasShipping = shipping_incl_tax > 0;
        const hasCustomsDuty = customs_duty_amount > 0;
        const netSubtotal = hasCustomsDuty ? subtotalIncludingTax - customs_duty_amount : subtotalIncludingTax;

        return (
            <>
                <dl
                  block="CartOverlay"
                  elem="SubTotal"
                  mods={ { hasSeparator: !(hasShipping || hasCustomsDuty) } }
                >
                    <dt>{ __('Subtotal:') }</dt>
                    <dd>{ instance.renderPriceLine(netSubtotal) }</dd>
                </dl>
                { hasShipping && (
                    <dl
                      block="CartOverlay"
                      elem="SubTotal"
                      mods={ { hasSeparator: !hasCustomsDuty } }
                    >
                        <dt>{ __('Shipping Total:') }</dt>
                        <dd>{ instance.renderPriceLine(shipping_incl_tax) }</dd>
                    </dl>
                ) }
                { hasCustomsDuty && (
                    <dl
                      block="CartOverlay"
                      elem="Customs"
                    >
                        <dt>{ __('Estimated duties:') }</dt>
                        <dd>{ instance.renderPriceLine(customs_duty_amount) }</dd>
                    </dl>
                ) }
            </>
        );
    }
}

export const { renderSubTotal } = new CartOverlayPlugin();

export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            renderSubTotal
        }
    }
};
