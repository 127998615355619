/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import isArabic from 'Util/Arabic';
import isKsa from 'Util/Arabic/isKsa';
import history from 'Util/History';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import {
    ARABIC_LOCALE,
    ENGLISH_LOCALE,
    REGISTER_CONFIRM_EMAIL_ID,
    REGISTER_CONFIRM_PASSWORD_ID,
    REGISTER_EMAIL_ID,
    REGISTER_FIRSTNAME_ID,
    REGISTER_LASTNAME_ID,
    REGISTER_PASSWORD_ID,
    REGISTER_SUBSCRIBE_ID
} from './MyAccountCreateAccount.config';

import './MyAccountCreateAccount.override.style';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccount {
    static propTypes = {
        ...super.propTypes,
        isNoTitle: PropTypes.bool.isRequired,
        locale: PropTypes.string.isRequired
    };

    renderCreateAccountSignUpInfoFields() {
        const { location: { state: { email = '' } = {} } } = history;
        const { range, minimunPasswordCharacter, handleDataChange } = this.props;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: REGISTER_EMAIL_ID,
                      name: 'email',
                      defaultValue: email,
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Confirm Your Email') }
                  attr={ {
                      id: REGISTER_CONFIRM_EMAIL_ID,
                      name: 'confirmEmail',
                      defaultValue: email,
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email,
                      match: (value) => {
                          const email = document.getElementById(REGISTER_EMAIL_ID);

                          return value && email.value === value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Emails do not match')
                      }
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Password') }
                  attr={ {
                      id: REGISTER_PASSWORD_ID,
                      name: 'password',
                      autocomplete: 'new-password'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const email = document.getElementById(REGISTER_EMAIL_ID);

                          if (value && email.value === value) {
                              return __('Passwords can\'t be the same as email!');
                          }

                          if (value.length < range.min) {
                              return __('Password should be at least %s characters long', range.min);
                          }

                          return validatePassword(value, range, minimunPasswordCharacter);
                      }
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Confirm password') }
                  attr={ {
                      id: REGISTER_CONFIRM_PASSWORD_ID,
                      name: 'confirm_password',
                      autocomplete: 'new-password'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const password = document.getElementById(REGISTER_PASSWORD_ID);

                          return value && password.value === value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Passwords do not match!')
                      }
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
            </>
        );
    }

    renderSubscribeToNewsletter() {
        return (
            <div
              block="Register"
              elem="Subscription"
            >
                <div block="Agreement">
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: REGISTER_SUBSCRIBE_ID,
                          name: 'is_subscribed'
                      } }
                      value="is_subscribed"
                      mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                    />
                    <p
                      block="MyAccountOverlay"
                      elem="Terms"
                    >
                        { __('Sign up for emails to get updates from PUMA on products and offers. You can unsubscribe at any time free of charge.') }
                    </p>
                    { this.renderTermsOfUseMessage() }
                </div>
            </div>
        );
    }

    // Arabic sentence structure is totally different which does not allow us to use translation in this case.
    renderTermsOfUseMessage() {
        if (isArabic()) {
            return (
                <p>
                    بإنشاء حساب، أنت توافق على
                    <span>&nbsp;</span>
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getPrivacyPolicyLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Privacy Policy') }
                    </a>
                    { __(' and ') }
                    <a
                      block="Link"
                      mix={ { block: 'Link', elem: 'Primary' } }
                      href={ this.getTermsOfUseLink() }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { __('Terms of use') }
                    </a>
                    <span>&nbsp;</span>
                    الخاصة بـ PUMA.
                </p>
            );
        }

        return (
            <p>
                { __('By creating an account, you agree to PUMA\'s ') }
                <a
                  block="Link"
                  mix={ { block: 'Link', elem: 'Primary' } }
                  href={ this.getPrivacyPolicyLink() }
                  target="_blank"
                  rel="noreferrer"
                >
                    { __('Privacy Policy') }
                </a>
                { __(' and ') }
                <a
                  block="Link"
                  mix={ { block: 'Link', elem: 'Primary' } }
                  href={ this.getTermsOfUseLink() }
                  target="_blank"
                  rel="noreferrer"
                >
                    { __('Terms of use') }
                </a>
                .
            </p>
        );
    }

    getTermsOfUseLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE:
            return isKsa() ? '/terms-of-use.html' : '/ar/terms-of-use.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/terms-of-use.html' : '/terms-of-use.html';
        default:
            return '/terms-of-use.html';
        }
    }

    getPrivacyPolicyLink() {
        const { locale } = this.props;

        switch (locale) {
        case ARABIC_LOCALE: // don' t have arabic version yet
            return isKsa() ? '/privacy-policy.html' : '/ar/privacy-policy.html';
        case ENGLISH_LOCALE:
            return isKsa() ? '/en/privacy-policy.html' : '/privacy-policy.html';
        default:
            return '/politica-de-privacidad.html';
        }
    }

    renderCreateAccountPersonalInfoFields() {
        const { location: { state: { firstName = '', lastName = '' } = {} } } = history;
        const { handleDataChange } = this.props;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('First Name') }
                  attr={ {
                      id: REGISTER_FIRSTNAME_ID,
                      name: 'firstname',
                      defaultValue: firstName,
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Last Name') }
                  attr={ {
                      id: REGISTER_LASTNAME_ID,
                      name: 'lastname',
                      defaultValue: lastName,
                      autocomplete: 'family-name'
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  events={ {
                      onChange: handleDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
            </>
        );
    }

    renderSubmitButton() {
        const { isRegisterDisabled } = this.props;

        return (
            <div block="RegisterButton">
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                      block="Button"
                      type="submit"
                      disabled={ isRegisterDisabled }
                    >
                        { __('Create an account') }
                    </button>
                </div>
            </div>
        );
    }

    renderTitle() {
        const { isNoTitle } = this.props;

        if (isNoTitle) {
            return null;
        }

        return (
            <div block="RegistrationTitle">
                <h3>
                    { __('Registration') }
                </h3>
            </div>
        );
    }

    renderCreateAccountForm() {
        const { onError, onSuccess, newsletterActive } = this.props;

        return (
            <Form
              key="create-account"
              onSubmit={ onSuccess }
              onError={ onError }
            >
                { this.renderTitle() }
                { this.renderCreateAccountPersonalInfoFields() }
                { this.renderCreateAccountSignUpInfoFields() }
                { newsletterActive ? this.renderSubscribeToNewsletter() : null }
                { this.renderSubmitButton() }
            </Form>
        );
    }

    render() {
        return (
            <div>
                { this.renderCreateAccountForm() }
            </div>
        );
    }
}

export default MyAccountCreateAccountComponent;
