/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { SET_ANALYTICS_DATA, SET_LAST_ORDER_STATUS } from 'Store/Analytics/Analytics.action';
import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Scandipwa/Store/Analytics/Reducer/getInitialState */
export const getInitialState = () => ({ promos: {}, orderStatus: '' });

/** @namespace Scandipwa/Store/Analytics/Reducer/AnalyticsReducer */
export const AnalyticsReducer = (state = getInitialState(), action) => {
    const { type, promos, orderStatus } = action;

    switch (type) {
    case SET_ANALYTICS_DATA:
        BrowserDatabase.setItem(
            promos,
            'promos'
        );

        return {
            ...state,
            promos
        };
    case SET_LAST_ORDER_STATUS:
        return {
            ...state,
            orderStatus
        };
    default:
        return state;
    }
};

export default AnalyticsReducer;
