/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-did-update-set-state */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Scandipwa/Component/Image/Component */
export class ImageComponent extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        srcset: PropTypes.string,
        sizes: PropTypes.string,
        onLoad: PropTypes.func,
        isPreventBlink: PropTypes.bool
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        isPreventBlink: false
    };

    onClick = this.onClick.bind(this);

    static getDerivedStateFromProps(props, state) {
        const { src, isPreventBlink, srcset } = props;
        const { prevSrc, prevSrcSet } = state;
        // eslint-disable-next-line fp/no-let
        let newState = null;

        if (prevSrc !== src || prevSrcSet !== srcset) {
            if (!isPreventBlink || !src) {
                newState = {
                    ...ImageComponent.onImageChange(props),
                    prevSrc: src,
                    prevSrcSet: srcset
                };
            }
        }

        return newState;
    }

    static onImageChange(props) {
        const { src } = props;

        if (!src) {
            return { imageStatus: IMAGE_NOT_SPECIFIED };
        }

        return { imageStatus: IMAGE_LOADING };
    }

    __construct(props = {}) {
        super.__construct(props);

        this.state = {
            imageStatus: IMAGE_LOADING,
            prevSrc: props.src,
            prevSrcSet: props.srcset
        };
    }

    preloadImage() {
        // preload image so blink will be less noticeable
        const { src, srcset } = this.props;

        const image = new window.ImageComponent();

        image.onload = () => {
            this.setState({
                prevSrc: src,
                prevSrcSet: srcset
            });
        };

        image.src = src;

        if (srcset) {
            image.srcset = srcset;
        }
    }

    componentDidUpdate() {
        const { src, srcset } = this.props;
        const { prevSrc, prevSrcSet } = this.state;

        // this might happen only if "isPreventBlink" was enabled
        if (src !== prevSrc || srcset !== prevSrcSet) {
            this.preloadImage();
        }
    }

    onError() {
        const { onError } = this.props;

        if (onError) {
            onError();
        }

        this.setState({ imageStatus: IMAGE_NOT_FOUND });
    }

    onLoad() {
        const { onLoad } = this.props;

        if (onLoad) {
            onLoad();
        }

        this.setState({ imageStatus: IMAGE_LOADED });
    }

    onClick() {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }
    }

    renderImage() {
        const {
            alt,
            isPlaceholder,
            style,
            sizes,
            imageratio
        } = this.props;

        const {
            imageStatus,
            prevSrc,
            prevSrcSet
        } = this.state;

        if (isPlaceholder) {
            return null;
        }

        const loadingStyle = (imageStatus === IMAGE_LOADING && imageratio)
            ? { height: `calc(100vw / ${ imageratio })` } : {};

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  srcSet={ prevSrcSet }
                  src={ prevSrc || '' }
                  sizes={ sizes }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ { ...style, ...loadingStyle } }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  onClick={ this.onClick }
                />
            );
        default:
            return null;
        }
    }
}

export default ImageComponent;
