/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import AdyenCheckout from '../component/AdyenCheckout';
import { ADYEN_APPLE_PAY_METHOD_CODE } from '../util/Adyen';

/** @namespace Checkoutcom/Plugin/CartOverlay/Plugin Copy */
export class CartPageComponent {
    aroundRenderButtons = (args, callback, instance) => (
        <>
            { callback.apply(instance, args) }
            { !!window.ApplePaySession && (
                <AdyenCheckout
                  code={ ADYEN_APPLE_PAY_METHOD_CODE }
                  isApplePayExpress
                  applePayExpressSource="express_cart"
                />
            ) }
        </>
    );
}

export const { aroundRenderButtons } = new CartPageComponent();

export default {
    'Scandipwa/Route/CartPage/Component': {
        'member-function': {
            renderButtons: aroundRenderButtons
        }
    }
};
