import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';
import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Scandipwa/Route/SomethingWentWrong/Container */
export class SomethingWentWrongContainer extends SourceSomethingWentWrong {
    componentDidMount() {
        const reloadAttemptedKey = 'OOPS_RELOAD_ATTEMPTED';
        const reloadAttempted = BrowserDatabase.getItem(reloadAttemptedKey);

        if (reloadAttempted) {
            BrowserDatabase.deleteItem(reloadAttemptedKey);
            super.componentDidMount();
            return;
        }

        BrowserDatabase.setItem(true, reloadAttemptedKey);
        window.location.reload(true);

        super.componentDidMount();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
