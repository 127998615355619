/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_VIEW_ITEM_GA4 } from '../../../util/Event';
import ProductHelper from '../GA4_utils';
import { NOT_APPLICABLE } from '../GA4_utils/Product';
import BaseEventEvent from './BaseEvent.event';

export const SPAM_PROTECTION_TIMEOUT = 2000;

/**
 * View item push event
 */
export class ViewItemEvent extends BaseEventEvent {
    /**
     * Set delay
     *
     * @type {number}
     */
    eventHandleDelay = 2000;

    /**
     * Last product path name
     *
     * @type {null|string}
     */
    lastPath = null;

    /**
     * Bind on view item
     */
    bindEvent() {
        Event.observer(EVENT_GTM_VIEW_ITEM_GA4, ({ product, pathname }) => {
            this.handle(product, pathname);
        });
    }

    /**
     * Handle view item event
     *
     * @param product
     * @param pathname
     */
    handler(product, pathname) {
        const { sku } = product;
        if (this.spamProtection(SPAM_PROTECTION_TIMEOUT, sku)
            || pathname === this.lastPath
        ) {
            return;
        }

        this.lastPath = pathname;

        const category = this.getAppState()?.BreadcrumbsReducer?.breadcrumbs?.[1]?.name;
        const productToPass = ProductHelper.getProductData(product, category);

        this.pushEventData({
            currency: this.getCurrencyCode(),
            value: productToPass.price,
            items: [
                {
                    ...productToPass,
                    quantity: product.salable_qty || NOT_APPLICABLE,
                    item_variant: NOT_APPLICABLE,
                    ...this.getItemListData(product, false, true)
                }
            ]
        });
    }
}

export default ViewItemEvent;
