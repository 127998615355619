/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export class CheckoutOrderSummaryPlugin {
    renderTotals(args, callback, instance) {
        const {
            totals: {
                prices: {
                    grand_total: { value: grand_total },
                    subtotal_including_tax: { value: subtotal },
                    discount,
                    shipping_incl_tax = 0,
                    customs_duty_amount = 0
                },
                cash_on_delivery_fee = 0
            }
        } = instance.props;

        const discount_amount = discount?.amount?.value ?? 0;
        const netSubtotal = customs_duty_amount > 0 ? subtotal - customs_duty_amount : subtotal;

        return (
            <div block="CheckoutOrderSummary" elem="OrderTotals">
                <ul>
                    { instance.renderPriceLine(netSubtotal, __('Cart Subtotal')) }
                    { shipping_incl_tax > 0
                       && instance.renderPriceLine(shipping_incl_tax, __('Shipping Total')) }
                    { cash_on_delivery_fee > 0
                       && instance.renderPriceLine(cash_on_delivery_fee, __('Cash on Delivery Fee')) }
                    { customs_duty_amount > 0 && instance.renderPriceLine(customs_duty_amount, __('Estimated duties')) }
                    { discount_amount !== 0 && instance.renderPriceLine(discount_amount, __('Discount')) }
                    { instance.renderPriceLine(grand_total + cash_on_delivery_fee, __('Grand Total')) }
                </ul>
            </div>
        );
    }
}

export const { renderTotals } = new CheckoutOrderSummaryPlugin();

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderTotals
        }
    }
};
