/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const NAV_STATE = 'NAV_STATE';

/** @namespace Scandipwa/Store/NavigationTabs/Action/setNavState */
export const setNavState = (navState) => ({
    type: NAV_STATE,
    navState
});
