/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Abdelrahman Hady <abdelrahman.hady@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-ar
 */

import { EVENT_TIMEOUT_ON_LOAD } from '../../component/GoogleTagManager/events/CheckoutOption.event';
import { ORDER_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, { EVENT_GTM_CHECKOUT_OPTION, EVENT_GTM_PAGE_DATA } from '../../util/Event';

/** CheckoutPayments */
export const aroundComponentDidMount = (args, callback, instance) => {
    const { selectedPaymentCode } = instance.state;
    const { paymentMethods } = instance.props;

    const selectedPaymentMethod = {};

    paymentMethods.forEach((method) => {
        const { code, title } = method;

        if (code === selectedPaymentCode) {
            selectedPaymentMethod.title = title;
        }
    });

    setTimeout(
        () => {
            Event.dispatch(
                EVENT_GTM_CHECKOUT_OPTION,
                { step: 2, option: selectedPaymentCode }
            );
            Event.dispatch(
                EVENT_GTM_PAGE_DATA,
                { type: ORDER_TYPE, paymentType: selectedPaymentMethod.title }
            );
        },
        EVENT_TIMEOUT_ON_LOAD
    );

    return callback(...args);
};

// eslint-disable-next-line no-unused-vars
export const aroundSelectPaymentMethod = (args, callback, instance) => {
    const [{ code }] = args;
    Event.dispatch(
        EVENT_GTM_CHECKOUT_OPTION,
        { step: 2, option: code }
    );

    const paymentMethod = {};
    const { paymentMethods } = instance.props;

    paymentMethods.forEach((method) => {
        const { code: paymentCode, title } = method;

        if (paymentCode === code) {
            paymentMethod.title = title;
        }
    });

    Event.dispatch(
        EVENT_GTM_PAGE_DATA,
        { type: ORDER_TYPE, paymentType: paymentMethod.title }
    );

    return callback(...args);
};

/** CheckoutDeliveryOptionsContainer */
export const aroundComponentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;

    const shippingMethodData = {};
    const { selectedShippingMethod: { carrier_code: selectedShippingMethodCode }, shippingMethods } = instance.props;

    if (shippingMethods.length) {
        const {
            selectedShippingMethod: { carrier_code: prevSelectedShippingMethodCode }
        } = prevProps;

        if (selectedShippingMethodCode && selectedShippingMethodCode !== prevSelectedShippingMethodCode) {
            shippingMethods.forEach((e) => {
                const { method_code, carrier_title, price_incl_tax } = e;

                if (selectedShippingMethodCode === method_code) {
                    shippingMethodData.title = carrier_title;
                    shippingMethodData.price = price_incl_tax;

                    // eslint-disable-next-line no-useless-return
                    return;
                }
            });

            const { title, price } = shippingMethodData;

            Event.dispatch(
                EVENT_GTM_CHECKOUT_OPTION,
                { step: 1, option: selectedShippingMethodCode }
            );

            Event.dispatch(
                EVENT_GTM_PAGE_DATA,
                { type: ORDER_TYPE, shippingType: title, shipping: price }
            );
        }
    }

    return callback(...args);
};

export default {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            'componentDidMount': aroundComponentDidMount,
            'selectPaymentMethod': aroundSelectPaymentMethod
        }
    },
    'Component/CheckoutDeliveryOptions/Container': {
        'member-function': {
            'componentDidUpdate': aroundComponentDidUpdate
        }
    }
};
