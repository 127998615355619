/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_VIEW_CART_GA4 } from '../../../util/Event';
import ProductHelper from '../GA4_utils';
import BaseEventEvent from './BaseEvent.event';

export const VIEW_CART_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On cart page
 */
export class ViewCartEvent extends BaseEventEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = VIEW_CART_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_VIEW_CART_GA4, ({ totals }) => {
            this.handle(totals);
        });
    }

    /**
     * Handle
     */
    handler(totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const { items = [], prices: { grand_total: { value: grandTotal = 0 } = {} } = {} } = totals || {};
        const cartItems = items.map(
            (item) => ({
                ...ProductHelper.getItemData(item),
                ...this.getItemListData(item.product),
                quantity: ProductHelper.getQuantity(item)
            })
        );

        this.pushEventData({
            currency: this.getCurrencyCode(),
            value: grandTotal,
            items: cartItems
        });
    }
}

export default ViewCartEvent;
