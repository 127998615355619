import Event, {
    EVENT_GTM_CATEGORY_SORT_CHANGE_GA4
} from '../../util/Event';

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/categorySortChange */
export const CategorySortChange = (args, callback) => {
    const [value, isMobileSort = false] = args;
    const selectedValue = isMobileSort ? value.target.value : value;

    Event.dispatch(EVENT_GTM_CATEGORY_SORT_CHANGE_GA4, selectedValue);

    callback(...args);
};

export default {
    'Component/CategorySort/Component': {
        'member-function': {
            onChange: CategorySortChange
        }
    }
};
