/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_ForgotPassword
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MyAccountQuery from 'Query/MyAccount.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { fetchMutation } from 'Util/Request';

import ForgotPasswordComponent from './ForgotPassword.component';

/** @namespace Scandipwa/Component/ForgotPassword/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Scandipwa/Component/ForgotPassword/Container */
export class ForgotPasswordContainer extends PureComponent {
    static propTypes = {
        forgotPassword: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired
    };

    state = {
        email: '',
        confirmationEmail: '',
        areEmailsEqual: false,
        isLoading: false
    };

    containerFunctions = {
        onEmailChange: this.onEmailChange.bind(this),
        onConfirmationEmailChange: this.onConfirmationEmailChange.bind(this),
        onSubmit: this.onSubmit.bind(this),
        onPopupClose: this.onPopupClose.bind(this)
    };

    containerProps() {
        const { areEmailsEqual, isLoading } = this.state;

        return {
            isEqualEmails: areEmailsEqual,
            isLoading
        };
    }

    onEmailChange(field) {
        const { confirmationEmail } = this.state;
        const { target: { value } } = field;

        this.setState({
            email: value,
            areEmailsEqual: value === confirmationEmail
        });
    }

    onConfirmationEmailChange(field) {
        const { email } = this.state;
        const { target: { value } } = field;

        this.setState({
            confirmationEmail: value,
            areEmailsEqual: value === email
        });
    }

    getMessage(email) {
        return __(
            'If there is an account associated with %s you will receive an email with a link to reset your password.',
            email
        );
    }

    reset() {
        this.setState({
            areEmailsEqual: true,
            isLoading: false
        });
    }

    onPopupClose() {
        this.reset();
    }

    onSubmit() {
        const { email } = this.state;
        const mutation = MyAccountQuery.getForgotPasswordMutation({ email });
        const { hideActiveOverlay, showSuccessNotification, showErrorNotification } = this.props;

        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Component/ForgotPassword/Container/ForgotPasswordContainer/onSubmit/fetchMutation/then */
            () => {
                showSuccessNotification(this.getMessage(email));
                hideActiveOverlay();
                this.reset();
            },
            /** @namespace Scandipwa/Component/ForgotPassword/Container/ForgotPasswordContainer/onSubmit/fetchMutation/then/showErrorNotification/catch */
            (error) => showErrorNotification(error[0].message)
        );
    }

    render() {
        return (
            <ForgotPasswordComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(null, mapDispatchToProps)(ForgotPasswordContainer);
