import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import DatePickerComponent from 'Component/DatePicker/DatePicker.component';
import {
    DAYS_30,
    DEFAULT_DAYS, FEB_LEAP, FEB_NORM,
    MIN_YEAR, MONTHS,
    MONTHS_WITH_30_DAYS,
    MONTHS_WITH_31_DAYS
} from 'Component/DatePicker/DatePicker.config';

/** @namespace Scandipwa/Component/DatePicker/Container */
export class DatePickerContainer extends PureComponent {
    static propTypes = {
        getDate: PropTypes.func.isRequired
    };

    containerFunctions = {
        changeIfLeapYear: this.changeIfLeapYear.bind(this),
        changeDays: this.changeDays.bind(this),
        getDay: this.getDay.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        const year = (new Date()).getFullYear();
        const yearCount = year - MIN_YEAR;

        this.state = {
            years: Array.from(new Array(yearCount + 1), (val, index) => MIN_YEAR + index),
            days: Array.from(new Array(DEFAULT_DAYS), (val, index) => index + 1),
            months: MONTHS,
            selectedYear: MIN_YEAR,
            selectedMonth: 1,
            selectedDay: 1,
            yearWasChanged: false,
            monthWasChanged: false,
            dayWasChanged: false
        };
    }

    containerProps = () => {
        const { getDate } = this.props;

        const {
            dayWasChanged,
            days,
            monthWasChanged,
            months,
            selectedDay,
            selectedMonth,
            selectedYear,
            yearWasChanged,
            years
        } = this.state;

        return {
            getDate,
            dayWasChanged,
            days,
            monthWasChanged,
            months,
            selectedDay,
            selectedMonth,
            selectedYear,
            yearWasChanged,
            years
        };
    };

    checkIfLeapYear(year) {
        // eslint-disable-next-line no-magic-numbers
        const isLeapCheckStages = [400, 100, 4];

        if (year % isLeapCheckStages[0] === 0) {
            return true;
        }

        if (year % isLeapCheckStages[1] === 0) {
            return false;
        }

        return year % isLeapCheckStages[2] === 0;
    }

    async changeIfLeapYear(year) {
        await this.setState({
            selectedYear: parseInt(year, 10),
            yearWasChanged: true
        });

        await this.changeDays(null);
    }

    setDays(days) {
        this.setState({
            days: Array.from(new Array(days), (val, index) => index + 1)
        });
    }

    changeDaysState(month) {
        const { selectedYear } = this.state;
        const isLeapYear = this.checkIfLeapYear(selectedYear);

        if (MONTHS_WITH_30_DAYS.includes(month)) {
            this.setDays(DAYS_30);
        } else if (MONTHS_WITH_31_DAYS.includes(month)) {
            this.setDays(DEFAULT_DAYS);
        } else if (isLeapYear) {
            this.setDays(FEB_LEAP);
        } else {
            this.setDays(FEB_NORM);
        }
    }

    getDay(day) {
        this.setState({
            selectedDay: parseInt(day, 10),
            dayWasChanged: true
        });
    }

    async changeDays(month) {
        const { selectedMonth } = this.state;
        if (month) {
            await this.setState({
                selectedMonth: parseInt(month, 10),
                monthWasChanged: true
            });
            this.changeDaysState(month);
        } else {
            this.changeDaysState(selectedMonth);
        }
    }

    render() {
        return (
            <DatePickerComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default (DatePickerContainer);
