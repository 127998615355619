/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Attributes/Query */
export class AttributesQuery {
    getProductAttributesQuery() {
        return new Field('customAttributeMetadata')
            .addArgument(
                'attributes',
                '[AttributeInput!]!',
                [
                    {
                        attribute_code: 'gender',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'season',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'color',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'color_description',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'product_division',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'line_name',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'age_group',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'dept_code',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'sport_code',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'subcat_id',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'collection',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'technology',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'style_number',
                        entity_type: 'catalog_product'
                    },
                    {
                        attribute_code: 'refinement_color',
                        entity_type: 'catalog_product'
                    }
                ]
            )
            .addField(new Field('items')
                .addFieldList(
                    [
                        'attribute_code',
                        'attribute_type',
                        new Field('attribute_options')
                            .addFieldList(
                                [
                                    'value',
                                    'label'
                                ]
                            )
                    ]
                ));
    }
}

export default new AttributesQuery();
