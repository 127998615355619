/* eslint-disable import/prefer-default-export */
import currencyMap from 'Util/Price/Price.config';
import getStore from 'Util/Store';

export const CURRENCY_AED = 'AED';
export const CURRENCY_SAR = 'SAR';
export const STORE_CODE_UAE_EN = 'en';
export const STORE_CODE_KSA_EN = 'ksa_en';

export * from 'SourceUtil/Price/Price';

/** @namespace Scandipwa/Util/Price/formatCurrency */
export const formatCurrency = (currency = 'USD') => {
    const { ConfigReducer: { code } = {} } = getStore().getState() || {};

    if ([STORE_CODE_UAE_EN, STORE_CODE_KSA_EN].includes(code)) {
        if (currency === CURRENCY_AED) {
            return CURRENCY_AED;
        } if (currency === CURRENCY_SAR) {
            return CURRENCY_SAR;
        }
    }

    return currencyMap[currency];
};
