/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export class CartPagePlugin {
    renderTotalDetails(args, callback, instance) {
        const {
            totals: {
                prices: {
                    subtotal_including_tax: { value: subtotal_incl_tax = 0 },
                    shipping_incl_tax = 0,
                    customs_duty_amount = 0
                }
            }
        } = instance.props;

        const netSubtotal = customs_duty_amount > 0 ? subtotal_incl_tax - customs_duty_amount : subtotal_incl_tax;

        return (
            <dl
              block="CartPage"
              elem="TotalDetails"
              aria-label={ __('Order total details') }
            >
                <dt>{ __('Subtotal:') }</dt>
                <dd>{ instance.renderPriceLine(netSubtotal) }</dd>
                { customs_duty_amount > 0 && (
                    <>
                        <dt>{ __('Estimated duties:') }</dt>
                        <dd>{ instance.renderPriceLine(customs_duty_amount) }</dd>
                    </>
                ) }
                { shipping_incl_tax > 0 && (
                    <>
                        <dt>{ __('Shipping Total:') }</dt>
                        <dd>{ instance.renderPriceLine(shipping_incl_tax) }</dd>
                    </>
                ) }
                { instance.renderDiscount() }
            </dl>
        );
    }
}

export const { renderTotalDetails } = new CartPagePlugin();

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderTotalDetails
        }
    }
};
