/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_CATEGORY_SORT_CHANGE_GA4 } from '../../../util/Event';
import BaseEventEvent from './BaseEvent.event';

/**
 * Category sort change event
 * @namespace Gtm/Component/GoogleTagManager/GA4_events/CategorySortChange/Event */
export class CategorySortChangeEvent extends BaseEventEvent {
    /**
     * Set delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Bind category sort events
     */
    bindEvent() {
        Event.observer(EVENT_GTM_CATEGORY_SORT_CHANGE_GA4, (sortValue) => {
            this.handle(sortValue);
        });
    }

    /**
     * Handle category sort change
     */
    handler(sortValue) {
        this.pushEventData({
            domevent: sortValue.toLowerCase(),
            domlabel: ''
        });
    }
}

export default CategorySortChangeEvent;
