/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AdyenPayments/Query/AdyenPaymentDetails/Mutation */
export class AdyenPaymentDetailsMutation {
    handleAdyenPaymentDetails(payload, cartId) {
        return new Field('adyenPaymentDetails')
            .addArgument('payload', 'String!', payload)
            .addArgument('cart_id', 'String!', cartId)
            .addFieldList(this.getAdyenPaymentStatusFields());
    }

    getAdyenPaymentStatusFields() {
        return [
            'action',
            'isFinal',
            'resultCode',
            'additionalData'
        ];
    }
}

export default new AdyenPaymentDetailsMutation();
