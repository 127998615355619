/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { GUEST_EMAIL } from 'Component/CheckoutSuccess/CheckoutSuccess.config';
import { CUSTOMER_EMAIL_COOKIE } from 'Component/Router/Router.config';
import CheckoutQuery from 'Query/Checkout.query';
import { ORDER_ID_KEY, SHIPPING_ADDRESS_KEY } from 'Route/Checkout/Checkout.config';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { updateCheckoutState } from 'Store/Checkout/Checkout.action';
import { PAYMENT_TOTALS } from 'Store/Checkout/Checkout.dispatcher';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { deleteCartId } from 'Util/Cart';
import { invalidateTempGuestData } from 'Util/CheckoutAddressData/CheckoutAddressData';
import { fetchQuery } from 'Util/Request/Query';

import CheckoutSuccessComponent from './CheckoutSuccess.component';

export const IS_CART_RESET = 'IS_CART_RESET';
export const SHOULD_RESET_CART = 'SHOULD_RESET_CART';

/** @namespace Scandipwa/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isProcessed: state.CheckoutReducer.isProcessed,
    locale: state.ConfigReducer.code
});

/** @namespace Scandipwa/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCheckoutState: (state) => dispatch(updateCheckoutState(state))
});

/** @namespace Scandipwa/Component/CheckoutSuccess/Container */
export class CheckoutSuccessContainer extends PureComponent {
    static propTypes = {
        ...super.propTypes,
        isSignedIn: PropTypes.bool.isRequired
    };

    state = {
        isLoading: true,
        storeName: ''
    };

    componentDidMount() {
        const { isSignedIn } = this.props;

        invalidateTempGuestData();

        if (!isSignedIn) {
            if (window.pageData) {
                if (window.pageData.customer) {
                    window.pageData.customer.email = BrowserDatabase.getItem(GUEST_EMAIL);
                } else {
                    window.pageData.customer = {
                        email: BrowserDatabase.getItem(GUEST_EMAIL)
                    };
                }
            }

            BrowserDatabase.setItem(BrowserDatabase.getItem(GUEST_EMAIL), CUSTOMER_EMAIL_COOKIE);
        }

        fetchQuery(CheckoutQuery.getStoreName()).then(
            /** @namespace Scandipwa/Component/CheckoutSuccess/Container/CheckoutSuccessContainer/componentDidMount/fetchQuery/then */
            ({ getEmailGeneralName }) => {
                this.setState({
                    storeName: getEmailGeneralName,
                    isLoading: false
                });
            },
            this._handleError
        );
    }

    componentWillUnmount() {
        this.resetCart();
    }

    resetCart() {
        const { resetCart, resetGuestCart } = this.props;

        deleteCartId();
        BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        BrowserDatabase.deleteItem(CART_TOTALS);
        BrowserDatabase.deleteItem(ORDER_ID_KEY);
        BrowserDatabase.deleteItem(SHIPPING_ADDRESS_KEY);
        BrowserDatabase.deleteItem(SHOULD_RESET_CART);

        // Will get new cart from BE
        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }
    }

    render() {
        return (
            <CheckoutSuccessComponent
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessContainer);
