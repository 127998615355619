import FIELD_TYPE from 'Component/Field/Field.config';
import { FormContainer as SourceFormContainer } from 'SourceComponent/Form/Form.container';
import getFieldsData from 'Util/Form/Extract';
import { validateGroup } from 'Util/Validator';

/** @namespace Scandipwa/Component/Form/Container */
export class FormContainer extends SourceFormContainer {
    async onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onSubmit,
            onError,
            returnAsObject = false,
            validationRule
        } = this.props;

        const fields = getFieldsData(
            this.formRef, false, [FIELD_TYPE.numberWithControls, FIELD_TYPE.button], returnAsObject
        );

        // Has custom validation function, not just a regex.
        const telephoneField = fields.filter(({ name }) => name === 'telephone');
        // eslint-disable-next-line fp/no-let
        let isPhoneValid = true;

        if (telephoneField.length > 0) {
            isPhoneValid = telephoneField[0].field.getAttribute('is-data-valid') === 'true'
                            || (
                                telephoneField[0].field.getAttribute('is-data-valid') === null
                                && telephoneField[0].field.getAttribute('value') !== ''
                            );
        }

        const isValid = validateGroup(this.formRef, validationRule);

        if (isValid !== true || isPhoneValid !== true) {
            if (typeof onError === 'function') {
                onError(this.formRef, fields, isValid);
            }

            return;
        }

        if (typeof onSubmit === 'function') {
            onSubmit(this.formRef, fields);
        }
    }
}

export default FormContainer;
