/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceOverlay from 'SourceComponent/Overlay/Overlay.component';
import CSS from 'Util/CSS';
import isMobile from 'Util/Mobile';

/**
 * Product measurements
 * @class ProductMeasurements
 * @namespace Scandipwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    componentDidMount() {
        this.handleScroll();
        window.addEventListener('scroll', this.handleScroll);

        const isVisible = this.getIsVisible();

        if (isVisible) {
            this.onVisible();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    freezeScroll() {
        const { id } = this.props;

        if (id === 'navigation_search') {
            return;
        }

        super.freezeScroll();
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        this.handleScroll();
    }

    unfreezeScroll() {
        const { id } = this.props;

        if (id === 'navigation_search') {
            return;
        }

        super.unfreezeScroll();
    }

    handleScroll = () => {
        const { id } = this.props;
        const topBanner = document.getElementsByClassName('global-promo-banner')[0];
        const topBannerHeight = topBanner ? topBanner.offsetHeight : 0;
        const navBarHeight = 60;

        const pageYOffset = isMobile.iOS() && document.body.style.top
            ? -1 * parseInt(document.body.style.top, 10) : window.pageYOffset;

        const offset = pageYOffset || window.pageYOffset;

        const pageScroll = (window.pageYOffset || document.documentElement.scrollTop)
            - (document.documentElement.clientTop || 0);
        const top = pageScroll > topBannerHeight
            ? navBarHeight
            : (topBannerHeight + navBarHeight - offset);

        if (id === 'navigation_search') {
            CSS.setVariable(
                this.overlayRef,
                'overlay-position-top',
                `${ top }px`
            );
        }
    };

    /**
     * On overlay visible
     * Freezes scroll if isRenderInPortal is true and some overlay, except placeholder is opened
     */
    onVisible() {
        const {
            onVisible,
            isStatic,
            device,
            isRenderInPortal,
            payload,
            id
        } = this.props;

        this.handleScroll();

        if (isStatic) {
            return;
        }

        if (device.isMobile && isRenderInPortal) {
            this.freezeScroll();
        }

        if (id !== 'search') {
            this.overlayRef.current.focus();
        }

        onVisible(payload);
    }

    /**
     * On overlay hide
     * Unfreezes scroll if isRenderInPortal is true
     */
    onHide() {
        const {
            onHide,
            isStatic,
            device,
            isRenderInPortal
        } = this.props;

        this.handleScroll();

        if (isStatic) {
            return;
        }

        if (device.isMobile && isRenderInPortal) {
            this.unfreezeScroll();
        }

        onHide();
    }

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            isStatic,
            style
        } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              block="Overlay"
              ref={ this.overlayRef }
              mods={ { isVisible, isStatic, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
              style={ style }
            >
                { children && children }
            </div>
        );
    }
}

export default OverlayComponent;
