/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';
import SwatchSlider from 'Component/SwatchSlider';
import { TABLET_WIDTH } from 'Component/SwatchSlider/SwatchSlider.config';
import { ProductType } from 'Type/ProductList.type';
import { HistoryType } from 'Type/Router.type';

import './ProductCarousel.style';

/**
 * Product carousel
 * @class ProductCarousel
 * @namespace Scandipwa/Component/ProductCarousel/Component */
export class ProductCarouselComponent extends PureComponent {
    static propTypes = {
        productButtonStyle: PropTypes.string.isRequired,
        productSkuArray: PropTypes.string.isRequired,
        onActiveImageChange: PropTypes.func.isRequired,
        activeImage: PropTypes.number.isRequired,
        carouselProducts: PropTypes.arrayOf(ProductType).isRequired,
        title: PropTypes.string.isRequired,
        contentTitle: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
        carouselId: PropTypes.string.isRequired,
        history: HistoryType.isRequired,
        isRecommender: PropTypes.bool,
        dataComponentSubPosition: PropTypes.string.isRequired
    };

    static defaultProps = {
        isRecommender: false
    };

    /**
     * Default state
     *
     * @type {{hoveredProduct: number, isDragging: boolean}}
     */
    state = {
        hoveredProduct: 0,
        isDragging: false
    };

    renderProductCard = this.renderProductCard.bind(this);

    isSliderDragged = this.isSliderDragged.bind(this);

    /**
     * Returns slider drag status
     *
     * @param isDragging
     */
    isSliderDragged(isDragging) {
        this.setState({ isDragging });
    }

    /**
     * Renders all products(slides) for carousel
     *
     * @returns {*[]|*}
     */
    renderItems() {
        const { carouselProducts } = this.props;

        if (!carouselProducts) {
            return Array.from(
                { length: 4 },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        return carouselProducts.map(this.renderProductCard);
    }

    /**
     * Renders product(slide) for carousel
     *
     * @param product
     * @param i
     * @returns {JSX.Element}
     */
    renderProductCard(product, i) {
        const {
            button, productButtonStyle, history, isRecommender
        } = this.props;
        const { hoveredProduct, isDragging } = this.state;
        const { id = i, sku } = product;

        const isHovered = hoveredProduct === id;

        return (
            <div
              key={ i }
              block="ProductCarousel"
              elem="ProductCard"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseEnter={ () => this.setState({ hoveredProduct: id }) }
              // eslint-disable-next-line react/jsx-no-bind
              onMouseLeave={ () => this.setState({ hoveredProduct: 0 }) }
              data-scarabitem={ isRecommender ? sku : null }
            >
                <ProductCard
                  block="ProductCarousel"
                  elem="Card"
                  product={ product }
                  key={ id }
                  showProductButton={ window.innerWidth >= TABLET_WIDTH && isHovered }
                  buttonText={ button }
                  productButtonStyles={ JSON.parse(productButtonStyle) || {} }
                  isSliderDragged={ isDragging }
                  history={ history }
                />
            </div>
        );
    }

    /**
     * Renders product carousel title
     *
     * @returns {JSX.Element|null}
     */
    renderCarouselTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
          <h2
            block="ProductCarousel"
            elem="Title"
          >
              { title }
          </h2>
        );
    }

    /**
     * Creates portal to feature hero banner if it is part of it
     *
     * @param content
     * @returns {{children: *, implementation: *, containerInfo: *, $$typeof: symbol | number, key: null|string}|*}
     */
    renderCarouselPortal(content) {
        const { carouselId } = this.props;

        const carouselDomElement = document.getElementById(carouselId);
        // If carousel is part of feature hero banner it somehow can`t calculate the slide width, that`s why teleporting it
        if (carouselDomElement) {
            return createPortal(content, carouselDomElement);
        }

        return content;
    }

    render() {
        const {
            onActiveImageChange,
            activeImage,
            productSkuArray,
            carouselProducts,
            isRecommender,
            dataComponentSubPosition,
            carouselId,
            contentTitle
        } = this.props;
        const isLoading = !carouselProducts.length;

        if (!productSkuArray || (isRecommender && isLoading)) {
            return null;
        }

        return this.renderCarouselPortal(
            <ContentWrapper
              label="Product carousel"
              wrapperMix={ { block: 'ProductCarousel', elem: 'Wrapper' } }
              dataComponentSubPosition={ dataComponentSubPosition }
              carouselId={ carouselId }
              mix={ { block: 'ProductCarousel promo-content-custom-class', mods: { isLoading } } }
              contentTitle={ contentTitle }
            >
                { this.renderCarouselTitle() }
                <SwatchSlider
                  mix={ { block: 'ProductCarousel', elem: 'SliderWrapper' } }
                  slidesOnDesktop={ 4 }
                  slidesOnTablet={ 3 }
                  slidesOnMobile={ 2 }
                  scrollToStart={ false }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isSliderDragged={ this.isSliderDragged }
                >
                    { this.renderItems() }
                </SwatchSlider>
            </ContentWrapper>
        );
    }
}

export default ProductCarouselComponent;
