import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import media from 'Util/Media';

import { DEFAULT_OG_IMAGE, DEFAULT_OG_IMAGE_PATH } from './Meta.config';

/** @namespace Scandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    code: state.ConfigReducer.code,
    country: state.ConfigReducer.default_country,
    og_image: state.MetaReducer.og_image
});

/** @namespace Scandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...this.propTypes,
        code: PropTypes.string,
        country: PropTypes.string,
        og_image: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        code: '',
        country: '',
        og_image: ''
    };

    _generateOgMetaFromMetadata(metadata, param = 'property') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }

    getOgImage() {
        const { og_image } = this.props;
        const default_img = media(DEFAULT_OG_IMAGE, DEFAULT_OG_IMAGE_PATH);

        if (!og_image) {
            return default_img;
        }

        return og_image;
    }

    _getMetadata() {
        const { code, country } = this.props;
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords()
        };

        const locale = `${code}_${country}` === 'ksa_en_SA' ? 'en_SA' : `${code}_${country}`;

        const ogmeta = {
            'og:title': this._getTitle(),
            'og:description': this._getDescription(),
            'og:url': window.location.href,
            'og:locale': locale,
            'og:image': this.getOgImage()
        };

        return [...this._generateMetaFromMetadata(meta), ...this._generateOgMetaFromMetadata(ogmeta)];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
