/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AdyenPayments/Query/SetPaymentMethodAndPlaceOrder/Mutation */
export class SetPaymentMethodAndPlaceOrderMutation {
    setPaymentMethodAndPlaceOrder(input) {
        return new Field('setPaymentMethodAndPlaceOrder')
            .addArgument('input', 'SetPaymentMethodAndPlaceOrderInput!', input)
            .addFieldList(this.setPaymentMethodAndPlaceOrderFields());
    }

    setPaymentMethodAndPlaceOrderFields() {
        return [
            this.getOrderField()
        ];
    }

    getOrderField() {
        return new Field('order')
            .addFieldList(this.getOrderFields());
    }

    getOrderFields() {
        return [
            'order_number'
        ];
    }
}

export default new SetPaymentMethodAndPlaceOrderMutation();
