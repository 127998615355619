/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { CheckoutQuery as SourceQuery } from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceQuery {
    _getOrderField() {
        return new Field('order')
            .addFieldList([
                'id',
                'order_id',
                'redirectUrl',
                'threeDS2',
                'type',
                'token',
                'status_label'
            ]);
    }

    getStoreName() {
        return new Field('getEmailGeneralName');
    }

    getNewsletterSubscribeMutation(email, first_name, last_name) {
        const mutation = new Field('addEmailSubscriptionFromCheckout')
            .addArgument('email', 'String!', email)
            .addArgument('first_name', 'String!', first_name)
            .addArgument('last_name', 'String!', last_name);

        return mutation;
    }

    _getPaymentMethodFields() {
        return ['code', 'title', 'img_src'];
    }
}

export default new CheckoutQuery();
