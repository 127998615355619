/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { CartQuery as SourceQuery } from 'SourceQuery/Cart.query';
import Field from 'Util/Query/Field';

/** @namespace Scandipwa/Query/Cart/Query */
export class CartQuery extends SourceQuery {
    getApplyCouponMutation(couponCode, quoteId) {
        const input = {
            cart_id: quoteId,
            coupon_code: couponCode
        };

        return new Field('applyCouponToCart')
            .addArgument('input', 'ApplyCouponToCartInput', input)
            .addField(this._getCouponDataFields(couponCode))
            .addField(this._getCart());
    }

    getCouponDataQuery(couponCode) {
        return this._getCouponDataFields(couponCode);
    }

    _getCouponDataFields(couponCode) {
        return new Field('getCouponData')
            .addArgument('couponCode', 'String!', couponCode)
            .addFieldList(['id', 'code', 'name', 'type', 'amount']);
    }

    _getPricesFields() {
        return [
            ...super._getPricesFields(),
            'shipping_incl_tax'
        ];
    }

    _getCartItemFields() {
        return [
            'qty',
            'sku',
            'price',
            'price_incl_tax',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            'base_row_total_incl_tax',
            this._getCustomizableOptionsFields(),
            this._getBundleOptionsField(),
            this._getProductField()
        ];
    }
}

export default new CartQuery();
