/** * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';
export const UPDATE_CHECKOUT_STATUS = 'UPDATE_CHECKOUT_STATUS';
export const UPDATE_CHECKOUT_STATE = 'UPDATE_CHECKOUT_STATE';
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
export const UPDATE_PAYMENT_TOTALS = 'UPDATE_PAYMENT_TOTALS';
export const UPDATE_SHIPPING_METHODS = 'UPDATE_SHIPPING_METHODS';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const UPDATE_IS_SAME_AS_SHIPPING = 'UPDATE_IS_SAME_AS_SHIPPING';

/** @namespace Scandipwa/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields) => ({
    type: UPDATE_SHIPPING_FIELDS,
    shippingFields
});

/** @namespace Scandipwa/Store/Checkout/Action/updateEmail */
export const updateEmail = (email) => ({
    type: UPDATE_EMAIL,
    email
});

/** @namespace Scandipwa/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable) => ({
    type: UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable
});

/**
 * Update checkout loading status
 * @param {Object} status
 * @namespace Scandipwa/Store/Checkout/Action/updateCheckoutStatus */
export const updateCheckoutStatus = (status) => ({
    type: UPDATE_CHECKOUT_STATUS,
    status
});

/**
 * Update checkout state
 * @param {Object} state
 * @namespace Scandipwa/Store/Checkout/Action/updateCheckoutState */
export const updateCheckoutState = (state) => ({
    type: UPDATE_CHECKOUT_STATE,
    state
});

/**
 * Update checkout payment totals
 * @param {Object} totals
 * @namespace Scandipwa/Store/Checkout/Action/updatePaymentTotals */
export const updatePaymentTotals = (totals) => ({
    type: UPDATE_PAYMENT_TOTALS,
    totals
});

/**
 * Update checkout shipping methods
 * @param {Object} shippingMethods
 * @namespace Scandipwa/Store/Checkout/Action/updateShippingMethods */
export const updateShippingMethods = (shippingMethods) => ({
    type: UPDATE_SHIPPING_METHODS,
    shippingMethods
});

/**
 * Update checkout shipping address
 * @param {Object} shippingAddress
 * @namespace Scandipwa/Store/Checkout/Action/updateShippingAddress */
export const updateShippingAddress = (shippingAddress) => ({
    type: UPDATE_SHIPPING_ADDRESS,
    shippingAddress
});

/**
 * Update isSameAsShipping state
 * @param {Boolean} isSameAsShipping
 * @namespace Scandipwa/Store/Checkout/Action/updateIsSameAsShipping */
export const updateIsSameAsShipping = (isSameAsShipping) => ({
    type: UPDATE_IS_SAME_AS_SHIPPING,
    isSameAsShipping
});
