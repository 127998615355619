// eslint-disable-next-line import/prefer-default-export
export const MIN_YEAR = 1945;
export const MONTHS = [
    __('January'),
    __('February'),
    __('March'),
    __('April'),
    __('May'),
    __('June'),
    __('July'),
    __('August'),
    __('September'),
    __('October'),
    __('November'),
    __('December')
];
export const DEFAULT_DAYS = 31;
export const DAYS_30 = 30;
export const FEB_LEAP = 29;
export const FEB_NORM = 28;

// eslint-disable-next-line no-magic-numbers
export const MONTHS_WITH_31_DAYS = [1, 3, 5, 7, 8, 10, 12];
// eslint-disable-next-line no-magic-numbers
export const MONTHS_WITH_30_DAYS = [4, 6, 9, 11];
