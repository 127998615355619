/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AdyenAlternativePayments from '../component/AdyenAlternativePayments';
import AdyenCheckout from '../component/AdyenCheckout';
import AdyenPayment from '../component/AdyenPayment';
import {
    ADYEN_APPLE_PAY_METHOD_CODE,
    ADYEN_CC_METHOD_CODE,
    ADYEN_GC_METHOD_CODE,
    ADYEN_GOOGLE_PAY_METHOD_CODE,
    ADYEN_HPP_METHOD_CODE
} from '../util/Adyen';

const selectPaymentMethod = (args, callback, instance) => {
    const [{ code }, type] = args;
    const {
        onPaymentMethodSelect,
        setOrderButtonEnableStatus
    } = instance.props;

    if (
        code !== ADYEN_HPP_METHOD_CODE
        && code !== ADYEN_APPLE_PAY_METHOD_CODE
        && code !== ADYEN_GOOGLE_PAY_METHOD_CODE
    ) {
        return callback(...args);
    }

    instance.setState({
        selectedPaymentCode: code
    });

    onPaymentMethodSelect(code);

    // Enable order button if payment method is Givex giftcard
    if (type === ADYEN_GC_METHOD_CODE) {
        return setOrderButtonEnableStatus(true);
    }

    return setOrderButtonEnableStatus(false);
};

const addAdyenPayments = (member, instance) => {
    const { setOrderButtonEnableStatus } = instance.props;

    return {
        ...member,
        [ADYEN_APPLE_PAY_METHOD_CODE]: () => (
            !!window.ApplePaySession && (
                <AdyenCheckout
                  code={ ADYEN_APPLE_PAY_METHOD_CODE }
                  setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
                />
            )
        ),
        [ADYEN_GOOGLE_PAY_METHOD_CODE]: () => (
            <AdyenCheckout
              code={ ADYEN_GOOGLE_PAY_METHOD_CODE }
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
        ),
        [ADYEN_CC_METHOD_CODE]: () => (
            <AdyenCheckout code="card" />
        )
    };
};

const addAdyenPaymentsRenderer = (args, callback, instance) => {
    const [method] = args;
    const { code } = method;
    const {
        selectPaymentMethod,
        selectedPaymentCode
    } = instance.props;
    const isSelected = selectedPaymentCode === code;

    if (code === ADYEN_CC_METHOD_CODE) {
        return (
            <AdyenPayment
              key={ code }
              isSelected={ isSelected }
              method={ method }
              onClick={ selectPaymentMethod }
            />
        );
    }

    if (code === ADYEN_GOOGLE_PAY_METHOD_CODE) {
        return (
            <AdyenAlternativePayments
              key={ code }
              isSelected={ isSelected }
              method={ method }
              onClick={ selectPaymentMethod }
            />
        );
    }

    if (code === ADYEN_APPLE_PAY_METHOD_CODE) {
        return (
            !!window.ApplePaySession
            && (
                <AdyenAlternativePayments
                  key={ code }
                  isSelected={ isSelected }
                  method={ method }
                  onClick={ selectPaymentMethod }
                />
            )
        );
    }

    // Some unneeded methods like "adyen_cc_vault" methods.
    if (code.includes('adyen_')) {
        return (null);
    }

    return callback(...args);
};

export default {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: addAdyenPayments
        },
        'member-function': {
            renderPayment: addAdyenPaymentsRenderer
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-function': {
            selectPaymentMethod
        }
    },
    'Component/CheckoutPayment/Component': {
        'member-property': {
            paymentMethodRenderMap: addAdyenPayments
        }
    }
};
