/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import { CHECKOUTCOM_CC } from './CheckoutPayments.component.plugin';

export class CheckoutPaymentsContainer {
    checkoutComCardData = {};

    /**
     * Data map
     * @param originalMember
     * @returns {*}
     */
    aroundDataMap = (originalMember) => ({
        ...originalMember,
        [CHECKOUTCOM_CC]: this.getCheckoutComCardData.bind(this)
    });

    /**
     * Container functions
     * @param originalMember
     * @returns {*}
     */
    aroundContainerFunctions = (originalMember) => ({
        ...originalMember,
        setCheckoutComCardData: this.setCheckoutComCardData.bind(this)
    });

    /**
     * Set checkout.com card data
     * @param data
     */
    setCheckoutComCardData = (data) => {
        this.checkoutComCardData = { ...this.checkoutComCardData, ...data };
    };

    /**
     * Get checkout.com card data
     * @returns {*}
     */
    getCheckoutComCardData() {
        return {
            asyncData: {
                ...this.checkoutComCardData,
                code: 'checkoutcom_card_payment'
            }
        };
    }
}

export const {
    aroundDataMap,
    aroundContainerFunctions
} = new CheckoutPaymentsContainer();

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap: [
                {
                    position: 100,
                    implementation: aroundDataMap
                }
            ],
            containerFunctions: [
                {
                    position: 100,
                    implementation: aroundContainerFunctions
                }
            ]
        }
    }
};
