/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com | info@scandiweb.com>
 */

export const CHECKOUTCOM_GOOGLE_PAY = 'checkoutcom_google_pay';
export const GOOGLE_PAY_SCRIPT_ID = 'google_pay_script_id';
export const GOOGLE_PAY_BUTTON_PLACEHOLDER_ID = 'GOOGLE_PAY_BUTTON_PLACEHOLDER_ID';
export const GOOGLE_PAY_SCRIPT_SOURCE = 'https://pay.google.com/gp/p/js/pay.js';
export const GOOGLE_PAY_ALLOWED_PAYMENT_METHODS = ['CARD', 'TOKENIZED_CARD'];
