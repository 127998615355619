/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    FieldFileContainer as SourceFieldFileContainer
} from 'SourceComponent/FieldFile/FieldFile.container';

/** @namespace Scandipwa/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    containerFunctions = {
        ...this.containerFunctions,
        resetFieldValue: this.resetFieldValue.bind(this)

    };

    onChange(value) {
        const { events: { onChange } = {}, validate } = this.props;

        if (this.fieldRef) {
            const { files } = this.fieldRef;

            this.setState({ isLoading: true });
            const file = files[0];
            const { name, type, size } = file || {};
            const isValid = validate({ type, size });

            if (!isValid) {
                this.setState({
                    fileName: '',
                    isLoading: false
                });

                return;
            }

            if (!name) {
                this.setState({
                    fileName: '',
                    isLoading: false
                });

                return;
            }

            const reader = new FileReader();

            reader.onload = () => {
                this.setState({
                    fileName: name,
                    isLoading: false,
                    value: reader.result
                });
                this.fieldRef.fileData = JSON.stringify({
                    file_data: reader.result,
                    file_name: name
                });

                if (typeof onChange === 'function') {
                    onChange({
                        fileName: name,
                        value: reader.result,
                        type
                    });
                }
            };
            reader.onerror = () => {
                // TODO: Add showNotification('error', __('Failed to upload file'))
                this.setState({ fileName: '', isLoading: false });

                if (typeof onChange === 'function') {
                    onChange(value);
                }
            };
            reader.readAsDataURL(files[0]);
        }
    }

    resetFieldValue() {
        const { resetFieldValue } = this.props;

        if (resetFieldValue) {
            resetFieldValue();
        }

        this.setState({ fileName: '', value: '' });
    }

    containerProps() {
        const {
            events,
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            setRef
        } = this.props;
        const { fileName, isLoading, value } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            setRef,
            events: {
                ...events,
                onChange: this.onChange.bind(this)
            },
            fileName,
            isLoading,
            value
        };
    }
}

export default FieldFileContainer;
