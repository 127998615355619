/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AdyenPayments/Query/AdyenPayments/Query */
export class AdyenPaymentsQuery {
    getPaymentMethods(cartId, shopper_locale) {
        return new Field('adyenPaymentMethods')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('shopper_locale', 'String', shopper_locale)
            .addFieldList(this.getAdyenPaymentMethodsFields());
    }

    getAdyenPaymentMethodsFields() {
        return [
            this.getPaymentMethodsResponseField(),
            this.getPaymentMethodsExtraDetailsField()
        ];
    }

    getPaymentMethodsResponseField() {
        return new Field('paymentMethodsResponse')
            .addFieldList(this.getPaymentMethodsResponseFields());
    }

    getPaymentMethodsResponseFields() {
        return [
            this.getPaymentMethodsField()
        ];
    }

    getPaymentMethodsField() {
        return new Field('paymentMethods')
            .addFieldList(this.getPaymentMethodsFields());
    }

    getPaymentMethodsFields() {
        return [
            'name',
            'type',
            'brand',
            'brands',
            this.getResponseConfigurationField()
        ];
    }

    getResponseConfigurationField() {
        return new Field('configuration')
            .addFieldList(this.getResponseConfigurationFields());
    }

    getResponseConfigurationFields() {
        return [
            'merchantId',
            'merchantName',
            'gatewayMerchantId'
        ];
    }

    getDetailsField() {
        return new Field('details')
            .addFieldList(this.getDetailsFields());
    }

    getDetailsFields() {
        return [
            'key',
            'type',
            'optional',
            this.getItemsField()
        ];
    }

    getItemsField() {
        return new Field('items')
            .addFieldList(this.getItemsFields());
    }

    getItemsFields() {
        return [
            'id',
            'name'
        ];
    }

    getPaymentMethodsExtraDetailsField() {
        return new Field('paymentMethodsExtraDetails')
            .addFieldList(this.getPaymentMethodsExtraDetailsFields());
    }

    getPaymentMethodsExtraDetailsFields() {
        return [
            'type',
            'isOpenInvoice',
            this.getIconField(),
            this.getConfigurationField()
        ];
    }

    getIconField() {
        return new Field('icon')
            .addFieldList(this.getIconFields());
    }

    getIconFields() {
        return [
            'url',
            'width',
            'height'
        ];
    }

    getConfigurationField() {
        return new Field('configuration')
            .addFieldList(this.getConfigurationFields());
    }

    getConfigurationFields() {
        return [
            'currency',
            this.getAmountField()
        ];
    }

    getAmountField() {
        return new Field('amount')
            .addFieldList(this.getAmountFields());
    }

    getAmountFields() {
        return [
            'value',
            'currency'
        ];
    }

    getAdyenStoreConfig() {
        return new Field('storeConfig')
            .addFieldList(this.getAdyenStoreConfigFields());
    }

    getAdyenStoreConfigFields() {
        return [
            'adyen_client_key_test',
            'adyen_client_key_live',
            'adyen_demo_mode',
            'adyen_has_holder_name',
            'adyen_holder_name_required',
            'adyen_title_renderer',
            'adyen_return_path_error'
        ];
    }

    handleAdyenPaymentDetails(payload) {
        return new Field('adyenPaymentDetails')
            .addArgument('payload', 'String', payload);
    }

    /**
     * Get apple pay express shipping method query
     * @return {Field}
     */
    getApplePayExpressShippingQuery = (guestCartId) => new Field('getApplePayExpressShipping')
        .addArgument('guestCartId', 'String', guestCartId)
        .addFieldList(['label', 'price', 'id']);
}

export default new AdyenPaymentsQuery();
