import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';
import { setNavState } from 'Store/NavigationTabs';
import { clearPopupState } from 'Store/Popup/Popup.action';

/** @namespace Scandipwa/Component/Popup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setNavState: (key) => dispatch(setNavState(key)),
    clearPopupState: () => dispatch(clearPopupState())
});

/** @namespace Scandipwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    containerFunctions = {
        onVisible: this.onVisible.bind(this),
        changeHeaderState: this.changeHeaderState.bind(this)
    };

    containerProps() {
        const {
            clearPopupState,
            block,
            payload,
            history,
            location,
            isHideCloseButton
        } = this.props;

        return {
            ...super.containerProps(),
            block,
            clearPopupState,
            payload,
            history,
            location,
            isHideCloseButton
        };
    }

    changeHeaderState(isLanguagePopup) {
        const { setNavState } = this.props;

        if (isLanguagePopup) {
            setNavState(true);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopupContainer));
