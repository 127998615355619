/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

const aroundOnFormSuccess = (args, callback, instance) => {
    callback.apply(instance, args);

    if (window.Frames) {
        const [address] = args;
        const { firstname, lastname } = address;

        if (window.Frames.isCardValid()) {
            window.Frames.cardholder = {
                name: `${ firstname } ${ lastname }`
            };

            window.Frames.submitCard();
        }
    }
};

export default {
    'Scandipwa/Component/CheckoutNewAddressForm/Component': {
        'member-function': {
            onFormSuccess: aroundOnFormSuccess
        }
    }
};
