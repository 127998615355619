/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, {
    EVENT_GTM_VIEW_ITEM_LIST_GA4
} from '../../../util/Event';
import { getCurrentVariantIndexFromFilters } from '../../../util/Product';
import ProductHelper from '../GA4_utils';
// eslint-disable-next-line import/no-cycle
import { NOT_APPLICABLE } from '../GA4_utils/Product';
import BaseEventEvent from './BaseEvent.event';

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */
export const PLP_PAGE = 'Product List';
export const SEARCH_PAGE = 'Search';

/**
 * Constants
 *
 * @type {number}
 */
export const SPAM_PROTECTION_DELAY = 2000;
export const PRODUCT_IMPRESSION_COUNT = 36;
export const PRODUCT_IMPRESSION_CHUNK_SIZE = 4;
export const EVENT_HANDLE_DELAY = 700;

/**
 * GTM PWA Impression Event
 *
 * Called on: PLP, Search
 */
export class ViewItemListEvent extends BaseEventEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        // PLP and Search
        Event.observer(EVENT_GTM_VIEW_ITEM_LIST_GA4, ({
            pageType, items, filters, category
        }) => {
            this.handle(pageType, items, filters, category);
        });
    }

    /**
     * Handle Impressions
     *
     * @param pageType
     * @param products Product list
     * @param filters Category filters
     * @param category
     */
    handler(pageType = PLP_PAGE, products = [], filters = {}, category = {}) {
        const impressions = this.getImpressions(pageType, products, filters, category);
        const storage = this.getStorage();
        const impressionUID = this.getImpressionUID(impressions);

        if (!impressions
            || Object.values(impressions).length === 0
            || this.spamProtection(SPAM_PROTECTION_DELAY, `${ pageType }_${ impressionUID }`)
        ) {
            return;
        }

        storage.impressions_ga4 = [];
        storage.impressions_ga4.push(...impressions);
        this.setStorage(storage);

        this.pushEventData({
            currency: this.getCurrencyCode(),
            items: impressions
        });
    }

    /**
     * Get impressions
     *
     * @param pageType
     * @param products
     * @param filters
     * @param category
     * @return {{quantity: number, discount: number, index: *, availability: boolean, list: string, item_list_name: string, price: number, name: string, variant: string, id: string, position, category: string, brand: string}[]}
     */
    getImpressions(pageType = PLP_PAGE, products, filters, category) {
        const { url_path = '' } = category;
        const productCollection = this.getProductCollection(pageType, products);

        return Object.values(productCollection || [])
            .slice(-PRODUCT_IMPRESSION_COUNT) // Last from list
            .filter((product) => Object.values(product).length)
            .map((product, index) => {
                const configurableVariantIndex = getCurrentVariantIndexFromFilters(product, filters);

                const productData = ProductHelper.getProductData(
                    { ...product, configurableVariantIndex, category: url_path }
                );

                return {
                    ...productData,
                    item_variant: product.ean || '',
                    quantity: product.salable_qty || NOT_APPLICABLE,
                    index,
                    item_list_id: this.getBreadcrumbs(),
                    item_list_name: pageType,
                    item_list_position: index + 1
                };
            });
    }

    /**
     * Get collection of products
     *
     * @param pageType
     * @param products
     *
     * @return {Array}
     */
    getProductCollection(pageType = PLP_PAGE, products) {
        switch (pageType) {
        case PLP_PAGE:
        case SEARCH_PAGE:
            return products || [];
        default:
            return [];
        }
    }

    /**
     * Get provided impression UID
     *
     * @param impression
     * @return {string}
     */
    getImpressionUID(impression = []) {
        return impression.reduce((acc, { id }) => `${ acc }_${ id }`, '');
    }

    /**
     * Get breadcrumbs seperated by '-'
     *
     * @returns {*}
     */
    getBreadcrumbs() {
        const { BreadcrumbsReducer: { breadcrumbs } } = this.getAppState();

        return breadcrumbs.map((item) => item.name)
            .reverse()
            .join('-');
    }
}

export default ViewItemListEvent;
