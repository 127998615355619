/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { STORE_CODE_KSA_AR, STORE_CODE_UAE } from 'Component/Router/Router.config';
import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Scandipwa/Util/Arabic/isArabic/isArabic */
export const isArabic = () => (BrowserDatabase.getItem('config')?.storeConfig?.code
    ? BrowserDatabase.getItem('config').storeConfig.code === STORE_CODE_UAE
    || BrowserDatabase.getItem('config').storeConfig.code === STORE_CODE_KSA_AR
    : false);

export default isArabic;
