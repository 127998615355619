/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_BEGIN_CHECKOUT_GA4 } from '../../../util/Event';
import BaseEventEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On cart page
 */
export class BeginCheckoutEvent extends BaseEventEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_BEGIN_CHECKOUT_GA4, () => {
            this.handle();
        });
    }

    /**
     * Handle
     */
    handler() {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({
            currency: this.getCurrencyCode(),
            value: this.getItemsTotal(),
            items: this.getCartItemsWithListData()
        });
    }
}

export default BeginCheckoutEvent;
