/* eslint-disable max-lines */
/* eslint-disable consistent-return */
// eslint-disable @scandipwa/scandipwa-guidelines/no-duplicate-namespaces

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { IS_CART_RESET, SHOULD_RESET_CART } from 'Component/CheckoutSuccess/CheckoutSuccess.container';
import CartQuery from 'Query/Cart.query';
import { ORDER_ID_KEY } from 'Route/Checkout/Checkout.config';
import {
    CartDispatcher as SourceCartDispatcher
} from 'SourceStore/Cart/Cart.dispatcher';
import { setPromosData } from 'Store/Analytics/Analytics.action';
import { updateIsLoadingCart } from 'Store/Cart/Cart.action';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { updateEmail, updateShippingFields } from 'Store/Checkout/Checkout.action';
import { PAYMENT_TOTALS } from 'Store/Checkout/Checkout.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId } from 'Util/Cart';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
import getStore from 'Util/Store';

import {
    CHECKOUTCOM_PAGEDATA_ORDER_STORAGE,
    CHECKOUTCOM_PAYMENT_TOTALS_STORAGE
} from '../../../packages/@scandipwa/checkoutcom/src/plugin/Checkout.container.plugin';
import {
    TABBY_PAGEDATA_ORDER_STORAGE,
    TABBY_PAYMENT_TOTALS_STORAGE
} from '../../../packages/@scandipwa/tabby/src/plugin/Checkout.container.plugin';
import {
    TAMARA_PAGEDATA_ORDER_STORAGE,
    TAMARA_PAYMENT_TOTALS_STORAGE
} from '../../../packages/@scandipwa/tamara/src/plugin/Checkout.container.plugin';

export const GUEST_QUOTE_ID = 'guest_quote_id';

/** @namespace Scandipwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async addProductToCart(dispatch, options = {}) {
        const { products = [], cartId: userCartId } = options;

        const cartId = userCartId || getCartId();

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification('error', __('No product data!')));

            return Promise.reject();
        }

        try {
            if (!cartId) {
                return Promise.reject();
            }

            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products)
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification('error', __(getErrorMessage(error))));
                });

                return Promise.reject();
            }

            await this.updateInitialCartData(dispatch);
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification('error', __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification('error', __(getErrorMessage(error))));

            return Promise.reject();
        }

        return Promise.resolve();
    }

    async applyCouponToCart(dispatch, couponCode, onShippingEstimationFieldsChange, saveAddressInformation) {
        const isCustomerSignedIn = isSignedIn();
        const cartId = getCartId();

        if (!isCustomerSignedIn && !cartId) {
            return false;
        }

        const callback = (getCouponData, cartData) => {
            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was applied!')));
            dispatch(setPromosData(getCouponData));
        };

        return fetchMutation(CartQuery.getApplyCouponMutation(
            couponCode, cartId
        )).then(
            /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/applyCouponToCart/fetchMutation/then */
            ({ applyCouponToCart: { cartData, getCouponData } }) => {
                if (onShippingEstimationFieldsChange && saveAddressInformation) {
                    return onShippingEstimationFieldsChange(
                        this.prepareCouponAddress()
                    ).then(
                        /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/applyCouponToCart/fetchMutation/then/onShippingEstimationFieldsChange/then */
                        () => {
                            callback(getCouponData, cartData);
                            return true;
                        }, /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/applyCouponToCart/fetchMutation/then/onShippingEstimationFieldsChange/then/catch */
                        (error) => {
                            dispatch(showNotification('error', error[0].message));
                            return false;
                        }
                    );
                }

                callback(getCouponData, cartData);
                return true;
            },
            /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/applyCouponToCart/fetchMutation/then/catch */
            (error) => {
                dispatch(showNotification('error', error[0].message));
                return false;
            }
        );
    }

    async removeCouponFromCart(dispatch, onShippingEstimationFieldsChange, saveAddressInformation) {
        const isCustomerSignedIn = isSignedIn();
        const cartId = getCartId();

        if (!isCustomerSignedIn && !cartId) {
            return;
        }

        const callback = (cartData) => {
            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was removed!')));
            dispatch(setPromosData({}));
        };

        return fetchMutation(CartQuery.getRemoveCouponMutation(
            cartId
        )).then(
            /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeCouponFromCart/fetchMutation/then */
            ({ removeCouponFromCart: { cartData } }) => {
                if (onShippingEstimationFieldsChange && saveAddressInformation) {
                    return onShippingEstimationFieldsChange(
                        this.prepareCouponAddress()
                    ).then(
                        /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeCouponFromCart/fetchMutation/then/onShippingEstimationFieldsChange/then */
                        () => {
                            callback(cartData);
                        }
                    );
                }

                callback(cartData);
            },
            /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeCouponFromCart/fetchMutation/then/dispatch/catch */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

    prepareCouponAddress() {
        const {
            ConfigReducer: { default_country },
            MyAccountReducer: { customer: signedCustomer }
        } = getStore().getState();
        const { addresses, selectedAddressId } = signedCustomer;

        if (isSignedIn() && selectedAddressId) {
            const selectedAddress = addresses.find(({ id }) => id === selectedAddressId);

            if (selectedAddress) {
                const {
                    city,
                    postcode,
                    region: { region, region_id }
                } = selectedAddress;

                return {
                    city,
                    country_id: default_country,
                    region_id,
                    region,
                    postcode
                };
            }
        }

        return {
            city: '',
            country_id: default_country,
            region_id: 0,
            region: '',
            postcode: ''
        };
    }

    isVariantOutOfStock(variants, sku) {
        const index = variants.findIndex(({ product: { sku: vSku } }) => sku === vSku);

        if (index !== -1) {
            return variants[index].product.stock_status === 'OUT_OF_STOCK';
        }

        return true;
    }

    removeOutdatedItems(data, dispatch) {
        const { pathname } = window.location;

        if (/checkout|tabby|tamara/.test(pathname)) {
            return;
        }

        try {
            const { items = [] } = data;

            items.map((item) => {
                if (
                    (item.product.type_id === 'configurable'
                && item.product.variants.length === 0)
                || this.isVariantOutOfStock(item.product.variants, item.sku)
                ) {
                    return fetchMutation(CartQuery.getRemoveCartItemMutation(
                        item.id,
                        getCartId()
                    )).then(
                        /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeOutdatedItems/items/map/fetchMutation/then */
                        ({ removeItemFromCart: { cartData } }) => {
                            this._updateCartData(cartData, dispatch);
                            dispatch(showNotification(
                                'info',
                                `Product ${item.product.name} was removed from the cart, as it is not longer available`
                            ));
                        },
                        /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeOutdatedItems/items/map/fetchMutation/then/dispatch/catch */
                        (error) => dispatch(showNotification('error', error[0].message))
                    );
                }

                return null;
            });
        } catch (error) {
            // Do nothing
        }
    }

    async updateInitialCartData(dispatch, isForCustomer = false, disableLoader = false, isInitRequest = false) {
        try {
            if (!disableLoader) {
                dispatch(updateIsLoadingCart(true));
            }

            const { pathname } = window.location;
            const shouldResetCart = BrowserDatabase.getItem(SHOULD_RESET_CART);

            if (/checkout_com\/payment\/fail/.test(pathname) && !shouldResetCart) {
                return;
            }

            if (/tabby\/result\/cancel|tabby\/result\/failure/.test(pathname) && !shouldResetCart) {
                return;
            }

            if (/tamara\/payment\/.+\/cancel|tamara\/payment\/.+\/failure/.test(pathname) && !shouldResetCart) {
                return;
            }

            if (shouldResetCart) {
                BrowserDatabase.deleteItem(SHOULD_RESET_CART);
                BrowserDatabase.deleteItem(ORDER_ID_KEY);
                BrowserDatabase.deleteItem(CHECKOUTCOM_PAYMENT_TOTALS_STORAGE);
                BrowserDatabase.deleteItem(CHECKOUTCOM_PAGEDATA_ORDER_STORAGE);
                BrowserDatabase.deleteItem(TAMARA_PAGEDATA_ORDER_STORAGE);
                BrowserDatabase.deleteItem(TAMARA_PAYMENT_TOTALS_STORAGE);
                BrowserDatabase.deleteItem(TABBY_PAGEDATA_ORDER_STORAGE);
                BrowserDatabase.deleteItem(TABBY_PAYMENT_TOTALS_STORAGE);
                BrowserDatabase.deleteItem(PAYMENT_TOTALS);
                BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
                BrowserDatabase.deleteItem(CART_TOTALS);
                BrowserDatabase.setItem(1, IS_CART_RESET);
            }

            if (/tamara\/payment\/.+\/success/.test(pathname) && isInitRequest) {
                return;
            }

            const quoteId = await this._getCartId(dispatch);
            const {
                cartData = {},
                cartData: {
                    is_virtual = false,
                    shipping_address: {
                        selected_shipping_method: {
                            address,
                            address: {
                                street = null,
                                email = ''
                            } = {}
                        } = {},
                        method_code
                    } = {}
                } = {}
            } = await fetchQuery(
                CartQuery.getCartQuery(
                    quoteId
                )
            );

            if (address && street) {
                if (!is_virtual) {
                    await dispatch(
                        updateShippingFields({
                            ...this.prepareCheckoutAddressFormat(address),
                            method_code
                        })
                    );
                }

                await dispatch(updateEmail(email));
            }

            if (isForCustomer && !getAuthorizationToken()) {
                dispatch(updateIsLoadingCart(false));

                return null;
            }

            await this._updateCartData(cartData, dispatch);
            await this.removeOutdatedItems(cartData, dispatch);

            if (!disableLoader) {
                dispatch(updateIsLoadingCart(false));
            }

            return null;
        } catch (error) {
            dispatch(updateIsLoadingCart(false));

            if (isInitRequest) {
                if (!isSignedIn()) {
                    return this.createGuestEmptyCart(dispatch);
                }
            }

            return null;
        }
    }
}

export default new CartDispatcher();
