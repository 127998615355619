/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PropTypes } from 'prop-types';

import SourceFieldSelectContainer from 'SourceComponent/FieldSelect/FieldSelect.container';

import { EU_PREFIX, SIZES } from './FieldSelect.config';

/**
 * Field select container
 * @class FieldSelectContainer
 * @namespace Scandipwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    static propTypes = {
        ...super.propTypes,
        isSize: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isSize: false
    };

    containerProps() {
        const {
            value,
            isLabelInner,
            innerLabel
        } = this.props;

        const {
            valueIndex,
            searchString,
            isSelectExpanded
        } = this.state;

        return {
            ...super.containerProps(),
            value,
            isLabelInner,
            innerLabel,
            unsortedOptions: this.getOptions(),
            options: this.sortSelectOptions(),
            valueIndex,
            searchString,
            isSelectExpanded
        };
    }

    sortSelectOptions() {
        const { noSort, isSize } = this.props;
        const options = this.getOptions();

        /**
         * Trim all null label values, sort alphabetically or by size
         */
        if (noSort) {
            return options;
        }

        return options.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const textA = a.label.includes(EU_PREFIX)
                ? a.label.replace(EU_PREFIX, '').toUpperCase()
                : a.label.toUpperCase();
            const textB = b.label.includes(EU_PREFIX)
                ? b.label.replace(EU_PREFIX, '').toUpperCase()
                : b.label.toUpperCase();

            if (SIZES.includes(textA)) {
                return SIZES.indexOf(textA) - SIZES.indexOf(textB);
            }

            // isSize to sort sizes (in number format)
            if (isSize) {
                return parseFloat(textA) - parseFloat(textB);
            }

            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }
}

export default FieldSelectContainer;
