/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

export const aroundCategoryProductListRender = (args, callback) => callback(...args);

export default {
    'Component/CategoryProductList/Container': {
        'member-function': {
            render: aroundCategoryProductListRender
        }
    }
};
