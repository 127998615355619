/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import { Draggable as SourceDraggable } from 'SourceComponent/Draggable/Draggable.component';
import { isArabic } from 'Util/Arabic';

/** @namespace Scandipwa/Component/Draggable/Component */
export class DraggableComponent extends SourceDraggable {
    static propTypes = {
        ...super.propTypes,
        isSliderOverlay: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isSliderOverlay: false
    };

    _handleDragStart = ({ clientX, clientY }) => {
        const { onDragStart, isSliderOverlay, isMobile } = this.props;

        if (onDragStart) {
            onDragStart();
        }

        // Slider overlays picture width is centered and 70% of window.innerWidth
        // so drag is disabled when cursor is on slider overlay image
        if (!isMobile && isSliderOverlay
            // eslint-disable-next-line no-magic-numbers
            && (clientX >= window.innerWidth * 0.15 && clientX <= window.innerWidth * 0.85)) {
            this.setState({
                isDragging: false
            });
        } else {
            this.setState({
                isDragging: true
            });
        }

        this.setState({
            originalX: clientX,
            originalY: clientY
        });
    };

    render() {
        const {
            children,
            handleFocus,
            handleKey,
            draggableRef,
            mix,
            useDir
        } = this.props;

        return (
            <div
              block="Draggable"
              mix={ mix }
              ref={ draggableRef }
              onMouseDown={ this.handleMouseDown }
              onTouchStart={ this.handleTouchStart }
              onClick={ this.handleClick }
              onContextMenu={ this.handleClick }
              onKeyDown={ handleKey }
              onFocus={ handleFocus }
              tabIndex={ 0 }
              role="button"
              aria-label="Draggable area"
              dir={ useDir ? (isArabic() ? 'rtl' : 'ltr') : undefined }
            >
                { children }
            </div>
        );
    }
}

export default DraggableComponent;
