/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    formatURI,
    getFetch,
    getGraphqlEndpoint,
    handleConnectionError,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    HTTP_503_SERVICE_UNAVAILABLE,
    parseResponse,
    putPersistedQuery
} from 'SourceUtil/Request/Request';
import { isSignedIn, refreshAuthorizationToken } from 'Util/Auth';
import { refreshUid } from 'Util/Compare';

export const ONE_HOUR_IN_SECONDS = 3600;
export * from 'SourceUtil/Request/Request';

/** @namespace Scandipwa/Util/Request/getStoreCodePath */
export const getStoreCodePath = () => {
    const path = location.pathname;
    // eslint-disable-next-line no-undef
    const firstPathPart = path.split('/')[1] === 'en' && window.website === 'ksa' ? 'ksa_en' : path.split('/')[1];

    if (window.storeList.includes(firstPathPart)) {
        return `/${ firstPathPart }`;
    }

    return '';
};

/**
 * Make GET request to endpoint (via ServiceWorker)
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @param  {Number} cacheTTL Cache TTL (in seconds) for ServiceWorker to cache responses
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Scandipwa/Util/Request/executeGet */
export const executeGet = async (queryObject, name, cacheTTL = ONE_HOUR_IN_SECONDS, signal) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    // Fetch only throws on network error, http errors have to be handled manually.
    try {
        const result = await getFetch(uri, name, signal);

        if (result.status === HTTP_410_GONE) {
            const putResponse = await putPersistedQuery(
                getGraphqlEndpoint(),
                query,
                Number.isInteger(cacheTTL) ? cacheTTL : ONE_HOUR_IN_SECONDS
            );

            if (putResponse.status === HTTP_201_CREATED) {
                return parseResponse(await getFetch(uri, name, signal));
            }
        }

        if (result.status === HTTP_503_SERVICE_UNAVAILABLE) {
            handleConnectionError(result.status, result.statusText);
            throw new Error(result.statusText);
        }

        // Successful and all other http responses go here:
        return parseResponse(result);
    } catch (error) {
        // Network error
        handleConnectionError(error, 'executeGet failed');
        throw new Error(error);
    }
};
