/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/forbid-prop-types */
/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_AddToCartPopup
 * @author      Deniss Strombergs <info@scandiweb.com>
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CartItem from 'Component/CartItem';
import Popup from 'Component/Popup';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { TotalsType } from 'Type/MiniCart.type';
import { appendWithStoreCode } from 'Util/Url';

import { ADD_TO_CART_POPUP } from './AddToCartPopup.config';

import './AddToCartPopup.style.scss';

/** @namespace Scandipwa/Component/AddToCartPopup/Component */
export class AddToCartPopupComponent extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
        onContinueShoppingClick: PropTypes.func.isRequired,
        getAddedProductItem: PropTypes.func.isRequired,
        addedProduct: PropTypes.object.isRequired
    };

    getBannerHeight() {
        const height = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue('--header-top-banner-height'),
            10
        );

        return height || 0;
    }

    setPopUpTopMargin() {
        const topBannerHeight = this.getBannerHeight();
        const currScrollPosition = window.pageYOffset;
        const visiblePart = topBannerHeight - currScrollPosition;

        if (visiblePart > 0) {
            document.documentElement.style.setProperty('--visible-global-banner-part', `${visiblePart}px`);
        } else {
            document.documentElement.style.setProperty('--visible-global-banner-part', 0);
        }
    }

    renderProceedToCheckoutButton() {
        return (
            <a
              block="Button"
              elem="AddToCart"
              mods={ { block: 'AddToCartPopup', elem: 'ProceedToCheckout' } }
              href={ appendWithStoreCode(CHECKOUT_URL) }
            >
                { __('Proceed to checkout') }
            </a>
        );
    }

    render() {
        const { getAddedProductItem, onContinueShoppingClick } = this.props;
        const addedProductItem = getAddedProductItem();
        this.setPopUpTopMargin();

        if (!addedProductItem) {
            return null;
        }

        const { item_id, price } = addedProductItem;
        const {
            addedProduct: {
                quantity
            },
            totals: {
                total_quantity = 0,
                prices: {
                    quote_currency_code
                }
            }
        } = this.props;

        addedProductItem.qty = quantity;
        addedProductItem.row_total = price;

        return (
            <Popup
              id={ ADD_TO_CART_POPUP }
              mix={ { block: 'AddToCartPopup' } }
            >
                <CartItem
                  key={ item_id }
                  item={ addedProductItem }
                  currency_code={ quote_currency_code }
                  shouldRenderQuantity
                />
                { this.renderProceedToCheckoutButton() }
                <div
                  block="AddToCartPopup"
                  elem="Links"
                >
                    <a
                      block="AddToCartPopup"
                      elem="AddToCartLink"
                      href="/cart"
                    >
                        { __('Go to the cart (%s)', total_quantity) }
                    </a>
                    <button
                      block="AddToCartPopup"
                      elem="ContinueShoppingLink"
                      onClick={ onContinueShoppingClick }
                    >
                        { __('Continue shopping') }
                    </button>
                </div>
            </Popup>
        );
    }
}

export default AddToCartPopupComponent;
