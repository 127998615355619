/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    DEFAULT_COUNTRY_KSA, DEFAULT_COUNTRY_UAE
} from 'Component/CheckoutNewAddressForm/CheckoutNewAddressForm.config';
import { SHOULD_RESET_CART } from 'Component/CheckoutSuccess/CheckoutSuccess.container';
import CheckoutQuery from 'Query/Checkout.query';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { prepareQuery } from 'SourceUtil/Query';
import { executeGet, fetchMutation } from 'SourceUtil/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import isKsa from 'Util/Arabic/isKsa';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getCartId } from 'Util/Cart';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { CHECKOUTCOM_APPLE_PAY } from '../../plugin/CheckoutShippingBilling.container.plugin';
import CheckoutComQuery from '../../query/CheckoutCom.query';
import CheckoutComApplePayComponent from './CheckoutComApplePay.component';

/** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartTotals: state.CartReducer.cartTotals,
    default_title: state.ConfigReducer.default_title
});

/** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message))
});

/** @namespace Checkoutcom/Component/CheckoutComApplePay/Container */
export class CheckoutComApplePayContainer extends PureComponent {
    /**
     * Props
     * @type {*}
     */
    static propTypes = {
        setCheckoutLoading: PropTypes.func.isRequired,
        setOrderButtonVisibility: PropTypes.func.isRequired,
        savePaymentInformation: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        billingAddress: PropTypes.object,
        // eslint-disable-next-line react/boolean-prop-naming
        showError: PropTypes.bool,
        default_title: PropTypes.string.isRequired,
        cartTotals: PropTypes.shape({
            grand_total: PropTypes.number,
            quote_currency_code: PropTypes.string
        }).isRequired

    };

    static defaultProps = {
        billingAddress: {},
        showError: false
    };

    /**
     * Container methods that should be available on component
     * @type {*}
     */
    containerFunctions = {
        handleApplePayButtonClick: this.handleApplePayButtonClick.bind(this)
    };

    /**
     * Component did mount
     */
    componentDidMount() {
        const { setOrderButtonVisibility } = this.props;

        setOrderButtonVisibility(false);

        this.requestConfig().then(
            this.launchPaymentMethod.bind(this)
        );
    }

    /**
     * Component will unmount
     */
    componentWillUnmount() {
        const { setOrderButtonVisibility } = this.props;

        setOrderButtonVisibility(true);
    }

    /**
     * Constructor
     * @param props
     * @param context
     */
    __construct(props, context) {
        super.__construct(props, context);

        this.state = {
            isApplePayAvailable: !!window.ApplePaySession,
            applePayDisabled: true,
            isLoading: true,
            merchant_id: null,
            button_style: null,
            supported_networks: null,
            merchant_capabilities: null
        };
    }

    onRequestConfigSuccess = (response) => {
        const { storeConfig: { checkout_com: { apple_pay } } } = response;
        this.setState({
            isLoading: false,
            ...apple_pay
        });
    };

    onRequestConfigFailure = (_error) => {
        this.setState({ isLoading: false });
    };

    requestConfig() {
        const promise = executeGet(
            prepareQuery([CheckoutComQuery.getApplePayConfigQuery()]),
            'CheckoutComApplePayConfigContainer',
            ONE_MONTH_IN_SECONDS
        );

        promise.then(
            this.onRequestConfigSuccess,
            this.onRequestConfigFailure
        );

        return promise;
    }

    /**
     * Place order
     * @param event
     * @returns {Promise<void>}
     * @private
     */
    _placeOrder = async (event) => {
        const { payment: { token: cardToken } } = event;
        const cart_id = getCartId();

        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id,
            payment_method: {
                code: CHECKOUTCOM_APPLE_PAY,
                additional_data: {
                    cardToken
                }
            }
        }));

        const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
        const { placeOrder: { order: { order_id } } } = orderData;

        return order_id;
    };

    onApplePaySessionCanMakePaymentsWithActiveCardResolve = (canMakePayments) => {
        const { showError } = this.props;

        if (canMakePayments) {
            this.setState({ applePayDisabled: false });
        } else {
            showError(__('Apple Pay is available but not currently active.'));
        }
    };

    onApplePaySessionCanMakePaymentsWithActiveCardError = (_error) => {
        const { showError } = this.props;

        showError(__('Something went wrong!'));
    };

    /**
     * Launch payment method
     */
    launchPaymentMethod() {
        const { showError } = this.props;
        const { isApplePayAvailable, merchant_id, button_style } = this.state;

        if (!isApplePayAvailable) {
            const missingApplePayMessage = __('Apple Pay is not available for this browser.');

            showError(__(missingApplePayMessage));
            console.error(missingApplePayMessage);

            return;
        }

        this.setState({ applePayButtonClass: `ApplePayButton-${ button_style }` });

        const merchantIdentifier = merchant_id;

        new Promise((resolve) => {
            resolve(window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier));
        }).then(
            this.onApplePaySessionCanMakePaymentsWithActiveCardResolve
        ).catch(
            this.onApplePaySessionCanMakePaymentsWithActiveCardError
        );
    }

    /**
     * Handle apple pay click
     */
    handleApplePayButtonClick() {
        const {
            cartTotals: {
                prices: {
                    grand_total: { value: grand_total },
                    quote_currency_code
                }
            },
            default_title
        } = this.props;

        const countryCode = isKsa() ? DEFAULT_COUNTRY_KSA : DEFAULT_COUNTRY_UAE;
        const paymentRequest = {
            countryCode,
            currencyCode: quote_currency_code,
            supportedNetworks: this._getSupportedNetworks(),
            merchantCapabilities: this._getMerchantCapabilities(),
            total: { label: default_title, amount: grand_total }
        };

        const applePaySession = new window.ApplePaySession(1, paymentRequest);

        this._addApplePayEvents(applePaySession);
        window.applePayCheckoutComSession = applePaySession;

        document.getElementById(SHIPPING_STEP).dispatchEvent(new Event('submit'));
    }

    /**
     * Add apple pay button events
     * @param applePaySession
     */
    _addApplePayEvents = (applePaySession) => {
        const {
            cartTotals: {
                prices: {
                    grand_total: { value: grand_total }
                }
            },
            showError,
            default_title,
            setDetailsStep,
            setCheckoutLoading
        } = this.props;

        applePaySession.onvalidatemerchant = (event) => {
            const promise = this._performValidation(event.validationURL);

            promise.then(
                /** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/CheckoutComApplePayContainer/then/catch/promise/then */
                (response) => {
                    const {
                        verifyCheckoutComApplePay: merchantSession,
                        verifyCheckoutComApplePay: { statusMessage = '' }
                    } = response;

                    if (statusMessage) {
                        showError(__(statusMessage));
                        console.error('Cannot validate merchant:', merchantSession);

                        return;
                    }

                    applePaySession.completeMerchantValidation(merchantSession);
                }
            ).catch(
                /** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/CheckoutComApplePayContainer/then/catch */
                (error) => console.error(error)
            );
        };

        applePaySession.onshippingcontactselected = () => {
            const status = window.ApplePaySession.STATUS_SUCCESS;
            const newTotal = {
                type: 'final',
                label: default_title,
                amount: grand_total
            };

            applePaySession.completeShippingContactSelection(status, [], newTotal, this._getLineItems());
        };

        applePaySession.onshippingmethodselected = () => {
            const status = window.ApplePaySession.STATUS_SUCCESS;
            const newTotal = {
                type: 'final',
                label: default_title,
                amount: grand_total
            };

            applePaySession.completeShippingMethodSelection(status, newTotal, this._getLineItems());
        };

        applePaySession.onpaymentmethodselected = () => {
            const newTotal = {
                type: 'final',
                label: default_title,
                amount: grand_total
            };

            applePaySession.completePaymentMethodSelection(newTotal, this._getLineItems());
        };

        applePaySession.onpaymentauthorized = (event) => {
            this._placeOrder(event).then(
                /** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/CheckoutComApplePayContainer/then/catch/_placeOrder/then */
                (orderId) => {
                    const status = orderId
                        ? window.ApplePaySession.STATUS_SUCCESS
                        : window.ApplePaySession.STATUS_FAILURE;

                    applePaySession.completePayment(status);

                    if (orderId) {
                        BrowserDatabase.setItem(1, SHOULD_RESET_CART);

                        return setDetailsStep(orderId);
                    }

                    return null;
                }
            ).catch(
                /** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/CheckoutComApplePayContainer/then/catch */
                (errors) => {
                    applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);

                    if (Array.isArray(errors) && errors.length) {
                        const [error] = errors;
                        showError(__(error.message));
                    }
                }
            );
        };

        applePaySession.oncancel = () => {
            if (setCheckoutLoading) {
                setCheckoutLoading(false);
            }

            console.log('Apple Pay session was cancelled.');
        };
    };

    /**
     * Get supported networks
     * @return {array}
     */
    _getSupportedNetworks = () => {
        const { supported_networks = '' } = this.state;

        return supported_networks.split(',');
    };

    /**
     * Get merchant capabilities
     * @return {array}
     */
    _getMerchantCapabilities = () => {
        const { merchant_capabilities } = this.state;
        const output = ['supports3DS'];
        const capabilities = merchant_capabilities.split(',');

        return output.concat(capabilities);
    };

    /**
     * Get line items
     * @returns {*[]}
     */
    _getLineItems = () => [];

    /**
     * Get apple pay validation
     * @param validationUrl
     * @returns {Promise<Request>}
     */
    _performValidation = (validationUrl) => {
        this.setState({ isLoading: true });
        const mutation = CheckoutComQuery.getVerifyCheckoutComApplePayQuery(validationUrl);

        return fetchMutation(mutation).finally(
            /** @namespace Checkoutcom/Component/CheckoutComApplePay/Container/CheckoutComApplePayContainer/fetchMutation/finally */
            () => this.setState({ isLoading: false })
        );
    };

    /**
     * Render
     * @returns {*}
     */
    render() {
        const { isApplePayAvailable } = this.state;

        if (!isApplePayAvailable) {
            return null;
        }

        return (
            <CheckoutComApplePayComponent
              { ...this.containerFunctions }
              { ...this.state }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComApplePayContainer);
