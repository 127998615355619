/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { PaymentMethodType } from 'Type/Checkout.type';

import { ADYEN_SCHEME_PAYMENT_TYPE } from '../../util/Adyen';
import AdyenPayment from '../AdyenPayment';

/** @namespace Scandiweb/AdyenPayments/Component/AdyenAlternativePayments/Component */
export class AdyenAlternativePaymentsComponent extends PureComponent {
    static propTypes = {
        method: PaymentMethodType.isRequired,
        getIsSelected: PropTypes.func.isRequired,
        handlePaymentTypeChange: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired
    };

    render() {
        const {
            method,
            getIsSelected,
            handlePaymentTypeChange,
            setOrderButtonEnableStatus,
            method: {
                code,
                title: name
            }
        } = this.props;

        const type = code.replace(/adyen_/g, '');
        const isSelected = getIsSelected(type);

        if (type === ADYEN_SCHEME_PAYMENT_TYPE) {
            return null;
        }

        return (
            <AdyenPayment
              key={ type }
              type={ type }
              name={ name }
              isSelected={ isSelected }
              method={ method }
              onClick={ handlePaymentTypeChange }
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
        );
    }
}

export default AdyenAlternativePaymentsComponent;
