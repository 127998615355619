/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ADD_ADDRESS } from 'SourceComponent/MyAccountAddressPopup/MyAccountAddressPopup.config';
import Event from "../../util/Event/Event";
import { EVENT_GTM_PAGE_DATA } from '../../util/Event';
import { NAVIGATION_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import { PAGE_TYPE_ACCOUNT_ADD_ADDRESS }
from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/NavigationPageDataEvent.handler';
import { GOTO_PREVIOUS_NAVIGATION_STATE } from 'Store/Navigation/Navigation.action'
import { ADDRESS_POPUP_ID } from 'Component/CheckoutNewAddressPopup/CheckoutNewAddressPopup.config';

const OverlayReducer = (args, callback, context) => {
    const {
        overlayPayload: {
            MyAccountAddressPopup: {
                action
            } = {}
        } = {},
        activeOverlay
    } = args[0];
    const { type } = args[1];

    if (activeOverlay === ADDRESS_POPUP_ID) {
        Event.dispatch(EVENT_GTM_PAGE_DATA,
            {
                type: NAVIGATION_TYPE,
                specialPageType: PAGE_TYPE_ACCOUNT_ADD_ADDRESS
            });
    }

    if (action === ADD_ADDRESS && type === GOTO_PREVIOUS_NAVIGATION_STATE) {
        Event.dispatch(EVENT_GTM_PAGE_DATA, { type: NAVIGATION_TYPE });
    }

    return callback(...args);
};

export default {
    'Store/Overlay/Reducer': {
        'function': OverlayReducer
    }
}
