/** @namespace Scandipwa/Util/Scarab/pushRecommenderData */
export const pushRecommenderData = (locale, createArrayOfIds, isKsa, containerId) => {
    window.ScarabQueue.push(['availabilityZone', locale]);
    window.ScarabQueue.push(['recommend', {
        logic: isKsa ? 'HOME_LOGIC_HOMEPAGE_KSA' : 'HOME_LOGIC_HOMEPAGE',
        limit: 10,
        containerId,
        success(SC) {
            createArrayOfIds(SC.page.products.map((product) => product.id));
        }
    }]);
    window.ScarabQueue.push(['go']);
};
