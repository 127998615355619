import BaseMeta from 'SourceComponent/Meta/Meta.component';

/** @namespace Scandipwa/Component/Meta/Component */
export class MetaComponent extends BaseMeta {
    renderCanonical() {
        // eslint-disable-next-line fp/no-let
        let { canonical_url } = this.props;

        if (canonical_url && canonical_url.includes('?')) {
            canonical_url = canonical_url.split('?')[0];
        }

        const canonical = canonical_url || window.location.origin + window.location.pathname;

        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <link rel="canonical" href={ canonical } />
        );
    }

    renderTitle() {
        const {
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        return (
            <title>{ `${ titlePrefix }${ title }${ titleSuffix }` }</title>
        );
    }

    renderMeta() {
        const { metadata } = this.props;
        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          property={ property }
                          content={ content }
                        />
                    );
                }) }
            </>
        );
    }
}

export default MetaComponent;
