/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RECOMMENDER_CHECKOUT_PAGE_ID } from 'Route/Checkout/Checkout.config';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { CustomerType } from 'Type/Account.type';
import isKsa from 'Util/Arabic/isKsa';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { pushRecommenderData } from 'Util/Scarab';

import OrderSuccessCarouselComponent from './OrderSuccessCarousel.component';

/** @namespace Scandipwa/Component/OrderSuccessCarousel/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    locale: state.ConfigReducer.code,
    customer: state.MyAccountReducer.customer
});
/** @namespace Scandipwa/Component/OrderSuccessCarousel/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/OrderSuccessCarousel/Container */
export class OrderSuccessCarouselContainer extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        locale: PropTypes.string.isRequired,
        customer: CustomerType.isRequired
    };

    state = {
        productIds: '',
        isTriggered: false
    };

    createArrayOfIds = this.createArrayOfIds.bind(this);

    componentDidMount() {
        this.requestScarabPush();
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    containerProps() {
        const { productIds } = this.state;

        return { productIds };
    }

    requestScarabPush = () => {
        const { productIds = '', isTriggered } = this.state;
        const { customer, isSignedIn } = this.props;

        if (!productIds && !isTriggered && window.ScarabQueue) {
            this.setState({ isTriggered: true });

            if (isSignedIn) {
                if (customer) {
                    window.ScarabQueue.push(['setEmail', customer.email]);
                    this.pushRecommenderData();
                }
            } else {
                this.pushRecommenderData();
            }
        }
        // eslint-disable-next-line no-magic-numbers
        this.timeoutId = setTimeout(this.requestScarabPush, 5000); // Every 5 s requesting recommended products
        setTimeout(() => {
            clearTimeout(this.timeoutId);
            // eslint-disable-next-line no-magic-numbers
        }, 15000);
    };

    pushRecommenderData() {
        const { locale } = this.props;

        pushRecommenderData(locale, this.createArrayOfIds, isKsa(), RECOMMENDER_CHECKOUT_PAGE_ID);
    }

    createArrayOfIds(ids) {
        const { items: cartItems = [] } = BrowserDatabase.getItem(CART_TOTALS);
        const idsToIgnore = cartItems.map((item) => item.product.sku);
        const validIds = ids.filter((id) => !idsToIgnore.includes(id));

        this.setState({
            productIds: validIds
        });
    }

    render() {
        return (
            <OrderSuccessCarouselComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccessCarouselContainer);
