/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_ForgotPasswordPopup
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

// eslint-disable-next-line import/prefer-default-export
export const MOBILE_FORGOT_PASSWORD_POPUP = 'MOBILE_FORGOT_PASSWORD_POPUP';
export const DESKTOP_FORGOT_PASSWORD_POPUP = 'DESKTOP_FORGOT_PASSWORD_POPUP';
