/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AdyenCheckout from '../component/AdyenCheckout';
import { ADYEN_APPLE_PAY_METHOD_CODE } from '../util/Adyen';

export class CheckoutShippingBillingComponent {
    aroundRender = (args, callback, instance) => (
        <>
            { !!window.ApplePaySession && (
                <AdyenCheckout
                  code={ ADYEN_APPLE_PAY_METHOD_CODE }
                  isApplePayExpress
                  applePayExpressSource="express"
                />
            ) }
            { callback.apply(instance, args) }
        </>
    );
}

export const { aroundRender } = new CheckoutShippingBillingComponent();

export default {
    'Scandipwa/Component/CheckoutShippingBilling/Component': {
        'member-function': {
            render: aroundRender
        }
    }
};
