/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ADD_TO_CART_POPUP
} from 'Component/AddToCartPopup/AddToCartPopup.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import {
    CART_OVERLAY
} from 'SourceComponent/Header/Header.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { TotalsType } from 'Type/MiniCart.type';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

import AddToCartComponent from './AddToCart.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "cartDispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace Scandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showOverlay: (overlayKey, payload) => dispatch(toggleOverlayByKey(overlayKey, payload)),
    showPopup: (payload) => dispatch(showPopup('title', payload))
});

/* @namespace Scandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        ...super.propTypes,
        isLoading: PropTypes.bool,
        showNotification: PropTypes.func.isRequired,
        setQuantityToDefault: PropTypes.func,
        showOverlay: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        salable_qty: PropTypes.number.isRequired
    };

    static defaultProps = {
        ...super.defaultProps,
        setQuantityToDefault: () => {
        },
        isLoading: false
    };

    state = {
        ...this.state,
        isLoading: false,
        shouldShowAddToCartPopup: false,
        search: ''
    };

    containerProps() {
        const {
            areDetailsLoaded,
            product
        } = this.props;

        const {
            isLoading,
            shouldShowAddToCartPopup,
            search
        } = this.state;

        return {
            ...super.containerProps(),
            product,
            isLoading,
            shouldShowAddToCartPopup,
            search,
            areDetailsLoaded
        };
    }

    async addProductToCart() {
        const {
            product,
            product: { sku },
            totals: { items },
            quantity,
            showNotification,
            addToCart,
            updateSelectedValues
        } = this.props;

        const restrictedSku = '705249';

        if (restrictedSku === sku) {
            if (quantity > 2) {
                this.setState({ isLoading: false });
                showNotification('error', __('The requested qty exceeds the maximum qty allowed in the shopping cart'));
                return;
            }

            // eslint-disable-next-line fp/no-let
            let count = 0;
            // eslint-disable-next-line react/prop-types
            items.map((item) => {
                if (item.product.sku === sku) {
                    count += item.qty;
                }

                return null;
            });

            if (count + quantity > 2) {
                this.setState({ isLoading: false });
                showNotification('error', __('The requested qty exceeds the maximum qty allowed in the shopping cart'));
                return;
            }
        }

        if (updateSelectedValues) {
            await updateSelectedValues();
        }

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        if (!this.validate()) {
            return;
        }

        this.setState({ isAdding: true });

        if (typeof addToCart === 'function') {
            addToCart().then(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch/addToCart/then */
                () => {
                    this.afterAddToCart();
                    this.setState({ isAdding: false });
                }
            ).catch(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch */
                () => this.setState({ isAdding: false, isLoading: false })
            );
        } else {
            const {
                quantity,
                cartId,
                fallbackAddToCart
            } = this.props;

            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            fallbackAddToCart({
                products: magentoProduct,
                cartId
            }).then(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch/fallbackAddToCart/then */
                () => {
                    this.afterAddToCart();
                    this.setState({ isAdding: false });
                }
            ).catch(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch */
                () => this.setState({ isAdding: false, isLoading: false })
            );
        }
    }

    handleButtonClick(e) {
        const { withLink } = this.props;

        // Prevent container Link from triggering redirect
        if (!withLink) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.setState({ isLoading: true }, () => this.addProductToCart());
    }

    afterAddToCart() {
        const {
            setQuantityToDefault,
            showPopup,
            showOverlay,
            quantity
        } = this.props;

        setQuantityToDefault();

        this.setState({ isLoading: false });

        if (window.matchMedia('(min-width: 1180px)').matches) {
            showOverlay(CART_OVERLAY);
        } else if (window.matchMedia('(min-width: 1025px)').matches) {
            showOverlay(CART_OVERLAY, {
                isAfterAddToCart: true
            });
        } else {
            this.setState({ shouldShowAddToCartPopup: true }, () => {
                showPopup(__('%s product has been added to cart', quantity));
                showOverlay(ADD_TO_CART_POPUP);
            });
        }
    }

    render() {
        return (
            <AddToCartComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
