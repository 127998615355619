/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import AddToCartPopup from 'Component/AddToCartPopup';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Scandipwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        ...super.propTypes,
        shouldShowAddToCartPopup: PropTypes.bool.isRequired
    };

    renderPlaceholder() {
        const { isLoading, mix } = this.props;

        return (
            <div
              block="AddToCart"
              mods={ { isLoading, isPlaceholder: true } }
              mix={ mix }
            />
        );
    }

    render() {
        const {
            mix,
            product,
            isLoading,
            shouldShowAddToCartPopup,
            quantity,
            configurableVariantIndex,
            areDetailsLoaded,
            salable_qty,
            isDisabled,
            isAdding,
            handleButtonClick
        } = this.props;

        if (!areDetailsLoaded) {
            return this.renderPlaceholder();
        }

        const addedProduct = {
            product,
            quantity,
            configurableVariantIndex
        };

        return (
            <>
                <button
                  onClick={ handleButtonClick }
                  block="Button AddToCart"
                  mix={ mix }
                  mods={ { isLoading } }
                  disabled={ isDisabled || isAdding || salable_qty <= 0 }
                >
                    <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span>
                </button>
                { shouldShowAddToCartPopup ? <AddToCartPopup addedProduct={ addedProduct } /> : null }
            </>
        );
    }
}

export default AddToCartComponent;
