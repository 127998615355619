/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/forbid-prop-types */
/**
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @author Karlis Ruza <info@scandiweb.com
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { HistoryType, LocationType } from 'Type/Router.type';

import { HtmlComponent } from './Html.component';

/** @namespace Scandipwa/Component/Html/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    basePath: state.ConfigReducer.base_link_url,
    locale: state.ConfigReducer.code,
    defaultCountry: state.ConfigReducer.default_country,
    wppNumber: state.ConfigReducer.wpp_number,
    timeFrom: state.ConfigReducer.time_from,
    timeTo: state.ConfigReducer.time_to,
    workDays: state.ConfigReducer.work_days,
    wppTextMessage: state.ConfigReducer.wpp_text_message,
    isSubscriptionBlockActive: state.ConfigReducer.isSubscriptionBlockActive,
    timezone: state.ConfigReducer.timezone
});

/** @namespace Scandipwa/Component/Html/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/Html/Container */
export class HtmlContainer extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
        customComponents: PropTypes.object,
        history: HistoryType.isRequired,
        location: LocationType.isRequired,
        basePath: PropTypes.string,
        locale: PropTypes.string,
        defaultCountry: PropTypes.string,
        workDays: PropTypes.string,
        timeFrom: PropTypes.string,
        timeTo: PropTypes.string,
        wppNumber: PropTypes.string,
        wppTextMessage: PropTypes.string,
        isSubscriptionBlockActive: PropTypes.bool.isRequired
    };

    static defaultProps = {
        defaultCountry: '',
        locale: '',
        basePath: '',
        customComponents: {},
        workDays: '',
        timeFrom: '',
        timeTo: '',
        wppNumber: '',
        wppTextMessage: ''
    };

    render() {
        return (
            <HtmlComponent { ...this.props } />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HtmlContainer));
