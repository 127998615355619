/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { DEFAULT_MAX_PRODUCTS } from 'Component/ProductActions/ProductActions.config';
import {
    CartItemContainer as SourceCartItemContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CartItem/CartItem.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { objectToUri } from 'Util/Url';

import CartItemComponent from './CartItem.component';

/** @namespace Scandipwa/Component/CartItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cloudinaryStatus: state.ConfigReducer.isCloudinaryCDNActive,
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandipwa/Component/CartItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/CartItem/Container */
export class CartItemContainer extends SourceCartItemContainer {
    static propTypes = {
        ...SourceCartItemContainer.propTypes,
        cloudinaryStatus: PropTypes.bool.isRequired,
        showNotification: PropTypes.func.isRequired,
        isOutdated: PropTypes.bool
    };

    static defaultProps = {
        ...SourceCartItemContainer.defaultProps,
        isOutdated: false
    };

    state = { isLoading: false, isMounted: false };

    setStateNotLoading = this.setStateNotLoading.bind(this);

    setStateNotLoading() {
        this.setState({ isLoading: false, isMounted: true });
    }

    _getProductLinkTo() {
        const {
            item: {
                product,
                product: {
                    type_id,
                    configurable_options,
                    parent,
                    variants = [],
                    url
                }
            }
        } = this.props;

        if (type_id !== PRODUCT_TYPE.configurable) {
            return {
                pathname: url,
                state: { product }
            };
        }

        const variant = variants[this._getVariantIndex()];

        if (!variant) {
            return null;
        }

        const { attributes } = variant;

        const parameters = Object.entries(attributes).reduce(
            (parameters, [code, { attribute_value }]) => {
                if (Object.keys(configurable_options).includes(code)) {
                    return { ...parameters, [code]: attribute_value };
                }

                return parameters;
            }, {}
        );

        return {
            pathname: url,
            state: { product: parent || product },
            search: objectToUri(parameters)
        };
    }

    _getProductThumbnail() {
        const product = this.getCurrentProduct();
        const { thumbnail = null } = product;

        if (thumbnail && Object.keys(thumbnail).length) {
            const { url = null } = thumbnail;

            if (url) {
                return url;
            }
        }

        return '';
    }

    getMaxQuantity() {
        const { salable_qty } = this.getCurrentProduct() || {};

        return salable_qty < DEFAULT_MAX_PRODUCTS ? salable_qty : DEFAULT_MAX_PRODUCTS - 1;
    }

    render() {
        return (
            <CartItemComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
