/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';

// eslint-disable-next-line max-len
export const OrderTracking = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "order-tracking" */ '../component/OrderTracking'));

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const addOrderTrackingComponent = (member) => {
    const maxPosition = Math.max(
        // eslint-disable-next-line no-magic-numbers
        member.map((route) => {
            route.position;
        }).filter((num) => num <= 1000)
    );

    return [
        ...member,
        {
            component: <Route
              path={ withStoreRegex('/shipping/tracking/popup') }
              render={ (props) => <OrderTracking { ...props } /> }
            />,
            position: maxPosition + 10
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addOrderTrackingComponent
        }
    }
};
