/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { HEADER_AND_FOOTER_STATE, MANAGE_FOOTER_BOTTOM_SPACE } from './HeaderAndFooter.action';

export const initialState = {
    isHeaderAndFooterHidden: false,
    isFooterBootomSpaceNeeded: false
};

/** @namespace Scandipwa/Store/HeaderAndFooter/Reducer/HeaderAndFooterReducer */
export const HeaderAndFooterReducer = (state = initialState, action) => {
    const { type, isHeaderAndFooterHidden = false, isFooterBootomSpaceNeeded } = action;

    switch (type) {
    case HEADER_AND_FOOTER_STATE:
        return { ...state, isHeaderAndFooterHidden };
    case MANAGE_FOOTER_BOTTOM_SPACE:
        return { ...state, isFooterBootomSpaceNeeded };
    default:
        return state;
    }
};

export default HeaderAndFooterReducer;
