/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Link from 'Component/Link';
import { WISHLIST_ERROR } from 'Component/Notification/Notification.config';
import SourceNotification from 'SourceComponent/Notification/Notification.component';

import './Notification.extended.style';

/**
 * Notification
 * @class Notification
 * @namespace Scandipwa/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;
        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        // Custom condition to use links in the msg, not just plain text.
        if (msgType === WISHLIST_ERROR) {
            mods.type = 'error';

            return (
                <div block="Notification" mods={ mods } ref={ this.notification }>
                    <p block="Notification" elem="Text">
                    { __('Please ') }
                    <Link
                      block="QuickLink"
                      mix={ { block: 'Link', elem: 'Animated', mods: { bold_white: true } } }
                      to="/customer/account/login/"
                    >
                        { __('login or register') }
                    </Link>
                    { __(' to add items to your wishlist.') }
                    </p>
                    { this.renderDebug() }
                </div>
            );
        }

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
