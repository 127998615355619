/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import { NO_MATCH } from 'Component/Header/Header.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import NoMatchComponent from './NoMatch.component';

/** @namespace Scandipwa/Route/NoMatch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Scandipwa/Route/NoMatch/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    urlRewrite: state.UrlRewritesReducer.urlRewrite
});

/** @namespace Scandipwa/Route/NoMatch/Container */
export class NoMatchContainer extends PureComponent {
    static propTypes = {
        changeHeaderState: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        urlRewrite: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.updateHeaderState();
        this.updateMeta();
    }

    updateHeaderState() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: NO_MATCH,
            title: __('Page not found'),
            isHiddenOnMobile: true
        });
    }

    updateMeta() {
        const { updateMeta } = this.props;

        updateMeta({ title: __('Page not found') });
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ cleanUpTransition }) => (
                    <NoMatchComponent
                      { ...{ ...this.props, cleanUpTransition } }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer);
