/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getCartId } from 'Util/Cart';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import CheckoutComQuery from '../../query/CheckoutCom.query';
import CheckoutComGooglePayComponent from './CheckoutComGooglePay.component';
import {
    CHECKOUTCOM_GOOGLE_PAY,
    GOOGLE_PAY_ALLOWED_PAYMENT_METHODS,
    GOOGLE_PAY_SCRIPT_ID,
    GOOGLE_PAY_SCRIPT_SOURCE
} from './CheckoutComGooglePay.config';

/** @namespace Checkoutcom/Component/CheckoutComGooglePay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartTotals: state.CartReducer.cartTotals,
    currencyCode: state.CartReducer.cartTotals.quote_currency_code,
    countryCode: state.ConfigReducer.default_country
});

/** @namespace Checkoutcom/Component/CheckoutComGooglePay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Checkoutcom/Component/CheckoutComGooglePay/Container */
export class CheckoutComGooglePayContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        setOrderButtonVisibility: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        cartTotals: PropTypes.object.isRequired,
        currencyCode: PropTypes.string.isRequired,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        countryCode: PropTypes.string.isRequired
    };

    state = {
        isLoading: true,
        googlePayConfiguration: {},
        isGooglePayAvailable: false
    };

    paymentsClient = null;

    componentDidMount() {
        const { setOrderButtonVisibility } = this.props;

        setOrderButtonVisibility(false);
        this.initialize();

        document.addEventListener(
            'triggerGooglePayPopup',
            this.onGooglePayButtonClick
        );
    }

    componentWillUnmount() {
        const { setOrderButtonVisibility } = this.props;

        setOrderButtonVisibility(true);
        this.removeScript();

        document.removeEventListener('triggerGooglePayPopup');
    }

    __construct() {
        super.__construct();

        this.onGooglePayButtonClick = this.onGooglePayButtonClick.bind(this);
    }

    initialize() {
        this.appendScript(
            this.requestConfig
        );
    }

    appendScript = (onLoadCallback) => {
        const script = document.createElement('script');
        script.src = GOOGLE_PAY_SCRIPT_SOURCE;
        script.async = true;
        script.id = GOOGLE_PAY_SCRIPT_ID;
        script.onload = onLoadCallback;
        document.head.appendChild(script);
    };

    removeScript = () => {
        const script = document.querySelector(`#${ GOOGLE_PAY_SCRIPT_ID }`);

        if (script) {
            script.remove();
        }
    };

    requestConfig = () => {
        const promise = executeGet(
            prepareQuery([CheckoutComQuery.getGooglePayConfigQuery()]),
            'CheckoutComGooglePayContainer',
            ONE_MONTH_IN_SECONDS
        );

        promise.then(
            this.handleConfigFetchSuccess,
            this.handleConfigFetchFailure
        );
    };

    getTokenizationParameters = () => {
        const {
            googlePayConfiguration: {
                public_key
            }
        } = this.state;

        return {
            tokenizationType: 'PAYMENT_GATEWAY',
            parameters: {
                gateway: 'checkoutltd',
                gatewayMerchantId: public_key
            }
        };
    };

    getAllowedPaymentMethods = () => {
        const {
            googlePayConfiguration: {
                supported_networks = ''
            }
        } = this.state;

        return [
            {
                type: 'CARD',
                parameters: {
                    allowedCardNetworks: supported_networks.split(','),
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS']
                },
                tokenizationSpecification: this.getTokenizationParameters()
            }
        ];
    };

    getGooglePaymentDataConfiguration = () => {
        const {
            cartTotals: {
                prices: {
                    grand_total: {
                        value: grand_total
                    },
                    quote_currency_code: currencyCode
                }
            },
            countryCode
        } = this.props;
        const {
            googlePayConfiguration: {
                merchant_id: merchantId,
                supported_networks
            }
        } = this.state;

        return {
            merchantInfo: {
                merchantId
            },
            allowedPaymentMethods: GOOGLE_PAY_ALLOWED_PAYMENT_METHODS,
            transactionInfo: {
                currencyCode,
                countryCode,
                totalPriceStatus: 'FINAL',
                totalPrice: String(grand_total)
            },
            callbackIntents: ['PAYMENT_AUTHORIZATION'],
            cardRequirements: {
                allowedCardNetworks: supported_networks.split(','),
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS']
            },
            paymentMethodTokenizationParameters: this.getTokenizationParameters()
        };
    };

    // eslint-disable-next-line no-unused-vars
    onOrderSuccess = (paymentData, orderData) => null;

    processPayment = async (paymentData) => {
        const {
            setDetailsStep,
            showErrorNotification,
            setLoading
        } = this.props;
        const cart_id = getCartId();

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id,
                payment_method: {
                    code: CHECKOUTCOM_GOOGLE_PAY,
                    additional_data: {
                        cardToken: {
                            signature: JSON.parse(paymentData.paymentMethodToken.token).signature,
                            protocolVersion: JSON.parse(paymentData.paymentMethodToken.token).protocolVersion,
                            signedMessage: JSON.parse(paymentData.paymentMethodToken.token).signedMessage
                        }
                    }
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
            const { placeOrder: { order: { order_id } } } = orderData;

            if (order_id) {
                this.onOrderSuccess(paymentData, orderData);
                setDetailsStep(order_id);
            }
        } catch (e) {
            setLoading(false);

            showErrorNotification(__('Something went wrong! Please, try again!'));
        }
    };

    triggerGooglePay() {
        const googlePaymentDataConfiguration = this.getGooglePaymentDataConfiguration();

        this.paymentsClient
            .loadPaymentData(
                googlePaymentDataConfiguration
            )
            .then(
                this.processPayment,
                this.handleFailure.bind(this)
            );
    }

    handleFailure(err) {
        const {
            showErrorNotification,
            setLoading
        } = this.props;
        const { statusCode } = err;
        let { message } = err;

        if (!message) {
            switch (statusCode) {
            case 'CANCELED':
                message = __('Payment has been cancelled.');
                break;
            default:
                message = __('Something went wrong! Please, try again!');
                break;
            }
        }

        showErrorNotification(message);
        setLoading(false);
    }

    onGooglePayButtonClick() {
        const { setLoading } = this.props;

        setLoading(true);

        this.triggerGooglePay();
    }

    handleConfigFetchSuccess = (data) => {
        const {
            storeConfig: {
                checkout_com: {
                    google_pay: {
                        merchant_id: merchantId,
                        environment
                    },
                    google_pay
                }
            }
        } = data;

        this.setState({
            googlePayConfiguration: google_pay
        });

        const { google: { payments: { api: { PaymentsClient } } } } = window;

        this.paymentsClient = new PaymentsClient({
            environment,
            merchantInfo: {
                merchantId,
                merchantName: 'Puma'
            },
            paymentDataCallbacks: {
                onPaymentAuthorized: () => null
            }
        });

        this.paymentsClient
            .isReadyToPay(
                {
                    allowedPaymentMethods: GOOGLE_PAY_ALLOWED_PAYMENT_METHODS
                }
            )
            .then(
                /** @namespace Checkoutcom/Component/CheckoutComGooglePay/Container/CheckoutComGooglePayContainer/then/finally/isReadyToPay/then */
                () => {
                    this.setState({
                        isGooglePayAvailable: true
                    });
                },
                this.handleFailure.bind(this)
            )
            .finally(
                /** @namespace Checkoutcom/Component/CheckoutComGooglePay/Container/CheckoutComGooglePayContainer/then/finally */
                () => {
                    this.setState({
                        isLoading: false
                    });
                }
            );
    };

    handleConfigFetchFailure = (err) => {
        const { showErrorNotification } = this.props;

        showErrorNotification(JSON.stringify(err));

        this.setState({
            isLoading: false
        });
    };

    containerProps = () => {
        const {
            isLoading,
            googlePayConfiguration,
            isGooglePayAvailable
        } = this.state;

        return {
            isLoading,
            googlePayConfiguration,
            isGooglePayAvailable
        };
    };

    render() {
        return (
            <CheckoutComGooglePayComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComGooglePayContainer);
