/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import { cloneElement } from 'react';

/** @namespace ScandiPWA/CheckoutComGraphQl/Plugin/Component */
export class CheckoutBillingComponent {
    /**
     * Render payment methods
     * @param args
     * @param callback
     * @param instance
     * @returns {*}
     */
    aroundRenderPayments = (args, callback, instance) => {
        const {
            setOrderButtonEnableStatus,
            props: { paymentMethods, savePaymentInformation }
        } = instance;

        if (!paymentMethods.length) {
            return null;
        }

        const original = callback.apply(instance, args);

        return cloneElement(
            original,
            { setOrderButtonEnableStatus, savePaymentInformation },
            original.props.children
        );
    };
}

export const { aroundRenderPayments } = new CheckoutBillingComponent();

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments: [
                {
                    position: 100,
                    implementation: aroundRenderPayments
                }
            ]
        }
    }
};
