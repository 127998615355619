/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/** @namespace Gtm/Component/GoogleTagManager/Events/PageDataEvent/Handlers/EventPageDataEvent/Handler */
export class EventPageDataEventHandler {
    /**
     * Returns event object data for pageData object
     *
     * @param data
     * @returns {{widget: *, origin: string, name: *, type: *, error: {}}}
     */
    getEventsData(data) {
        const { eventData = [] } = data;

        if (!eventData.length) {
            return [];
        }

        const dataLayerEvents = [];

        eventData.forEach((event) => {
            dataLayerEvents.push(this.getEventData(event));
        });

        return dataLayerEvents;
    }

    getEventData(event) {
        const {
            name = '',
            eventType = '',
            widget = '',
            origin = '',
            error = ''
        } = event;

        return {
            name,
            type: eventType,
            widget,
            origin,
            error
        };
    }
}

export default EventPageDataEventHandler;
