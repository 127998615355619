/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { isArabic } from 'Util/Arabic';

import './ProductPrice.style';

/** @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    static propTypes = {
        ...super.propTypes,
        roundedRegularPrice: PropTypes.string,
        roundedFinalPrice: PropTypes.string,
        currency: PropTypes.string,
        // eslint-disable-next-line react/boolean-prop-naming
        disablePercentageLabel: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        roundedRegularPrice: '0',
        roundedFinalPrice: '0',
        currency: '$',
        disablePercentageLabel: false
    };

    getCurrentPriceSchema() {
        const { isSchemaRequired, variantsCount } = this.props;

        if (variantsCount >= 1) {
            return isSchemaRequired ? { itemProp: 'Saleprice' } : {};
        }

        return isSchemaRequired ? { itemProp: 'price' } : {};
    }

    renderHighPriceSchema() {
        const {
            isSchemaRequired,
            variantsCount,
            roundedRegularPrice
        } = this.props;

        if (isSchemaRequired && variantsCount >= 1) {
            return (
                <meta
                  itemProp="highPrice"
                  content={ roundedRegularPrice }
                />
            );
        }

        return null;
    }

    /**
     * Renders old price
     *
     * @returns {JSX.Element}
     */
    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            currency
        } = this.props;

        if (isArabic()) {
            return (
                <span
                  block="ProductPrice"
                  elem="HighPrice"
                  mods={ { isVisible: discountPercentage > 0 } }
                  aria-label={ __('Old product price') }
                >
                    { this.renderHighPriceSchema() }
                    { roundedRegularPrice }
                    <span>{ currency }</span>
                </span>
            );
        }

        return (
            <span
              block="ProductPrice"
              elem="HighPrice"
              mods={ { isVisible: discountPercentage > 0 } }
              aria-label={ __('Old product price') }
            >
                { this.renderHighPriceSchema() }
                <span>{ currency }</span>
                { roundedRegularPrice }
            </span>
        );
    }

    /**
     * Render current price
     *
     * @returns {JSX.Element}
     */
    renderCurrentPrice() {
        const {
            discountPercentage,
            roundedFinalPrice,
            currency
        } = this.props;

        const schema = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if (isArabic()) {
            return (
                <PriceSemanticElementName
                  block="ProductPrice"
                  elem="CurrentPrice"
                >
                    <data
                      value={ roundedFinalPrice }
                    >
                        <span { ...schema }>{ roundedFinalPrice }</span>
                        <span>{ currency }</span>
                    </data>
                </PriceSemanticElementName>
            );
        }

        return (
            <PriceSemanticElementName
              block="ProductPrice"
              elem="CurrentPrice"
            >
                <data
                  value={ roundedFinalPrice }
                >
                    <span>{ currency }</span>
                    <span { ...schema }>{ roundedFinalPrice }</span>
                </data>
            </PriceSemanticElementName>
        );
    }

    renderPercentageDiscountLabel() {
        const {
            discountPercentage,
            disablePercentageLabel
        } = this.props;

        if (!discountPercentage || disablePercentageLabel) {
            return null;
        }

        if (isArabic()) {
            return (
                <span block="ProductPrice" elem="PercentageLabel">
                { __('OFF') }
                { ' ' }
                { Math.round(discountPercentage) }
                %
                </span>
            );
        }

        return (
            <span block="ProductPrice" elem="PercentageLabel">
            { Math.round(discountPercentage) }
            %
            { ' ' }
            { __('OFF') }
            </span>
        );
    }

    renderSchema() {
        const { isSchemaRequired, priceCurrency } = this.props;

        if (isSchemaRequired) {
            return (
                <meta itemProp="priceCurrency" content={ priceCurrency } />
            );
        }

        return null;
    }

    render() {
        const { price } = this.props;

        if (!price) {
            return null;
        }

        const {
            roundedFinalPrice,
            currency,
            mix
        } = this.props;

        if (!roundedFinalPrice) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mix={ mix }
              aria-label={ `Product price: ${ roundedFinalPrice } ${ currency }` }
            >
                { this.renderCurrentPrice() }
                { this.renderOldPrice() }
                <br />
                { this.renderPercentageDiscountLabel() }
                { this.renderSchema() }
            </p>
        );
    }
}

export default ProductPriceComponent;
