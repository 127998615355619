/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CheckoutPayment from 'Component/CheckoutPayment';

import { AdyenPaymentsContext } from '../../context/AdyenPayments';
import { ADYEN_CARD_PAYMENT_TYPE, ADYEN_CC_METHOD_CODE, ADYEN_GC_METHOD_CODE } from '../../util/Adyen';
import AdyenPayment from './AdyenPayment.component';

/** @namespace Scandiweb/AdyenPayments/Component/AdyenPayment/Container */
export class AdyenPaymentContainer extends CheckoutPayment {
    static contextType = AdyenPaymentsContext;

    containerFunctions = {
        handlePaymentClick: this.handlePaymentClick.bind(this)
    };

    containerProps() {
        const {
            name,
            type,
            method,
            onClick,
            isSelected
        } = this.props;

        const {
            adyenPaymentMethods
        } = this.context;

        return {
            name,
            type,
            method,
            onClick,
            isSelected,
            adyenPaymentMethods,
            icon: this.getAdyenPaymentIcon()
        };
    }

    getAdyenPaymentIcon() {
        const {
            type,
            method: {
                code
            }
        } = this.props;
        const {
            adyenPaymentMethods: {
                paymentMethodsExtraDetails = {}
            } = {}
        } = this.context;

        const isCCMethod = code === ADYEN_CC_METHOD_CODE;
        const paymentType = isCCMethod ? ADYEN_CARD_PAYMENT_TYPE : type;

        switch (paymentType) {
        case ADYEN_CARD_PAYMENT_TYPE:
            return paymentMethodsExtraDetails?.card?.icon?.url;
        case ADYEN_GC_METHOD_CODE:
            return paymentMethodsExtraDetails?.givex?.icon?.url;
        default:
            return paymentMethodsExtraDetails[paymentType]?.icon?.url;
        }
    }

    handlePaymentClick() {
        const {
            onClick,
            method,
            type
        } = this.props;

        if (!type) {
            onClick(method);
            return;
        }

        onClick(type);
    }

    render() {
        return (
            <AdyenPayment
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AdyenPaymentContainer;
