/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

export const SET_ANALYTICS_DATA = 'SET_ANALYTICS_DATA';

/** @namespace Scandipwa/Store/Analytics/Action/setPromosData */
export const setPromosData = (promos) => ({
    type: SET_ANALYTICS_DATA,
    promos
});

export const SET_LAST_ORDER_STATUS = 'SET_LAST_ORDER_STATUS';

/** @namespace Scandipwa/Store/Analytics/Action/setLastOrderStatus */
export const setLastOrderStatus = (orderStatus) => ({
    type: SET_LAST_ORDER_STATUS,
    orderStatus
});
