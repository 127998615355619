/* eslint-disable no-magic-numbers */
/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { PLP_DATA_OBJECT }
from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/ProductsPageDataEvent.handler';
import { PLP_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, {
    EVENT_GTM_ORDER_CONFIRMATION,
    EVENT_GTM_PAGE_DATA,
    EVENT_GTM_PURCHASE,
    EVENT_GTM_PURCHASE_GA4
} from '../../util/Event';

export const PAYMENT_METHOD = 'payment_method';

export const setDetailsStep = (args, callback, instance) => {
    const [orderID] = args;

    const {
        totals,
        totals: { items = [] }
    } = instance.props;
    const { cash_on_delivery_fee = 0, email } = instance.state;
    const payment_type = BrowserDatabase.getItem(PAYMENT_METHOD) || '';
    const { shippingFields: { firstname } } = instance.props;
    const cashOnDeliveryFee = cash_on_delivery_fee || 0; // in case its value is null

    Event.dispatch(
        EVENT_GTM_PURCHASE_GA4,
        {
            orderID,
            totals: {
                ...totals, items, payment_type, cashOnDeliveryFee, email, firstname
            }
        }
    );

    Event.dispatch(
        EVENT_GTM_PURCHASE,
        {
            orderID,
            totals: {
                ...totals, items, payment_type, cashOnDeliveryFee
            }
        }
    );

    Event.dispatch(
        EVENT_GTM_ORDER_CONFIRMATION,
        { orderID, totals }
    );

    Event.dispatch(
        EVENT_GTM_PAGE_DATA,
        {
            type: PLP_TYPE,
            items
        }
    );

    return callback(...args);
};

export const componentWillUnmount = (args, callback) => {
    if (window.pageData) {
        // eslint-disable-next-line fp/no-delete
        delete window.pageData[PLP_DATA_OBJECT];
    }

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            setDetailsStep,
            componentWillUnmount
        }
    }
};
