/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { updateEventData } from 'Store/Event/Event.action'; // Note: Event store was not created by plugin due to a bug

import Event, { EVENT_GTM_ADD_TO_CART_GA4, EVENT_GTM_PRODUCT_ADD_TO_CART } from '../../util/Event';
import { handlePageDataEventPromiseError } from '../../util/Events/PageData/Event';

export const aroundAfterAddToCart = (args, callback, instance) => {
    const {
        product,
        product: { type_id, variants },
        quantity,
        configurableVariantIndex,
        groupedProductQuantity
    } = instance.props;

    if (type_id === 'grouped') {
        Event.dispatch(EVENT_GTM_ADD_TO_CART_GA4, {
            product: {
                ...product,
                quantities: groupedProductQuantity
            },
            isGrouped: true
        });

        Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: {
                ...product,
                quantities: groupedProductQuantity
            },
            isGrouped: true
        });
    } else {
        const productToAdd = variants
            ? { ...product, configurableVariantIndex }
            : product;

        Event.dispatch(EVENT_GTM_ADD_TO_CART_GA4, {
            product: productToAdd,
            quantity,
            configurableVariantIndex
        });

        Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: productToAdd,
            quantity,
            configurableVariantIndex
        });
    }

    return callback(...args);
};

export const addProductToCart = (args, callback) => callback(...args)
    .then((result) => {
        const [dispatch] = args;
        const additionalEventData = { name: 'product added' };

        dispatch(updateEventData({ ..._getEventData(), ...additionalEventData }));

        return result;
    });

export const _getEventData = () => ({
    eventType: 'product details',
    widget: 'page'
});

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            'afterAddToCart': aroundAfterAddToCart
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            'addProductToCart': addProductToCart
        }
    },
    'Store/Cart/Dispatcher/addProductToCartFetchMutationCatch': {
        'function': (args, callback, context) => handlePageDataEventPromiseError(
            args, callback, context, _getEventData(), updateEventData
        )
    }
};
