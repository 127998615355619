/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import { Field } from 'SourceUtil/Query';

/** @namespace Checkoutcom/Query/CheckoutCom/Query */
export class CheckoutComQuery {
    /**
     * Get card config query
     * @return {Field}
     */
    getCardConfigQuery() {
        return new Field('storeConfig')
            .addField(
                new Field('checkout_com').addFieldList([this._getCardPaymentConfigQuery()])
            );
    }

    /**
     * Get google pay config query
     * @return {Field}
     */
    getGooglePayConfigQuery() {
        return new Field('storeConfig')
            .addField(
                new Field('checkout_com').addFieldList([this._getGooglePayPaymentConfigQuery()])
            );
    }

    /**
     * Get google pay payment method config query
     * @return {Field}
     * @private
     */
    _getGooglePayPaymentConfigQuery() {
        return new Field('google_pay')
            .addFieldList([
                'is_enabled',
                'merchant_id',
                'button_style',
                'supported_networks',
                'environment',
                'public_key'
            ]);
    }

    /**
     * Get apple pay config query
     * @return {Field}
     */
    getApplePayConfigQuery() {
        return new Field('storeConfig')
            .addField(
                new Field('checkout_com').addFieldList([this._getApplePayPaymentConfigQuery()])
            );
    }

    /**
     * Get apple pay express shipping method query
     * @return {Field}
     */
    getApplePayExpressShippingQuery = (guestCartId) => new Field('getApplePayExpressShipping')
        .addArgument('guestCartId', 'String', guestCartId)
        .addFieldList(['label', 'price', 'id']);

    /**
     * Get card payment method config query
     * @return {Field}
     * @private
     */
    _getCardPaymentConfigQuery() {
        return new Field('card')
            .addFieldList([
                'public_key',
                'style',
                'debug',
                'language_fallback'
            ]);
    }

    /**
     * Get apple pay payment method config query
     * @return {Field}
     * @private
     */
    _getApplePayPaymentConfigQuery() {
        return new Field('apple_pay')
            .addFieldList([
                'merchant_id',
                'button_style',
                'supported_networks',
                'merchant_capabilities'
            ]);
    }

    /**
     * Get 3ds verification query
     * @param sessionId
     * @returns {*}
     */
    getVerifyCheckoutCom3dSecureMutation(sessionId) {
        return new Field('verifyCheckoutCom')
            .addArgument('session_id', 'String!', sessionId)
            .addFieldList(['order_number', 'user_email', 'error']);
    }

    /**
     * Get 3ds failure query
     * @param sessionId
     * @returns {Field}
     */
    getFailCheckoutCom3dSecureMutation(sessionId) {
        return new Field('failCheckoutCom')
            .addArgument('session_id', 'String!', sessionId);
    }

    /**
     * Get apple pay verification query
     * @param validationUrl
     * @returns {*}
     */
    getVerifyCheckoutComApplePayQuery(validationUrl) {
        return new Field('verifyCheckoutComApplePay')
            .addArgument('validation_url', 'String!', validationUrl)
            .addFieldList(this._getApplePayPaymentStatusMessageFields());
    }

    /**
     * Get apple pay status fields
     * @returns {string[]}
     * @private
     */
    _getApplePayPaymentStatusMessageFields() {
        return [
            'nonce',
            'operationalAnalyticsIdentifier',
            'displayName',
            'domainName',
            'merchantIdentifier',
            'epochTimestamp',
            'expiresAt',
            'merchantSessionIdentifier',
            'signature',
            'statusMessage',
            'status'
        ];
    }
}

export default new CheckoutComQuery();
