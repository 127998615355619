/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AdyenPayments/Query/AdyenPaymentStatus/Query */
export class AdyenPaymentStatusQuery {
    getAdyenPaymentStatus(orderNumber, cartId) {
        return new Field('adyenPaymentStatus')
            .addArgument('orderNumber', 'String', orderNumber)
            .addArgument('cartId', 'String!', cartId)
            .addFieldList(this.getAdyenPaymentStatusFields());
    }

    getAdyenPaymentStatusFields() {
        return [
            'action',
            'isFinal',
            'resultCode',
            'additionalData'
        ];
    }
}

export default new AdyenPaymentStatusQuery();
