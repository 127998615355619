/** @namespace Scandipwa/Util/ImageCDN/formatImageUrl */
// eslint-disable-next-line import/prefer-default-export
export const formatImageUrl = (cloudinaryStatus, url, size = '2000') => {
    const sizeString = size ? `,w_${size},h_${size}` : '';

    const srcForPISA = `${url}w/${size}/h/${size}/`;
    const srcForCloudinary = url.replace(
        '.net/images',
        `.com/image/upload/f_auto,q_auto,b_rgb:fafafa${sizeString}/global`
    );

    return cloudinaryStatus ? `${srcForCloudinary }fmt/png/` : srcForPISA;
};

/** @namespace Scandipwa/Util/ImageCDN/formatImageSetUrl */
export const formatImageSetUrl = (cloudinaryStatus, url, sizes = ['157', '220', '480', '706']) => {
    const srcSetForPISA = sizes.reduce((acc, value) => {
        acc.push(`${url}w/${value}/h/${value}/ ${value}w`);

        return acc;
    }, []).join(',');

    const srcSetForCloudinary = sizes.reduce((acc, value) => {
        acc.push(
            // eslint-disable-next-line no-template-curly-in-string,max-len
            `${url.replace('.net/images', `.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_${value},h_${value}/global`) } ${value}w`
        );

        return acc;
    }, []).join(',');

    return cloudinaryStatus ? srcSetForCloudinary : srcSetForPISA;
};
