/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_AddToCartPopup
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { TotalsType } from 'Type/MiniCart.type';

import AddToCartPopupComponent from './AddToCartPopup.component';

/** @namespace Scandipwa/Component/AddToCartPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandipwa/Component/AddToCartPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/AddToCartPopup/Container */
export class AddToCartPopupContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        addedProduct: PropTypes.object.isRequired
    };

    containerFunctions = {
        getAddedProductItem: this.getAddedProductItem.bind(this),
        onContinueShoppingClick: this.onContinueShoppingClick.bind(this)
    };

    getAddedProductItem() {
        const {
            addedProduct: {
                product,
                product: { variants },
                configurableVariantIndex
            },
            totals: { items }
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { sku: addedProductSku } = productOrVariant;

        return items.find((item) => {
            const { sku } = item;
            return sku === addedProductSku;
        });
    }

    onContinueShoppingClick() {
        const { hideActiveOverlay } = this.props;
        hideActiveOverlay();
    }

    render() {
        return (
            <AddToCartPopupComponent
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps)(AddToCartPopupContainer);
