/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const HEADER_AND_FOOTER_STATE = 'HEADER_AND_FOOTER_STATE';
export const MANAGE_FOOTER_BOTTOM_SPACE = 'MANAGE_FOOTER_BOTTOM_SPACE';

/** @namespace Scandipwa/Store/HeaderAndFooter/Action/setHeaderAndFooterState */
export const setHeaderAndFooterState = (isHeaderAndFooterHidden) => ({
    type: HEADER_AND_FOOTER_STATE,
    isHeaderAndFooterHidden
});

/** @namespace Scandipwa/Store/HeaderAndFooter/Action/setIsFooterBootomSpaceNeeded */
export const setIsFooterBootomSpaceNeeded = (isFooterBootomSpaceNeeded) => ({
    type: MANAGE_FOOTER_BOTTOM_SPACE,
    isFooterBootomSpaceNeeded
});
