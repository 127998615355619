/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails, updateCustomerSignInStatus } from 'SourceStore/MyAccount/MyAccount.action';
import {
    MyAccountDispatcher as SourceMyAccountDispatcher
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { updateIsLocked } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import {
    deleteAuthorizationToken,
    getAuthorizationToken,
    GRAPHQL_AUTH,
    isSignedIn
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "accountDispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export const CUSTOMER = 'customer';

export const ONE_MONTH_IN_SECONDS = 2628000;

/**
 * My account actions
 * @class MyAccount
 * @namespace Scandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                const customerData = this.mergePrevAndCurrentCustomerData(customer);
                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customerData));
                BrowserDatabase.setItem(customerData, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
            (error) => {
                const { extensions: { category } } = error[0];

                if (category === GRAPHQL_AUTH) {
                    dispatch(updateIsLocked(true));
                }
                dispatch(showNotification('error', getErrorMessage(error)));

                if (window.location.href.includes('/customer/account')) {
                    window.location.reload();
                } else {
                    window.location.href = appendWithStoreCode('/customer/account');
                }
            }
        );
    }

    mergePrevAndCurrentCustomerData(customer) {
        const prevCustomerData = BrowserDatabase.getItem(CUSTOMER);
        const selectedAddressObject = {
            selectedAddressId: prevCustomerData?.selectedAddressId,
            selectedBillingId: prevCustomerData?.selectedBillingId
        };

        if (prevCustomerData && prevCustomerData.addresses && prevCustomerData.addresses.length > 0) {
            const tmpAddress = prevCustomerData.addresses.find((item) => item.id === -1);

            if (tmpAddress && !customer.addresses.find((item) => item.id === -1)) {
                return {
                    ...customer,
                    ...selectedAddressObject,
                    addresses: [...customer.addresses, tmpAddress],
                    newAddressId: -1
                };
            }

            return {
                ...customer,
                ...selectedAddressObject,
                newAddressId: prevCustomerData.newAddressId
            };
        }

        return { ...customer, ...selectedAddressObject };
    }

    logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        if (authTokenExpired) {
            if (isWithNotification) {
                dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            }

            this.handleForceRedirectToLoginPage();
        } else {
            if (isSignedIn()) {
                fetchMutation(MyAccountQuery.getRevokeAccountToken());
                deleteAuthorizationToken();
            }

            if (isWithNotification) {
                dispatch(showNotification('success', __('You are successfully logged out!')));
            }
        }

        deleteCartId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
    }
}

export default new MyAccountDispatcher();
