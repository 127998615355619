/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    fetchMutation,
    fetchQuery
} from 'Util/Request';
import getStore from 'Util/Store';

import AdyenPaymentStatusQuery from '../query/AdyenPaymentStatus.query';
import SetPaymentMethodAndPlaceOrderMutation from '../query/SetPaymentMethodAndPlaceOrder.mutation';
import {
    ADYEN_APPLE_PAY_METHOD_CODE,
    ADYEN_CC_METHOD_CODE,
    ADYEN_GOOGLE_PAY_METHOD_CODE,
    ADYEN_HPP_METHOD_CODE
} from '../util/Adyen';
import { handleAdyenResult } from '../util/AdyenCCMethod';
import { validateActionOrPlaceOrder } from '../util/AdyenHppMethod';

const addAdyenCCToPaymentData = (args, callback) => {
    const result = callback(...args);
    const { code } = result;

    if (!window.adyenComponent || !code.includes('adyen')) {
        return result;
    }

    if (code === ADYEN_CC_METHOD_CODE) {
        if (!window.adyenComponent.isValid) {
            return result;
        }

        window.adyenComponent.submit();
    }

    const cartId = getStore().getState().CartReducer.cartTotals.id;
    const stateData = JSON.stringify(window.adyenStateData.data);

    if (code === ADYEN_CC_METHOD_CODE) {
        const additionalData = {
            cartId,
            stateData,
            cc_type: 'MC',
            guestEmail: '',
            combo_card_type: 'credit'
        };

        return {
            code,
            additional_data: additionalData
        };
    }

    if (
        code === ADYEN_HPP_METHOD_CODE
        || code === ADYEN_APPLE_PAY_METHOD_CODE
        || code === ADYEN_GOOGLE_PAY_METHOD_CODE
    ) {
        const additionalData = {
            cartId,
            stateData,
            guestEmail: '',
            brand_code: code
        };

        return {
            code,
            additional_data: additionalData
        };
    }

    return result;
};

const checkForAdyenHPPMethod = async (args, callback, instance) => {
    const { showErrorNotification } = instance.props;
    const [argsVals] = args;

    const { paymentMethod: { code } } = argsVals;
    const cartId = getStore().getState().CartReducer.cartTotals.id;

    if (code === ADYEN_CC_METHOD_CODE) {
        const stateData = JSON.stringify(window.adyenStateData.data);
        const additionalData = {
            cartId,
            stateData,
            guestEmail: ''
        };

        try {
            const {
                setPaymentMethodAndPlaceOrder: {
                    order: {
                        order_number: orderNumber
                    } = {}
                } = {}
            } = await fetchMutation(SetPaymentMethodAndPlaceOrderMutation.setPaymentMethodAndPlaceOrder({
                cart_id: cartId,
                payment_method: {
                    code,
                    adyen_additional_data_cc: additionalData
                }
            }));

            window.orderNumber = orderNumber;
            const {
                adyenPaymentStatus
            } = await fetchQuery(AdyenPaymentStatusQuery.getAdyenPaymentStatus(orderNumber, cartId));

            return handleAdyenResult(adyenPaymentStatus);
        } catch (e) {
            window.adyenComponent.unmount();
            window.adyenComponent.remount();
            console.log('error >>>', e);
            showErrorNotification('An error occurred, please try another payment method.');
            instance.setState({ isLoading: false });

            return null;
        }
    }

    if (
        code === ADYEN_HPP_METHOD_CODE
        || code === ADYEN_APPLE_PAY_METHOD_CODE
        || code === ADYEN_GOOGLE_PAY_METHOD_CODE
    ) {
        const stateData = JSON.stringify(window.adyenStateData.data);
        const additionalData = {
            cartId,
            stateData,
            guestEmail: '',
            brand_code: code
        };

        try {
            const {
                setPaymentMethodAndPlaceOrder: {
                    order: {
                        order_number: orderNumber
                    } = {}
                } = {}
            } = await fetchMutation(SetPaymentMethodAndPlaceOrderMutation.setPaymentMethodAndPlaceOrder({
                cart_id: cartId,
                payment_method: {
                    code,
                    adyen_additional_data: additionalData
                }
            }));

            window.orderNumber = orderNumber;
            const {
                adyenPaymentStatus
            } = await fetchQuery(AdyenPaymentStatusQuery.getAdyenPaymentStatus(orderNumber, cartId));

            if (window.setOrderButtonEnableStatus) {
                window.setOrderButtonEnableStatus(false);
            }

            return validateActionOrPlaceOrder(adyenPaymentStatus, orderNumber);
        } catch (e) {
            console.log('error >>>', e);
            showErrorNotification('An error occurred, please try another payment method.');
            instance.setState({ isLoading: false });

            return null;
        }
    }

    return callback(...args);
};

export default {
    'Scandipwa/Component/CheckoutShippingBilling/Container': {
        'member-function': {
            getPaymentData: addAdyenCCToPaymentData
        }
    },
    'Scandipwa/Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: checkForAdyenHPPMethod
        }
    }
};
