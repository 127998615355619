/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { NATIONAL_ID_LENGTH } from '../../../packages/@scandipwa/nationalidnumber/src/component/NationalIdNumber/NationalIdNumber.component';

export const MIN_PASSWORD_LENGTH = 8;

/** @namespace Scandipwa/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Scandipwa/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Scandipwa/Component/Form/Config/minLength */
export const minLength = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace Scandipwa/Component/Form/Config/validatePasswordStrength */
export const validatePasswordStrength = ({ value }) => value.match(/^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{8,}$/gm);

/** @namespace Scandipwa/Component/Form/Config/validateTelephone */
export const validateTelephone = (props) => {
    const {
        value,
        prefix = {},
        minLength,
        minLengthWithMask,
        dataset = {},
        newPrefix
    } = props;

    const phonePrefix = newPrefix || prefix || dataset.prefix;

    if (value === '') {
        return false;
    }

    const isZero = value.match(/\(([^)]+)\)/)[1].charAt(0) === '0';

    // Checking if number starts with 0, if yes then add extra 2 digits for minLength
    if (minLengthWithMask && isZero) {
        return +minLengthWithMask + 2 <= value.replace(/[()_-\s]/g, '').substr(phonePrefix.length).length;
    }

    if (minLengthWithMask) {
        return minLengthWithMask <= value.replace(/[()_-\s]/g, '').substr(phonePrefix.length).length;
    }

    return minLength <= value.replace(/[()_-\s]/g, '').substr(phonePrefix.length).length;
};

/** @namespace Scandipwa/Component/Form/Config/validatePostalCode */
export const validatePostalCode = (props) => {
    const {
        value,
        minLength
    } = props;

    return minLength <= value.replace(/[()_-\s]/g, '').length;
};

/** @namespace Scandipwa/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Scandipwa/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    if (typeof password === 'string') {
        return value === password;
    }

    const { current: { value: passwordValue } } = password || { current: {} };

    return value === passwordValue;
};

/** @namespace Scandipwa/Component/Form/Config/validateEmailMatch */
export const validateEmailMatch = ({ value }, { email }) => {
    const { current: { value: emailValue } } = email || { current: {} };
    return value === emailValue;
};

/** @namespace Scandipwa/Component/Form/Config/validationNationalId */
export const validationNationalId = ({ value }) => value.length === NATIONAL_ID_LENGTH;

/** @namespace Scandipwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Please enter a valid email address')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: minLength,
        message: __('Password should be at least 8 characters long')
    },
    passwordStrength: {
        validate: validatePasswordStrength,
        message: __('Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.')
    },
    telephone: {
        validate: validateTelephone,
        message: __('This field is required!')
    },
    postalcode: {
        validate: validatePostalCode,
        message: __('This field is required!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This is a required field!')
    },
    matchPassword: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    emailMatch: {
        validate: validateEmailMatch,
        message: __('Email does not match')
    },
    nationalId: {
        validate: validationNationalId,
        message: __('Please enter the exact 10 numbers from your national ID') // Variable can't be used as argument, ScandiPWA bug
    }
});

export default formConfig();
