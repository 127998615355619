/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { PaymentMethodType } from 'Type/Checkout.type';

import { AdyenPaymentsContext } from '../../context/AdyenPayments';
import AdyenAlternativePayments from './AdyenAlternativePayments.component';

/** @namespace Scandiweb/AdyenPayments/Component/AdyenAlternativePayments/Container */
export class AdyenAlternativePaymentsContainer extends PureComponent {
    static propTypes = {
        method: PaymentMethodType.isRequired,
        selectPaymentMethod: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired
    };

    static contextType = AdyenPaymentsContext;

    containerFunctions = {
        getIsSelected: this.getIsSelected.bind(this),
        handlePaymentTypeChange: this.handlePaymentTypeChange.bind(this)
    };

    getIsSelected(type) {
        const {
            isSelected
        } = this.props;
        const {
            selectedPaymentType
        } = this.context;

        return type === selectedPaymentType && isSelected;
    }

    handlePaymentTypeChange(type) {
        const { setSelectedPaymentType } = this.context;
        const {
            onClick,
            method
        } = this.props;

        onClick(method, type);
        setSelectedPaymentType(type);
    }

    containerProps() {
        const {
            method,
            method: {
                code
            },
            isSelected
        } = this.props;
        const {
            selectedPaymentType,
            adyenPaymentMethods: {
                paymentMethodsResponse: {
                    paymentMethods = []
                } = {}
            } = {}
        } = this.context;

        return {
            code,
            method,
            isSelected,
            paymentMethods,
            selectedPaymentType
        };
    }

    render() {
        return (
            <AdyenAlternativePayments
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AdyenAlternativePaymentsContainer;
