/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Beshoy Samuel <info@scandiweb.com>
 */

import { IS_CART_RESET } from 'Component/CheckoutSuccess/CheckoutSuccess.container';
import CheckoutQuery from 'Query/Checkout.query';
import { SHIPPING_ADDRESS_KEY } from 'Route/Checkout/Checkout.config';
import { fetchMutation } from 'SourceUtil/Request';
import { PAYMENT_TOTALS } from 'Store/Checkout/Checkout.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getCartId } from 'Util/Cart';

export const TABBY_PAYMENT_CODE = 'tabby';

export const TABBY_PAGEDATA_ORDER_STORAGE = 'TABBY_PAGEDATA_ORDER';

export const TABBY_PAYMENT_TOTALS_STORAGE = 'TABBY_PAYMENT_TOTALS';
export const IN_TABBY_GATEWAY = 'IN_TABBY_GATEWAY';

/** @namespace Puma/Tabby/Plugin/Container */
export class CheckoutContainer {
     /**
      * @param args
      * @param callback
      * @param instance
      * @returns {Promise<void>}
      */
     aroundSavePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
         const [paymentInformation] = args;
         const { paymentMethod: { code, additional_data } } = paymentInformation;
         const { shippingAddress } = instance.state;

         if (code.includes(TABBY_PAYMENT_CODE)) {
             const cart_id = getCartId();

             try {
                 await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                     cart_id,
                     payment_method: {
                         code, [code]: additional_data
                     }
                 }));

                 const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
                 const {
                     placeOrder: {
                         order: {
                             order_id,
                             tabby
                         }
                     }
                 } = orderData;

                 if (tabby) {
                     const { gateway_redirect } = tabby;

                     BrowserDatabase.setItem(0, IS_CART_RESET);

                     if (gateway_redirect) {
                         BrowserDatabase.setItem(1, IN_TABBY_GATEWAY);
                         BrowserDatabase.setItem(window.pageData.order, TABBY_PAGEDATA_ORDER_STORAGE);
                         BrowserDatabase.setItem(shippingAddress, SHIPPING_ADDRESS_KEY);
                         BrowserDatabase.setItem(
                             instance.props.totals,
                             TABBY_PAYMENT_TOTALS_STORAGE
                         );

                         window.location.href = gateway_redirect;

                         return;
                     }
                 }

                 BrowserDatabase.setItem(shippingAddress, SHIPPING_ADDRESS_KEY);
                 instance.setDetailsStep(order_id);
             } catch (e) {
                 instance._handleError(e);
             }
         } else {
             await callback.apply(instance, args);
         }
     };
}

export const { aroundSavePaymentMethodAndPlaceOrder } = new CheckoutContainer();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: [
                {
                    position: 120,
                    implementation: aroundSavePaymentMethodAndPlaceOrder
                }
            ]
        }
    }
};
