/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace,quote-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CHECKOUT_EVENT_DELAY } from '../../component/GoogleTagManager/events/Checkout.event';
import { FORM_DATA_OBJECT } from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/FormDataEvent.handler';
import { PLP_DATA_OBJECT }
    from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/ProductsPageDataEvent.handler';
import Event, { EVENT_GTM_VIEW_CART_GA4 } from '../../util/Event';

export const componentDidMount = (args, callback, instance) => {
    const { totals = {} } = instance.props;

    setTimeout(
        () => Event.dispatch(EVENT_GTM_VIEW_CART_GA4, { totals }),
        CHECKOUT_EVENT_DELAY
    );

    return callback(...args);
};

export const componentWillUnmount = (args, callback, instance) => {
    if (window.pageData) {
        delete window.pageData[FORM_DATA_OBJECT];
        delete window.pageData[PLP_DATA_OBJECT];
    }

    return callback(...args);
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            componentWillUnmount,
            componentDidMount
        }
    }
};
