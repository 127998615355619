/* eslint-disable no-unused-vars,@scandipwa/scandipwa-guidelines/use-namespace,quote-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { QUICKLINKS_LOGOUT_TIMEOUT } from 'Component/QuickLinks/QuickLinks.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { updateEventData } from 'Store/Event/Event.action'; // Note: Event store was not created by plugin due to a bug
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

import { CUSTOMER_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, {
    EVENT_GTM_GENERAL_GA4,
    EVENT_GTM_PAGE_DATA,
    EVENT_GTM_USER_LOGIN,
    EVENT_GTM_USER_REGISTER
} from '../../util/Event';
import { handlePageDataEventPromiseError } from '../../util/Events/PageData/Event';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapDispatchToProps = (args, callback, context) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        logout: (widget) => MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.logout(null, dispatch, widget)
        )
    };
};

export const createAccount = (args, callback, instance) => {
    const [options, dispatch] = args;
    const { customer: { email }, password } = options;
    const mutation = MyAccountQuery.getCreateAccountMutation(options);

    const additionalEventData = {
        eventType: 'account',
        name: 'new account created'
    };

    return fetchMutation(mutation).then(
        /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationThen */
        (data) => {
            const { createCustomer: { customer } } = data;
            const { confirmation_required } = customer;

            if (confirmation_required) {
                return 2;
            }

            dispatch(updateEventData({ ..._getEventData(), ...additionalEventData }));
            Event.dispatch(EVENT_GTM_USER_REGISTER);

            return instance.signIn({ email, password }, dispatch).then(
                () => true,
                () => false
            );
        },
        /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationError */
        (error) => {
            dispatch(showNotification('error', error[0].message));
            Promise.reject();

            return false;
        }
    );
};

export const signIn = async (args, callback, instance) => {
    const [, dispatch] = args;
    // eslint-disable-next-line fp/no-let
    let additionalEventData = { name: 'user logged in' };

    try {
        await callback(...args).then(() => {
            Event.dispatch(EVENT_GTM_USER_LOGIN);
            updatePageDataCustomerData();
        });
    } catch (error) {
        const { message } = error;

        additionalEventData = { name: 'error', error: message };

        throw error;
    } finally {
        dispatch(updateEventData({ ..._getEventData(), ...additionalEventData }));

        Event.dispatch(EVENT_GTM_GENERAL_GA4);
    }
};

export const logOut = (args, callback, instance) => {
    const [, dispatch1, dispatch2] = args;
    const dispatch = dispatch1 || dispatch2;

    dispatch(updateEventData({ ..._getEventData() }));
    updatePageDataCustomerData();

    return callback(...args);
};

export const handleLogout = (args, callback, instance) => {
    const { logout, setIsLoading } = instance.props;

    const additionalEventData = { widget: 'modal' };

    setIsLoading(true);

    setTimeout(() => {
        logout(additionalEventData);
        setIsLoading(false);
    }, QUICKLINKS_LOGOUT_TIMEOUT);

    instance.changeHeaderState();
};

export const updatePageDataCustomerData = () => {
    Event.dispatch(EVENT_GTM_PAGE_DATA, { type: CUSTOMER_TYPE });
};

export const _getEventData = () => ({
    eventType: 'login',
    name: 'user logged out',
    widget: 'page'
});

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            'createAccount': createAccount,
            'signIn': signIn,
            'logout': logOut
        }
    },
    'Store/MyAccount/Dispatcher/createAccountFetchMutationError': {
        'function': (args, callback, context) => handlePageDataEventPromiseError(
            args, callback, context, _getEventData(), updateEventData
        )
    },
    'Component/QuickLinks/Container': {
        'member-function': {
            'handleLogout': handleLogout
        }
    },
    'Component/QuickLinks/Container/mapDispatchToProps': {
        'function': mapDispatchToProps
    }
};
