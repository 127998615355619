/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const TEXT_TYPE = 'text';
export const NUMBER_TYPE = 'number';
export const RADIO_TYPE = 'radio';
export const CHECKBOX_TYPE = 'checkbox';
export const PASSWORD_TYPE = 'password';
export const SELECT_TYPE = 'select';
export const SELECT_SEARCH_TYPE = 'select_match';
export const EMAIL_TYPE = 'email';
export const PHONE_TYPE = 'phone';
export const ZIP_TYPE = 'zip';
export const ADDRESS_TYPE = 'address';

export const FIELD_TYPE = {
    email: 'email',
    text: 'text',
    numberWithControls: 'numberWithControls',
    number: 'number',
    radio: 'radio',
    checkbox: 'checkbox',
    textarea: 'textarea',
    password: 'password',
    select: 'select',
    file: 'file',
    multi: 'multi',
    date: 'date',
    time: 'time',
    tel: 'tel',
    dateTime: 'datetime-local',
    submit: 'submit',
    button: 'button',
    selectSearch: 'select_match',
    phone: 'phone',
    zip: 'zip',
    address: 'address'
};

export const ENTER_KEY_CODE = 13;
export const FIELD_RADIO_NONE = 'none';

export default FIELD_TYPE;
