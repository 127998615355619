/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author      Gela Gzirishvili <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

const getPartnerFields = () => new Field('partners').addFieldList([
    'url',
    'mandatory_fields',
    'optional_fields',
    'partner_image',
    'isMembershipImage'
]);

const getPartnersField = () => [
    'is_enabled',
    getPartnerFields()
];

const getPartnerField = () => [new Field('partnerships').addFieldList(getPartnersField())];

export const addPartnersToQuery = (args, callback) => [
    ...callback(...args),
    ...getPartnerField()
];

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addPartnersToQuery
        }
    }
};
