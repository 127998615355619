/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_EVENT_DATA } from './Event.action';

/** @namespace Scandipwa/Store/Event/Reducer/getInitialState */
export const getInitialState = () => ({
    eventDataArray: []
});

/** @namespace Scandipwa/Store/Event/Reducer/EventDataReducer */
export const EventDataReducer = (
    state = getInitialState(),
    action
) => {
    const { type, eventData = {} } = action;
    const { eventDataArray } = state;

    switch (type) {
    case UPDATE_EVENT_DATA:
        return {
            ...state,
            eventDataArray: [
                ...eventDataArray,
                eventData
            ]
        };
    default:
        return state;
    }
};

export default EventDataReducer;
