/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './CheckoutComApplePay.style';

/** @namespace Checkoutcom/Component/CheckoutComApplePay/Component */
export class CheckoutComApplePayComponent extends PureComponent {
    /**
     * Props
     * @type {*}
     */
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        cartTotals: PropTypes.shape({
            grand_total: PropTypes.number,
            quote_currency_code: PropTypes.string
        }).isRequired
    };

    /**
     * Render
     * @returns {null|*}
     */
    render() {
        const {
            isLoading,
            applePayButtonClass,
            applePayDisabled,
            handleApplePayButtonClick
        } = this.props;

        return (
            <div block="CheckoutComApplePayPayment" elem="Wrapper">
                <Loader isLoading={ isLoading } />
                <button
                  type="button"
                  block="CheckoutComApplePayPayment"
                  elem="Button"
                  onClick={ handleApplePayButtonClick }
                  disabled={ applePayDisabled }
                  className={ applePayButtonClass }
                >
                    <div block="ApplePayIcon" />
                    <div block="ApplePayText">{ __('Pay') }</div>
                </button>
            </div>
        );
    }
}

export default CheckoutComApplePayComponent;
