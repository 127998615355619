/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import { CartItemPrice as SourceCartItemPrice } from 'SourceComponent/CartItemPrice/CartItemPrice.component';
import { isArabic } from 'Util/Arabic';
import { formatCurrency, roundPrice } from 'Util/Price';

import '../ProductPrice/ProductPrice.style';

/** @namespace Scandipwa/Component/CartItemPrice/Component */
export class CartItemPriceComponent extends SourceCartItemPrice {
    static propTypes = {
        ...SourceCartItemPrice.propTypes,
        regularPriceValue: PropTypes.number.isRequired,
        isDiscounted: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...super.defaultProps,
        regularPriceValue: 0,
        isDiscounted: false
    };

    renderFinalPrice() {
        const { finalProductPrice, currency_code } = this.props;
        const price = roundPrice(finalProductPrice);

        if (isArabic()) {
            return (
                <span aria-label={ __('Current product price') }>
                    <data value={ price }>{ `${ price } ${ formatCurrency(currency_code) }` }</data>
                </span>
            );
        }

        return (
            <span aria-label={ __('Current product price') }>
                <data value={ price }>{ `${ formatCurrency(currency_code) } ${ price }` }</data>
            </span>
        );
    }

    renderHighPrice() {
        const {
            currency_code,
            regularPriceValue,
            isDiscounted,
            quantity
        } = this.props;

        if (!isDiscounted) {
            return null;
        }

        const price = roundPrice(regularPriceValue * quantity);

        if (isArabic()) {
            return (
                <span block="ProductPrice" elem="HighPrice" mods={ { isVisible: true } }>
                    <data value={ regularPriceValue }>
                        { /* eslint-disable-next-line max-len */ }
                        { `${ price } ${ formatCurrency(currency_code) }` }
                    </data>
                </span>
            );
        }

        return (
            <span block="ProductPrice" elem="HighPrice" mods={ { isVisible: true } }>
                <data value={ regularPriceValue }>
                    { /* eslint-disable-next-line max-len */ }
                    { `${ formatCurrency(currency_code) }     ${ price }` }
                </data>
            </span>
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                { this.renderFinalPrice() }
                { this.renderHighPrice() }
            </div>
        );
    }
}

export default CartItemPriceComponent;
