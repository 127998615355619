/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';

import { DEFAULT_COUNTRY_UAE } from 'Component/Html/Html.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import {
    STORE_CODE_KSA_AR,
    STORE_CODE_UAE
} from 'Component/Router/Router.config';
import { HistoryType } from 'Type/Router.type';
import browserHistory from 'Util/History';
import { hash } from 'Util/Request/Hash';

import './common.style';
import './feature-grid.style';
import './feature-hero.style';
import './full-bleed-hero.style';
import './global-promo-banner.style';
import './info-hotspot-hero.style';
import './mixed-content-list.style';
import './navigation-promo-banner.style';
import './product-carousel.style';
import './product-group-features.style';
import './product-hotspot-hero.style';
import './side-by-side.style';
import './split-hero.style';
import './trending-inline.style';
import './video.style';
import './promo-tile.style';
import './banners-pop-up.style.scss';
import './text-block.style.scss';
import './contact-block.style.scss';

export const FullBleedHeroCounter = lazy(() => import('Component/FullBleedHeroCounter'));
export const ExpandableContent = lazy(() => import('Component/ExpandableContent'));
export const VideoCarouselItem = lazy(() => import('Component/VideoCarouselItem'));
export const VideoCarousel = lazy(() => import('Component/VideoCarousel'));
export const FullBleedHeroCarousel = lazy(() => import('Component/FullBleedHeroCarousel'));
export const TrendingInlineGroupCarousel = lazy(() => import('Component/TrendingInlineGroupCarousel'));
export const ProductCarousel = lazy(() => import('Component/ProductCarousel'));
export const SubscriptionBlockFormCMS = lazy(() => import('Component/SubscriptionBlockFormCMS'));

/** @namespace Scandipwa/Component/Html/Component */
export class HtmlComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        customComponents: PropTypes.object.isRequired,
        history: HistoryType.isRequired,
        basePath: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
        defaultCountry: PropTypes.string.isRequired,
        workDays: PropTypes.string,
        timeFrom: PropTypes.string,
        timeTo: PropTypes.string,
        wppNumber: PropTypes.string,
        wppTextMessage: PropTypes.string,
        isSubscriptionBlockActive: PropTypes.bool.isRequired,
        timezone: PropTypes.string
    };

    static defaultProps = {
        workDays: '',
        timeFrom: '',
        timeTo: '',
        wppNumber: '',
        wppTextMessage: '',
        timezone: ''
    };

    createdOutsideElements = {};

    rules = [
        {
            query: { attribs: [{ class: 'text-block' }] },
            replace: this.replaceTextBlock
        },
        {
            query: { attribs: [{ class: 'affiliate-terms__ask-item' }] },
            replace: this.replaceJsAffiliateAsk
        },
        {
            query: { attribs: [{ class: 'locale-selector-language-link' }] },
            replace: this.replaceLanguageSwitcherLink
        },
        {
            query: { attribs: [{ class: 'accordion' }] },
            replace: this.replaceExpand
        },
        {
            query: { attribs: [{ class: 'contact-puma' }] },
            replace: this.replaceWorkingHours
        },
        {
            query: { attribs: [{ id: 'cms-subscription-block' }] },
            replace: this.replaceCmsSubscriptionBlock
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['div', 'span', 'li'] },
            replace: this.replaceCustomComponent
        },
        {
            query: { name: ['productlistcarousel'] },
            replace: this.replaceProductCarousel
        },
        {
            query: { name: ['counter'] },
            replace: this.replaceFullBleedHeroCounter
        },
        {
            query: { name: ['videocarousel'] },
            replace: this.replaceVideoCarousel
        },
        {
            query: { name: ['fullbleedherocarousel'] },
            replace: this.replaceFullBleedHeroCarousel
        },
        {
            query: { name: ['trendinginlinegroupcarousel'] },
            replace: this.replaceTrendingInlineGroupCarousel
        },
        {
            query: { name: ['videohero'] },
            replace: this.replaceVideoHero
        }
    ];

    parserOptions = {
        // eslint-disable-next-line consistent-return
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                } if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;
                return replace.call(this, domNode);
            }
        }
    };

    attributesToProps(attribs) {
        const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());

        const convertPropertiesToValidFormat = (properties) => Object.entries(properties)
            .reduce((validProps, [key, value]) => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value)) {
                    return { ...validProps, [toCamelCase(key)]: +value };
                }

                return { ...validProps, [toCamelCase(key)]: value };
            }, {});

        const properties = convertPropertiesToValidFormat(attribs);
        return attributesToProps(properties);
    }

    /**
     * Replace input.
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceInput({ attribs }) {
        return <input { ...attributesToProps(attribs) } />;
    }

    /**
     * Wrap table in container
     *
     * @param attribs
     * @param children
     * @returns {*}
     */
    wrapTable({ attribs, children }) {
        return (
            <div block="Table" elem="Wrapper">
                <table { ...attributesToProps(attribs) }>
                    { domToReact(children, this.parserOptions) }
                </table>
            </div>
        );
    }

    replaceStyle(elem) {
        const { children } = elem;

        if (!children || !children[0]) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }

        const elemHash = hash(children[0].data);

        if (this.createdOutsideElements[elemHash]) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }

        const style = document.createElement('style');

        if (children && children[0]) {
            style.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(style);
        this.createdOutsideElements[elemHash] = true;

        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    // eslint-disable-next-line consistent-return
    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link { ...attributesToProps({ ...attrs, to: href }) }>
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }
        }
    }

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs['data-ignore-replacement'] !== undefined) {
            return null;
        }

        if (attribs.src) {
            return <Image { ...attributes } />;
        }

        return null;
    }

    replaceCustomComponent({ attribs, children }) {
        const { customComponents, basePath } = this.props;
        const attributes = attributesToProps(attribs);
        const customComponent = (customComponents || {})[attributes.className];

        if (!basePath) {
            return null;
        }

        if (customComponent) {
            return customComponent(attributes, domToReact(children, this.parserOptions));
        }

        return null;
    }

    replaceWorkingHours({ children }) {
        const {
            wppNumber,
            wppTextMessage
        } = this.props;

        if (!this.checkIfShowNumber()) {
            return (
                <>
                    { domToReact(children, this.parserOptions) }
                </>
            );
        }

        return (
            <div
              block="Contact"
              elem="Block"
            >
                <p>
                    { domToReact(children, this.parserOptions) }
                    { wppTextMessage }
                    <a
                      block="Phone"
                      href={ `https://wa.me/${ this.createWhatsAppLink(wppNumber) }` }
                      target="_blank"
                      rel="noreferrer"
                    >
                        { wppNumber }
                    </a>
                </p>
            </div>
        );
    }

    replaceCmsSubscriptionBlock({ attribs }) {
        // Having to destruct this way because magento doesn't show attributes not starting with 'data-' in admin panel html cms content
        const {
            'data-header': header,
            'data-subheader': subHeader,
            'data-emarsys-source-code': emarsysSourceCode,
            'data-img-url': imgUrl
        } = attribs;

        return (
            <SubscriptionBlockFormCMS
              header={ header }
              subHeader={ subHeader }
              emarsysSourceCode={ emarsysSourceCode }
              imgUrl={ imgUrl }
            />
        );
    }

    createWhatsAppLink(number) {
        return number.replace(/\D/g, '').replace(/^0+/, '');
    }

    checkIfShowNumber() {
        const {
            workDays,
            timeFrom,
            timeTo,
            timezone
        } = this.props;

        const now = new Date();
        const nowLocal = timezone
            ? new Date(now.toLocaleString('en-US', { timeZone: timezone }))
            : now;
        const nowLocalTimestamp = nowLocal.getTime();
        const today = nowLocal.getDay();
        const dayArr = workDays.split(',').map(Number);
        const fromArr = timeFrom.split(',').map(Number);
        const toArr = timeTo.split(',').map(Number);

        const fromTime = new Date(nowLocalTimestamp).setHours(fromArr[0], fromArr[1], fromArr[2]);
        const toTime = new Date(nowLocalTimestamp).setHours(toArr[0], toArr[1], toArr[2]);

        return dayArr.includes(today) && (fromTime <= now.getTime() && toTime >= now.getTime());
    }

    replaceLanguageSwitcherLink({ attribs, children }) {
        const { locale, defaultCountry } = this.props;
        const { href } = attribs;

        // Replace locale links for language switcher UAE
        if (locale === STORE_CODE_UAE && defaultCountry === DEFAULT_COUNTRY_UAE) {
            return (
                <button
                  onClick={ () => {
                      browserHistory.push('/');
                      browserHistory.go(0);
                  } }
                  block="Link"
                  elem="LanguageSwitcher"
                >
                    { __('English') }
                </button>
            );
        }

        if (locale === STORE_CODE_KSA_AR) {
            return (
                <button
                  onClick={ () => {
                      browserHistory.push('/en/');
                      browserHistory.go(0);
                  } }
                  block="Link"
                  elem="LanguageSwitcher"
                >
                    { __('English') }
                </button>
            );
        }

        if (locale.includes('en')) {
            return (
                <button
                  onClick={ () => {
                      browserHistory.push('/ar/');
                      browserHistory.go(0);
                  } }
                  block="Link"
                  elem="LanguageSwitcher"
                >
                    { __('العربية') }
                </button>
            );
        }

        return (
            <button
              onClick={ () => {
                  browserHistory.push(href);
                  browserHistory.go(0);
              } }
              block="Link"
              elem="LanguageSwitcher"
            >
                { domToReact(children, this.parserOptions) }
            </button>
        );
    }

    replaceProductCarousel({ attribs }) {
        const { history } = this.props;

        const {
            productskuarray,
            productbuttonstyle,
            title,
            contenttitle,
            button,
            carouselid,
            datacomponentsubposition = null
        } = attribs;

        if (productskuarray.length === 0) {
            return null;
        }

        return (
            <ProductCarousel
              productSkuArray={ productskuarray.split(',') }
              productButtonStyle={ productbuttonstyle }
              title={ title }
              contentTitle={ contenttitle }
              button={ button }
              carouselId={ carouselid }
              history={ history }
              dataComponentSubPosition={ datacomponentsubposition }
            />
        );
    }

    replaceFullBleedHeroCounter({ attribs }) {
        const {
            counter_id,
            time_stamp,
            color,
            color_mobile,
            counter_alignment,
            counter_title
        } = attribs;

        return (
            <FullBleedHeroCounter
              counterId={ counter_id }
              timeStamp={ time_stamp }
              color={ color }
              mobileColor={ color_mobile }
              alignment={ counter_alignment }
              counterTitle={ counter_title }
            />
        );
    }

    replaceVideoHero({ attribs }) {
        const {
            id,
            videoid,
            options,
            title,
            text,
            desktoppreviewimage,
            mobilepreviewimage,
            desktopvideourl,
            desktopvideofile,
            mobilevideofile,
            mobilevideourl,
            contentTitle
        } = attribs;

        return (
            <VideoCarouselItem
              id={ id }
              title={ title }
              videoId={ videoid }
              options={ options }
              text={ text }
              desktopPreviewImage={ desktoppreviewimage }
              mobilePreviewImage={ mobilepreviewimage }
              desktopVideoUrl={ desktopvideourl }
              desktopVideoFile={ desktopvideofile }
              mobileVideoFile={ mobilevideofile }
              mobileVideoUrl={ mobilevideourl }
              contentTitle={ contentTitle }
            />
        );
    }

    replaceVideoCarousel({ attribs, children }) {
        const {
            id,
            title,
            text,
            autoplay,
            contentTitle
        } = attribs;

        return (
            <VideoCarousel
              id={ id }
              title={ title }
              text={ text }
              autoplay={ autoplay }
              contentTitle={ contentTitle }
            >
                { domToReact(children.filter((child) => child.name === 'videocarouselitem'), this.parserOptions) }
            </VideoCarousel>
        );
    }

    replaceFullBleedHeroCarousel({ attribs, children }) {
        const {
            id,
            title,
            text,
            fbhc_auto_slide,
            fbhc_manual_slide,
            fbhc_auto_slide_interval,
            contentTitle
        } = attribs;

        return (
            <FullBleedHeroCarousel
              id={ id }
              title={ title }
              text={ text }
              fbhc_auto_slide={ fbhc_auto_slide }
              fbhc_manual_slide={ fbhc_manual_slide }
              fbhc_auto_slide_interval={ fbhc_auto_slide_interval }
              contentTitle={ contentTitle }
            >
                { domToReact(children.filter((child) => child.name === 'fullbleedherocarouselitem'),
                    this.parserOptions) }
            </FullBleedHeroCarousel>
        );
    }

    replaceTrendingInlineGroupCarousel({ attribs, children }) {
        const {
            id,
            tigc_auto_slide,
            tigc_manual_slide,
            tigc_auto_slide_interval,
            tigc_slide_anim_behavior,
            tigc_slide_anim_duration,
            group_items
        } = attribs;

        return (
            <TrendingInlineGroupCarousel
              id={ id }
              tigc_auto_slide={ tigc_auto_slide }
              tigc_manual_slide={ tigc_manual_slide }
              tigc_auto_slide_interval={ tigc_auto_slide_interval }
              tigc_slide_anim_behavior={ tigc_slide_anim_behavior }
              tigc_slide_anim_duration={ tigc_slide_anim_duration }
              group_items={ group_items }
            >
                { domToReact(children.filter((child) => child.name === 'div'),
                    this.parserOptions) }
            </TrendingInlineGroupCarousel>
        );
    }

    replaceExpand({ children }) {
        // Get heading from h3 tag = child 2
        const headingText = children[1].children[0].data;

        return (
            <ExpandableContent heading={ headingText }>
                { domToReact(children.slice(2), this.parserOptions) }
            </ExpandableContent>
        );
    }

    replaceJsAffiliateAsk({ children }) {
        if (children.length > 1) {
            const onClick = ({ target }) => {
                const parent = target.parentElement;
                const changeClassToOpenOrClosed = (classList) => {
                    const openClass = `${classList[0] }_open`;

                    if (!classList.contains(openClass)) {
                        classList.add(openClass);
                    } else {
                        classList.remove(openClass);
                    }
                };

                for (const child of parent.children) {
                    changeClassToOpenOrClosed(child.classList);
                }
            };

            return (
                <div
                  className="affiliate-terms__ask-item"
                >
                    <div
                      className="affiliate-terms__ask-item-title"
                      onClick={ onClick }
                    >
                        { children[1].children[0].data }
                    </div>
                    <div
                      block="affiliate"
                      elem="terms__ask-desc"
                    >
                        { children[3].children[0].data }
                    </div>
                </div>
            );
        }

        return (
            domToReact(children, this.parserOptions)
        );
    }

    replaceTextBlock({ children }) {
        const { content } = this.props;

        const onClick = ({ target }) => {
            const parent = target.parentNode;

            if (parent.classList.contains('expanded')) {
                parent.classList.remove('expanded');

                // eslint-disable-next-line no-param-reassign
                target.textContent = target.dataset.more;
            } else {
                parent.classList.add('expanded');

                // eslint-disable-next-line no-param-reassign
                target.textContent = target.dataset.less;
            }
        };

        let showMore = false; // eslint-disable-line fp/no-let

        try {
            showMore = children[1].attribs['data-showmore'] === '1';
        } catch (error) {
            // Do nothing here
        }

        const regex = new RegExp('id="([^"]*?)"');
        const id = content.match(regex);

        return (
            <div
              data-component-sub-position="1"
              className="promo-content-custom-class pagecontainer text-block"
              id={ id ? id[1] : null }
            >
                { domToReact(children, this.parserOptions) }
                { showMore && (
                    <span
                      className="text-block-link"
                      data-more={ __('Read more') }
                      data-less={ __('Read less') }
                      onClick={ onClick }
                    >
                        { __('Read more') }
                    </span>
                ) }
            </div>
        );
    }

    replaceScript() {
        // Will not inject script tags, components should be refactored to use React functions
    }

    render() {
        const { content } = this.props;

        if (!content) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                { parser(content, this.parserOptions) }
            </Suspense>
        );
    }
}

export default HtmlComponent;
