/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CheckoutPayment from 'Component/CheckoutPayment';

import './AdyenPayment.style';

/** @namespace Scandiweb/AdyenPayments/Component/AdyenPayment/Component */
export class AdyenPaymentComponent extends CheckoutPayment {
    render() {
        const {
            name,
            isSelected,
            method: {
                title,
                img_src
            },
            handlePaymentClick
        } = this.props;

        const hasImage = !!img_src;
        const label = name || title;

        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected, hasImage } }
                  elem="Button"
                  onClick={ handlePaymentClick }
                  type="button"
                >
                    { label }
                    { this.renderImage() }
                </button>
            </li>
        );
    }
}

export default AdyenPaymentComponent;
