/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Beshoy Samuel <info@scandiweb.com>
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const SWITCH_ITEMS_TYPE = 'SWITCH_ITEMS_TYPE';

export const TamaraGatewayReturn = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "contactpage" */ '../route/TamaraGatewayReturn')
);

/** @namespace Puma/Tamara/Plugin/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Puma/Tamara/Plugin/Component */
export class RouterComponentPlugin {
    /**
     * Router components
     * @param originalMember
     * @returns {*[]}
     */
    [SWITCH_ITEMS_TYPE] = (originalMember) => [
        ...originalMember,
        {
            component: <Route
              path={ withStoreRegex('/tamara/payment/:order_id/:type') }
              render={ () => <TamaraGatewayReturn /> }
            />,
            position: 120
        }
    ];
}

export const { [SWITCH_ITEMS_TYPE]: routes } = new RouterComponentPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: [
                {
                    position: 120,
                    implementation: routes
                }
            ]
        }
    }
};
