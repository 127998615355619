/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { GET_ORDER_LIST } from './Order.action';

export const ORDERS = 'orders';

/** @namespace Scandipwa/Store/Order/Reducer/getFormattedDate */
export const getFormattedDate = (rawDate = '') => {
    const date = new Date(rawDate.replace(/\s/, 'T'));
    const RADIX = 10;

    const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);

    return `${day}.${month}.${date.getFullYear()}`;
};

/** @namespace Scandipwa/Store/Order/Reducer/getFormattedTotal */
export const getFormattedTotal = (rawTotal = '') => rawTotal.toFixed(2);

/** @namespace Scandipwa/Store/Order/Reducer/formatOrders */
export const formatOrders = (orders) => orders.reduceRight((acc, order) => {
    const { order_date, total: { grand_total }, id: uid } = order;
    const formattedDate = getFormattedDate(order_date);
    const formattedTotal = getFormattedTotal(grand_total.value);

    return [
        ...acc,
        {
            ...order,
            id: atob(uid),
            created_at: formattedDate,
            grand_total: formattedTotal
        }
    ];
}, []);

export const orderList = BrowserDatabase.getItem(ORDERS) || [];

/** @namespace Scandipwa/Store/Order/Reducer/getInitialState */
export const getInitialState = () => ({
    orderList,
    isLoading: !orderList.length
});

/** @namespace Scandipwa/Store/Order/Reducer/OrderReducer */
export const OrderReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        orderList,
        status
    } = action;

    switch (type) {
    case GET_ORDER_LIST:
        const { items = [], page_info } = orderList;
        const formattedOrders = formatOrders(items);

        BrowserDatabase.setItem(formattedOrders, ORDERS, ONE_MONTH_IN_SECONDS);

        return {
            ...state,
            isLoading: status,
            orderList: { items: formattedOrders, pageInfo: page_info }
        };

    default:
        return state;
    }
};

export default OrderReducer;
