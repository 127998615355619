/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_EVENT_DATA = 'UPDATE_EVENT_DATA';

/** @namespace Scandipwa/Store/Event/Action/updateEventData */
export const updateEventData = (eventData) => ({
    type: UPDATE_EVENT_DATA,
    eventData
});
