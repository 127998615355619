/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AdyenPaymentsProvider } from '../context/AdyenPayments';

const addAdyenPaymentsContextProvider = (member) => [
    (children) => (
         <AdyenPaymentsProvider>
             { children }
         </AdyenPaymentsProvider>
    ),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addAdyenPaymentsContextProvider
        }
    }
};
